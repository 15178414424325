import "./index.scss";

const HeaderTitle = (props) => {
  const { title, extra, icon } = props;
  return (
    <div className="headerTitleContent">
      <div className="left">
        {icon ? (
          <span className="iconBox">{icon}</span>
        ) : (
          <span className="block"></span>
        )}
        <div className="title">{title}</div>
      </div>
      <div className="right"> {extra}</div>
    </div>
  );
};

export default HeaderTitle;
