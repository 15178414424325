import React, { useState, useRef, useEffect } from "react";
import { DatePicker, Tag } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const MyRangePicker = ({ onChange, defaultValue, time }) => {
  const today = new Date();
  const year = today.getFullYear();
  const m = today.getMonth() + 1;
  const month = m?.toString().padStart(2, "0"); // 月份从0开始，需要加1
  const day = today.getDate();
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const rangePickerRef = useRef(null);

  useEffect(() => {
    if (time) {
      setValue(time);
    } else {
      setValue([moment(), moment()]);
    }
  }, []);

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tomorrow = moment().add(1, "day").startOf("day"); // 明天的起始时间

    const tooLate = dates[0] && current.diff(dates[0], "days") >= 7;
    const tooEarly = dates[1] && dates[1].diff(current, "days") >= 7;
    const isFuture = current?.isAfter(tomorrow);
    if (time) {
      return isFuture;
    } else {
      return !!tooEarly || !!tooLate || isFuture;
    }
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const handleRangePickerChange = (val, time) => {
    setValue(val);
    if (val !== null) {
      onChange && onChange(val, time);
    } else {
      onChange && onChange([], []);
    }
  };
  const handleGetLastWeek = () => {
    const [start, end] = dates || [];
    const today = moment().startOf("day"); // 今天的起始时间
    const newEnd = moment(start).add(6, "days");
    if (start) {
      if (newEnd.isAfter(today)) {
        // 如果 newEnd 超过了今天，则将其设为今天
        setValue([start, today]);
        setDates([start, today]);
        onChange &&
          onChange(
            [start, today],
            [start.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")]
          );
      } else {
        setValue([start, newEnd]);
        setDates([start, newEnd]);
        onChange &&
          onChange(
            [start, newEnd],
            [start.format("YYYY-MM-DD"), newEnd.format("YYYY-MM-DD")]
          );
      }
    } else if (end) {
      const newEnd = moment(end).subtract(6, "days");

      setValue([newEnd, end]);
      setDates([newEnd, end]);

      onChange &&
        onChange(
          [newEnd, end],
          [newEnd.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")]
        );
    } else {
      const newEnd = moment(today).subtract(6, "days");
      setValue([newEnd, today]);
      setDates([newEnd, today]);

      onChange &&
        onChange(
          [newEnd, today],
          [newEnd.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")]
        );
    }
    rangePickerRef.current.blur();
  };
  return (
    <RangePicker
      ref={rangePickerRef}
      value={dates || value}
      disabledDate={disabledDate}
      onCalendarChange={(val) => {
        // console.log(val);
        setDates(val);
      }}
      onChange={handleRangePickerChange}
      onOpenChange={onOpenChange}
      allowClear={true}
      format="YYYY-MM-DD"
      style={{ width: "250px" }}
      showFooter={true}
      renderExtraFooter={() => (
        <Tag
          color="blue"
          style={{ cursor: "pointer" }}
          onClick={handleGetLastWeek}
        >
          获取最近一周
        </Tag>
      )}

      // presets={[
      //   {
      //     label: (
      //       <Tag
      //         color="blue"
      //         style={{ cursor: "pointer" }}
      //         onClick={handleGetLastWeek}
      //       >
      //         获取最近一周
      //       </Tag>
      //     ),
      //     value: [moment().subtract(7, "days"), moment()],
      //   },
      // ]}
    />
  );
};

export default MyRangePicker;
