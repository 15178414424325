import "./index.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  PlusCircleFilled,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MinusCircleOutlined,
  VerticalAlignTopOutlined,
  VerticalAlignBottomOutlined,
  PlusCircleOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import MyEmpty from "../../../../../components/Empty";
import {
  Form,
  Input,
  Table,
  Checkbox,
  Tooltip,
  Select,
  message,
  Badge,
  InputNumber,
} from "antd";
import update from "immutability-helper";
import DropdownSvg from "../../../../../assets/下拉.svg";
import LoadingSvg from "../../../../../assets/loading.svg";
import { ReactSVG } from "react-svg";
import projectApi from "../../../../../apis/project";
import EleStandardDrawer from "../EleStandardDrawer";
import MessageModal from "../../../../../components/MessageModal";

const { Option } = Select;

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  colNameList,
  MouseEnter,
  handleSave,
  elementData,
  getElePublishedList,
  setStatus,
  setChiName,
  isDuplicateColName,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  const handleInputBlur = async (e) => {
    if (isValueChanged(e.target.value)) {
      // 修改的时候将前面的状态取消为null
      await setStatus(record?.col_desc);
      await setChiName(e.target.value);
      await getElePublishedList(e.target.value, record);
    }
    save();
  };

  const isValueChanged = (value) =>
    record.col_desc === "" || record?.col_desc !== value;

  // 可编辑类型判断
  const contentType = (title) => {
    if (title === "中文名") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: "请输入中文名，不超过100个字符",
            },
            {
              pattern: /^^(.|\n){0,100}$/,
              message: "中文名不超过100个字符",
            },
          ]}
        >
          <Input
            ref={inputRef}
            onPressEnter={handleInputBlur}
            onBlur={handleInputBlur}
          />
        </Form.Item>
      );
    }

    if (title === "字段名") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: "请输入字段名，不超过63个字符",
            },
            {
              pattern: /^^(.|\n){0,63}$/,
              message: "字段名不超过63个字符",
            },
          ]}
        >
          <Input
            ref={inputRef}
            onPressEnter={async (e) => {
              if (
                e.target.value !== "" &&
                record?.col_name !== e.target.value
              ) {
                await isDuplicateColName(e.target.value);
              }

              save();
            }}
            onBlur={async (e) => {
              if (
                e.target.value !== "" &&
                record?.col_name !== e.target.value
              ) {
                await isDuplicateColName(e.target.value);
              }
              save();
            }}
          />
        </Form.Item>
      );
    }

    if (title === "小数位") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <InputNumber
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            controls={false}
            disabled={
              record.col_type_code !== null &&
              record.col_type_code !== "NUMERIC"
            }
          />
        </Form.Item>
      );
    }
    if (title === "长度") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <InputNumber
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            controls={false}
            disabled={
              record.col_type_code !== null &&
              record.col_type_code !== "NUMERIC" &&
              record.col_type_code !== "VARCHAR"
            }
          />
        </Form.Item>
      );
    }

    if (title === "索引名称") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: "请输入索引名称，不超过64个字符",
            },
            {
              pattern: /^^(.|\n){0,64}$/,
              message: "索引名称不超过64个字符",
            },
          ]}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            disabled={record?.index_id}
            style={{ width: "300px" }}
          />
        </Form.Item>
      );
    }

    if (title === "字段类型") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: "请选择字段类型",
            },
          ]}
        >
          <Select
            placeholder="请选择"
            showSearch={true}
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            ref={inputRef}
            onChange={save}
            style={{ width: "160px" }}
            onMouseEnter={(e) => {
              form.setFieldsValue({
                col_type_code: record?.col_type_code,
              });
            }}
          >
            {stringType?.map((item) => {
              return (
                <Option value={item} key={item}>
                  {dicCodeInfoList?.DG_DATA_TYPE?.map((value) => value[item])}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      );
    }

    if (title === "字段") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Select
            placeholder="请选择"
            mode="multiple"
            maxTagCount="responsive"
            allowClear
            showSearch={true}
            ref={inputRef}
            disabled={record?.index_id}
            onBlur={save}
            onMouseEnter={(e) => {
              if (record?.index_id)
                form.setFieldsValue({
                  cols: record?.cols?.reduce(
                    (cur, pre) => [...cur, pre.col_name],
                    []
                  ),
                });
            }}
            onFocus={() =>
              form.setFieldsValue({
                cols: record?.cols?.reduce(
                  (cur, pre) => [...cur, pre.col_name],
                  []
                ),
              })
            }
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            style={{ width: "350px" }}
          >
            {colNameList?.map((item) => {
              return (
                <Option value={item} key={item}>
                  {item}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      );
    }
    if (title === "主键") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          valuePropName="checked"
        >
          <Checkbox
            ref={inputRef}
            onFocus={async () => {
              await form.setFieldsValue({
                pk_flag: !record.pk_flag,
              });
              await save();
            }}
          />
        </Form.Item>
      );
    }
    if (title === "不为空") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          valuePropName="checked"
        >
          <Checkbox
            ref={inputRef}
            onFocus={async () => {
              await form.setFieldsValue({
                not_null_flag: !record.not_null_flag,
              });
              await save();
            }}
          />
        </Form.Item>
      );
    }
    if (title === "自增") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          valuePropName="checked"
        >
          <Checkbox
            ref={inputRef}
            // onChange={save}
            disabled={
              record.col_type_code !== null &&
              record.col_type_code !== "INTEGER" &&
              record.col_type_code !== "BIGINT"
            }
            onFocus={async () => {
              await form.setFieldsValue({
                auto_inc_flag: !record.auto_inc_flag,
              });
              await save();
            }}
          />
        </Form.Item>
      );
    }
    if (title === "默认值" || title === "分区名" || title === "分区值") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      );
    }
    if (title === "唯一索引") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          valuePropName="checked"
        >
          <Checkbox
            ref={inputRef}
            onChange={save}
            disabled={record?.index_id}
            onFocus={async () => {
              await form.setFieldsValue({
                uniq_index_flag: !record.uniq_index_flag,
              });
              await save();
            }}
          />
        </Form.Item>
      );
    }
  };
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);
  // 字符串类型
  const stringType = dicCodeInfoList?.DG_DATA_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      let values = await form.validateFields();
      toggleEdit();
      if ("col_type_code" in values) {
        if (
          values.col_type_code !== "NUMERIC" &&
          values.col_type_code !== "VARCHAR"
        ) {
          values.col_length = null;
        }
        if (values.col_type_code !== "NUMERIC") {
          values.col_scale = null;
        }
        if (
          !(
            values.col_type_code === "INTEGER" ||
            values.col_type_code === "BIGINT"
          )
        ) {
          values.auto_inc_flag = false;
        }
      }
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      contentType(title)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 20,
          minHeight: 15,
          minWidth: 10,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

// 表格
const FieldTable = (props, ref) => {
  const {
    title,
    ddlTableList,
    ddlLoding,
    colNameList,
    columsSource,
    setColumsSource,
    indexSource,
    setIndexSource,
    partSource,
    setPartSource,
    ptnData, // 分区表格数据
    setDdlTableList,
    addNew, // 新增模型or修改模型
    modifyId, // 模型修改对应的id
    colLayerCode, // 表名对应数据分层
    colsubCode, // 表名对应主题域
    taleName, // 表名
  } = props;

  const [elementData, setElementData] = useState(null);
  const [ChiName, setChiName] = useState("");

  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);

  // 字符串类型
  const stringType = dicCodeInfoList?.DG_DATA_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  // 获取数据元标准
  const getElePublishedList = (value, oldvalue) => {
    if (value === "") {
      return;
    }
    projectApi
      .getElePublishedList({ key_word: value }, { orders: [] })
      .then((res) => {
        if (res.code === 200) {
          if (res?.data?.total !== 0) {
            setElementData(res.data.items);
            EleStandardDrawer({
              maskClosable: true,
              noBtn: false,
              title: "col_id" in oldvalue ? "修改数据元" : "新增数据元",
              elementData: res.data.items,
              columsSource,
              onOk: (data) => {
                // data是勾选的数据  value是输入的新值  oldvalue是原本的那一栏的数据
                addElementData(data, value, oldvalue);
              },
              onCancel: () => {
                // 处理没有选的时候的单独设置状态
                setNoSelectStatus(value, oldvalue);
              },
            });
          } else {
            setElementData([]);
          }
        } else {
          message.error("程序报错，请联系工作人员");
        }
      });
  };

  const [MouseEnter, setMouseEnter] = useState({});
  const [columsNumber, setColumnsNumber] = useState(0);
  const [indexNumber, setIndexNumber] = useState(0);
  const [partNumber, setPartNumber] = useState(0);
  const [partdata, setPartData] = useState({});

  const defaultColumns = [
    {
      title: "中文名",
      dataIndex: "col_desc",
      editable: true,
      render: (_, info) => (
        <>
          {Object.keys(info).includes("status") ? (
            <>
              <Badge
                color={
                  info?.status !== null
                    ? info?.status
                      ? "#61D4AD"
                      : "#FE545F"
                    : null
                }
              />
              <Tooltip
                title={
                  info?.status !== null ? (
                    info?.status ? (
                      <div>符合数据元标准</div>
                    ) : (
                      <div>不符合数据元标准</div>
                    )
                  ) : null
                }
              >
                {info?.col_desc}
              </Tooltip>
            </>
          ) : (
            <div> {info?.col_desc}</div>
          )}
        </>
      ),
    },
    {
      title: "字段名",
      dataIndex: "col_name",
      editable: true,
      render: (_, info) => info.col_name,
    },
    {
      title: "字段类型",
      dataIndex: "col_type_code",
      editable: true,
      width: "10%",
      render: (_, info) => {
        return (
          <Select
            placeholder="请选择"
            showSearch={true}
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            value={info?.col_type_code}
            style={{ width: "160px" }}
          >
            {stringType?.map((item) => {
              return (
                <Option value={item} key={item}>
                  {dicCodeInfoList?.DG_DATA_TYPE?.map((value) => value[item])}
                </Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: "主键",
      dataIndex: "pk_flag",
      width: "5%",
      editable: true,
      render: (_, record) => <Checkbox checked={record?.pk_flag} />,
    },
    {
      title: "不为空",
      dataIndex: "not_null_flag",
      width: "5%",
      editable: true,
      render: (_, record) => <Checkbox checked={record?.not_null_flag} />,
    },
    {
      title: "自增",
      dataIndex: "auto_inc_flag",
      width: "5%",
      editable: true,
      render: (_, record) => (
        <Checkbox
          checked={record?.auto_inc_flag}
          disabled={
            record.col_type_code !== "INTEGER" &&
            record.col_type_code !== "BIGINT" &&
            record.col_type_code !== null
          }
        />
      ),
    },

    {
      title: "长度",
      dataIndex: "col_length",
      editable: true,
      render: (_, info) => info.col_length,
      width: "6%",
    },
    {
      title: "小数位",
      dataIndex: "col_scale",
      editable: true,
      render: (_, info) => info.col_scale,
      width: "6%",
    },
    {
      title: "默认值",
      dataIndex: "def_value",
      editable: true,
      width: "8%",
    },
    {
      title: (
        <>
          <Tooltip title="新增">
            <PlusCircleOutlined
              className="iconStyle"
              onClick={() => {
                handleAdd("columns");
                setElementData(null);
              }}
            />
          </Tooltip>
          {/* <Tooltip
            title="上移"
            onClick={() => {
              if (selectedRowList.length === 0) {
                return;
              }
              // 计算需要移动的行数
              const moveCount = 1;
              // 获取当前表格数据
              const currentData = [...columsSource];

              selectedRowList.forEach((selectedRow) => {
                const index = currentData.findIndex(
                  (row) => row === selectedRow
                );
                if (index > -1) {
                  const newIndex = Math.max(0, index - moveCount);
                  // 移动数据
                  const movedRow = currentData.splice(index, 1)[0];
                  currentData.splice(newIndex, 0, movedRow);
                }
              });

              // 更新排序属性
              currentData.forEach((obj, index) => (obj.ord = index + 1));

              // 更新状态
              setColumsSource(currentData);
              setDdlTableList({ ...ddlTableList, columns: currentData });
              setSelectedRowList([]);
              setSelectedRowKeys([]);
            }}
          >
            <ArrowUpOutlined className="iconStyle" />
          </Tooltip>
          <Tooltip title="下移">
            <ArrowDownOutlined className="iconStyle" />
          </Tooltip>
          <Tooltip title="置顶">
            <VerticalAlignTopOutlined className="iconStyle" />
          </Tooltip>
          <Tooltip title="置底">
            <VerticalAlignBottomOutlined className="iconStyle" />
          </Tooltip> */}
        </>
      ),
      dataIndex: "operation",
      width: "12%",
      render: (_, record, index) => (
        <>
          {MouseEnter?.col_name === record.col_name ? (
            <>
              <Tooltip title="新增">
                <PlusCircleOutlined
                  onClick={() => handleAddOne(record, "columns")}
                  className="insideIconStyle"
                />
              </Tooltip>
              <Tooltip title="上移">
                <ArrowUpOutlined
                  className="insideIconStyle"
                  onClick={() => {
                    if (index === 0) {
                      return;
                    }
                    // 对列表项进行重新排序
                    const data = update(columsSource, {
                      $splice: [
                        [index, 1],
                        [index - 1, 0, record],
                      ],
                    });
                    data?.forEach((obj, index) => (obj.ord = index + 1));
                    setColumsSource(data);
                    setDdlTableList({ ...ddlTableList, columns: data });
                  }}
                />
              </Tooltip>

              <Tooltip title="下移">
                <ArrowDownOutlined
                  className="insideIconStyle"
                  onClick={() => {
                    if (index === columsSource.length - 1) {
                      return;
                    }
                    // 对列表项进行重新排序
                    const data = update(columsSource, {
                      $splice: [
                        [index, 1],
                        [index + 1, 0, record],
                      ],
                    });
                    data?.forEach((obj, index) => (obj.ord = index + 1));
                    setColumsSource(data);
                    setDdlTableList({ ...ddlTableList, columns: data });
                  }}
                />
              </Tooltip>
              <Tooltip title="置顶">
                <VerticalAlignTopOutlined
                  className="insideIconStyle"
                  onClick={() => {
                    if (index === 0) {
                      return;
                    }
                    // 对列表项进行重新排序
                    const data = update(columsSource, {
                      $splice: [
                        [index, 1],
                        [0, 0, record], // 置顶，将当前项移到数组开头
                      ],
                    });
                    data?.forEach((obj, index) => (obj.ord = index + 1));
                    setColumsSource(data);
                    setDdlTableList({ ...ddlTableList, columns: data });
                  }}
                />
              </Tooltip>

              <Tooltip title="置底">
                <VerticalAlignBottomOutlined
                  className="insideIconStyle"
                  onClick={() => {
                    if (index === columsSource.length - 1) {
                      return;
                    }
                    // 对列表项进行重新排序
                    const data = update(columsSource, {
                      $splice: [
                        [index, 1],
                        [columsSource.length, 0, record],
                      ],
                    });
                    data?.forEach((obj, index) => (obj.ord = index + 1));
                    setColumsSource(data);
                    setDdlTableList({ ...ddlTableList, columns: data });
                  }}
                />
              </Tooltip>

              <Tooltip title="删除">
                <MinusCircleOutlined
                  onClick={() => columsDelete(record.ord)}
                  className="insideIconStyle"
                />
              </Tooltip>
            </>
          ) : null}
        </>
      ),
    },
  ];
  const Indexcolumn = [
    {
      title: "索引名称",
      dataIndex: "index_name",
      editable: true,
    },
    {
      title: "字段",
      dataIndex: "cols",
      editable: true,
      width: "40%",
      render: (_, info) => {
        return (
          <Select
            placeholder="请选择"
            allowClear
            showSearch={true}
            mode="multiple"
            maxTagCount="responsive"
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            defaultValue={info?.cols?.reduce(
              (cur, pre) => [...cur, pre.col_name],
              []
            )}
            disabled={info?.index_id}
            style={{ width: "350px" }}
            options={colNameList?.map((item) => ({
              label: item,
              value: item,
            }))}
          ></Select>
        );
      },
    },
    {
      title: "唯一索引",
      dataIndex: "uniq_index_flag",
      editable: true,
      width: "10%",
      render: (_, record) => (
        <Checkbox
          checked={record.uniq_index_flag}
          disabled={record?.index_id}
        />
      ),
    },
    {
      title: (
        <PlusCircleFilled
          style={{
            fontSize: "18px",
            color: "#006AB2",
            marginTop: "8px",
          }}
          onClick={() => handleAdd("index")}
        />
      ),
      dataIndex: "operation",
      width: "9%",
      render: (_, record) =>
        indexSource?.length >= 1 ? (
          <>
            <Tooltip title="删除">
              <MinusCircleOutlined
                onClick={() => indexDelete(record.ord)}
                style={{
                  color: "#006AB2",
                  fontSize: "18px",
                }}
              />
            </Tooltip>
          </>
        ) : null,
    },
  ];
  const partitioncolumn = [
    {
      title: "分区名",
      dataIndex: "ptn_name",
      editable: true,
    },
    {
      title: "分区值",
      dataIndex: "ptn_value",
      editable: true,
    },
    {
      title: (
        <PlusCircleFilled
          style={{
            fontSize: "18px",
            color: "#006AB2",
            marginTop: "8px",
          }}
          onClick={() => handleAdd("partition")}
        />
      ),
      dataIndex: "operation",
      width: "9%",
      render: (_, record) =>
        partSource?.length >= 1 ? (
          <>
            <Tooltip title="删除">
              <MinusCircleOutlined
                onClick={() => handleDelete(record.key)}
                style={{
                  color: "#006AB2",
                  fontSize: "18px",
                }}
              />
            </Tooltip>
          </>
        ) : null,
    },
  ];

  useEffect(() => {
    if (title === "字段设计") {
      setColumsSource(ddlTableList?.columns);
      setColumnsNumber(ddlTableList?.columns?.length);
    }
    if (title === "索引") {
      setIndexSource(ddlTableList?.indexes);
      setIndexNumber(ddlTableList?.indexes?.length);
    }

    if (title === "分区") {
      setPartSource(ptnData);
      setPartNumber(ptnData?.length);
      setPartData(ddlTableList?.ptns[0]);
      if (ptnData === null) {
        setPartSource([]);
        setPartNumber(0);
      }
    }
  }, [ddlTableList]);

  useEffect(() => {
    if (elementData !== null && elementData.length == 0) {
      let data = [...columsSource];
      data?.map((item) => {
        if (ChiName === item?.col_desc) {
          item.status = false;
        } else if (
          Object.keys(item).includes("status") === false &&
          ChiName !== item?.col_desc
        ) {
          item.status = null;
        }
        return item;
      });
      setColumnsNumber(data.length);
      setColumsSource(data);
      setDdlTableList({ ...ddlTableList, columns: data });
    }
  }, [elementData]);

  // 数据元判断符不符合要求（单独添加数据元）
  const addElementData = (value, info, oldvalue) => {
    // value-手动添加的数据元 info是新的名字 oldvalue是改动的那条数据
    let data = [...columsSource];
    let mergedData = [];
    if (value?.length !== 0) {
      if ("col_id" in oldvalue) {
        const indexToUpdate = data.findIndex(
          (item) => item.col_name === oldvalue.col_name
        );
        if (indexToUpdate !== -1) {
          data[indexToUpdate].col_desc = value[0].col_desc;
          data[indexToUpdate].col_name = value[0].col_name;
          data[indexToUpdate].status = true;
          data[indexToUpdate].col_length = value[0].col_length;
          data[indexToUpdate].col_type_code = value[0].col_type_code;
          data[indexToUpdate].col_scale = value[0].col_scale;
          if (data[indexToUpdate].col_type_code !== "NUMERIC") {
            data[indexToUpdate].col_length = null;
            data[indexToUpdate].col_scale = null;
          }
          if (
            !(
              data[indexToUpdate].col_type_code === "INTEGER" ||
              data[indexToUpdate].col_type_code === "BIGINT"
            )
          ) {
            data[indexToUpdate].auto_inc_flag = false;
          }
        }
        mergedData = [...data];
      } else {
        data = data.filter((item) => item.col_desc !== oldvalue.col_desc);
        mergedData = [...data, ...value];
      }

      const sortedData = mergedData.map((item, index) => ({
        ...item,
        ord: index + 1,
      }));
      // console.log(sortedData);
      setColumnsNumber(sortedData.length);
      setColumsSource(sortedData);
      setDdlTableList({ ...ddlTableList, columns: sortedData });
      duplicateName(sortedData);
    } else {
      setNoSelectStatus(info, oldvalue);
    }
  };

  // 判断是否有重复的col_name给予提示
  const hasDuplicateColName = (columnsSource) => {
    const colNamesSet = new Set();
    const duplicateColNames = [];

    for (const column of columnsSource) {
      if (colNamesSet.has(column.col_name)) {
        duplicateColNames.push(column.col_name);
      } else {
        colNamesSet.add(column.col_name);
      }
    }

    if (duplicateColNames.length > 0) {
      return {
        flag: true,
        col_names: duplicateColNames.join("、 "),
      };
    } else {
      return {
        flag: false,
        col_names: null,
      };
    }
  };

  // 处理存在重复的col_name（多个）
  const duplicateName = (value) => {
    const result = hasDuplicateColName(value);
    if (result.flag) {
      MessageModal({
        type: "warning",
        description: `存在重复的字段名: ${result.col_names}`,
        title: "重复操作",
        okText: "确认",
        onOk: () => {},
      });
    }
  };
  // 处理存在重复的col_name（单个）
  const isDuplicateColName = (name) => {
    const result = columsSource.some((column) => column.col_name === name);
    if (result) {
      MessageModal({
        type: "warning",
        description: `存在重复的字段名: ${name}`,
        title: "重复操作",
        okText: "确认",
        onOk: () => {},
      });
    }
  };

  // 处理没有选的时候的单独设置状态（修改中文名会丢掉id的问题）
  const setNoSelectStatus = (value, oldvalue) => {
    // console.log(oldvalue);
    let data = [...columsSource];
    data = data.map((item) => {
      if (item.col_desc === oldvalue.col_desc) {
        item.col_desc = value;
        item.status = true;
        if ("col_id" in oldvalue) {
          item.col_id = oldvalue.col_id;
        }
      }
      return item;
    });

    setColumsSource(data);
    setDdlTableList({ ...ddlTableList, columns: data });
  };

  // 修改的时候讲前面的状态取消为null
  const setStatus = (value) => {
    let data = [...columsSource];
    data = data.map((item) => {
      if (item.col_desc === value) {
        item.status = null;
      }
      return item;
    });
    setColumsSource(data);
    setDdlTableList({ ...ddlTableList, columns: data });
  };

  const columsDelete = (key) => {
    const newData = columsSource.filter((item) => item.ord !== key);
    // 对列表项进行重新排序
    newData?.forEach((obj, index) => (obj.ord = index + 1));
    setColumsSource(newData);
    setColumnsNumber(columsNumber - 1);
    setDdlTableList({ ...ddlTableList, columns: newData });
  };
  const indexDelete = (key) => {
    const newData = indexSource.filter((item) => item.ord !== key);
    // 对列表项进行重新排序
    newData?.forEach((obj, index) => (obj.ord = index + 1));
    setIndexSource(newData);
    setIndexNumber(indexNumber - 1);
    setDdlTableList({ ...ddlTableList, indexes: newData });
  };
  const handleDelete = (key) => {
    const newData = partSource.filter((item) => item.key !== key);
    // 对列表项进行重新排序
    newData?.forEach((obj, index) => (obj.ord = index + 1));
    setPartSource(newData);
    setPartNumber(partNumber - 1);
    if (newData?.length !== 0) {
      let ptnData = newData?.reduce(
        (cur, pre) => [
          ...cur,
          { ptn_name: pre.ptn_name, ptn_value: pre.ptn_value },
        ],
        []
      );
      setDdlTableList({
        ...ddlTableList,
        ptns: [
          {
            ...partdata,
            ptn_cols: ptnData,
          },
        ],
      });
    }
  };

  // 点击单条数据后面的新增
  const handleAddOne = (record, key) => {
    if (key === "columns") {
      const newData = {
        ord: columsNumber + 1,
        col_name: ``,
        col_desc: "",
        col_length: null,
        col_scale: null,
        def_value: null,
        col_type_code: null,
        pk_flag: false,
        not_null_flag: false,
        auto_inc_flag: false,
      };
      const data = [...columsSource];

      const index = data.findIndex((item) => item.col_id === record.col_id);
      data.splice(index + 1, 0, newData);
      data.forEach((item, index) => {
        item.ord = index + 1;
      });
      setElementData(null);
      setColumnsNumber(columsNumber + 1);
      setColumsSource(data);
    }
  };
  // 点击整体新增按钮
  const handleAdd = (key) => {
    if (key === "columns") {
      const newData = {
        ord: columsNumber + 1,
        col_name: ``,
        col_desc: "",
        col_length: null,
        col_scale: null,
        def_value: null,
        col_type_code: null,
        pk_flag: false,
        not_null_flag: false,
        auto_inc_flag: false,
      };
      const newArr = [newData, ...columsSource];
      newArr.forEach((item, index) => {
        item.ord = index + 1;
      });
      setElementData(null);
      setColumnsNumber(columsNumber + 1);
      setColumsSource(newArr);
    }
    if (key === "index") {
      let tableName = "";
      if (colLayerCode !== "" && colsubCode !== "") {
        tableName = `${colLayerCode}_${colsubCode}_${taleName}__index${
          indexNumber + 1
        }`;
      }
      if (tableName.length > 64) {
        tableName = tableName.substring(tableName.length - 64);
      }
      const indexnewData = {
        ord: indexNumber + 1,
        index_name: tableName,
        cols: [],
        uniq_index_flag: false,
      };

      if (!addNew) {
        indexnewData.table_id = modifyId;
      }

      setIndexNumber(indexNumber + 1);
      setIndexSource([...indexSource, indexnewData]);
    }
    if (key === "partition") {
      const partnewData = {
        key: partNumber,
        ptn_name: ``,
        ptn_value: "",
      };
      setPartNumber(partNumber + 1);
      setPartSource([...partSource, partnewData]);
    }
  };

  const handleSave = (row) => {
    if (Object.keys(row)?.length > 5) {
      const newData = [...columsSource];
      const index = newData.findIndex((item) => row.ord === item.ord);
      const item = newData[index];

      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      // 对列表项进行重新排序
      newData?.forEach((obj, index) => (obj.ord = index + 1));
      setColumsSource(newData);
      setDdlTableList({ ...ddlTableList, columns: newData });
    }

    if (Object.keys(row)?.includes("index_name")) {
      let cols = [];
      if (row?.cols?.length === 0) {
        cols = row?.cols;
      }
      if (row?.cols?.length !== 0 && typeof row?.cols?.[0] == "string") {
        cols = row?.cols?.reduce(
          (cur, pre, index) => [...cur, { col_name: pre, ord: index + 1 }],
          []
        );
      } else {
        cols = row?.cols;
      }
      const rows = { ...row, cols };
      const indexnewData = [...indexSource];
      const i = indexnewData.findIndex((item) => row.ord === item.ord);
      const item = indexnewData[i];
      indexnewData.splice(i, 1, {
        ...item,
        ...rows,
      });
      setIndexSource(indexnewData);
      setDdlTableList({ ...ddlTableList, indexes: indexnewData });
    }

    if (Object.keys(row)?.includes("ptn_name")) {
      const partnewData = [...partSource];
      const i = partnewData.findIndex((item) => row.key === item.key);
      const item = partnewData[i];
      partnewData.splice(i, 1, {
        ...item,
        ...row,
      });
      setPartSource(partnewData);
      let ptnData = partnewData?.reduce(
        (cur, pre) => [
          ...cur,
          { ptn_name: pre.ptn_name, ptn_value: pre.ptn_value },
        ],
        []
      );
      setDdlTableList({
        ...ddlTableList,
        ptns: [
          {
            ...partdata,
            ptn_cols: ptnData,
          },
        ],
      });
    }
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        // modifyFlag,
        MouseEnter,
        elementData,
        getElePublishedList,
        handleSave,
        setChiName,
        setStatus,
        isDuplicateColName,
      }),
    };
  });

  const Indexcolumns = Indexcolumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        colNameList,
        MouseEnter,
        handleSave,
      }),
    };
  });
  const partitioncolumns = partitioncolumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        MouseEnter,
        handleSave,
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <div className="FieldTable">
      {title === "字段设计" ? (
        <Table
          className="columsInfo"
          components={components}
          // rowClassName={() => "editable-row"}
          // rowClassName={modifyFlag ? "" : "notCur"}
          dataSource={columsSource}
          columns={columns}
          pagination={false}
          rowKey={(record, index) => record.ord}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
          loading={
            ddlLoding
              ? {
                  indicator: (
                    <ReactSVG
                      src={LoadingSvg}
                      className="anticon-spin anticon myLoadingIcon"
                    />
                  ),
                }
              : false
          }
          onRow={(record) => {
            return {
              onMouseEnter: () => {
                setMouseEnter(record);
              },
              onMouseLeave: (event) => {
                setMouseEnter({});
              },
              // draggable: true,
              // style: { cursor: "move" },
              // onDragStart: (ev) => {
              //   ev.dataTransfer.effectAllowed = "move";
              //   ev.dataTransfer.setData(
              //     "text",
              //     ev.target.getAttribute("data-row-key")
              //   );
              // },
              // onDragEnter: (ev) => {
              //   const nodes = ev.target.parentNode.childNodes;
              //   nodes.forEach(
              //     (item) => (item.style.borderTop = "2px dashed #1890ff")
              //   );
              // },
              // onDragLeave: (ev) => {
              //   const nodes = ev.target.parentNode.childNodes;
              //   nodes.forEach((item) => (item.style.borderTop = ""));
              // },

              // onDrop: (ev) => {
              //   ev.preventDefault();
              //   ev.stopPropagation();
              //   const dragId = Number(ev.dataTransfer.getData("text"));
              //   const dropCol =
              //     ev.target.tagName !== "TR" ? ev.target.parentNode : ev.target;

              //   const dropId = Number(dropCol.getAttribute("data-row-key"));

              //   const dragIndex = columsSource?.findIndex(
              //     (item, index) => item.ord === dragId
              //   );
              //   const dropIndex = columsSource?.findIndex(
              //     (item, index) => item.ord === dropId
              //   );
              //   if (dragIndex === -1 || dropIndex === -1) {
              //     return;
              //   }
              //   const data = [...columsSource];
              //   const [dragItem] = data.splice(dragIndex, 1);

              //   data.splice(dropIndex, 0, dragItem);
              //   setColumsSource(data);
              //   dropCol.childNodes.forEach(
              //     (item) => (item.style.borderTop = "")
              //   );
              //   data.forEach((obj, index) => (obj.ord = index + 1));
              //   setColumsSource(data);
              //   setDdlTableList({ ...ddlTableList, columns: data });
              //   console.log(data);
              // },
              // onDragOver: (ev) => ev.preventDefault(),
            };
          }}
        />
      ) : null}
      {title === "索引" ? (
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          dataSource={indexSource}
          columns={Indexcolumns}
          rowKey={(record, index) => index}
          pagination={false}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
          loading={
            ddlLoding
              ? {
                  indicator: (
                    <ReactSVG
                      src={LoadingSvg}
                      className="anticon-spin anticon myLoadingIcon"
                    />
                  ),
                }
              : false
          }
        />
      ) : null}
      {title === "分区" ? (
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          dataSource={partSource}
          columns={partitioncolumns}
          pagination={false}
          rowKey={(record, index) => index}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
          loading={
            ddlLoding
              ? {
                  indicator: (
                    <ReactSVG
                      src={LoadingSvg}
                      className="anticon-spin anticon myLoadingIcon"
                    />
                  ),
                }
              : false
          }
        />
      ) : null}
    </div>
  );
};
export default FieldTable;
