import "./index.scss";
import { Table, Pagination, Badge, message } from "antd";
import { useRef, useState, useEffect, forwardRef } from "react";
import MyEmpty from "../../../../../components/Empty";
import projectApi from "../../../../../apis/project";
import MySpin from "../../../../../components/MySpin";
import LoadingSvg from "../../../../../assets/loading.svg";
import { ReactSVG } from "react-svg";
import GovReleaseDrawer from "../../../PackageGov/components/GovReleaseDrawer";
import DevDialogDrawer from "../../../ServiceDev/components/DialogDrawer";
import GovDetailDrawer from "../../../PackageGov/components/GovDetailDrawer";
import DevDetailDrawer from "../../../ServiceDev/components/DevDetailDrawer";
const ServiceCheckTable = (props) => {
  const {
    defaultData,
    tableName,
    changePagination,
    changeOrders,
    tableLoading,
    apiTableLoading,
    recallItem, // 撤回
    agreeItem, // 同意
    rejectItem, // 驳回
    portKey, // 判断排序和分页点击的是哪个表格
    setApiTableLoading,
    setTableLoading,
    getPkgAuditList,
    getApiAuditList,
  } = props;

  const serviceRefTable = useRef(null);
  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const data = defaultData?.items;
  const [ReleaseDrawerVisible, setReleaseDrawerVisible] = useState(false); // api发布模型
  const [RelDialogDrawerLoading, setRelDialogDrawerLoading] = useState(true); // api发布loading加载
  const [RelDefaultData, setRelDefaultData] = useState([]); // api编辑服务信息

  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false);
  const [devDefaultData, setDevDefaultData] = useState(null); // 编辑服务信息
  const [release, setRelease] = useState(false); // 提交审核判断

  const [DetailDrawerVisible, setDetailDrawerVisible] = useState(false); // 查看详情
  const [DetailDrawerLoading, setDetailDrawerLoading] = useState(false); // 查看详情loading
  const [DetailInfo, setDetailInfo] = useState({}); // 详情数据
  const [VersoList, setVersonList] = useState([]); // 详情版本列表
  const [agreeFlag, setAgreeFlag] = useState(false); //判断api详情是从页面还是审核页面打开

  const [devDetailDrawerVisible, setDevDetailDrawerVisible] = useState(false); // 查看详情
  const [devDetailDrawerLoading, setDevDetailDrawerLoading] = useState(false); // 查看详情loading
  const [devDetailInfo, setDevDetailInfo] = useState({}); // 详情数据
  const [devVersoList, setDevVersonList] = useState([]); // 详情版本列表
  const [devagreeFlag, setDevAgreeFlag] = useState(false); //判断api详情是从页面还是审核页面打开

  // 审核状态颜色切换
  const changeColor = (value) => {
    if (value === "IN_APPROVAL") {
      return "#FEC054";
    } else if (value === "APPROVED") {
      return "#61D4AD";
    } else if (value === "REJECTED") {
      return "#FE545F";
    } else if (value === "CANCELED") {
      return "#D8D8D8";
    }
  };

  useEffect(() => {
    if (tableName === "myapply") {
      setColumns([
        {
          title: "中文名",
          dataIndex: "chi_name",
          sorter: true,
        },
        {
          title: "英文名",
          dataIndex: "eng_name",
          sorter: true,
        },
        {
          title: "变更类型",
          dataIndex: "chg_type_code_value",
          sorter: true,
        },
        {
          title: "当前版本",
          dataIndex: "cur_ver_no",
        },
        {
          title: "流程状态",
          dataIndex: "appr_stat_code_value",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.appr_stat_code)} />
                {info.appr_stat_code_value}
              </span>
            );
          },
        },
        {
          title: "申请时间",
          dataIndex: "apply_time",
          sorter: true,
        },
        {
          title: "版本更新说明",
          dataIndex: "rn",
        },
        {
          title: "审核人",
          dataIndex: "apprd_by_id_value",
        },
        {
          title: "审核时间",
          dataIndex: "apprd_time",
        },
        {
          title: "审核意见",
          dataIndex: "apprd_opn",
        },
        {
          title: "操作",
          width: "8%",
          dataIndex: "操作",
          render: (_, record) => {
            return (
              <div className="operation" key={record.key}>
                {record?.appr_stat_code === "IN_APPROVAL" ||
                record?.appr_stat_code === "REJECTED" ? (
                  <span
                    style={{
                      color: "#006AB2",
                      marginRight: "16px",
                    }}
                    onClick={() => recallItem(record, portKey)}
                  >
                    撤回
                  </span>
                ) : null}

                {record?.appr_stat_code === "REJECTED" ? (
                  <span
                    style={{
                      color: "#006AB2",
                    }}
                    onClick={() => {
                      portKey === "pkg"
                        ? subReview(record)
                        : submitCheck(record);
                    }}
                  >
                    重新提交
                  </span>
                ) : null}
              </div>
            );
          },
        },
      ]);
    } else if (tableName === "ToCheck") {
      setColumns([
        {
          title: "中文名",
          dataIndex: "chi_name",
          sorter: true,
        },
        {
          title: "英文名",
          dataIndex: "eng_name",
          sorter: true,
        },
        {
          title: "变更类型",
          dataIndex: "chg_type_code_value",
          sorter: true,
        },
        {
          title: "当前版本",
          dataIndex: "cur_ver_no",
        },
        {
          title: "申请人",
          dataIndex: "apply_by_id_value",
        },
        {
          title: "申请时间",
          dataIndex: "apply_time",
          sorter: true,
        },
        {
          title: "版本更新说明",
          dataIndex: "rn",
        },
        {
          title: "操作",
          width: "12%",
          dataIndex: "操作",
          render: (_, record) => {
            return (
              <div className="operation" key={record.key}>
                <span
                  style={{
                    color: "#006AB2",
                    marginRight: "16px",
                  }}
                  onClick={() => {
                    portKey === "pkg"
                      ? getDetailInfo(null, record, true)
                      : getdevDetailInfo(null, record, true);
                  }}
                >
                  详情
                </span>
                <span
                  style={{
                    color: "#006AB2",
                    marginRight: "16px",
                  }}
                  onClick={() => agreeItem(record, portKey)}
                >
                  同意
                </span>
                <span
                  style={{
                    color: "#006AB2",
                  }}
                  onClick={() => rejectItem(record, portKey)}
                >
                  拒绝
                </span>
              </div>
            );
          },
        },
      ]);
    } else if (tableName === "check") {
      setColumns([
        {
          title: "中文名",
          dataIndex: "chi_name",
          sorter: true,
        },
        {
          title: "英文名",
          dataIndex: "eng_name",
          sorter: true,
        },
        {
          title: "变更类型",
          dataIndex: "chg_type_code_value",
          sorter: true,
        },
        {
          title: "发布版本",
          dataIndex: "rls_ver_no",
        },
        {
          title: "流程状态",
          dataIndex: "appr_stat_code_value",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.appr_stat_code)} />
                {info.appr_stat_code_value}
              </span>
            );
          },
        },
        {
          title: "申请人",
          dataIndex: "apply_by_id_value",
        },
        {
          title: "申请时间",
          dataIndex: "apply_time",
          sorter: true,
        },
        {
          title: "版本更新说明",
          dataIndex: "rn",
        },
        {
          title: "审核人",
          dataIndex: "apprd_by_id_value",
        },
        {
          title: "审核时间",
          dataIndex: "apprd_time",
        },
        {
          title: "审核意见",
          dataIndex: "apprd_opn",
        },
      ]);
    }
    setTotal(defaultData?.total);
    setPageNumber(defaultData?.page);
    setPageSize(defaultData?.size);
  }, [defaultData]);

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize, portKey);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  // 改变表格排序
  const tableChange = (pagination, filters, sorter, extra) => {
    if (sorter.order !== undefined) {
      changeOrders(sorter, portKey);
    }
  };

  // 获取api版本
  const getPkgVerNoList = (id) => {
    projectApi.getPkgVerNoList(id).then((res) => {
      if (res.code === 200) {
        setVersonList([{ api_pkg_ver_id: 0, ver_no: "当前" }, ...res.data]);
      } else {
        setVersonList([{ api_pkg_ver_id: 0, ver_no: "当前" }]);
      }
    });
  };

  // 获取api详情的信息
  const getDetailInfo = (id, DataInfo, flag) => {
    if (flag) {
      getPkgVerNoList(DataInfo.api_pkg_id);
    }
    setDetailDrawerLoading(true);
    setAgreeFlag(true);
    setDetailDrawerVisible(true);
    let params = {
      api_pkg_id: DataInfo.api_pkg_id,
    };
    if (id !== null) {
      params.ver_no = id;
    }
    projectApi.getPkgDetail(params).then((res) => {
      if (res.code === 200) {
        setDetailInfo({ ...DataInfo, ...res.data });
        setDetailDrawerLoading(false);
      } else {
        setDetailInfo({});
        setDetailDrawerLoading(false);
      }
    });
  };

  // 获取数据服务版本
  const getSerVerNoList = (id) => {
    projectApi.getSerVerNoList(id).then((res) => {
      if (res.code === 200) {
        setDevVersonList(res.data);
      } else {
        setDevVersonList([]);
      }
    });
  };

  // 查看数据服务详情信息
  const getdevDetailInfo = (id, Info, flag) => {
    if (flag) {
      getSerVerNoList(Info?.api_id);
    }
    let params = {
      api_id: Info.api_id,
    };
    if (id !== null) {
      params.ver_no = id;
    }
    setDevDetailDrawerLoading(true);
    setDevDetailDrawerVisible(true);
    setDevAgreeFlag(true);
    projectApi.getSerDetail(params).then((res) => {
      if (res.code === 200) {
        setDevDetailInfo({ ...res.data, ...Info });
        setDevDetailDrawerLoading(false);
      } else {
        setDevDetailInfo({});
        setDevDetailDrawerLoading(false);
      }
    });
  };

  // api确认提交审核
  const subReview = (data) => {
    setRelDefaultData([data]);
    setReleaseDrawerVisible(true);
  };

  // api发布审核
  const releaseDrawerSuccess = (data) => {
    projectApi.reviewApiPkg(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setReleaseDrawerVisible(false);
        setApiTableLoading(true);
        getPkgAuditList({
          appr_stat_code: "REJECTED",
          order_list: ["updated_time", "desc"].toString(),
        });
      } else {
        message.error(res.message);
      }
    });
  };

  //服务提交审核
  const submitCheck = (data) => {
    setDevDefaultData([data]);
    setDialogDrawerVisible(true);
    setRelease(true);
  };

  // 服务发布审核
  const dialogDrawerSuccess = (rn, data) => {
    projectApi.reviewPkg(rn, data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setTableLoading(true);
        setDialogDrawerVisible(false);
        getApiAuditList({
          appr_stat_code: "REJECTED",
          order_list: ["updated_time", "desc"].toString(),
        });
      } else {
        message.error(res.message);
      }
    });
  };

  return (
    <div className="ServiceCheckTable">
      {tableLoading ? (
        <MySpin></MySpin>
      ) : (
        <div className="tableContent" ref={serviceRefTable}>
          <Table
            className="myFormTable"
            pagination={false}
            columns={columns}
            dataSource={data}
            rowKey={(record, id) => id}
            onChange={tableChange}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
            style={{ marginBottom: "8px" }}
            loading={
              portKey === "pkg"
                ? apiTableLoading
                : tableLoading
                ? {
                    indicator: (
                      <ReactSVG
                        src={LoadingSvg}
                        className="anticon-spin anticon myLoadingIcon"
                      />
                    ),
                  }
                : false
            }
          />
          {total >= 10 ? (
            <div className="paginationBox">
              <Pagination
                className="myPagination"
                showQuickJumper
                total={total}
                current={pageNumber}
                pageSize={pageSize}
                onChange={onChange}
                pageSizeOptions={[10, 20, 30, 40]}
                showTotal={(total) => `共 ${total} 条`}
                showSizeChanger={true}
              />
            </div>
          ) : null}
        </div>
      )}

      <GovReleaseDrawer
        onOk={releaseDrawerSuccess}
        visible={ReleaseDrawerVisible}
        onCancel={() => {
          setReleaseDrawerVisible(false);
        }}
        defaultData={RelDefaultData}
      ></GovReleaseDrawer>

      <DevDialogDrawer
        drawerTitle={"提交发布"}
        okText={"提交发布"}
        cancelText="取消"
        onOk={dialogDrawerSuccess}
        visible={DialogDrawerVisible}
        onCancel={() => {
          setDialogDrawerVisible(false);
        }}
        submitCheckData={devDefaultData}
        release={release}
      ></DevDialogDrawer>

      <GovDetailDrawer
        VersoList={VersoList}
        visible={DetailDrawerVisible}
        defaultData={DetailInfo}
        onCancel={() => {
          setDetailDrawerVisible(false);
        }}
        loading={DetailDrawerLoading}
        agreeFlag={agreeFlag}
        agreeItem={agreeItem}
        rejectItem={rejectItem}
        getDetailInfo={getDetailInfo}
      ></GovDetailDrawer>

      <DevDetailDrawer
        VersoList={devVersoList}
        visible={devDetailDrawerVisible}
        defaultData={devDetailInfo}
        onCancel={() => {
          setDevDetailDrawerVisible(false);
        }}
        loading={devDetailDrawerLoading}
        agreeFlag={devagreeFlag}
        agreeItem={agreeItem}
        rejectItem={rejectItem}
        getDetailInfo={getdevDetailInfo}
      ></DevDetailDrawer>
    </div>
  );
};

export default forwardRef(ServiceCheckTable);
