import "./index.scss";
import { Table, Pagination, message } from "antd";
import { useRef, useState, useEffect } from "react";
import MyEmpty from "../../../../../components/Empty";
import projectApi from "../../../../../apis/project";
import LoadingSvg from "../../../../../assets/loading.svg";
import MessageModal from "../../../../../components/MessageModal";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import DialogDrawer from "../DialogDrawer";

const DetailTable = (props) => {
  const {
    changePagination,
    responsiblePersonList,
    tableLoading,
    layerList,
    subId,
    ManagementList, // 管理责任人列表
    getResponsiblePersonList, // 更新表格数据
    setTableLoading,
  } = props;

  // 数据分层主题域映射
  const layerSubList = useSelector((state) => state.Project.layerSubList);

  const data = responsiblePersonList?.items;

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);

  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [addNew, setAddNew] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTotal(responsiblePersonList?.total);
  }, [responsiblePersonList]);

  const Columns = [
    {
      title: "数据分层",
      dataIndex: "data_layer_id",
      render: (data, info) => {
        return (
          <div>
            {layerSubList !== undefined
              ? Object.values(layerSubList?.DATA_LAYER_RLS).find(
                  (item) => item?.data_layer_id === info?.data_layer_id
                )?.data_layer_name
              : info?.data_layer_id}
          </div>
        );
      },
    },
    {
      title: "主题域",
      dataIndex: "sub_id",
      render: (data, info) => {
        return (
          <div>
            {layerSubList !== undefined
              ? Object.values(layerSubList?.SUB_RLS).find(
                  (item) => item?.sub_id === info?.sub_id
                )?.sub_name
              : info?.sub_id}
          </div>
        );
      },
    },
    {
      title: "管理责任人",
      dataIndex: "da_rp_id_value",
    },
    {
      title: "操作",
      dataIndex: "",
      width: "12%",
      render: (_, record) => (
        <div className="operation">
          <span
            className="modify"
            style={{
              color: "#006AB2",
              marginRight: "10px",
            }}
            onClick={() => {
              setDialogDrawerVisible(true);
              setDefaultData(record);
              setAddNew(false);
              setLoading(true);
            }}
          >
            修改
          </span>
          <span
            className="delete"
            style={{
              color: "#006AB2",
            }}
            onClick={() => handleDelete(record?.asset_sub_auth_id)}
          >
            删除
          </span>
        </div>
      ),
    },
  ];

  const handleDelete = (key) => {
    MessageModal({
      type: "warning",
      description: "确定要删除管理责任人吗？",
      title: "删除管理责任人",
      okText: "删除",
      onOk: () => {
        projectApi.deleteResponsiblePersonList(key).then((res) => {
          if (res.code === 200) {
            setTableLoading(true);
            message.success(res.message);
            getResponsiblePersonList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  // 修改管理责任人
  const dialogDrawerSuccess = (value) => {
    projectApi
      .updateResponsiblePerson({
        asset_sub_auth_id: value?.asset_sub_auth_id,
        sub_id: value?.sub_id,
        data_layer_id: value?.data_layer_id,
        da_rp_id: value?.da_rp_id,
      })
      .then((res) => {
        if (res.code === 200) {
          setTableLoading(true);
          message.success(res.message);
          getResponsiblePersonList();
          setDialogDrawerVisible(false);
        } else {
          message.error(res.message);
        }
      });
  };

  return (
    <div className="AssetTableContent">
      <div className="tableContent">
        <Table
          className="myFormTable"
          pagination={false}
          columns={Columns}
          dataSource={data}
          rowKey={(record) => record?.asset_sub_auth_id}
          scroll={{
            y: "calc(100vh - 600px)",
          }}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
          loading={
            tableLoading
              ? {
                  indicator: (
                    <div style={{ background: "#fff" }}>
                      <ReactSVG
                        src={LoadingSvg}
                        className="anticon-spin anticon myLoadingIcon"
                      />
                    </div>
                  ),
                }
              : false
          }
        />
        {total >= 10 ? (
          <div className="paginationBox">
            <Pagination
              className="myPagination"
              showQuickJumper
              total={total}
              current={pageNumber}
              pageSize={pageSize}
              pageSizeOptions={[10, 20, 50, 100]}
              onChange={onChange}
              showTotal={(total) => `共 ${total} 条`}
              showSizeChanger={true}
            />
          </div>
        ) : null}
      </div>
      <DialogDrawer
        drawerTitle="修改管理责任人"
        onOk={dialogDrawerSuccess}
        visible={DialogDrawerVisible}
        onCancel={() => {
          setDialogDrawerVisible(false);
        }}
        layerList={layerList}
        subId={subId}
        addNew={addNew}
        ManagementList={ManagementList}
        defaultData={defaultData}
        loading={loading}
        setLoading={setLoading}
      ></DialogDrawer>
    </div>
  );
};
export default DetailTable;
