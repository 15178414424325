import { Badge, Tooltip, Checkbox } from "antd";
import {
  CloseCircleOutlined,
  FormOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";

import greyIcon from "../../../../../assets/文件夹灰.svg";
import yellowIcon from "../../../../../assets/文件夹黄.svg";
import blueIcon from "../../../../../assets/文件夹蓝.svg";
import greenIcon from "../../../../../assets/文件夹绿.svg";
const PackageGovCard = (props) => {
  const {
    defaultData,
    DeleteItem, // 删除api服务包
    modelHandle,
    subReview, // 提交审核
    getDetailInfo, // 获取详情信息
  } = props;

  // img src切换
  const changeImage = (value) => {
    if (value === "TO_BE_RELEASED") {
      return blueIcon;
    } else if (value === "IN_APPROVAL") {
      return yellowIcon;
    } else if (value === "RELEASED") {
      return greenIcon;
    } else if (value === "DISCARDED") {
      return greyIcon;
    }
  };

  // 流程状态颜色切换
  const changeColor = (value) => {
    if (value === "TO_BE_RELEASED") {
      return (
        <>
          <Badge color="#6CAEFF" /> 待发布
        </>
      );
    } else if (value === "IN_APPROVAL") {
      return (
        <>
          <Badge color="#FEC054" />
          审核中
        </>
      );
    } else if (value === "RELEASED") {
      return (
        <>
          <Badge color="#61D4AD" />
          已发布
        </>
      );
    } else if (value === "DISCARDED") {
      return (
        <>
          <Badge color="#D8D8D8" />
          已废弃
        </>
      );
    }
  };

  return (
    <div className="cardItem">
      <div className="leftItem">
        <Checkbox
          value={defaultData}
          disabled={defaultData?.rls_stat_code !== "TO_BE_RELEASED"}
        ></Checkbox>
      </div>
      <div className="imgItem">
        <img
          src={changeImage(defaultData?.rls_stat_code)}
          className="infoImg"
          style={{ width: "56px", height: "56px" }}
        ></img>
      </div>
      <div className="rightItem">
        <div
          className="title"
          onClick={() => {
            getDetailInfo(defaultData?.last_ver_no, defaultData, true);
          }}
        >
          <span>{`${defaultData?.chi_name} / ${defaultData?.eng_name}`}</span>
        </div>
        <div className="webContent">
          {changeColor(defaultData?.rls_stat_code)}
        </div>
        <div className="detailContent">
          <span className="label">API总数</span>
          <span className="value" style={{ marginLeft: "-5px" }}>
            {Object.keys(defaultData?.api_id_count)?.length !== 0
              ? `${
                  ~~defaultData?.api_id_count?.NEW?.length +
                  ~~defaultData?.api_id_count?.DISCARD?.length +
                  ~~defaultData?.api_id_count?.ORIGINAL?.length
                }`
              : 0}
          </span>
          <span className="label" style={{ width: "40px" }}>
            新增
          </span>
          <span className="value">
            {defaultData?.api_id_count?.NEW?.length === undefined
              ? 0
              : defaultData?.api_id_count?.NEW?.length}
          </span>
          <span className="label" style={{ width: "40px" }}>
            废弃
          </span>
          <span className="value">
            {defaultData?.api_id_count?.DISCARD?.length === undefined
              ? 0
              : defaultData?.api_id_count?.NEW?.length}
          </span>
          <span className="label">当前版本</span>
          <span className="value"> {defaultData?.last_ver_no}</span>
          <span className="label">创建人</span>
          <span className="value" style={{ width: "100px" }}>
            {" "}
            {defaultData?.created_by_id_value}
          </span>
          <span className="label">创建时间</span>
          <span className="value" style={{ width: "140px" }}>
            {defaultData?.created_time}
          </span>
          <span className="label">更新时间</span>
          <span className="value" style={{ width: "140px" }}>
            {defaultData?.updated_time}
          </span>
        </div>
      </div>
      <div className="iconItem">
        {defaultData?.rls_stat_code !== "IN_APPROVAL" ? (
          <Tooltip placement="top" title="编辑" arrowPointAtCenter>
            <FormOutlined
              onClick={() => modelHandle(defaultData?.api_pkg_id)}
            />
          </Tooltip>
        ) : null}

        {defaultData?.rls_stat_code === "DISCARDED" ? (
          <Tooltip placement="top" title="删除" arrowPointAtCenter>
            <CloseCircleOutlined
              style={{ marginLeft: "5px" }}
              onClick={() => DeleteItem(defaultData?.api_pkg_id)}
            />
          </Tooltip>
        ) : null}

        {defaultData?.rls_stat_code === "TO_BE_RELEASED" ? (
          <Tooltip placement="top" title="提交发布" arrowPointAtCenter>
            <SnippetsOutlined
              style={{ marginLeft: "5px" }}
              onClick={() => subReview([defaultData])}
            />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};
export default PackageGovCard;
