import { Menu, Layout } from "antd";
import "./index.scss";
import { useEffect, useState } from "react";

const { Sider } = Layout;

export default function Sidebar(props) {
  const { setBreadcrumbName, setBreadcrumb, setLoading, apiName, setApiName } =
    props;
  const [selectedKeys, setSelectedKeys] = useState(["开通服务"]); // 初始化选中的菜单项
  const [openKeys, setOpenKeys] = useState(["快速入门"]); // 初始化展开的菜单项
  const initMenu = (list, parentKey = "") => {
    list = list?.map((item, index) => {
      const uniqueKey = `${item.breadcrumb}-${item.menu_name}`;
      if (item.children && item.children.length > 0) {
        return (
          <Menu.SubMenu title={item.menu_name} key={item.menu_name}>
            {initMenu(item.children)}
          </Menu.SubMenu>
        );
      }

      return (
        <Menu.Item
          key={item.menu_name}
          onClick={() => {
            setBreadcrumbName(item.menu_name);
            setBreadcrumb(item);
            setLoading(true);
            setSelectedKeys([item.menu_name]);
            setApiName(null);
          }}
        >
          <a>{item.menu_name}</a>
        </Menu.Item>
      );
    });
    return list;
  };
  // 添加逻辑：查找匹配的菜单项并触发点击事件
  const handleClickMenu = (menuName) => {
    const result = props.routerList[1]?.children?.reduce((acc, item) => {
      if (item.children && item.children.length > 0) {
        acc.push(...item.children);
      }
      return acc;
    }, []);
    const matchedItem = result.find((item) => item.menu_name === menuName);
    if (matchedItem) {
      setBreadcrumbName(matchedItem.menu_name);
      setBreadcrumb(matchedItem);
      setLoading(true);
      setSelectedKeys([matchedItem.menu_name]);
      setOpenKeys(["API参考", matchedItem.breadcrumb]);
    }
  };

  // 在组件挂载时触发点击事件
  useEffect(() => {
    if (apiName) {
      handleClickMenu(apiName);
    }
  }, [apiName]);

  return (
    <div className="mySidebarContent">
      <Sider
        trigger={null}
        width={275}
        style={{
          height: "calc(100% - 40px)",
          background: "#fff",
        }}
        className="site-layout-background"
      >
        <div
          className="title"
          style={{
            background: "linear-gradient(223deg, #006bb3 0%, #15d2d6 100%)",
          }}
        >
          数据服务
        </div>

        <Menu
          mode="inline"
          style={{
            height: "100%",
            borderRight: 0,
            overflow: "auto",
            background: "#fff",
          }}
          selectedKeys={selectedKeys}
          openKeys={openKeys} // 设置展开的菜单项
          onOpenChange={(keys) => setOpenKeys(keys)} // 处理展开菜单项的变化
        >
          {initMenu(
            props.routerList?.filter((item) => !item.hide),
            false
          )}
        </Menu>
      </Sider>
    </div>
  );
}
