import "./index.scss";
import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { Drawer, Button, Form, Input, Space } from "antd";
import tableIcon from "../../../../../assets/表16.svg";
import {
  CloseOutlined,
  PlusCircleFilled,
  MinusCircleFilled,
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import HeaderTitle from "../../../../../components/HeaderTitle";
import MySpin from "../../../../../components/MySpin";
import AddModal from "../AddModal";

const { TextArea } = Input;

const DialogDrawer = (props) => {
  const {
    /* 标题 */
    drawerTitle,
    /* 关闭触发的回调 */
    onCancel,
    /* 确定触发的回调 */
    onOk,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    visible,
    /* 判断新增还是修改 */
    addNew,
    /* 初始值 */
    defaultData,
    loading,
  } = props;

  const [form] = Form.useForm();
  const [relationList, setRelationList] = useState([]); // 选择接口列表

  useEffect(() => {
    if (addNew === false) {
      setRelationList(defaultData?.api_list);
    }
  }, [defaultData]);

  useEffect(() => {
    if (addNew) {
      setRelationList([]);
    }
  }, [visible]);

  // 添加接口
  const handleAdd = () => {
    let params = {
      addNew: addNew,
      maskClosable: true,
      noIcon: true,
      noBtn: false,
      title: "添加接口",
      onOk: (value) => {
        handleAddItem(value);
      },
    };
    if (addNew) {
      AddModal(params);
    } else {
      params.defaultData = defaultData;
      AddModal(params);
    }
  };

  // 自定义动态新增add方法
  const handleAddItem = (value) => {
    const data = value?.map((item) => {
      return {
        key: item.api_id,
        api_id: item.api_id,
        chi_name: item.chi_name,
        eng_name: item.eng_name,
      };
    });
    const list = form.getFieldValue("api_ids") || relationList;
    setRelationList(list?.concat(data));
    const nextList = list?.concat(data);
    form.setFieldsValue({
      api_ids: nextList,
    });
  };

  // 自定义动态删除remove方法
  const handleRemoveItem = (value) => {
    const list = form.getFieldValue("api_ids") || relationList;
    const nextList = list?.filter((item) => item.api_id !== value.api_id);
    setRelationList(nextList);
    form.setFieldsValue({
      api_ids: nextList,
    });
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  // 判断接口是否有新增或者删除
  const compareArrays = (arr1, arr2) => {
    let difference = [];
    for (let i = 0; i < arr1.length; i++) {
      let found = false;
      for (let j = 0; j < arr2.length; j++) {
        if (arr1[i].api_id === arr2[j].api_id) {
          found = true;
          break;
        }
      }
      if (!found) {
        difference.push(arr1[i]);
      }
    }
    return difference;
  };

  // 提交表单录入
  const onFinish = (value) => {
    const newMetaCons = relationList?.reduce(
      (prev, cur) => [...prev, cur?.api_id],
      []
    );

    let param = {
      pkg_chi_name: value?.pkg_chi_name,
      pkg_eng_name: value?.pkg_eng_name,
      pkg_desc: value?.pkg_desc,
    };
    if (addNew) {
      if (relationList?.length === 0) {
        onOk && onOk(param);
      } else {
        param.api_ids = newMetaCons;
        onOk && onOk(param);
      }
    } else {
      let del = compareArrays(defaultData?.api_list, relationList)?.reduce(
        (prev, cur) => [...prev, cur?.api_id],
        []
      );
      let add = compareArrays(relationList, defaultData?.api_list)?.reduce(
        (prev, cur) => [...prev, cur?.api_id],
        []
      );
      console.log(defaultData, del, add);
      if (
        (defaultData?.api_list?.length === 0 && relationList?.length === 0) ||
        (defaultData?.api_list?.length !== 0 &&
          del?.length === 0 &&
          add?.length === 0)
      ) {
        param.api_pkg_id = defaultData?.api_pkg_id;
        onOk && onOk(param);
      } else if (del?.length !== 0 && add?.length === 0) {
        param.api_pkg_id = defaultData?.api_pkg_id;
        param.del_api_ids = del;
        onOk && onOk(param);
      } else if (del?.length === 0 && add?.length !== 0) {
        param.api_pkg_id = defaultData?.api_pkg_id;
        param.add_api_ids = add;
        onOk && onOk(param);
      } else if (del?.length !== 0 && add?.length !== 0) {
        param.api_pkg_id = defaultData?.api_pkg_id;
        param.add_api_ids = add;
        param.del_api_ids = del;
        onOk && onOk(param);
      }
    }
  };

  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      width="600px"
      className="metaDialogDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              form.submit();
            }}
            style={{ background: "#016DB4", color: "#fff" }}
          >
            {okText ? okText : "确认"}
          </Button>

          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            {cancelText ? cancelText : "取消"}
          </Button>
        </div>
      }
    >
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: `100%`,
            width: "100%",
          }}
        >
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            className="dialogDrawerForm"
            preserve={false}
            onFinish={onFinish}
          >
            <HeaderTitle title="基本信息"></HeaderTitle>

            <Form.Item
              label="中文名称"
              name="pkg_chi_name"
              rules={[
                {
                  required: true,
                  message: "请输入中文名称，不超过100个字符",
                },
                {
                  pattern: /^^(.|\n){0,100}$/,
                  message: "中文名称不超过100个字符",
                },
              ]}
              initialValue={addNew ? null : defaultData?.chi_name}
            >
              <Input
                allowClear
                disabled={
                  (addNew === false &&
                    defaultData?.db_type_code === "POSTGRESQL") ||
                  defaultData?.db_type_code === "MYSQL"
                }
                placeholder="1~100个字符"
                style={{ width: "260px" }}
              />
            </Form.Item>

            <Form.Item
              label="英文名称"
              name="pkg_eng_name"
              rules={[
                {
                  required: true,
                  message: "请输入英文名称，不超过100个字符或符号",
                },
                {
                  pattern: /^^(.|\n){0,100}$/,
                  message: "英文名称不超过100个字符或符号",
                },
              ]}
              initialValue={addNew ? null : defaultData?.eng_name}
            >
              <Input
                allowClear
                disabled={
                  (addNew === false &&
                    defaultData?.db_type_code === "POSTGRESQL") ||
                  defaultData?.db_type_code === "MYSQL"
                }
                placeholder="1-100个英文字符或符号"
                style={{ width: "260px" }}
              />
            </Form.Item>

            <Form.Item
              name="pkg_desc"
              label="描述"
              initialValue={addNew ? null : defaultData?.api_pkg_desc}
            >
              <TextArea
                allowClear
                style={{
                  width: "540px",
                }}
                autoSize={{ minRows: 5 }}
              />
            </Form.Item>

            <HeaderTitle title="API服务列表"></HeaderTitle>

            <Form.List name="api_ids">
              {(fields, { add, remove }) => (
                <>
                  <Form.Item
                    label="添加接口"
                    style={{
                      width: "calc(100% - 30px)",
                    }}
                  ></Form.Item>
                  <PlusCircleFilled
                    style={{
                      fontSize: "18px",
                      color: "#006AB2",
                      height: "18px",
                    }}
                    onClick={() => handleAdd()}
                  />

                  {relationList?.map((item, index) => (
                    <Space key={item?.api_id} align="baseline">
                      <Form.Item {...item} style={{ width: "254px" }}>
                        <ReactSVG
                          src={tableIcon}
                          style={{
                            width: "16px",
                            height: "16px",
                            marginRight: "8px",
                          }}
                        />
                        <div>{`${item.chi_name} / ${item.eng_name}`}</div>
                      </Form.Item>
                      <MinusCircleFilled
                        onClick={() => handleRemoveItem(item)}
                        style={{ color: "#006AB2", fontSize: "18px" }}
                        className="dynamic-delete-button"
                      />
                    </Space>
                  ))}
                </>
              )}
            </Form.List>
          </Form>
        </Scrollbars>
      )}
    </Drawer>
  );
};

export default DialogDrawer;
