import "./index.scss";
import { Tabs, message, Button, Select } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import ReleaseIcon from "../../../assets/icon_发布 1.svg";
import AddIcon from "../../../assets/+.svg";
import DropdownSvg from "../../../assets/下拉.svg";
import DataStandardTable from "../../../components/DataStandardTable";
import DialogDrawer from "../../../components/StandardDialogDrawer";
import ReleaseDrawer from "../../../components/ReleaseDrawer";
import projectApi from "../../../apis/project";
import { useGetState } from "ahooks";
import MessageModal from "../../../components/MessageModal";
import Scrollbars from "react-custom-scrollbars";
const { TabPane } = Tabs;
const { Option } = Select;

const Layer = (props) => {
  const [orders, setOrders, getOrders] = useGetState([
    { order_name: "updated_time", order_by: "desc" },
  ]);
  const [tableLoading, setTableLoading] = useState(true);
  const [activeKey, setActiveKey, getActiveKey] = useGetState("1");
  const [drawerTitle, setDrawerTitle] = useState("");
  const [addNew, setAddNew] = useState(true);
  const [unpublishedList, setUnpublishedList] = useState([]);
  const [publishedList, setpublishedList] = useState([]);
  const [versonList, setVersonList] = useState([]);
  const [verson, setVerson] = useState(null);
  const [nextVerson, setNextVerson] = useState(null); // 发布标准版本号
  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false);
  const [params, setParams] = useState({ page: 1, size: 10 });

  const [ReleaseDrawerVisible, setReleaseDrawerVisible] = useState(false);

  const [standard, setStandard] = useState(false);
  const [versonStatusCode, setVersonStatusCode] = useState("");
  const [versonStatusList, setVersonStatusList] = useState({});
  const [checkList, setCheckList] = useState({});

  const tabChange = (key) => {
    setActiveKey(key);
    if (key === "1") {
      setpublishedList([]);
    }
    if (key === "2") {
      getVersonList();
      setOrders([]);
    }
  };

  useEffect(() => {
    if (activeKey === "1") versonStatus();
  }, [getActiveKey()]);

  useEffect(() => {
    if (activeKey === "1" && versonStatusCode !== "") getUnpublishedList();
  }, [versonStatusCode]);

  useEffect(() => {
    if (activeKey === "2" && verson !== null) {
      getPublishedList(verson);
      getCheckDetail(verson);
    }
  }, [verson]);

  // 新增标准
  const dialogDrawerSuccess = (value) => {
    setDialogDrawerVisible(false);
    projectApi.AddLayerStandard(value).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        getUnpublishedList();
      } else {
        message.error(res.message);
      }
    });
  };

  // 更新数据分层标准
  const updateDialogDrawer = () => {
    getUnpublishedList();
  };

  // 排序
  const changeOrders = (data) => {
    const str = "_value";
    setOrders([
      {
        order_name: data.field.includes("_value")
          ? data.field.split(str).join("")
          : data.field,
        order_by: data.order === "descend" ? "desc" : "asc",
      },
    ]);
    setParams({ page: 1, size: 10 });
  };

  // 获取未发布标准
  const getUnpublishedList = () => {
    setTableLoading(true);
    projectApi
      .getLayerUnpublishedList(params, { orders })
      .then((res) => {
        if (res.code === 200) {
          setUnpublishedList(res.data);
        } else {
          setUnpublishedList([]);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  // 获取已发布标准
  const getPublishedList = (id) => {
    setTableLoading(true);
    projectApi
      .getLayerPublishedList({ ver_no: id, ...params }, { orders })
      .then((res) => {
        if (res.code === 200) {
          setpublishedList(res.data);
        } else {
          setpublishedList([]);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  // 获取标准版本
  const getVersonList = () => {
    projectApi
      .getVersonList(
        { dg_std_type: "DATA_LAYER" },
        { orders: [{ order_name: "std_ver_id", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          setVersonList(res.data);
          if (res.data.total !== 0) {
            setVerson(res.data.items[0].ver_no);
          }
        } else {
          setVersonList([]);
        }
      });
  };

  // 获取发布标准版本号
  const getNextVersonList = () => {
    projectApi.getNextVersonList({ dg_std_type: "DATA_LAYER" }).then((res) => {
      if (res.code === 200) {
        setNextVerson(res.data);
        setReleaseDrawerVisible(true);
      } else {
        setNextVerson([]);
      }
    });
  };

  // 版本选择
  const versonChange = (value) => {
    setVerson(value);
  };

  // 改变分页数据
  const changePagination = (page, pageSize) => {
    setParams({ page: page, size: pageSize });
  };

  useEffect(() => {
    if (activeKey === "1" && versonStatusCode !== "") {
      getUnpublishedList();
    } else if (activeKey === "2" && verson !== null) {
      getPublishedList(verson);
    }
  }, [params, getOrders()]);

  // useEffect(() => {
  //   if (activeKey === "1" && versonStatusCode !== "") {
  //     getUnpublishedList();
  //   }
  //   if (activeKey === "2" && verson !== null) {
  //     getPublishedList(verson);
  //   }
  // }, [getOrders()]);

  // 新增分层标准
  const layerHandle = () => {
    setDialogDrawerVisible(true);
    setDrawerTitle("新增数据分层");
    setAddNew(true);
  };

  // 发布标准
  const releaseDrawerSuccess = (data) => {
    applyStandards({ wf_type_code: "DATA_LAYER_RLS", ...data });
  };

  // 发布标准请求
  const applyStandards = (data) => {
    projectApi.applyStandards(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        versonStatus();
        setReleaseDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 审核状态查询
  const versonStatus = () => {
    projectApi
      .versonStatus({ dg_wf_type_code: "DATA_LAYER_RLS" })
      .then((res) => {
        if (res.code === 200) {
          setVersonStatusCode(res.data?.appr_stat_code);
          if (res.data?.appr_stat_code === "IN_APPROVAL") {
            setStandard(true);
          }
        } else {
          message.error(res.message);
        }
      });
  };

  // 点击发布标准
  const addStandard = () => {
    getNextVersonList();

    projectApi
      .versonStatusStatics({
        dg_wf_type_code: "DATA_LAYER_RLS",
      })
      .then((res) => {
        if (res.code === 200) {
          setVersonStatusList(res.data);
        } else {
        }
      });
  };

  // 获取审核详情
  const getCheckDetail = (id) => {
    projectApi
      .getCheckDetail({ ver_no: id, wf_type_code: "DATA_LAYER_RLS" })
      .then((res) => {
        if (res.code === 200) {
          setCheckList(res.data);
          console.log(res.data);
        } else {
        }
      });
  };

  // 标准启用
  const operationEnable = (item) => {
    if (versonStatusCode !== "IN_APPROVAL") {
      MessageModal({
        type: "start",
        description: "确认要启用数据分层吗？",
        title: "启用数据分层",
        okText: "启用",
        onOk: () => {
          projectApi
            .operationEnable({
              oper_object_code: "DATA_LAYER_RLS",
              id: item.data_layer_id,
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                getUnpublishedList();
              } else {
                message.error(res.message);
              }
            });
        },
      });
    }
  };

  // 废弃标准
  const operationAbandon = (item) => {
    if (versonStatusCode !== "IN_APPROVAL") {
      MessageModal({
        type: "warning",
        description: "确认要废弃数据分层吗？",
        title: "废弃数据分层",
        okText: "废弃",
        onOk: () => {
          projectApi
            .operationAbandon({
              oper_object_code: "DATA_LAYER_RLS",
              id: item.data_layer_id,
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                getUnpublishedList();
              } else {
                message.error(res.message);
              }
            });
        },
      });
    }
  };

  // 删除标准
  const deleteItem = (item) => {
    if (versonStatusCode !== "IN_APPROVAL") {
      MessageModal({
        type: "warning",
        description: "确定删除数据分层吗？",
        title: "删除",
        okText: "删除",
        onOk: () => {
          projectApi
            .deleteLayerStandard({ data_larer_ids: [item.data_layer_id] })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                setParams({ page: 1, size: 10 });
              } else {
                message.error(res.message);
              }
            });
        },
      });
    }
  };

  return (
    <Scrollbars
      autoHide={true}
      autoHideTimeout={5000}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <div className="LayerContent">
        <Tabs className="myTabs" onChange={tabChange} defaultActiveKey="1">
          <TabPane tab="未发布标准" key="1">
            <div className="noReleseInfo">
              {versonStatusCode === "IN_APPROVAL" && standard ? (
                <div className="promptValue">
                  <InfoCircleFilled
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                    }}
                  />
                  <span>标准审核与发布中，暂不能进行修改</span>
                </div>
              ) : null}

              {versonStatusCode === "REJECTED" ? (
                <div className="promptValue">
                  <InfoCircleFilled
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                      color: "#FE545F",
                    }}
                  />
                  <span style={{ color: "#FE545F" }}>
                    标准审核被驳回，请修改后再重新发布
                  </span>
                </div>
              ) : null}

              <div className="btnBox">
                {versonStatusCode !== "REJECTED" ? (
                  <Button
                    type="primary"
                    icon={
                      <ReactSVG src={ReleaseIcon} className="Icon"></ReactSVG>
                    }
                    onClick={addStandard}
                    disabled={standard}
                  >
                    发布标准
                  </Button>
                ) : null}

                {versonStatusCode === "REJECTED" ? (
                  <Button
                    type="primary"
                    icon={
                      <ReactSVG src={ReleaseIcon} className="Icon"></ReactSVG>
                    }
                    onClick={addStandard}
                    disabled={standard}
                  >
                    重新发布
                  </Button>
                ) : null}

                <Button
                  type="primary"
                  icon={<ReactSVG src={AddIcon} className="Icon"></ReactSVG>}
                  onClick={() => layerHandle(true)}
                  disabled={standard}
                >
                  新增
                </Button>
              </div>
              <div className="infoBox">
                <DataStandardTable
                  defaultData={unpublishedList}
                  tableName="LayerUnpublished"
                  changePagination={changePagination}
                  activeKey={getActiveKey()}
                  changeOrders={changeOrders}
                  versonStatusCode={versonStatusCode}
                  updateDialogDrawer={updateDialogDrawer}
                  operationEnable={operationEnable}
                  deleteItem={deleteItem}
                  operationAbandon={operationAbandon}
                  tableLoading={tableLoading}
                  standard={standard}
                ></DataStandardTable>
              </div>
            </div>
          </TabPane>
          <TabPane tab="已发布标准" key="2">
            <div className="ReleseInfo">
              <div className="promptValue">
                <div className="selectInfo">
                  <div className="label">标准版本</div>
                  <div className="value">
                    <Select
                      placeholder="全部"
                      showSearch
                      filterOption={
                        (input, option) =>
                          (Array.isArray(option.children)
                            ? option.children.join("")
                            : option.children
                          )
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        // console.log(input, option)
                      }
                      showArrow="true"
                      onChange={versonChange}
                      value={verson}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                    >
                      {versonList?.items?.map((item, index) => {
                        return (
                          <Option value={item.ver_no} key={index}>
                            {item.ver_no}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">发布时间</div>
                  <div className="detailValue">{checkList?.apprd_time}</div>
                  <div className="detailKey">发布人</div>
                  <div className="detailValue">
                    {checkList?.apply_by_id_value}
                  </div>
                  <div className="detailKey">审核人</div>
                  <div className="detailValue">
                    {checkList?.apprd_by_id_value}
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <DataStandardTable
                  defaultData={publishedList}
                  tableName="LayerUnpublished"
                  changePagination={changePagination}
                  activeKey={getActiveKey()}
                  changeOrders={changeOrders}
                  flag={true}
                  tableLoading={tableLoading}
                ></DataStandardTable>
              </div>
            </div>
          </TabPane>
        </Tabs>

        <DialogDrawer
          title={drawerTitle}
          okText="确定"
          cancelText="取消"
          addNew={addNew}
          onOk={dialogDrawerSuccess}
          visible={DialogDrawerVisible}
          onCancel={() => {
            setDialogDrawerVisible(false);
          }}
        ></DialogDrawer>

        <ReleaseDrawer
          title="发布标准"
          onOk={releaseDrawerSuccess}
          visible={ReleaseDrawerVisible}
          onCancel={() => {
            setReleaseDrawerVisible(false);
          }}
          versonStatusList={versonStatusList}
          nextVerson={nextVerson}
        ></ReleaseDrawer>
      </div>
    </Scrollbars>
  );
};
export default Layer;
