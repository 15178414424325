import "./index.scss";
import React, { useEffect, useState, useRef, useContext } from "react";
import Scrollbars from "react-custom-scrollbars";
import {
  Button,
  Select,
  Modal,
  Form,
  Table,
  Tooltip,
  ConfigProvider,
  message,
} from "antd";
import { debounce } from "../../../../../unit/unit";
import LoadingSvg from "../../../../../assets/loading.svg";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import DropdownSvg from "../../../../../assets/下拉.svg";
import zhCN from "antd/lib/locale/zh_CN";
import wrapper from "./wrapper";
import { ReactSVG } from "react-svg";
import MyEmpty from "../../../../../components/Empty";
import projectApi from "../../../../../apis/project";
import { rest } from "lodash";

const { Option } = Select;

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  relationType, // 连线起点终点
  dicCodeInfoList, // 用户字典
  fieldList, // 字段
  matchupFieldList, // 对应参照字段
  modelTableList, //参照表
  getModelTableFieldList, // 获取对应参照字段
  getModelTableList, // 获取对应参照表
  setFlag,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  // 可编辑类型判断
  const contentType = (title) => {
    if (title === "字段") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: "请选择字段",
            },
          ]}
        >
          <Select
            placeholder="请选择字段"
            showSearch={true}
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            ref={inputRef}
            onChange={save}
            options={fieldList?.map((item) => ({
              label: item?.col_name,
              value: item?.col_id,
            }))}
            style={{ width: "210px" }}
          ></Select>
        </Form.Item>
      );
    }
    if (title === "对应参照字段") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: "请选择对应参照字段",
            },
          ]}
        >
          <Select
            placeholder="请选择"
            showSearch={true}
            ref={inputRef}
            onFocus={() => getModelTableFieldList(record?.fk_table_id, false)}
            onChange={save}
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            options={matchupFieldList?.map((item) => ({
              label: item?.col_name,
              value: item?.col_id,
            }))}
            style={{ width: "210px" }}
          ></Select>
        </Form.Item>
      );
    }

    if (title === "对应参照表") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: "请选择对应参照表",
            },
          ]}
        >
          <Select
            placeholder="请选择对应参照表"
            showSearch={true}
            ref={inputRef}
            filterOption={false}
            onSearch={debounce((e) => {
              if (e !== "") getModelTableList(e);
            }, 500)}
            allowClear
            onChange={save}
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            style={{ width: "350px" }}
            options={modelTableList?.map((item) => ({
              label: `${item?.table_cmnt}/${item?.table_name}`,
              value: item?.table_id,
            }))}
          ></Select>
        </Form.Item>
      );
    }

    if (title === "连线起点" || title === "连线终点") {
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Select
            placeholder="请选择"
            allowClear
            showSearch={true}
            ref={inputRef}
            onChange={save}
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            style={{ width: "100px" }}
          >
            {relationType?.map((item) => {
              return (
                <Option value={item} key={item}>
                  {dicCodeInfoList?.DG_TABLE_DESG_RELATION?.map(
                    (value) => value[item]
                  )}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      );
    }
  };

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      contentType(title)
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 20,
          minHeight: 15,
          minWidth: 10,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const ReferenceDrawer = (props) => {
  const [visible, setVisible] = useState(true);

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 不要按钮 */
    noBtn,
    /* 点击蒙层是否允许关闭 */
    maskClosable,
    /* 用户字典 */
    dicCodeInfoList,
    /* 连线起点和终点 */
    relationType,
    /* 表对应的id */
    tableId,
    /* 更新模型信息 */
    getModeldataList,
  } = props;

  // 表格
  const refTable = useRef(null);

  const [fksSource, setFksSource] = useState({}); // 子
  const [fieldList, setFieldList] = useState([]); // 字段
  const [matchupFieldList, setMatchupFieldList] = useState([]); // 对应参照字段
  const [modelTableList, setModelTableList] = useState([]); // 对应参照表

  const [number, setNumber] = useState(0); // 父
  const [dataSource, setDataSource] = useState([]); // 父
  const [tableLoading, setTableLoading] = useState(true);

  // 子参照配置
  const expandedRowRender = (data) => {
    const fkscolumn = [
      {
        title: "字段",
        dataIndex: "fk_col_id",
        editable: true,
        render: (_, info) => {
          return (
            <Select
              placeholder="请选择字段"
              showSearch={true}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              style={{ width: "210px" }}
              value={info?.fk_col_id}
              options={fieldList?.map((item) => ({
                label: item?.col_name,
                value: item?.col_id,
              }))}
            ></Select>
          );
        },
      },
      {
        title: "连线起点",
        dataIndex: "re_source_code",
        editable: true,
        render: (_, info) => {
          return (
            <Select
              placeholder="请选择"
              showSearch={true}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              style={{ width: "100px" }}
              value={info?.re_source_code}
            >
              {relationType?.map((item) => {
                return (
                  <Option value={item} key={item}>
                    {dicCodeInfoList?.DG_TABLE_DESG_RELATION?.map(
                      (value) => value[item]
                    )}
                  </Option>
                );
              })}
            </Select>
          );
        },
      },
      {
        title: "连线终点",
        dataIndex: "re_target_code",
        editable: true,
        render: (_, info) => {
          return (
            <Select
              placeholder="请选择"
              showSearch={true}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              style={{ width: "100px" }}
              value={info?.re_target_code}
            >
              {relationType?.map((item) => {
                return (
                  <Option value={item} key={item}>
                    {dicCodeInfoList?.DG_TABLE_DESG_RELATION?.map(
                      (value) => value[item]
                    )}
                  </Option>
                );
              })}
            </Select>
          );
        },
      },
      {
        title: "对应参照字段",
        dataIndex: "ref_col_id",
        editable: true,
        render: (_, info) => {
          return (
            <Select
              placeholder="请选择对应参照字段"
              showSearch={true}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              value={info?.ref_col_id}
              style={{ width: "210px" }}
              options={matchupFieldList?.map((item) => ({
                label: item?.col_name,
                value: item?.col_id,
              }))}
            ></Select>
          );
        },
      },

      {
        title: (
          <PlusCircleFilled
            style={{
              fontSize: "18px",
              color: "#006AB2",
              marginTop: "8px",
            }}
            onClick={() => handleAdd()}
          />
        ),
        dataIndex: "operation",
        width: "5%",
        render: (_, record) => (
          <Tooltip title="删除">
            <MinusCircleFilled
              onClick={() => indexDelete(record.ord)}
              style={{
                color: "#006AB2",
                fontSize: "18px",
              }}
            />
          </Tooltip>
        ),
      },
    ];

    const handleAdd = () => {
      const indexnewData = {
        ord: fksSource[data?.fk_table_id]?.length + 1,
        fk_col_id: null,
        re_source_code: null,
        re_target_code: null,
        ref_col_id: null,
        fk_table_id: data?.fk_table_id,
      };
      const value = fksSource;
      value[data?.fk_table_id] = [
        ...fksSource[data?.fk_table_id],
        indexnewData,
      ];
      setFksSource({ ...fksSource, ...value });
    };

    const indexDelete = (key) => {
      const newData = fksSource[data?.fk_table_id]
        .filter((item) => item.ord !== key)
        ?.reduce((cur, pre, index) => [...cur, { ...pre, ord: index + 1 }], []);
      const value = fksSource;
      value[data?.fk_table_id] = newData;
      setFksSource({ ...fksSource, ...value });
    };

    const handleSave = (row) => {
      const indexnewData = [...fksSource[data?.fk_table_id]];
      const i = indexnewData.findIndex((item) => row.ord === item.ord);
      const item = indexnewData[i];
      indexnewData.splice(i, 1, {
        ...item,
        ...row,
      });
      const value = fksSource;
      value[data?.fk_table_id] = indexnewData;
      setFksSource({ ...fksSource, ...value });
    };

    const fkscolumns = fkscolumn.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          relationType, // 连线起点终点
          dicCodeInfoList, // 用户字典
          fieldList, // 参照字段
          modelTableList, // 对应参照表
          matchupFieldList, // 对应参照字段
          getModelTableFieldList, // 获取对应参照字段
          handleSave,
        }),
      };
    });

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    return (
      <Table
        className="myFormTable"
        bordered={false}
        pagination={false}
        components={components}
        dataSource={fksSource[data.fk_table_id]}
        columns={fkscolumns}
        rowKey={(record, id) => record?.ord}
        locale={{
          emptyText: <MyEmpty></MyEmpty>,
        }}
      />
    );
  };

  // 获取原有的添加参照
  const getReferenceDesignList = (id) => {
    projectApi.getReferenceDesignList(id).then((res) => {
      if (res.code === 200) {
        if (res.data.length !== 0) {
          const data = res.data?.reduce(
            (cur, pre) => [
              ...cur,
              { ord: pre.ord, fk_table_id: pre.fk_metadata_id },
            ],
            []
          );
          setDataSource(data);
          setNumber(data?.length);
          const kfsItem = res.data?.reduce((cur, pre) => {
            const value = cur;
            const preValue = pre.fk_cols?.reduce(
              (cur, pre) => [
                ...cur,
                {
                  ord: pre.ord,
                  fk_col_id: pre.fk_col_id,
                  re_source_code: pre.re_source_code,
                  re_target_code: pre.re_target_code,
                  ref_col_id: pre.ref_col_id,
                },
              ],
              []
            );
            value[pre.fk_metadata_id] = preValue;
            return value;
          }, {});
          setFksSource(kfsItem);
        }
        setTableLoading(false);
      } else {
        setTableLoading(false);
      }
    });
  };

  // 获取字段
  const getModelTableFieldList = (id, flag) => {
    projectApi.getModelTableFieldList(id).then((res) => {
      if (res.code === 200) {
        if (flag) {
          setFieldList(res.data);
        } else {
          setMatchupFieldList(res.data);
        }
      } else {
        if (flag) {
          setFieldList([]);
        } else {
          setMatchupFieldList([]);
        }
      }
    });
  };

  // 获取表
  const getModelTableList = (value) => {
    let params = {
      page: 1,
      size: 100,
    };
    if (value !== undefined) {
      params.table_name = value;
    }
    projectApi.getModelTableList(params).then((res) => {
      if (res.code === 200) {
        setModelTableList(res.data.items);
      } else {
        setModelTableList([]);
      }
    });
  };

  useEffect(() => {
    getModelTableFieldList(tableId, true);
    getReferenceDesignList(tableId);
  }, [tableId]);

  useEffect(() => {
    getModelTableList();
    setNumber(0);
    setTableLoading(true);
  }, []);

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  // 添加参照
  const handleOk = () => {
    const fks = dataSource?.reduce(
      (cur, pre) => [
        ...cur,
        {
          ...pre,
          fk_cols: fksSource[pre?.fk_table_id]?.map(
            ({ fk_table_id, ...rest }) => rest
          ),
        },
      ],
      []
    );

    projectApi
      .addReferenceDesign({
        table_id: tableId,
        fks,
      })
      .then((res) => {
        if (res.code === 200) {
          message.success(res.message);
          getModeldataList();
          setVisible(false);
          setTimeout(() => {
            closeDialog();
          }, 500);
        } else {
          message.error(res.message);
        }
      });
  };

  const column = [
    {
      title: "对应参照表",
      dataIndex: "fk_table_id",
      editable: true,
      render: (_, info) => {
        return (
          <Select
            placeholder="请选择对应参照表"
            showSearch
            allowClear
            filterOption={false}
            suffixIcon={
              <ReactSVG
                src={DropdownSvg}
                style={{ width: "16px", height: "16px" }}
              ></ReactSVG>
            }
            style={{ width: "350px" }}
            value={info?.fk_table_id}
            options={modelTableList?.map((item) => ({
              label: `${item?.table_cmnt}/${item?.table_name}`,
              value: item?.table_id,
            }))}
            autoFocus={true}
          ></Select>
        );
      },
    },

    {
      title: (
        <PlusCircleFilled
          style={{
            fontSize: "18px",
            color: "#006AB2",
            marginTop: "8px",
          }}
          onClick={() => handleAdd()}
        />
      ),
      dataIndex: "operation",
      width: "5.4%",
      render: (_, record) =>
        dataSource?.length >= 1 ? (
          <>
            <Tooltip title="删除">
              <MinusCircleFilled
                onClick={() => numberDelete(record)}
                style={{
                  color: "#006AB2",
                  fontSize: "18px",
                }}
              />
            </Tooltip>
          </>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const indexnewData = {
      ord: number + 1,
      fk_table_id: null,
    };

    setDataSource([...dataSource, indexnewData]);
    setNumber(number + 1);
  };
  const numberDelete = (record) => {
    const newData = dataSource
      ?.filter((item) => item.ord !== record?.ord)
      ?.reduce((cur, pre, index) => [...cur, { ...pre, ord: index + 1 }], []);

    setDataSource(newData);
    setNumber(number - 1);
    let newObj = {};
    for (let key in fksSource) {
      if (Number(key) !== record?.fk_table_id) {
        newObj[key] = fksSource[key];
      }
    }
    setFksSource(newObj);
  };

  const handleSave = (row) => {
    const indexnewData = [...dataSource];
    const i = indexnewData.findIndex((item) => row.ord === item.ord);
    const item = indexnewData[i];
    indexnewData.splice(i, 1, {
      ...item,
      ...row,
    });
    setFksSource({ ...fksSource, [row.fk_table_id]: [] });
    setDataSource(indexnewData);
  };

  const columns = column.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        relationType, // 连线起点终点
        dicCodeInfoList, // 用户字典
        fieldList, // 参照字段
        modelTableList, // 对应参照表
        matchupFieldList, // 对应参照字段
        getModelTableList, // 获取对应参照表
        handleSave,
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  //getChildList子表格处理
  const getChildList = async (expanded, record) => {
    if (expanded === true && record?.fk_table_id !== null) {
      getModelTableFieldList(record?.fk_table_id, false);
    }

    if (record?.fk_table_id !== null) {
      if (
        Object.keys(fksSource)?.length === 0 ||
        Object.keys(fksSource).includes(`${record?.fk_table_id}`) === false
      ) {
        setFksSource({ ...fksSource, [record?.fk_table_id]: [] });
      } else {
        setFksSource({
          ...fksSource,
          [record?.fk_table_id]: fksSource[record?.fk_table_id],
        });
      }
    }
  };

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title={title}
        visible={visible}
        onCancel={handleCancel}
        wrapClassName="ReferenceDrawer"
        centered
        footer={null}
        maskClosable={maskClosable}
        width={984}
        // bodyStyle={{ maxHeight: "800px", overflow: "scroll" }}
      >
        <div className="addModalContent">
          <div
            className="tableContent"
            style={{ width: "100%" }}
            ref={refTable}
          >
            <Table
              className="myFormTable"
              expandable={{
                expandedRowRender,
                onExpand: getChildList,
                rowExpandable: (record) =>
                  record?.fk_table_id === null ? false : true,
              }}
              components={components}
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              rowKey={(record, id) => record?.ord}
              scroll={{
                y: "400px",
              }}
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
              loading={
                tableLoading
                  ? {
                      indicator: (
                        <ReactSVG
                          src={LoadingSvg}
                          className="anticon-spin anticon myLoadingIcon"
                        />
                      ),
                    }
                  : false
              }
            />
            {/* <VisualBox data={data} /> */}
          </div>

          {!noBtn && (
            <div className="drawerFooterBtnBox btnBox">
              <Button
                className="noBg"
                onClick={() => {
                  handleCancel();
                }}
              >
                关闭
              </Button>
              <Button
                onClick={() => {
                  handleOk();
                }}
                style={{
                  background: "#006AB2",
                  color: "#fff",
                  borderColor: "#006AB2",
                }}
              >
                添加
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default wrapper(ReferenceDrawer);
