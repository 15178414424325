import "./index.scss";
import React, { useState } from "react";
import { Modal, Button, Input, Form } from "antd";
import { InfoCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import wrapper from "./wrapper";
const { TextArea } = Input;
const MessageModal = (props) => {
  const [visible, setVisible] = useState(true);
  const [form] = Form.useForm();

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 确定触发的回调 */
    onOk,
    /* 类型 warning 和 默认 */
    type,
    /* 详细内容 */
    description,
    /* 提示内容 */
    message,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    /* 不要按钮 */
    noBtn,
    /* 不要图标 */
    noIcon,
    /* 详情文本信息 */
    infoText,
    /* 点击蒙层是否允许关闭 */
    maskClosable,
    /* 驳回反驳意见 */
    rejectTitle,
    /* 数据元存在二次判断 */
    name,
    /* 被表引用数据元 */
    data,
  } = props;

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const handleOk = () => {
    onOk && onOk();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  // 提交表单录入
  const onFinish = (value) => {
    onOk && onOk(value);
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const getStyleOptions = () => {
    let color = "";
    let icon = null;
    let col = "";
    switch (type) {
      case "warning":
        color = "#FE545F";
        col = "#fff";
        // icon = <InfoCircleFilled style={{ color: "#FE545F" }} />;
        break;
      case "start":
        color = "#61D4AD";
        col = "#fff";
        // icon = <InfoCircleFilled style={{ color: "#61D4AD" }} />;
        break;
      default:
        color = "#0068B2";
        col = "#fff";
        // icon = <CheckCircleFilled style={{ color: "#0068B2" }} />;
        break;
    }
    return {
      color,
      icon,
      btnStyle: {
        background: color,
        borderColor: color,
        color: col,
      },
    };
  };

  const StyleOptions = getStyleOptions();

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={handleCancel}
      //   onOk={handleOk}
      wrapClassName="messageModal"
      centered
      footer={null}
      maskClosable={maskClosable}
    >
      {/* 驳回反驳意见 */}
      {rejectTitle && (
        <div className="rejectInfo">
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            preserve={false}
            onFinish={onFinish}
          >
            <Form.Item
              name="apprd_opn"
              rules={[
                {
                  required: true,
                  message: "请输入驳回意见，不超过500个字",
                },
              ]}
              label="驳回意见"
            >
              <TextArea
                allowClear
                placeholder="请输入驳回意见，不超过500个字"
                autoSize={{ minRows: 5 }}
              />
            </Form.Item>
          </Form>
        </div>
      )}
      <div className="messageModalContent">
        {!noIcon && <div className="iconBox">{StyleOptions.icon}</div>}

        {description && <div className="descriptionBox">{description}</div>}
        {name === "数据元" ? (
          <div className="descriptionBox">
            <div>当前数据元已被表引用确定强制修改吗？</div>
            <div style={{ color: "#FE545F", fontSize: "12px" }}>
              引用表包含
              <span>（{data?.join("、")}）</span>
            </div>
          </div>
        ) : null}
        {message && (
          <div
            className="messageBox"
            style={{ color: title === "保存模型" ? "#FE545F" : null }}
          >
            {message}
          </div>
        )}
        {infoText && <div className="infoTextBox">{infoText}</div>}
        {!noBtn && (
          <div className="drawerFooterBtnBox btnBox">
            <Button
              className="noBg"
              onClick={() => {
                handleCancel();
              }}
            >
              {cancelText || "取消"}
            </Button>
            <Button
              style={StyleOptions.btnStyle}
              onClick={() => {
                if (rejectTitle) {
                  form.submit();
                } else {
                  handleOk();
                }
              }}
            >
              {okText || "确定"}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default wrapper(MessageModal);
