import "./index.scss";
import { Table, Pagination, Badge, message } from "antd";
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import MyEmpty from "../../../../../components/Empty";
import projectApi from "../../../../../apis/project";
import LoadingSvg from "../../../../../assets/loading.svg";
import { ReactSVG } from "react-svg";
import DetailDrawer from "../../../ModelDesign/components/DetailDrawer";
import ReleaseDrawer from "../../../../../components/ReleaseDrawer";

const ModelCheckTable = (props, ref) => {
  const {
    defaultData,
    tableName,
    changePagination,
    activeKey,
    changeOrders,
    tableLoading,
    recallItem, // 撤回
    rejectItem, // 拒绝
    agreeItem, // 同意
    MetadataStorageList, // 数据存储列表
    setTableLoading,
    getModelReleaseData, // 更新表格信息
  } = props;

  const ModelCheckTableRef = useRef(null);
  const data = defaultData?.items;

  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 表格多选
  const [selectedRowList, setSelectedRowList] = useState([]);

  const [DetailDrawerVisible, setDetailDrawerVisible] = useState(false); // 查看元数据详情
  const [DetailDrawerLoading, setDetailDrawerLoading] = useState(false); // 查看元数据详情loading
  const [ModelDesignInfo, setModelDesignInfo] = useState({}); // 模型详情数据
  const [metadataVersoList, setMetadataVersonList] = useState([]); // 元数据详情版本列表
  const [relationList, setRelationList] = useState([]); // 血缘关系列表
  const [chooseList, setChooseList] = useState([]);
  const [modelMetaInfo, setModelMetaInfo] = useState({}); // 元数据详情（模型id转元数据id，以及版本id）
  const [DetailId, setDetailId] = useState(""); // 元数据详情（模型id转元数据id）
  const [wfId, setWfId] = useState(""); // 模型id
  const [agreeFlag, setAgreeFlag] = useState(false); //判断详情是从模型页面还是审核页面打开

  const [ReleaseDrawerVisible, setReleaseDrawerVisible] = useState(false); // 发布模型
  const [submitModelDesignData, setSubmitModelDesignData] = useState([]); // 提交审核模型信息

  // 流程状态颜色切换
  const changeColor = (value) => {
    if (value === "IN_APPROVAL") {
      return "#FEC054";
    } else if (value === "APPROVED") {
      return "#6CAEFF";
    } else if (value === "REJECTED") {
      return "#FE545F";
    } else if (value === "CANCELED") {
      return "#D8D8D8";
    } else if (value === "DEPLOYED") {
      return "#61D4AD";
    } else if (value === "TERMINATED") {
      return "#B7B7B7";
    }
  };

  useEffect(() => {
    if (tableName === "myapply") {
      setColumns([
        {
          title: "表中文名",
          dataIndex: "table_cmnt",
          sorter: true,
        },
        {
          title: "表名",
          dataIndex: "table_name",
          sorter: true,
        },
        {
          title: "变更类型",
          dataIndex: "chg_type_code_value",
          width: "7%",
          sorter: true,
        },
        {
          title: "当前版本",
          dataIndex: "cur_ver_no",
        },
        {
          title: "流程状态",
          dataIndex: "appr_stat_code_value",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.appr_stat_code)} />
                {info.appr_stat_code_value}
              </span>
            );
          },
        },
        {
          title: "申请时间",
          dataIndex: "",
          sorter: true,
          width: "9%",
        },
        {
          title: "发布说明",
          dataIndex: "rn",
        },
        {
          title: "审核人",
          dataIndex: "apprd_by_id_value",
        },
        {
          title: "审核时间",
          dataIndex: "apprd_time",
          sorter: true,
          width: "9%",
        },
        {
          title: "审核意见",
          dataIndex: "wf_rec",
          render: (_, record) => {
            return <div>{record?.wf_rec[0]?.apprd_opn}</div>;
          },
        },
        {
          title: "操作",
          width: "11%",
          dataIndex: "操作",
          render: (_, record) => {
            return (
              <div className="operation" key={record.key}>
                {record.appr_stat_code === "IN_APPROVAL" ||
                record.appr_stat_code === "APPROVED" ||
                record.appr_stat_code === "REJECTED" ? (
                  <span
                    style={{
                      color: "#006AB2",
                      marginRight: "16px",
                    }}
                    onClick={() => recallItem(record)}
                  >
                    撤回
                  </span>
                ) : null}
                {record.appr_stat_code === "REJECTED" ? (
                  <span
                    style={{
                      color: "#006AB2",
                      marginRight: "16px",
                    }}
                    onClick={() => {
                      setReleaseDrawerVisible(true);
                      setSubmitModelDesignData([record]);
                    }}
                  >
                    重新提交
                  </span>
                ) : null}
              </div>
            );
          },
        },
      ]);
    } else if (tableName === "ToCheck") {
      setColumns([
        {
          title: "表中文名",
          dataIndex: "table_cmnt",
          sorter: true,
        },
        {
          title: "表名",
          dataIndex: "table_name",
          sorter: true,
        },
        {
          title: "变更类型",
          dataIndex: "chg_type_code_value",
          width: "7%",
          sorter: true,
        },
        {
          title: "当前版本",
          dataIndex: "cur_ver_no",
        },
        {
          title: "申请人",
          dataIndex: "apply_by_id_value",
        },
        {
          title: "申请时间",
          dataIndex: "apply_time",
          sorter: true,
          width: "9%",
        },
        {
          title: "发布说明",
          dataIndex: "rn",
        },
        {
          title: "操作",
          width: "12%",
          dataIndex: "操作",
          render: (_, record) => {
            return (
              <div className="operation" key={record.key}>
                <span
                  style={{
                    color: "#006AB2",
                    marginRight: "16px",
                  }}
                  onClick={() => {
                    getMetaDataId(record?.table_id);
                    setWfId(record?.wf_id);
                  }}
                >
                  详情
                </span>
                <span
                  style={{
                    color: "#006AB2",
                    marginRight: "16px",
                  }}
                  onClick={() => agreeItem([record?.wf_id], [record?.table_id])}
                >
                  同意
                </span>
                <span
                  style={{
                    color: "#006AB2",
                  }}
                  onClick={() =>
                    rejectItem([record?.wf_id], [record?.table_id])
                  }
                >
                  拒绝
                </span>
              </div>
            );
          },
        },
      ]);
    } else if (tableName === "check") {
      setColumns([
        {
          title: "表中文名",
          dataIndex: "table_cmnt",
          sorter: true,
        },
        {
          title: "表名",
          dataIndex: "table_name",
          sorter: true,
        },
        {
          title: "变更类型",
          dataIndex: "chg_type_code_value",
          width: "7%",
          sorter: true,
        },
        {
          title: "发布版本",
          dataIndex: "rls_ver_no",
        },
        {
          title: "流程状态",
          dataIndex: "appr_stat_code_value",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.appr_stat_code)} />
                {info.appr_stat_code_value}
              </span>
            );
          },
        },
        {
          title: "申请人",
          dataIndex: "apply_by_id_value",
        },
        {
          title: "申请时间",
          dataIndex: "apply_time",
          sorter: true,
          width: "9%",
        },
        {
          title: "发布说明",
          dataIndex: "rn",
        },
        {
          title: "审核人",
          dataIndex: "apprd_by_id_value",
        },
        {
          title: "审核时间",
          dataIndex: "apprd_time",
          sorter: true,
          width: "9%",
        },
        {
          title: "审核意见",
          dataIndex: "apprd_opn",
          render: (_, record) => {
            return <div>{record?.wf_rec[0]?.apprd_opn}</div>;
          },
        },
      ]);
    }
    setTotal(defaultData?.total);
    setPageNumber(defaultData?.page);
    setPageSize(defaultData?.size);
  }, [defaultData]);

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  // 改变表格排序
  const tableChange = (pagination, filters, sorter, extra) => {
    if (sorter.order !== undefined) {
      changeOrders(sorter);
    }
  };

  // 批量删除选项
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowList(selectedRows);
  };

  // 表格第一项多选
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      name: record.chg_type_code,
    }),
  };

  // 暴露表格给父组件
  useImperativeHandle(ref, () => ({
    selectedRowList,
    setDetailDrawerVisible,
  }));

  // 获取模型详情的信息
  const getModelDesignInfo = () => {
    setDetailDrawerLoading(true);
  };

  // 模型id 转 元数据id
  const getMetaDataId = (id) => {
    projectApi.getMetaDataId({ table_id: id }).then((res) => {
      if (res.code === 200) {
        setModelMetaInfo(res.data);
        getmetaDataInfo(res.data);
      } else {
        setModelMetaInfo({});
        message.info("该模型尚未部署，暂时不能查看详情");
      }
    });
  };

  // 获取元数据详情的信息
  const getmetaDataInfo = (ids) => {
    setDetailDrawerLoading(true);
    setDetailDrawerVisible(true);
    setAgreeFlag(true);
    setDetailId(ids.metadata_id);

    // 获取标签信息
    projectApi
      .getMetadataTag({ metadata_ver_id: ids?.metadata_ver_id })
      .then((res) => {
        if (res.code === 200) {
          setChooseList(res.data[ids?.metadata_ver_id]);
        } else {
          setChooseList([]);
        }
      });

    projectApi
      .getMetadataTable({ metadata_ver_id: ids?.metadata_ver_id })
      .then((res) => {
        if (res.code === 200) {
          metadataDetail(res.data, ids?.metadata_ver_id);
        } else {
          message.info("获取元数据详情失败");
        }
      });
  };

  // 元数据详情信息
  const metadataDetail = (data, id) => {
    projectApi
      .getBloodrRelation({
        metadata_ver_id: id,
      })
      .then((res) => {
        if (res.code === 200) {
          setRelationList(res.data);
        } else {
          setRelationList([]);
        }
      });

    // 表数据详情（修改模型的时候选择）
    projectApi.getMetaDBTable(data.metadata_ver_id).then((res) => {
      if (res.code === 200) {
        setModelDesignInfo({ ...data, table_info: res.data });
        setDetailDrawerLoading(false);
      } else {
        setModelDesignInfo(data);
        setDetailDrawerLoading(false);
      }
    });
  };

  // 获取版本
  const getMetadataVerson = (id) => {
    projectApi.getMetadataVerson({ metadata_id: id }).then((res) => {
      if (res.code === 200) {
        setMetadataVersonList(res.data);
      } else {
        setMetadataVersonList([]);
      }
    });
  };

  useEffect(() => {
    if (DetailDrawerVisible) getMetadataVerson(DetailId);
  }, [DetailDrawerVisible]);

  // 发布审核
  const releaseDrawerSuccess = (data) => {
    submitModelDesign(data);
  };

  // 发布审核请求
  const submitModelDesign = (data) => {
    const info = {
      wf_id: submitModelDesignData[0]?.wf_id,
      remarks: data?.remarks,
      table_id: data?.table_id[0],
    };
    projectApi.modelResubmit(info).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setReleaseDrawerVisible(false);
        setTableLoading(true);
        getModelReleaseData();
      } else {
        message.error(res.message);
      }
    });
  };

  return (
    <div className="ModelCheckTable">
      <div className="tableContent" ref={ModelCheckTableRef}>
        <Table
          className="myFormTable"
          pagination={false}
          columns={columns}
          dataSource={data}
          rowKey={(record, id) => id}
          onChange={tableChange}
          scroll={{
            y: "calc(100vh - 350px)",
          }}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
          loading={
            tableLoading
              ? {
                  indicator: (
                    <ReactSVG
                      src={LoadingSvg}
                      className="anticon-spin anticon myLoadingIcon"
                    />
                  ),
                }
              : false
          }
          rowSelection={activeKey === "2" ? rowSelection : null}
        />
        {total >= 10 ? (
          <div className="paginationBox">
            <Pagination
              className="myPagination"
              showQuickJumper
              total={total}
              current={pageNumber}
              pageSize={pageSize}
              pageSizeOptions={[10, 20, 50, 100]}
              onChange={onChange}
              showTotal={(total) => `共 ${total} 条`}
              showSizeChanger={true}
            />
          </div>
        ) : null}
      </div>

      <DetailDrawer
        visible={DetailDrawerVisible}
        defaultData={ModelDesignInfo}
        onCancel={() => {
          setDetailDrawerVisible(false);
        }}
        metadataVersoList={metadataVersoList}
        relationList={relationList}
        loading={DetailDrawerLoading}
        getModelDesignInfo={getModelDesignInfo}
        chooseList={chooseList}
        // metaDataCode={metaDataCode}
        MetadataStorageList={MetadataStorageList}
        modelMetaInfo={modelMetaInfo}
        agreeFlag={agreeFlag}
        agreeItem={agreeItem}
        rejectItem={rejectItem}
        getmetaDataInfo={getmetaDataInfo}
        wfId={wfId}
      ></DetailDrawer>

      <ReleaseDrawer
        title="模型提交发布"
        onOk={releaseDrawerSuccess}
        visible={ReleaseDrawerVisible}
        onCancel={() => {
          setReleaseDrawerVisible(false);
        }}
        submitModelDesignData={submitModelDesignData}
      ></ReleaseDrawer>
    </div>
  );
};

export default forwardRef(ModelCheckTable);
