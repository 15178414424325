import "./index.scss";
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import DropdownSvg from "../..//assets/下拉.svg";
import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  message,
  Radio,
  Spin,
  Space,
  Modal,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import MessageModal from "../MessageModal";
import { useSelector } from "react-redux";
import projectApi from "../../apis/project";
import { add } from "lodash";

const { TextArea } = Input;
const { Option } = Select;

const StandardDialogDrawer = (props) => {
  const [form] = Form.useForm();
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);

  // 标准类型
  const dataTypeList = dicCodeInfoList?.DG_DATA_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 确定触发的回调 */
    onOk,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    /* 初始值 */
    defaultData,
    visible,
    /* 判断新增还是修改 */
    addNew,
    /* 判断新增父主题域or子主题域 */
    addChild,
    layerList,
  } = props;
  const [coltypecode, setColtypecode] = useState(null);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  useEffect(() => {
    form.resetFields();
    setColtypecode(defaultData?.col_data_type_code);
  }, [defaultData]);

  useEffect(() => {
    if (!visible) {
      setColtypecode(null);
    }
  }, [visible]);

  // 获取数据元标准被模型使用详情
  const getEleUsed = (id, value) => {
    projectApi.getEleUsed(id).then((res) => {
      if (res.code === 200) {
        if (res.data?.length !== 0) {
          MessageModal({
            type: "warning",
            data: res.data,
            title: "确认修改",
            okText: "确定",
            name: "数据元",
            onOk: () => {
              onOk && onOk(value, title, defaultData, addChild);
            },
          });
        } else {
          onOk && onOk(value, title, defaultData, addChild);
        }
      }
    });
  };

  const onFinish = (value) => {
    if (title === "修改数据元") {
      getEleUsed(defaultData?.data_ele_id, value);
    } else {
      onOk && onOk(value, title, defaultData, addChild);
    }
  };

  const handleDataTypeChange = (value) => {
    setColtypecode(value);
    if (value !== "NUMERIC" && value !== "VARCHAR") {
      form.resetFields(["col_length"]);
    }
    if (value !== "NUMERIC") {
      form.resetFields(["col_scale"]);
    }
  };

  return (
    <Drawer
      title={title}
      placement="right"
      width="600px"
      className="StandardDialogDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              form.submit();
            }}
            style={{ background: "#016DB4", color: "#fff" }}
          >
            {okText ? okText : "确认"}
          </Button>
          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            {cancelText ? cancelText : "取消"}
          </Button>
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="dialogDrawerForm"
          preserve={false}
          onFinish={onFinish}
        >
          {title?.indexOf("数据分层") !== -1 ? (
            <>
              <Form.Item
                name="data_layer_name"
                label="分层名称"
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "请输入分层名称，不超过100个字符",
                  },
                  {
                    pattern: /^^(.|\n){0,100}$/,
                    message: "分层名称不超过100个字符",
                  },
                ]}
                initialValue={addNew ? null : defaultData?.data_layer_name}
              >
                <Input allowClear placeholder="1-100个字符" />
              </Form.Item>

              <Form.Item
                name="data_layer_eng_name"
                label="英文名称"
                style={{
                  width: "48%",
                }}
                initialValue={addNew ? [] : defaultData?.data_layer_eng_name}
                rules={[
                  {
                    required: true,
                    message: "请输入英文名称，不超过100个字符或符号",
                  },
                  {
                    pattern: /^^(.|\n){0,100}$/,
                    message: "英文名称不超过100个字符或符号",
                  },
                ]}
              >
                <Input allowClear placeholder="1-100个英文字符或符号" />
              </Form.Item>

              <Form.Item
                name="data_layer_eng_abbr"
                label="英文缩写"
                style={{
                  width: "48%",
                }}
                initialValue={addNew ? null : defaultData?.data_layer_eng_abbr}
                rules={[
                  {
                    required: true,
                    message: "请输入英文缩写，不超过12个字符",
                  },
                  {
                    pattern: /^^(.|\n){0,12}$/,
                    message: "英文缩写不超过12个字符",
                  },
                ]}
              >
                <Input allowClear placeholder="1-12个英文字符" />
              </Form.Item>

              {/* 描述 */}
              <Form.Item
                name="data_layer_desc"
                label="描述"
                style={{
                  width: "100%",
                }}
                initialValue={addNew ? null : defaultData?.data_layer_desc}
              >
                <TextArea allowClear autoSize={{ minRows: 5 }} />
              </Form.Item>
            </>
          ) : null}

          {title?.indexOf("主题域") !== -1 ? (
            <>
              <Form.Item
                name="sub_name"
                label="主题域名称"
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "请输入主题域名称，不超过100个字符",
                  },
                  {
                    pattern: /^^(.|\n){0,100}$/,
                    message: "主题域名称不超过100个字符",
                  },
                ]}
                initialValue={addNew ? null : defaultData?.sub_name}
              >
                <Input allowClear placeholder="1~100个字符" />
              </Form.Item>

              <Form.Item
                name="sub_eng_name"
                label="英文名称"
                style={{
                  width: "48%",
                }}
                initialValue={addNew ? null : defaultData?.sub_eng_name}
                rules={[
                  {
                    required: true,
                    message: "请输入英文名称，不超过100个字符或符号",
                  },
                  {
                    pattern: /^^(.|\n){0,100}$/,
                    message: "英文名称不超过100个字符或符号",
                  },
                ]}
              >
                <Input allowClear placeholder="1~100个英文字符或符号" />
              </Form.Item>

              <Form.Item
                name="sub_eng_abbr"
                label="英文缩写"
                style={{
                  width: "48%",
                }}
                initialValue={addNew ? null : defaultData?.sub_eng_abbr}
                rules={[
                  {
                    required: true,
                    message: "请输入英文缩写，不超过12个字符",
                  },
                  {
                    pattern: /^^(.|\n){0,12}$/,
                    message: "英文缩写不超过12个字符",
                  },
                ]}
              >
                <Input allowClear placeholder="1~12个英文字符" />
              </Form.Item>

              <Form.Item
                name="data_layer_id"
                label="归属数据分层"
                style={{
                  width: "48%",
                }}
                initialValue={defaultData?.data_layer_id}
                rules={[{ required: true, message: "请选择归属数据分层" }]}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch={true}
                  disabled={addChild === true}
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                >
                  {layerList?.map((item) => {
                    return (
                      <Option
                        value={item.data_layer_id}
                        key={item.data_layer_id}
                      >
                        {item.data_layer_name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {addChild ? (
                <Form.Item
                  name="parent_sub_id"
                  label="父主题域"
                  style={{
                    width: "48%",
                  }}
                  initialValue={
                    addChild !== true ? null : defaultData?.sub_name
                  }
                >
                  <Input controls={false} style={{ width: "270px" }} disabled />
                </Form.Item>
              ) : (
                <Form.Item
                  name="parent_sub_id"
                  label="父主题域"
                  style={{
                    width: "48%",
                  }}
                  initialValue={defaultData?.parent_sub_id}
                >
                  <Input controls={false} style={{ width: "270px" }} disabled />
                </Form.Item>
              )}

              {/* 描述 */}
              <Form.Item
                name="sub_desc"
                label="描述"
                style={{
                  width: "100%",
                }}
                initialValue={addNew ? null : defaultData?.sub_desc}
              >
                <TextArea allowClear autoSize={{ minRows: 5 }} />
              </Form.Item>
            </>
          ) : null}

          {title?.indexOf("词根") !== -1 ? (
            <>
              <Form.Item
                name="word_root_eng"
                label="英文全称"
                style={{
                  width: "48%",
                }}
                initialValue={addNew ? null : defaultData?.word_root_eng}
                rules={[
                  {
                    required: true,
                    message: "请输入英文全称，不超过100个英文字符或符号",
                  },
                  {
                    pattern: /^^(.|\n){0,100}$/,
                    message: "英文全称不超过100个英文字符或符号",
                  },
                ]}
              >
                <Input allowClear placeholder="1~100个英文字符或符号" />
              </Form.Item>

              <Form.Item
                name="word_root_eng_abbr"
                label="英文缩写"
                style={{
                  width: "48%",
                }}
                initialValue={addNew ? null : defaultData?.word_root_eng_abbr}
                rules={[
                  {
                    required: true,
                    message: "请输入英文缩写，不超过100个字符或符号",
                  },
                  {
                    pattern: /^^(.|\n){0,100}$/,
                    message: "英文缩写不超过100个字符或符号",
                  },
                ]}
              >
                <Input allowClear placeholder="1~100个英文字符" />
              </Form.Item>

              <Form.Item
                name="word_root_chi"
                label="中文"
                style={{
                  width: "100%",
                }}
                rules={[
                  {
                    required: true,
                    message: "请输入中文，支持输入多个中文，用“,”分割",
                  },
                ]}
                initialValue={addNew ? null : defaultData?.word_root_chi}
              >
                <TextArea
                  allowClear
                  autoSize={{ minRows: 5 }}
                  placeholder="支持输入多个中文，用“,”分割"
                />
              </Form.Item>

              {/* 描述 */}
              <Form.Item
                name="word_root_desc"
                label="描述"
                style={{
                  width: "100%",
                }}
                initialValue={addNew ? null : defaultData?.word_root_desc}
              >
                <TextArea allowClear autoSize={{ minRows: 5 }} />
              </Form.Item>
            </>
          ) : null}

          {title?.indexOf("数据元") !== -1 ? (
            <>
              <Form.Item
                name="col_cmnt"
                label="中文名称"
                style={{
                  width: "100%",
                }}
                initialValue={addNew ? null : defaultData?.col_cmnt}
                rules={[
                  {
                    required: true,
                    message: "请输入中文名称，不超过100个字符",
                  },
                  {
                    pattern: /^^(.|\n){0,100}$/,
                    message: "中文名称不超过100个字符",
                  },
                ]}
              >
                <Input allowClear placeholder="1~100个字符" />
              </Form.Item>

              <Form.Item
                name="col_name"
                label="字段名"
                style={{
                  width: "48%",
                }}
                initialValue={addNew ? null : defaultData?.col_name}
                rules={[
                  {
                    required: true,
                    message: "请输入字段名，不超过64个字符",
                  },
                  {
                    pattern: /^^(.|\n){0,100}$/,
                    message: "字段名不超过100个字符",
                  },
                ]}
              >
                <Input allowClear placeholder="1~64个英文字符、数字或_" />
              </Form.Item>

              <Form.Item
                name="col_data_type_code"
                label="字段类型"
                style={{
                  width: "48%",
                }}
                initialValue={addNew ? null : defaultData?.col_data_type_code}
                rules={[{ required: true, message: "请选择字段类型" }]}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch={true}
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  onChange={handleDataTypeChange}
                >
                  {dataTypeList?.map((item) => {
                    return (
                      <Option value={item} key={item}>
                        {dicCodeInfoList.DG_DATA_TYPE.map(
                          (value) => value[item]
                        )}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="col_length"
                label="长度"
                style={{
                  width: "48%",
                }}
                initialValue={addNew ? null : defaultData?.col_length}
              >
                <InputNumber
                  placeholder="请输入整数"
                  controls={false}
                  style={{ width: "270px" }}
                  disabled={
                    coltypecode !== "NUMERIC" && coltypecode !== "VARCHAR"
                  }
                />
                {coltypecode !== "NUMERIC" && coltypecode !== "VARCHAR" ? (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    仅支持精确数值型、变长字符型输入
                  </span>
                ) : null}
              </Form.Item>

              <Form.Item
                name="col_scale"
                label="小数位"
                style={{
                  width: "48%",
                }}
                initialValue={addNew ? null : defaultData?.col_scale}
              >
                <InputNumber
                  placeholder="请输入整数"
                  controls={false}
                  style={{ width: "270px" }}
                  disabled={coltypecode !== "NUMERIC"}
                />
                {coltypecode !== "NUMERIC" ? (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    仅支持精确数值型输入
                  </span>
                ) : null}
              </Form.Item>

              {/* 描述 */}
              <Form.Item
                name="data_ele_desc"
                label="描述"
                style={{
                  width: "100%",
                }}
                initialValue={addNew ? null : defaultData?.data_ele_desc}
              >
                <TextArea allowClear autoSize={{ minRows: 5 }} />
              </Form.Item>
            </>
          ) : null}
        </Form>
      </Scrollbars>
    </Drawer>
  );
};

export default StandardDialogDrawer;
