import "./index.scss";
import { Tabs, message, Button, Select, Input } from "antd";
import { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import { useGetState } from "ahooks";
import { useSelector } from "react-redux";
import projectApi from "../../../apis/project";
import Scrollbars from "react-custom-scrollbars";
import DropdownSvg from "../../../assets/下拉.svg";
import ModelCheckTable from "./components/ModelCheckTable";
import MessageModal from "../../../components/MessageModal";
import { debounce } from "../../../unit/unit";

const { TabPane } = Tabs;
const { Option } = Select;
const ModelCheck = (props) => {
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);
  const ModelCheckTableRef = useRef();
  // 变更类型
  const changeTypeValue = dicCodeInfoList?.DG_CHG_TYPE
    ? [{ all: "全部" }, ...dicCodeInfoList?.DG_CHG_TYPE]
    : [];
  const changeType = changeTypeValue?.map((item) => Object.keys(item)).flat();

  // 流程状态
  const statueTypeValue = dicCodeInfoList?.DG_WF_APPR_STAT
    ? [{ all: "全部" }, ...dicCodeInfoList?.DG_WF_APPR_STAT]
    : [];
  const statueType = statueTypeValue?.map((item) => Object.keys(item)).flat();

  // 已审核
  const checkApprState = () => {
    return statueType?.filter(
      (item) => item === "APPROVED" || item === "REJECTED"
    );
  };

  const [orders, setOrders] = useState(["apply_time", "desc"]);
  const [tableLoading, setTableLoading] = useState(false);
  const [activeKey, setActiveKey, getActiveKey] = useGetState("1");
  const [changeTypeCode, setChangeTypeCode] = useState("all"); // 我的申请变更类型
  const [tocheckTypeCode, setTockeckTypeCode] = useState("all"); // 待审核变更类型
  const [checkTypeCode, setCkeckTypeCode] = useState("all"); // 已审核变更类型

  const [statueTypeCode, setStatueTypeCode] = useState("all"); // 我的申请流程状态
  const [checkStatueTypeCode, setCheckStatueTypeCode] = useState("APPROVED"); // 已审核流程状态

  const [applyValue, setApplyValue] = useState(""); // 我的申请(表名/中文名)
  const [tocheckValue, setTockeckValue] = useState(""); // 待审核(表名/中文名)
  const [checkValue, setCkeckValue] = useState(""); // 已审核(表名/中文名)

  const [params, setParams] = useState({ page: 1, size: 10 });
  const [toCheckList, setToCheckList] = useState({}); // 待审核
  const [myapplyList, setMyapplyList] = useState({}); // 我的申请
  const [checkList, setCheckList] = useState({}); // 已审核
  const [orderInfo, setOrderInfo] = useState({}); // 排序对象

  const [MetadataStorageList, setMetadataStorageList] = useState([]); // 数据存储列表

  // 数据存储列表查询
  const getMetadataStorage = () => {
    projectApi.getMetadataStorage().then((res) => {
      if (res.code === 200) {
        setMetadataStorageList(res.data.items);
      } else {
        setMetadataStorageList([]);
      }
    });
  };
  useEffect(() => {
    getMetadataStorage();
  }, []);

  // tab切换
  const tabChange = (key) => {
    setActiveKey(key);
    if (key === "2") {
      setOrders(["apply_time", "desc"]);
      setParams({ page: 1, size: 10 });
      setOrderInfo({});
    } else if (key === "3") {
      setOrders(["appr_time", "desc"]);
      setParams({ page: 1, size: 10 });
      setOrderInfo({});
    } else if (key === "1") {
      setOrders(["apply_time", "desc"]);
      setParams({ page: 1, size: 10 });
      setOrderInfo({});
    }
  };

  // 改变分页数据
  const changePagination = (page, pageSize) => {
    setParams({ page: page, size: pageSize });
  };

  // 模型发布查询
  const getModelReleaseData = () => {
    let param = {
      order_list: orders.toString(),
      ...params,
    };
    if (activeKey === "2") {
      param.appr_stat_code = "IN_APPROVAL";
    }
    if (activeKey === "3") {
      param.appr_stat_code = checkStatueTypeCode;
    }
    if (applyValue !== "" && activeKey === "1") {
      param.model_name = applyValue;
    }
    if (tocheckValue !== "" && activeKey === "2") {
      param.model_name = tocheckValue;
    }
    if (checkValue !== "" && activeKey === "3") {
      param.model_name = checkValue;
    }
    if (changeTypeCode !== "all" && activeKey === "1") {
      param.chg_type_code = changeTypeCode;
    }
    if (tocheckTypeCode !== "all" && activeKey === "2") {
      param.chg_type_code = tocheckTypeCode;
    }
    if (checkTypeCode !== "all" && activeKey === "3") {
      param.chg_type_code = checkTypeCode;
    }
    if (statueTypeCode !== "all" && activeKey === "1") {
      param.appr_stat_code = statueTypeCode;
    }

    projectApi.getModelReleaseData(param).then((res) => {
      if (res.code === 200) {
        if (activeKey === "1") {
          setMyapplyList(res.data);
        } else if (activeKey === "2") {
          setToCheckList(res.data);
        } else if (activeKey === "3") {
          setCheckList(res.data);
        }
        setTableLoading(false);
      } else {
        if (activeKey === "1") {
          setMyapplyList({});
        } else if (activeKey === "2") {
          setToCheckList({});
        } else if (activeKey === "3") {
          setCheckList({});
        }
        setTableLoading(false);
      }
    });
  };
  useEffect(() => {
    setTableLoading(true);
    getModelReleaseData();
  }, [
    statueTypeCode,
    checkStatueTypeCode,
    changeTypeCode,
    tocheckTypeCode,
    checkTypeCode,
    applyValue,
    tocheckValue,
    checkValue,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTableLoading(false);
    }, 500);
    // 清除定时器
    return () => clearInterval(timer);
  }, [toCheckList, myapplyList, checkList]);

  useEffect(() => {
    setTableLoading(true);
    getModelReleaseData();
  }, [params, orders]);

  // 排序
  const changeOrders = (data) => {
    setOrderInfo(data);
    const str = "_value";
    setOrders([
      data.field.includes("_value")
        ? data.field.split(str).join("")
        : data.field,
      data.order === "descend" ? "desc" : "asc",
    ]);
    setParams({ page: 1, size: 10 });
  };

  // useEffect(() => {
  //   if (Object?.values(orderInfo)?.length !== 0) {
  //     setTableLoading(true);
  //     getModelReleaseData();
  //   }
  // }, [orders]);

  // 同意审核
  const agreeItem = (ids, tableId) => {
    MessageModal({
      description: "确定同意发布吗？",
      title: "同意发布",
      okText: "同意",
      onOk: () => {
        projectApi
          .modelReleaseAudit({
            wf_appr_type_code: "APPROVE",
            wf_ids: ids,
            table_id: tableId,
          })
          .then((res) => {
            if (res.code === 200) {
              message.success(res.message);
              setTableLoading(true);
              getModelReleaseData();
              ModelCheckTableRef.current.setDetailDrawerVisible(false);
            } else {
              message.error(res.message);
            }
          });
      },
    });
  };
  // 批量同意审核
  const batchAgreeItem = () => {
    const slectRowList = ModelCheckTableRef.current.selectedRowList.map(
      (item, index) => {
        return item.wf_id;
      }
    );
    const tableIds = ModelCheckTableRef.current.selectedRowList.map(
      (item, index) => {
        return item.table_id;
      }
    );
    if (slectRowList.length === 0) {
      message.info("请选择需要同意的模型");
    } else {
      agreeItem(slectRowList, tableIds);
    }
  };

  // 撤回审核
  const recallItem = (data) => {
    MessageModal({
      type: "warning",
      description: "确定要撤回发布吗？",
      title: "撤回发布",
      okText: "撤回",
      onOk: () => {
        projectApi
          .canceledModel({
            wf_id: data?.wf_id,
            table_id: data?.table_id,
          })
          .then((res) => {
            if (res.code === 200) {
              message.success(res.message);
              setTableLoading(true);
              getModelReleaseData();
            } else {
              message.error(res.message);
            }
          });
      },
    });
  };

  // 批量驳回拒绝审核
  const batchRejectItem = () => {
    const slectRowList = ModelCheckTableRef.current.selectedRowList.map(
      (item, index) => {
        return item.wf_id;
      }
    );
    const tableIds = ModelCheckTableRef.current.selectedRowList.map(
      (item, index) => {
        return item.table_id;
      }
    );
    if (slectRowList.length === 0) {
      message.info("请选择需要驳回的模型");
    } else {
      rejectItem(slectRowList, tableIds);
    }
  };

  // 驳回拒绝审核
  const rejectItem = (ids, tableId) => {
    MessageModal({
      type: "warning",
      title: "驳回发布",
      rejectTitle: "驳回意见",
      onOk: (item) => {
        projectApi
          .modelReleaseAudit({
            wf_appr_type_code: "REJECT",
            wf_ids: ids,
            audit_opinion: item?.apprd_opn,
            table_id: tableId,
          })
          .then((res) => {
            if (res.code === 200) {
              message.success(res.message);
              setTableLoading(true);
              getModelReleaseData();
              ModelCheckTableRef.current.setDetailDrawerVisible(false);
            } else {
              message.error(res.message);
            }
          });
      },
    });
  };

  return (
    <Scrollbars
      autoHide={true}
      autoHideTimeout={5000}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <div className="ModelCheckContent">
        <Tabs className="myTabs" onChange={tabChange} defaultActiveKey="1">
          <TabPane tab="我的申请" key="1">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">变更类型</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      value={changeTypeCode}
                      onChange={(e) => setChangeTypeCode(e)}
                    >
                      {changeType?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {changeTypeValue?.map((value) => value[item])}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="label">流程状态</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => setStatueTypeCode(e)}
                      value={statueTypeCode}
                    >
                      {statueType?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {statueTypeValue?.map((value) => value[item])}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="label">表名/中文名</div>
                  <div className="value">
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      onChange={debounce(
                        (e) => setApplyValue(e.target.value),
                        1000
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <ModelCheckTable
                  tableName="myapply"
                  activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  changePagination={changePagination}
                  defaultData={myapplyList}
                  changeOrders={changeOrders}
                  recallItem={recallItem}
                  setTableLoading={setTableLoading}
                  getModelReleaseData={getModelReleaseData}
                ></ModelCheckTable>
              </div>
            </div>
          </TabPane>
          <TabPane tab="待审核" key="2">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">变更类型</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => setTockeckTypeCode(e)}
                      value={tocheckTypeCode}
                    >
                      {changeType?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {changeTypeValue?.map((value) => value[item])}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="label">表名/中文名</div>
                  <div className="value">
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      onChange={debounce(
                        (e) => setTockeckValue(e.target.value),
                        1000
                      )}
                    />
                  </div>
                </div>
                <div className="btnBox">
                  <Button type="primary" onClick={batchAgreeItem}>
                    批量同意
                  </Button>
                  <Button type="primary" onClick={batchRejectItem}>
                    批量驳回
                  </Button>
                </div>
              </div>
              <div className="infoBox">
                <ModelCheckTable
                  tableName="ToCheck"
                  ref={ModelCheckTableRef}
                  activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  changePagination={changePagination}
                  changeOrders={changeOrders}
                  defaultData={toCheckList}
                  rejectItem={rejectItem}
                  agreeItem={agreeItem}
                  MetadataStorageList={MetadataStorageList}
                ></ModelCheckTable>
              </div>
            </div>
          </TabPane>
          <TabPane tab="已审核" key="3">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">变更类型</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => setCkeckTypeCode(e)}
                      value={checkTypeCode}
                    >
                      {changeType?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {changeTypeValue?.map((value) => value[item])}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="label">流程状态</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => setCheckStatueTypeCode(e)}
                      value={checkStatueTypeCode}
                    >
                      {checkApprState()?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {dicCodeInfoList?.DG_WF_APPR_STAT?.map(
                              (value) => value[item]
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="label">表名/中文名</div>
                  <div className="value">
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      onChange={debounce(
                        (e) => setCkeckValue(e.target.value),
                        1000
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <ModelCheckTable
                  tableName="check"
                  activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  changePagination={changePagination}
                  changeOrders={changeOrders}
                  defaultData={checkList}
                ></ModelCheckTable>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Scrollbars>
  );
};
export default ModelCheck;
