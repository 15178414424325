import { Menu, Layout } from "antd";
import { useEffect, useState } from "react";
import { useGetState } from "ahooks";
import { useNavigate, useLocation } from "react-router-dom";
import { createFromIconfontCN } from "@ant-design/icons";
import "./index.scss";
import logo from "../../assets/logo.png";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import Scrollbars from "react-custom-scrollbars";

const MyIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_3354122_puxmtsyxun9.js",
});

const { Sider } = Layout;

export default function Sidebar(props) {
  const menus = useSelector((state) => state.User.routers);
  const userInfo = useSelector((state) => state.User.userInfo);
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  const history = useNavigate();
  const [url, setUrl, getUrl] = useGetState("");
  const [openUrl, setOpenUrl, getOpenUrl] = useGetState("");
  const [urlList, setUrlList, getUrlList] = useGetState([]);
  const [breadcrumbName, setBreadcrumbName] = useState("");
  const [breadcrumbMenus, setBreadcrumbMenus] = useState([]);
  const [openKeys, setOpenKeys] = useState([]); // 初始化展开的菜单项
  useEffect(() => {
    findBreadcrumb(location.pathname, breadcrumbMenus);
  }, [location]);

  useEffect(() => {
    let menuList = [];
    menus[0]?.children?.forEach((item) => {
      menuList.push(item);
      if (item?.children && item?.children.length > 0) {
        menuList = menuList.concat(item?.children);
      }
    });
    if (menuList.map((item) => item.menu_url).includes(location.pathname)) {
      setUrl(location.pathname);
      const arr = location.pathname.split("/");
      setOpenUrl("/" + arr[1]);
      localStorage.setItem("openUrl", "/" + arr[1]);
    } else {
      if (location.pathname.split("/").length === 3) {
        setUrl("/" + location.pathname.split("/")[1]);
      } else if (location.pathname.split("/").length === 4) {
        setUrl(
          "/" +
            location.pathname.split("/")[1] +
            "/" +
            location.pathname.split("/")[2]
        );
      } else if (location.pathname.split("/").length === 5) {
        setUrl("/" + location.pathname.split("/")[1]);
      }

      const arr = location.pathname.split("/");
      setOpenUrl("/" + arr[1]);
      localStorage.setItem("openUrl", "/" + arr[1]);
    }
  }, [location.pathname, menus]);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const [collapsed, setCollapsed] = useState(false);
  const findBreadcrumb = (path, breadcrumbMenus) => {
    let result = breadcrumbMenus.find((item) => item.menu_url == path);
    setBreadcrumbName(result?.breadcrumb);
  };
  const to = (item) => {
    if (item.menu_url && item.open_mode === "CURRENT_PAGE") {
      history(item.menu_url);
      if (item.parent_name) {
        setBreadcrumbName(
          <span>
            <span style={{ color: "rgb(0,0,0,.3)" }}>
              {item.parent_name + " / "}
            </span>
            <span>{item.menu_name}</span>
          </span>
        );
      } else {
        setBreadcrumbName(item.menu_name);
      }
      if (item.menu_url === location.pathname) {
        window.location.reload();
      }
    } else if (
      item.menu_url &&
      item.open_mode === "NEW_PAGE" &&
      item.menu_name === "技术文档"
    ) {
      const showName = userInfo.show_name;
      const url = `${item.menu_url}?show_name=${encodeURIComponent(showName)}`;
      window.open(url, "_blank");
    } else if (item.menu_url && item.open_mode === "NEW_PAGE") {
      window.open(item.menu_url);
    }
  };
  const initMenusDate = async (menusList) => {
    let arr = [];
    let init = (list, parentName) => {
      return list?.map((item) => {
        const obj = JSON.parse(JSON.stringify(item));
        obj.breadcrumb =
          parentName + (parentName == "" ? "" : " / ") + obj.menu_name;
        arr.push(obj);
        if (obj.children && obj.children.length > 0) {
          obj.children = init(obj.children, obj.breadcrumb);
        }
        return obj;
      });
    };
    let menusCopy = init(menusList, "");

    setBreadcrumbMenus(arr);
    findBreadcrumb(pathname, arr);
  };

  useEffect(() => {
    initMenusDate(menus);
  }, [menus]);

  const initMenu = (list, isChildren) => {
    list = list?.map((item) => {
      if (item?.children && item?.children.length > 0) {
        return (
          <Menu.SubMenu
            // icon={item.icon}
            icon={isChildren ? null : <ReactSVG src={item?.icon}></ReactSVG>}
            title={item.menu_name}
            key={item.menu_url || item.menu_code}
          >
            {initMenu(item?.children, true)}
          </Menu.SubMenu>
        );
      }
      return (
        <Menu.Item
          key={item.menu_url || item.menu_code}
          icon={
            isChildren ? null : (
              <ReactSVG
                style={{ width: "16px", height: "16px" }}
                src={item?.icon}
              ></ReactSVG>
            )
          }
        >
          <a onClick={() => to(item)}>{item.menu_name}</a>
        </Menu.Item>
      );
    });
    return list;
  };

  return (
    <div className="sidebarContainer">
      <Sider
        trigger={null}
        width={216}
        style={{
          background: "linear-gradient(223deg, #006bb3 0%, #15d2d6 100%)",
          height: "100%",
          borderTopRightRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
        className="site-layout-background"
        collapsible
        collapsed={collapsed}
        collapsedWidth={50}
      >
        <div className="logo">
          {!collapsed && <img src={logo} className="logoImg" alt="" />}
          <span>
            {collapsed ? (
              <MyIcon
                className="anticon-menu-fold"
                type="icon-zhankai"
                onClick={() => setCollapsed(!collapsed)}
              ></MyIcon>
            ) : (
              <MyIcon
                className="anticon-menu-unfold"
                type="icon-shouqi1"
                onClick={() => setCollapsed(!collapsed)}
              ></MyIcon>
            )}
          </span>
        </div>
        <Scrollbars
          className="scrollbars"
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            // height: "calc(100% - 20px)",
            height: "92%",
            width: "100%",
          }}
        >
          <Menu
            mode="inline"
            style={{ height: "100%", borderRight: 0 }}
            selectedKeys={[pathname]}
            defaultOpenKeys={[localStorage.getItem("openUrl")]}
          >
            {initMenu(menus[0]?.children, false)}
          </Menu>
        </Scrollbars>
      </Sider>
    </div>
  );
}
