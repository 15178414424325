import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import G6 from "@antv/g6";
import "./index.scss";
import APIIcon from "assets/API.png";
import DSIcon from "assets/数据存储.png";
import webIcon from "assets/网站.png";
import taleIcon from "assets/表.png";
import projectApi from "../../../../../apis/project";
import MySpin from "../../../../../components/MySpin";

import canvg from "canvg";
import html2canvas from "html2canvas";
import { over } from "lodash";
// import html2canvas from "html2canvas";
const VisualBox = (prop) => {
  const {
    /* 血缘关系 */
    defaultData,
    /* 点击数据本身信息 */
    metaDataInfo,
    metaDataType,
    /* 查看详情 */
    getmetaDataInfo,
    childMethodRef,
    layerSubList,
    // isFetching,
    setFlowLoading,
    setSourceLoading,
    maxwidth,
    maxheight,
  } = prop;
  const [layerData, setLayerData] = useState([]);
  useEffect(() => {
    const layerData = Object.values(layerSubList.DATA_LAYER_RLS)?.map(
      (item) => {
        return {
          id: item.data_layer_id,
          label: item.data_layer_name,
          style: {
            fill: setColors(item.data_layer_id),
          },
        };
      }
    );
    setLayerData(layerData);
  }, [layerSubList]);

  useEffect(() => {
    const data = {
      id: "g1",
      name: metaDataInfo?.chi_name,
      label: metaDataInfo?.eng_name,
      ratio: metaDataInfo?.cons_type_code,
      metadata_ver_id: metaDataInfo?.metadata_ver_id,
      metadata_type_code: metaDataInfo?.metadata_type_code,
      metadata_id: metaDataInfo?.metadata_id,
      rate: 1.0,
      status: metaDataInfo?.data_layer_id,
      children: defaultData.map((item, index) => ({
        id: `g1-${item?.cons_type_code}-${index + 1}`,
        name: item.chi_name,
        label: item.eng_name,
        metadata_type_code: item?.metadata_type_code,
        ratio: item?.cons_type_code,
        metadata_ver_id: item?.metadata_ver_id,
        metadata_id: item?.metadata_id,
        rate: 1.0,
        status: item?.data_layer_id,
      })),
    };
    setRelationList(data);
  }, [defaultData]);
  const [RelationList, setRelationList] = useState({});
  const containerRef = useRef(null);
  const container = containerRef.current;
  let graph = null;
  const width = maxwidth;
  const height = maxheight;
  const englishRegex = /[a-zA-Z]/;
  const chineseRegex = /[\u4e00-\u9fa5]/;
  // 元数据类型切换icon
  const setMetaType = (item) => {
    if (item === "API") {
      return APIIcon;
    } else if (item === "DS") {
      return DSIcon;
    } else if (item === "WEB") {
      return webIcon;
    } else if (item === "TABLE") {
      return taleIcon;
    }
  };

  const updateRelationList = (item, child, visitedVersions = new Set()) => {
    const handleChild = (item, child) => {
      if (item.metadata_ver_id === child.f_metadata_ver_id) {
        if (!item.children) {
          item.children = [];
        }

        // 检查ratio是否与父项一致
        const isRatioMatched = item.ratio === child.cons_type_code;

        if (isRatioMatched) {
          item.children.push({
            id: `${item.id}-${item.children.length + 1}`,
            name: child.chi_name,
            label: child.eng_name,
            metadata_type_code: child.metadata_type_code,
            ratio: child.cons_type_code,
            metadata_ver_id: child.metadata_ver_id,
            metadata_id: child.metadata_id,
            rate: 1.0,
            status: child.data_layer_id,
          });
        }

        visitedVersions.add(child.f_metadata_ver_id);
      }

      if (item.children) {
        item.children.forEach((childItem) => {
          handleChild(childItem, child);
        });
      }
    };

    child.forEach((childItem) => {
      handleChild(item, childItem);
    });
  };

  const removalData = (node) => {
    if (node.children) {
      const uniqueMetadataVerIds = [
        ...new Set(node.children.map((child) => child.metadata_ver_id)),
      ];
      node.children.forEach((child) => {
        if (child.children) {
          child.children = removalData(child);
        }
      });
      node.children = uniqueMetadataVerIds.map((metadataVerId) => {
        return node.children.find(
          (child) => child.metadata_ver_id === metadataVerId
        );
      });
    }

    return node.children;
  };

  const findAndAddItem = (RelationList, child) => {
    if (!RelationList || !RelationList.children) {
      return;
    }

    RelationList.children.forEach((item) => {
      updateRelationList(item, child);
    });

    // 销毁重新渲染
    graph.destroy();

    const removalList = removalData(RelationList);
    // console.log(removalList);
    setRelationList(RelationList);
    initGraph(RelationList);
  };

  //获取单个血缘关系
  const getBloodrRelation = (id, info) => {
    projectApi
      .getBloodrRelation({
        metadata_ver_id: id,
      })
      .then((res) => {
        if (res.code === 200) {
          if (res.data?.length === 0) {
            info === "SOURCE"
              ? message.info("暂无上游数据")
              : message.info("暂无下游数据");
          } else {
            const data = res.data?.filter(
              (item) => item.cons_type_code === info
            );
            // 如果data有数据才重新渲染关系图
            if (data?.length !== 0) {
              findAndAddItem(RelationList, data);
            } else {
              info === "SOURCE"
                ? message.info("暂无上游数据")
                : message.info("暂无下游数据");
            }
          }
        }
      })
      .finally(() => {
        info === "SOURCE" ? setSourceLoading(false) : setFlowLoading(false);
      });
  };

  const setColors = (id) => {
    if (id === 1) {
      return "#6CAEFF";
    } else if (id === 2) {
      return "#FE545F";
    } else if (id === 3) {
      return "#FEC054";
    } else if (id === 4) {
      return "#C6C0FC";
    } else if (id === 5) {
      return "#61D4AD";
    } else if (id === 6) {
      return "#edd1d8";
    } else if (id === 7) {
      return "#b36d61";
    } else if (id === 8) {
      return "#c0ebd7";
    } else if (id === 9) {
      return "#725e82";
    } else if (id === 10) {
      return "#312520";
    } else if (id === 11) {
      return "#003472";
    } else if (id === 12) {
      return "#eaff56";
    } else if (id === 13) {
      return "#cca4e3";
    } else if (id === 14) {
      return "#CCC";
    } else if (id === 15) {
      return "#549688";
    } else if (id === 16) {
      return "#426666";
    }
  };

  // 展示换行处理
  const splitText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }

    const lines = [];
    let currentIndex = 0;

    while (currentIndex < text.length) {
      const remainingText = text.substr(currentIndex);

      if (remainingText.length <= maxLength) {
        lines.push(remainingText);
        currentIndex += remainingText.length;
      } else {
        const line = remainingText.substr(0, maxLength);
        const lastSpaceIndex = line.lastIndexOf(" ");

        if (lastSpaceIndex !== -1) {
          lines.push(line.substr(0, lastSpaceIndex + 1));
          currentIndex += lastSpaceIndex + 1;
        } else {
          lines.push(line);
          currentIndex += maxLength;
        }
      }
    }

    return lines.join("\n");
  };
  // 判断展示内容包含中英文

  // 默认配置
  const defaultConfig = {
    width,
    height,
    modes: {
      default: ["zoom-canvas", "drag-canvas"],
    },
    fitView: true,
    animate: true,
    defaultNode: {
      type: "flow-rect",
    },
    defaultEdge: {
      type: "cubic-horizontal",
      style: {
        stroke: "#D8D8D8",
        // endArrow: true, // 开启箭头
      },
    },
    // defaultEdge: {
    //   type: "flow-line",
    //   sourceAnchor: 0,
    //   targetAnchor: 1,
    //   style: {
    //     radius: 8,
    //     stroke: "#D8D8D8",
    //   },
    // },
    layout: {
      type: "indented",
      direction: "H",
      dropCap: false,
      indent: 300,
      getHeight: () => {
        return 40;
      },
      // 节点的宽度
      getWidth: () => {
        return 40;
      },
      // 节点之间的垂直距离
      getVGap: () => {
        return 25;
      },
      // 节点之间的水平距离
      getHGap: () => {
        return 50;
      },
      getSide: (d) => {
        // 设置左右展示的内容
        if (d.data.ratio === "SOURCE") {
          return "left";
        }
        return "right";
      },
    },
  };
  //  组件props
  const props = {
    data: RelationList,
    config: {
      padding: [20, 50],
      defaultLevel: 3,
      defaultZoom: 0.8,
      modes: { default: ["drag-canvas", "zoom-canvas", "dice-mindmap"] },
    },
  };
  // 自定义节点、边
  const registerFn = () => {
    /**
     * 自定义节点
     */
    G6.registerNode(
      "flow-rect",
      {
        shapeType: "flow-rect",
        draw(cfg, group) {
          const {
            id,
            name = "",
            label,
            collapsed,
            status,
            rate,
            ratio,
            metadata_type_code,
          } = cfg;
          // console.log(`${name.length * 10 + 30}`);
          const rectConfig = {
            width: 205,
            // width:`${name.length}`
            height: 75,
            lineWidth: 1,
            fontSize: 12,
            fill: id !== "g1" ? "#fff" : "rgb(213, 225, 247)",
            radius: 4,
            // 节点边框线的颜色设定
            stroke: id !== "g1" ? "#CED4D9" : "rgb(213, 225, 247)",
            opacity: 1,
          };

          const nodeOrigin = {
            x: -rectConfig.width / 2,
            y: -rectConfig.height / 2,
          };

          const textConfig = {
            textAlign: "left",
            textBaseline: "bottom",
          };

          const rect = group.addShape("rect", {
            attrs: {
              x: nodeOrigin.x,
              y: nodeOrigin.y,
              ...rectConfig,
            },
          });

          const rectBBox = rect.getBBox();

          // 中文名
          group.addShape("text", {
            attrs: {
              ...textConfig,
              x: 8 + nodeOrigin.x,
              y: 28 + nodeOrigin.y,
              // text: name.length > 16 ? name.substr(0, 16) + "..." : name,
              text:
                chineseRegex.test(name) && englishRegex.test(name)
                  ? splitText(name, 21)
                  : englishRegex.test(name) && chineseRegex.test(name) === false
                  ? splitText(name, 29)
                  : splitText(name, 18),
              fontSize: 9,
              opacity: 0.85,
              fill: "#000",
              cursor: "pointer",
            },
            name: "name-shape",
          });

          // 英文名
          const price = group.addShape("text", {
            attrs: {
              ...textConfig,
              x: 8 + nodeOrigin.x,
              y: rectBBox.maxY - 10,
              // text: label.length > 31 ? label.substr(0, 31) + "..." : label,
              // text: splitText(label, 24), // 调用 splitTextByWord 函数，根据单词自适应拆分文本
              text:
                chineseRegex.test(name) && englishRegex.test(name)
                  ? splitText(name, 21)
                  : englishRegex.test(name) && chineseRegex.test(name) === false
                  ? splitText(name, 29)
                  : splitText(name, 18),
              // text: label,
              fontSize: 9,
              fill: "#000",
              opacity: 0.85,
            },
            name: "label-shape",
          });

          // 元数据类型
          const type = group.addShape("image", {
            attrs: {
              ...textConfig,
              x: rectBBox.maxX - 30,
              y: rectBBox.maxY - 49,
              width: 20,
              height: 20,
              img: setMetaType(metadata_type_code),
            },
            draggable: true,
            name: "type-shape",
          });

          // bottom line background
          const bottomBackRect = group.addShape("rect", {
            attrs: {
              x: nodeOrigin.x,
              y: rectBBox.maxY - 4,
              width: rectConfig.width,
              height: 4,
              radius: [0, 0, rectConfig.radius, rectConfig.radius],
              fill: "#E0DFE3",
            },

            name: "bottomBackRect-shape",
          });
          // 底部颜色
          const bottomRect = group.addShape("rect", {
            attrs: {
              x: nodeOrigin.x,
              y: rectBBox.maxY - 4,
              width: rate * rectBBox.width,
              height: 4,
              radius: [0, 0, 0, rectConfig.radius],
              fill: setColors(status),
            },
            name: "bottomRect-shape",
          });

          // collapse rect 收起展开符号控制
          if (cfg.children && cfg.children.length) {
            group.addShape("rect", {
              attrs: {
                x: rectConfig.width / 2 - 8,
                y: -8,
                width: 16,
                height: 16,
                stroke: "rgba(0, 0, 0, 0.25)",
                cursor: "pointer",
                fill: "#fff",
              },
              // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
              name: "collapse-back",
              modelId: cfg.id,
            });
            // collpase text
            group.addShape("text", {
              attrs: {
                x: rectConfig.width / 2,
                y: -1,
                textAlign: "center",
                textBaseline: "middle",
                text: collapsed ? "+" : "-",
                fontSize: 16,
                cursor: "pointer",
                fill: "rgba(0, 0, 0, 0.25)",
              },
              // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
              name: "collapse-text",
              modelId: cfg.id,
            });
          }

          this.drawLinkPoints(cfg, group);
          return rect;
        },
        update(cfg, item) {
          const { level, status, name } = cfg;
          const group = item.getContainer();
          let mask = group.find((ele) => ele.get("name") === "mask-shape");
          let maskLabel = group.find(
            (ele) => ele.get("name") === "mask-label-shape"
          );
          if (level === 0) {
            group.get("children").forEach((child) => {
              if (child.get("name")?.includes("collapse")) return;
              child.hide();
            });
            if (!mask) {
              mask = group.addShape("rect", {
                attrs: {
                  x: -101,
                  y: -30,
                  width: 202,
                  height: 60,
                  opacity: 0,
                  fill: setColors(status),
                },
                // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
                name: "mask-shape",
              });
              // 缩放时候的样式
              maskLabel = group.addShape("text", {
                attrs: {
                  fill: "#fff",
                  fontSize: 13,
                  x: 0,
                  y: 10,
                  text: name.length > 14 ? name.substr(0, 14) + "..." : name,
                  textAlign: "center",
                  opacity: 0,
                },
                // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
                name: "mask-label-shape",
              });
              const collapseRect = group.find(
                (ele) => ele.get("name") === "collapse-back"
              );
              const collapseText = group.find(
                (ele) => ele.get("name") === "collapse-text"
              );
              collapseRect?.toFront();
              collapseText?.toFront();
            } else {
              mask.show();
              maskLabel.show();
            }
            mask.animate({ opacity: 1 }, 200);
            maskLabel.animate({ opacity: 1 }, 200);
            return mask;
          } else {
            group.get("children").forEach((child) => {
              if (child.get("name")?.includes("collapse")) return;
              child.show();
            });
            mask?.animate(
              { opacity: 0 },
              {
                duration: 200,
                callback: () => mask.hide(),
              }
            );
            maskLabel?.animate(
              { opacity: 0 },
              {
                duration: 200,
                callback: () => maskLabel.hide(),
              }
            );
          }
          this.updateLinkPoints(cfg, group);
        },
        setState(name, value, item) {
          if (name === "collapse") {
            const group = item.getContainer();
            const collapseText = group.find(
              (e) => e.get("name") === "collapse-text"
            );
            if (collapseText) {
              if (!value) {
                collapseText.attr({
                  text: "-",
                });
              } else {
                collapseText.attr({
                  text: "+",
                });
              }
            }
          }
        },
        getAnchorPoints() {
          return [
            [0, 0.5],
            [1, 0.5],
          ];
        },
      },
      "rect"
    );

    G6.registerEdge(
      "flow-cubic",
      {
        getControlPoints(cfg) {
          let controlPoints = cfg.controlPoints; // 指定controlPoints
          if (!controlPoints || !controlPoints.length) {
            const { startPoint, endPoint, sourceNode, targetNode } = cfg;
            const {
              x: startX,
              y: startY,
              coefficientX,
              coefficientY,
            } = sourceNode ? sourceNode.getModel() : startPoint;
            const { x: endX, y: endY } = targetNode
              ? targetNode.getModel()
              : endPoint;
            let curveStart = (endX - startX) * coefficientX;
            let curveEnd = (endY - startY) * coefficientY;
            curveStart = curveStart > 40 ? 40 : curveStart;
            curveEnd = curveEnd < -30 ? curveEnd : -30;
            controlPoints = [
              { x: startPoint.x + curveStart, y: startPoint.y },
              { x: endPoint.x + curveEnd, y: endPoint.y },
            ];
          }
          return controlPoints;
        },
        getPath(points) {
          const path = [];
          path.push(["M", points[0].x, points[0].y]);
          path.push([
            "C",
            points[1].x,
            points[1].y,
            points[2].x,
            points[2].y,
            points[3].x,
            points[3].y,
          ]);
          return path;
        },
      },
      "single-line"
    );
  };

  const initGraph = (data) => {
    if (!data) {
      return;
    }
    const { onInit, config } = props;
    const tooltip = new G6.Tooltip({
      offsetX: 20,
      offsetY: 30,
      itemTypes: ["node"],

      getContent(e) {
        const outDiv = document.createElement("div");
        if (e.item._cfg.type == "node") {
          // outDiv.style.width = "120px";
          const metaInfo = e?.item?._cfg?.model?.metadata_type_code;
          const item = metaDataType.find((obj) => obj.hasOwnProperty(metaInfo));
          outDiv.innerHTML = `
                <p>${e?.item?._cfg?.model?.name}</p>
                  <p>${e?.item?._cfg?.model?.label}</p>
                   <p>元数据类型：${item[metaInfo]}
                </p>
              `;
          return outDiv;
        } else {
          outDiv.style.display = "none";
          return outDiv;
        }
      },
    });

    // 上游
    const SOURCE = new G6.Legend({
      data: {
        nodes: [
          {
            id: "source",
            label: "获取上游",
            style: {
              fill: "#61D4AD",
            },
          },
        ],
      },
      align: "center",
      layout: "horizontal", // vertical
      position: "bottom-left",
      vertiSep: 12,
      horiSep: 24,
      offsetY: 50,
      padding: [8, 16, 4, 16],
      containerStyle: {
        fill: "#ccc",
        stroke: "#fff", // 设置边框颜色
        radius: 8, // 设置边框圆角半径
      },
      title: " ",
      titleConfig: {
        offsetY: -20,
      },
    });

    // 下游
    const FLOW = new G6.Legend({
      data: {
        nodes: [
          {
            id: "flow",
            label: "获取下游",
            style: {
              fill: "#FE545F",
            },
          },
        ],
      },
      align: "center",
      layout: "horizontal", // vertical
      position: "bottom-right",
      vertiSep: 12,
      horiSep: 24,
      offsetY: 50,
      padding: [8, 16, 4, 16],
      containerStyle: {
        fill: "#ccc",
        stroke: "#fff", // 设置边框颜色
        radius: 8, // 设置边框圆角半径
      },
      title: " ",
      titleConfig: {
        offsetY: -20,
      },
    });

    // const animateCfg = { duration: 200, easing: "easeCubic" };
    const toolbar = new G6.ToolBar({
      position: { x: 1200, y: 46 },
      getContent: () => `
  <ul class='g6-component-toolbar' style='border:none'>
    <li  code='zoomOut'>
      <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
        <path d="M658.432 428.736a33.216 33.216 0 0 1-33.152 33.152H525.824v99.456a33.216 33.216 0 0 1-66.304 0V461.888H360.064a33.152 33.152 0 0 1 0-66.304H459.52V296.128a33.152 33.152 0 0 1 66.304 0V395.52H625.28c18.24 0 33.152 14.848 33.152 33.152z m299.776 521.792a43.328 43.328 0 0 1-60.864-6.912l-189.248-220.992a362.368 362.368 0 0 1-215.36 70.848 364.8 364.8 0 1 1 364.8-364.736 363.072 363.072 0 0 1-86.912 235.968l192.384 224.64a43.392 43.392 0 0 1-4.8 61.184z m-465.536-223.36a298.816 298.816 0 0 0 298.432-298.432 298.816 298.816 0 0 0-298.432-298.432A298.816 298.816 0 0 0 194.24 428.8a298.816 298.816 0 0 0 298.432 298.432z"></path>
      </svg>
    </li>
    <li code='zoomIn'>
      <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
        <path d="M639.936 416a32 32 0 0 1-32 32h-256a32 32 0 0 1 0-64h256a32 32 0 0 1 32 32z m289.28 503.552a41.792 41.792 0 0 1-58.752-6.656l-182.656-213.248A349.76 349.76 0 0 1 480 768 352 352 0 1 1 832 416a350.4 350.4 0 0 1-83.84 227.712l185.664 216.768a41.856 41.856 0 0 1-4.608 59.072zM479.936 704c158.784 0 288-129.216 288-288S638.72 128 479.936 128a288.32 288.32 0 0 0-288 288c0 158.784 129.216 288 288 288z" p-id="3853"></path>
      </svg>
    </li>
    <li code='autoZoom'>
      <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
        <path d="M684.288 305.28l0.128-0.64-0.128-0.64V99.712c0-19.84 15.552-35.904 34.496-35.712a35.072 35.072 0 0 1 34.56 35.776v171.008h170.944c19.648 0 35.84 15.488 35.712 34.432a35.072 35.072 0 0 1-35.84 34.496h-204.16l-0.64-0.128a32.768 32.768 0 0 1-20.864-7.552c-1.344-1.024-2.816-1.664-3.968-2.816-0.384-0.32-0.512-0.768-0.832-1.088a33.472 33.472 0 0 1-9.408-22.848zM305.28 64a35.072 35.072 0 0 0-34.56 35.776v171.008H99.776A35.072 35.072 0 0 0 64 305.216c0 18.944 15.872 34.496 35.84 34.496h204.16l0.64-0.128a32.896 32.896 0 0 0 20.864-7.552c1.344-1.024 2.816-1.664 3.904-2.816 0.384-0.32 0.512-0.768 0.768-1.088a33.024 33.024 0 0 0 9.536-22.848l-0.128-0.64 0.128-0.704V99.712A35.008 35.008 0 0 0 305.216 64z m618.944 620.288h-204.16l-0.64 0.128-0.512-0.128c-7.808 0-14.72 3.2-20.48 7.68-1.28 1.024-2.752 1.664-3.84 2.752-0.384 0.32-0.512 0.768-0.832 1.088a33.664 33.664 0 0 0-9.408 22.912l0.128 0.64-0.128 0.704v204.288c0 19.712 15.552 35.904 34.496 35.712a35.072 35.072 0 0 0 34.56-35.776V753.28h170.944c19.648 0 35.84-15.488 35.712-34.432a35.072 35.072 0 0 0-35.84-34.496z m-593.92 11.52c-0.256-0.32-0.384-0.768-0.768-1.088-1.088-1.088-2.56-1.728-3.84-2.688a33.088 33.088 0 0 0-20.48-7.68l-0.512 0.064-0.64-0.128H99.84a35.072 35.072 0 0 0-35.84 34.496 35.072 35.072 0 0 0 35.712 34.432H270.72v171.008c0 19.84 15.552 35.84 34.56 35.776a35.008 35.008 0 0 0 34.432-35.712V720l-0.128-0.64 0.128-0.704a33.344 33.344 0 0 0-9.472-22.848zM512 374.144a137.92 137.92 0 1 0 0.128 275.84A137.92 137.92 0 0 0 512 374.08z"></path>
      </svg>
    </li>
  </ul>`,
    });

    graph = new G6.TreeGraph({
      container,
      ...defaultConfig,
      ...config,
      // plugins: [tooltip, SOURCE, FLOW],
      plugins: [tooltip, toolbar],
      // renderer: "svg",
    });
    if (typeof onInit === "function") {
      onInit(graph);
    }

    const handleCollapse = (e) => {
      const target = e.target;
      const id = target.get("modelId");
      const item = graph.findById(id);
      const nodeModel = item.getModel();
      nodeModel.collapsed = !nodeModel.collapsed;
      graph.layout();
      graph.setItemState(item, "collapse", nodeModel.collapsed);
    };
    graph.on("collapse-text:click", (e) => {
      handleCollapse(e);
    });
    graph.on("collapse-back:click", (e) => {
      handleCollapse(e);
    });

    let prevClickedNode = null; // 用于记录上一个点击的节点
    // 点击子节点的时候触发获取下一个级别的数据
    graph.on("node:click", (e) => {
      const mode = e.item._cfg.model;
      const id = e.item._cfg.id;
      const idLength = id.split("-").length - 1;
      const target = e.target; // 获取点击的图形元素

      // 恢复上一个节点的样式
      if (prevClickedNode) {
        const prevShape = prevClickedNode.get("keyShape");
        prevShape.attr({
          stroke: id !== "g1" ? "#CED4D9" : "rgb(213, 225, 247)",
          shadowColor: null, // 清除阴影颜色
          shadowBlur: null, // 清除阴影模糊度
          shadowOffsetX: null, // 清除阴影在 X 轴方向的偏移量
          shadowOffsetY: null, // 清除阴影在 Y 轴方向的偏移量
        });
      }

      // 获取当前节点的图形元素
      const shape = e.item.get("keyShape");

      if (target.get("name") === "name-shape") {
        // 修改边框颜色和宽度
        shape.attr({
          stroke: "#006AB2",
          shadowColor: "#000", // 设置阴影颜色为黑色
          shadowBlur: 10, // 设置阴影模糊度
          shadowOffsetX: 0, // 设置阴影在 X 轴方向的偏移量
          shadowOffsetY: 0, // 设置阴影在 Y 轴方向的偏移量
        });
        prevClickedNode = e.item; // 更新记录的上一个点击的节点
        getmetaDataInfo(
          false,
          e.item._cfg.model?.metadata_ver_id,
          {
            metadata_id: e.item._cfg.model?.metadata_id,
            metadata_type_code: e.item._cfg.model?.metadata_type_code,
          },
          e.item._cfg.model?.metadata_type_code
        );
      } else {
        if ("children" in mode) {
          // console.log('The object contains the "children" property.');
        } else {
          getBloodrRelation(
            e.item._cfg.model.metadata_ver_id,
            e.item._cfg.model.ratio
          );
        }
      }
    });

    graph.on("node:mouseenter", (e) => {
      const item = e.item; // 获取当前节点元素
      const group = item.getContainer(); // 获取当前节点的容器组
      const textShape = group.find(
        (element) => element.get("name") === "name-shape"
      );
      // 修改文字的颜色
      textShape.attr({
        fill: "#006AB2", // 修改文字颜色
        fontStyle: "bold", // 设置加粗属性
      });
    });
    graph.on("node:mouseleave", (e) => {
      const item = e.item; // 获取当前节点元素
      const group = item.getContainer(); // 获取当前节点的容器组
      const textShape = group.find(
        (element) => element.get("name") === "name-shape"
      );
      // 修改文字的颜色
      textShape.attr({
        fill: "#000",
        fontStyle: "normal",
      });
    });

    graph.on("afterlayout", () => {
      graph.getEdges().forEach((edge) => {
        const { source, target, startPoint, endPoint } = edge.getModel();
        const midX = (startPoint.x + endPoint.x) / 2;
        const midY = (startPoint.y + endPoint.y) / 2;
        if (target.includes("SOURCE")) {
          edge.update({
            style: {
              startArrow: true,
            },
          });
        } else {
          edge.update({
            style: {
              endArrow: true,
            },
          });
        }
      });
    });

    // 监听画布缩放，缩小到一定程度，节点显示缩略样式
    let currentLevel = 1;
    const briefZoomThreshold = Math.max(graph.getZoom(), 0.5);
    graph.on("viewportchange", (e) => {
      if (e.action !== "zoom") return;
      const currentZoom = graph.getZoom();
      let toLevel = currentLevel;
      if (currentZoom < briefZoomThreshold) {
        toLevel = 0;
      } else {
        toLevel = 1;
      }
      if (toLevel !== currentLevel) {
        currentLevel = toLevel;
        graph.getNodes().forEach((node) => {
          graph.updateItem(node, {
            level: toLevel,
          });
        });
      }
    });

    graph.data(data);
    graph.render();
  };

  // 获取上游数据
  const sourceData = () => {
    setSourceLoading(true);
    // const id = traverseChildrenAndGetMetadataVerIds(RelationList, "SOURCE");
    const metadataVerIds = traverseChildren(RelationList, "SOURCE");
    const uniqueMetadataVerIds = [...new Set(metadataVerIds)];
    if (uniqueMetadataVerIds?.length !== 0) {
      getBloodrRelation(uniqueMetadataVerIds, "SOURCE");
    } else {
      message.info("暂无上游数据");
      setSourceLoading(false);
    }
  };

  // 获取下游数据
  const flowData = () => {
    setFlowLoading(true);
    // const id = traverseChildrenAndGetMetadataVerIds(RelationList, "SOURCE");
    // console.log(id);
    const metadataVerIds = traverseChildren(RelationList, "FLOW");
    const uniqueMetadataVerIds = [...new Set(metadataVerIds)];
    if (uniqueMetadataVerIds?.length !== 0) {
      getBloodrRelation(uniqueMetadataVerIds, "FLOW");
    } else {
      message.info("暂无下游数据");
      setFlowLoading(false);
    }
  };

  // 处理一次性获取上游或者下游数据
  function traverseChildren(data, ratio) {
    let result = [];

    function traverse(node) {
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          if (child.ratio === ratio) {
            if (child.metadata_ver_id) {
              result.push(child.metadata_ver_id);
            }
            traverse(child);
          }
        }
      }
    }

    traverse(data);
    return result;
  }
  // const handleSaveClick = () => {
  //   // console.log(containerRef.current);
  //   // // 获取 SVG 元素并创建下载链接
  //   // const svgElement = containerRef.current.querySelector("svg");
  //   // const svgData = new XMLSerializer().serializeToString(svgElement);
  //   // const blob = new Blob([svgData], { type: "image/svg+xml" });
  //   // const url = URL.createObjectURL(blob);

  //   // // 创建下载链接并触发下载动作
  //   // const downloadLink = document.createElement("a");
  //   // downloadLink.href = url;
  //   // downloadLink.download = `${metaDataInfo.chi_name}（${metaDataInfo.eng_name}）-血缘关系.svg`;
  //   // downloadLink.click();

  //   // // 清理资源
  //   // URL.revokeObjectURL(url);
  //   // 获取 Canvas 元素
  //   const canvas = containerRef.current.querySelector("canvas");

  //   // 将 Canvas 转换为图像数据 URL
  //   const canvasDataUrl = canvas.toDataURL("image/png"); // 或者使用其他格式如 "image/jpeg"

  //   // 创建下载链接并触发下载动作
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = canvasDataUrl;
  //   downloadLink.download = `${metaDataInfo.chi_name}（${metaDataInfo.eng_name}）-血缘关系.png`; // 根据你的需要选择文件格式和扩展名
  //   downloadLink.click();
  // };

  const handleSaveClick = () => {
    // 获取 Canvas 元素
    const canvas = containerRef.current.querySelector("canvas");

    // 将 Canvas 转换为图像数据 URL
    const canvasDataUrl = canvas.toDataURL("image/png"); // 或者使用其他格式如 "image/jpeg"

    // 创建一个空的 SVG 元素
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svg.setAttribute("width", canvas.width);
    svg.setAttribute("height", canvas.height);

    // 创建一个 `image` 元素，将 Canvas 内容显示为 SVG 图像
    const image = new Image();
    image.src = canvasDataUrl;
    image.onload = () => {
      const svgImage = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );
      svgImage.setAttribute("x", "0");
      svgImage.setAttribute("y", "0");
      svgImage.setAttribute("width", canvas.width);
      svgImage.setAttribute("height", canvas.height);
      svgImage.setAttribute("href", canvasDataUrl);

      svg.appendChild(svgImage);

      // 将 SVG 转为字符串
      const svgData = new XMLSerializer().serializeToString(svg);
      const svgBlob = new Blob([svgData], { type: "image/svg+xml" });
      const svgUrl = URL.createObjectURL(svgBlob);

      // 创建下载链接并触发下载动作
      const downloadLink = document.createElement("a");
      downloadLink.href = svgUrl;
      downloadLink.download = `${metaDataInfo.chi_name}（${metaDataInfo.eng_name}）-血缘关系.svg`; // 根据你的需要选择文件格式和扩展名
      downloadLink.click();

      // 清理 URL 对象
      URL.revokeObjectURL(svgUrl);
    };
  };

  // const handleSaveClick = () => {
  //   // 获取 Canvas 元素
  //   const canvas = containerRef.current.querySelector("canvas");

  //   // 创建一个空的 SVG 元素
  //   const svgElement = document.createElementNS(
  //     "http://www.w3.org/2000/svg",
  //     "svg"
  //   );

  //   // 获取 Canvas 数据 URL
  //   const canvasDataUrl = canvas.toDataURL("image/png");

  //   // 将 Canvas 数据 URL 转换为 SVG
  //   canvg(svgElement, canvasDataUrl);

  //   // 创建下载链接并触发下载动作
  //   const svgData = new XMLSerializer().serializeToString(svgElement);
  //   const blob = new Blob([svgData], { type: "image/svg+xml" });
  //   const url = URL.createObjectURL(blob);

  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = url;
  //   downloadLink.download = `${metaDataInfo.chi_name}（${metaDataInfo.eng_name}）-血缘关系.svg`;
  //   downloadLink.click();

  //   // 清理资源
  //   URL.revokeObjectURL(url);
  // };

  useEffect(() => {
    if (Object.keys(RelationList)?.length !== 0) {
      registerFn();
      initGraph(RelationList);
    }
  }, [RelationList]);

  // useEffect(() => {
  //   childMethodRef.current = handleSaveClick; // 子组件挂载完成后更新引用值
  // }, [childMethodRef]);

  // 暴露的多个子组件事件
  const childEvents = {
    handleSaveClick: handleSaveClick, // 下载
    sourceData: sourceData, // 获取上游
    flowData: flowData, // 获取下游
  };
  useEffect(() => {
    childMethodRef.current = childEvents; // 子组件挂载完成后更新引用值
  }, [RelationList]); // 空数组表示只在组件挂载完成后执行一次

  return (
    <div
      ref={containerRef}
      className="VisualBoxContent"
      style={{ overflow: "hidden" }}
    >
      <div className="tooltip">
        {Object.values(layerSubList.DATA_LAYER_RLS)?.map((item, index) => (
          <div className="group" key={index}>
            <div
              className="shape"
              style={{ background: setColors(item.data_layer_id) }}
            ></div>
            <div className="name"> {item.data_layer_name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VisualBox;
