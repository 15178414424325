import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";

const AutMechanism = (props) => {
  return (
    <div className="AutMechanism">
      <div className="contentValue"></div>
    </div>
  );
};
export default AutMechanism;
