import "./App.scss";
import React, { useEffect, useState } from "react";
import { Navigate, Routes, Route, useLocation } from "react-router-dom";
// import { renderRoutes } from "react-router-config";
import { Layout, ConfigProvider } from "antd";
import zh_CN from "antd/lib/locale-provider/zh_CN";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./components/Sidebar";
import MyHeader from "./components/Header";
import { setUserRouters, setUserInfo } from "./store/user/user";
import { setDicCodeInfoList, setLayerSubList } from "./store/project/project";
import routers from "./router/router";
import Scrollbars from "react-custom-scrollbars";
import UserApi from "./apis/user/index";
import projectApi from "./apis/project/index";
import PrivateRoute from "./components/PrivateRoute";
import { removeToken } from "./unit/index";
import NotFound from "../src/view/404";
import "moment/locale/zh-cn";
import Login from "./view/Login";

const { Content } = Layout;

function App() {
  const userRouters = useSelector((state) => state.User.routers);

  const [breadcrumbName, setBreadcrumbName] = useState("");
  const [routerList, setRouterList] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();
  const math = userRouters[0]?.children[0]?.menu_url;

  useEffect(() => {
    getUserInfo();
    getMenus();
    getDicCodeInfo();
    getLayerSubList();
  }, []);

  const getUserInfo = async () => {
    let { data } = await UserApi.getUserInfo();

    if (!data) {
      logout();
      return;
    }
    dispatch(setUserInfo(data));
  };

  const getMenus = async () => {
    const { data } = await UserApi.getMenus();
    dispatch(setUserRouters(data));
  };

  // 将数组扁平化
  const flattenedData = (arr) => {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      result.push(arr[i]);
      if (Array.isArray(arr[i].children)) {
        result = result.concat(flattenedData(arr[i].children));
      }
    }
    return result;
  };

  // 处理新routers
  const handleMenu = (arr1, arr2) => {
    let newArray = [];
    for (let i = 0; i < arr1.length; i++) {
      for (let j = 0; j < arr2.length; j++) {
        if (arr1[i].menu_url === arr2[j].menu_url) {
          newArray.push(arr2[j]);
        }
      }
    }

    return newArray;
  };
  // 获取字典
  const getDicCodeInfo = async () => {
    const { data } = await projectApi.getDicCodeInfo();
    dispatch(setDicCodeInfoList(data));
  };
  // 获取主题域数据分层映射表
  const getLayerSubList = async () => {
    const { data } = await projectApi.getLayerSubList();
    dispatch(setLayerSubList(data));
  };

  useEffect(() => {
    const parentName = routers.find(
      (item) =>
        item.menu_url === "/" + location.pathname.split("/")[1] ||
        item.menu_url ===
          "/" +
            location.pathname.split("/")[1] +
            "/" +
            location.pathname.split("/")[2]
    );

    const name = routers.find(
      (item) =>
        item.menu_url === location.pathname ||
        item.menu_url.split("/")[1] +
          "/" +
          item.menu_url.split("/")[2] +
          "/" +
          item.menu_url.split("/")[3] +
          "/" +
          item.menu_url.split("/")[4] ===
          location.pathname.split("/")[1] +
            "/" +
            location.pathname.split("/")[2] +
            "/" +
            location.pathname.split("/")[3] +
            "/" +
            location.pathname.split("/")[4]
    );

    // 处理面包屑url
    const crumbName = (data) => {
      // console.log(data);
      if (data === "资产管理设置") {
        return "平台管理 / ";
      } else if (data === "概览" || data === "监控统计") {
        return "API Store / ";
      } else if (name?.menu_name === "应用管理") {
        return "API Store / ";
      } else if (data === "数据核对") {
        return "数据质量 / ";
      } else if (data === undefined || data === "") {
        return "";
      } else {
        return `${parentName?.menu_name} / `;
      }
    };
    if (crumbName() === undefined) {
      setBreadcrumbName("");
    }
    setBreadcrumbName(
      <span>
        <span style={{ color: "rgb(0,0,0,.3)" }}>
          {/* {parentName?.menu_name + " / "} */}
          {crumbName(parentName?.menu_name)}
        </span>
        <span>{name?.menu_name}</span>
      </span>
    );
  }, [location]);

  const logout = () => {
    let redirect = window.location.origin + window.location.pathname;
    UserApi.logout(redirect).then((res) => {
      removeToken();
      if (res?.data?.status === "redirect") {
        window.location.href = res.data.logout_url;
      }
      if (res.code == "3") {
        window.location.reload();
      }
    });
  };

  return (
    <div className="App">
      {userRouters.length !== 0 ? (
        <Layout className="my-layout">
          <Sidebar routerList={routers}></Sidebar>
          <Layout>
            <MyHeader></MyHeader>
            {/* 面包屑 */}
            <div className="breadcrumb">{breadcrumbName}</div>
            <Content className="my-content">
              {/* {element} */}
              <Scrollbars
                className="scrollbars"
                autoHide={true}
                autoHideTimeout={5000}
                style={{ height: "100%", width: "100%" }}
              >
                <div className="contentBox">
                  <ConfigProvider locale={zh_CN}>
                    {routers.length > 0 ? (
                      <Routes>
                        {routers?.map((item) => (
                          <Route
                            path={item.menu_url}
                            key={item.menu_url}
                            breadcrumbName={item.menu_name}
                            element={
                              <PrivateRoute>{item.element}</PrivateRoute>
                            }
                          />
                        ))}
                        <Route
                          path="/data_standard/"
                          element={<Navigate to="/data_standard/layer" />}
                        />
                        <Route
                          path="/data_definition/"
                          element={<Navigate to="/data_definition/metadata" />}
                        />
                        <Route
                          path="/data_service/"
                          element={<Navigate to="/data_service/dev" />}
                        />
                        <Route
                          path="/*"
                          element={<Navigate to={math || "/"} />}
                        />
                      </Routes>
                    ) : null}
                  </ConfigProvider>
                </div>
              </Scrollbars>
            </Content>
          </Layout>
        </Layout>
      ) : null}
    </div>
  );
}

export default App;
