import {
  Layout,
  Popconfirm,
  message,
  Popover,
  Badge,
  Avatar,
  Image,
  Tooltip,
  Input,
} from "antd";
import "./index.scss";
import logo from "../../assets/logo-g.png";
import { useSelector } from "react-redux";
import { useState } from "react";
import { createFromIconfontCN } from "@ant-design/icons";
import UserApi from "../../apis/user/index";
import { removeToken, setRedirectUrl } from "../../unit/index";
import NoticeDrawer from "../NoticeDrawer";
import searchIcon from "../../assets/搜索.svg";
import { ReactSVG } from "react-svg";
const { Header } = Layout;

const MyIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_3354122_puxmtsyxun9.js",
});

export function MyHeader(props) {
  const userInfo = useSelector((state) => state.User.userInfo);
  // console.log(userInfo);
  const [ispopover, setIspopover] = useState(false);

  const { user } = userInfo;

  const [visible, setVisible] = useState(false);
  const confirm = () => {
    let redirect = window.location.origin + window.location.pathname;
    UserApi.logout(redirect).then((res) => {
      // console.log(res.data.data.logout_url);
      removeToken();
      if (res?.data?.status === "redirect") {
        setRedirectUrl(window.location.href);
        window.location.href = res.data.logout_url;
      }
      if (res.code == "3") {
        window.location.reload();
      }
    });
    message.success("退出成功");
  };

  return (
    <div className="headerContainer">
      <Header>
        <div className="headerLeft">
          <span className="left-title">吾道数据治理平台</span>
        </div>
        <>
          <div className="ant-header-right">
            <img src={logo} alt="" />
            <span className="sys-pepo">{userInfo?.show_name}</span>

            <Popconfirm
              onConfirm={confirm}
              placement="topRight"
              title="你确定要退出吗？"
              okText="确定"
              cancelText="取消"
            >
              <a>退出</a>
            </Popconfirm>
            {/* 
            <span onClick={() => setVisible(true)}>
              <Badge dot>
                <MyIcon
                  type="icon-a-guangbo1"
                  className="cursor-pointer"
                ></MyIcon>
              </Badge>
            </span>

            <span className="setting">
              <Popover
                onVisibleChange={() => setIspopover(!ispopover)}
                visible={ispopover}
                title="设置"
                trigger="click"
                content={<a>重置</a>}
              >
                <MyIcon
                  type="icon-a-icon_shezhi1"
                  onClick={() => setIspopover(true)}
                ></MyIcon>
              </Popover>
            </span>
            <span>
              <MyIcon
                type="icon-a-icon_yiwenkongxin1"
                className="cursor-pointer"
              ></MyIcon>
            </span> */}
          </div>
        </>
      </Header>

      <NoticeDrawer
        visible={visible}
        onClose={() => setVisible(false)}
      ></NoticeDrawer>
    </div>
  );
}

export default MyHeader;
