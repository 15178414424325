import React from "react";
// import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "antd/dist/antd.min.css";
import "./index.scss";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import store from "./store/index";
import App from "./App";
import Login from "./view/Login";
import NotFound from "./view/404";
import { createRoot } from "react-dom/client";
import PrivateRoute from "./components/PrivateRoute";
import TecDocumentation from "./view/TecDocumentation/Home";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/404" element={<NotFound />} />
          {/* 技术文档 */}
          <Route
            path="/technical_documentation"
            element={<TecDocumentation></TecDocumentation>}
          />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <App></App>
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  </ConfigProvider>
);
