import Layer from "../view/DataStandard/Layer";
import Subject from "../view/DataStandard/Subject";
import Etyma from "../view/DataStandard/Etyma";
import Element from "../view/DataStandard/Element";
import Check from "../view/DataStandard/Check";

import MetaData from "../view/DataDefinition/MetaData";
import ModelDesign from "../view/DataDefinition/ModelDesign";
import ModelCheck from "../view/DataDefinition/ModelCheck";
import ModelApply from "../view/DataDefinition/ModelApply";
import DesignStandard from "../view/DataDefinition/DesignStandard";

import ServiceDev from "../view/DataService/ServiceDev";
import PackageGov from "../view/DataService/PackageGov";
import DataServiceOrder from "../view/DataService/DataServiceOrder";
import ServiceMonitoring from "../view/DataService/ServiceMonitoring";
import DataServiceCheck from "../view/DataService/DataServiceCheck";

import AssetManagement from "../view/PlatformManagement/AssetManagement";

import Overview from "../view/APIStore/Overview";
import AppManagement from "../view/APIStore/AppManagement";
import MgtStatistics from "../view/APIStore/MgtStatistics";
import Home from "../view/Home";
import DataCheck from "../view/DataQuality/DataCheck";
const routers = [
  {
    menu_url: "/",
    element: <Home> </Home>,
    menu_name: "",
  },
  {
    menu_url: "/data_standard",
    menu_name: "数据标准",
  },
  {
    menu_url: "/data_standard/layer",
    element: <Layer></Layer>,
    menu_name: "数据分层标准",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_standard/subject",
    element: <Subject></Subject>,
    menu_name: "主题域标准",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_standard/etyma",
    element: <Etyma> </Etyma>,
    menu_name: "词根标准",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_standard/element",
    element: <Element> </Element>,
    menu_name: "数据元标准",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_standard/check",
    element: <Check> </Check>,
    menu_name: "标准审核与发布",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_definition",
    menu_name: "数据定义",
  },
  {
    menu_url: "/data_definition/metadata",
    element: <MetaData></MetaData>,
    menu_name: "元数据管理",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_definition/model/design",
    element: <ModelDesign></ModelDesign>,
    menu_name: "模型设计中心",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_definition/model/check",
    element: <ModelCheck></ModelCheck>,
    menu_name: "模型审核与发布",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_definition/model/apply",
    element: <ModelApply></ModelApply>,
    menu_name: "模型部署实施",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_definition/model/design/standard",
    element: <DesignStandard></DesignStandard>,
    menu_name: "模型设计规范",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_service",
    menu_name: "数据服务",
  },
  {
    menu_url: "/data_service/dev",
    element: <ServiceDev></ServiceDev>,
    menu_name: "服务开发",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_service/package/gov",
    element: <PackageGov></PackageGov>,
    menu_name: "API包管理",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_service/check",
    element: <DataServiceCheck />,
    menu_name: "服务审核与发布",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_service/order",
    element: <DataServiceOrder></DataServiceOrder>,
    menu_name: "服务订单管理",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_service/monitoring",
    element: <ServiceMonitoring></ServiceMonitoring>,
    menu_name: "服务监控",
    open_mode: "CURRENT_PAGE",
  },

  {
    menu_url: "/platform_management",
    menu_name: "平台管理",
  },
  {
    menu_url: "/asset/management",
    element: <AssetManagement></AssetManagement>,
    menu_name: "资产管理设置",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_service/api/store",
    menu_name: "API Store",
  },
  {
    menu_url: "/overview",
    element: <Overview></Overview>,
    menu_name: "概览",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/data_service/app/management",
    element: <AppManagement></AppManagement>,
    menu_name: "应用管理",
    open_mode: "CURRENT_PAGE",
  },
  {
    menu_url: "/mgt/statistics",
    element: <MgtStatistics></MgtStatistics>,
    menu_name: "监控统计",
    open_mode: "CURRENT_PAGE",
  },

  {
    menu_url: "/data_quality",
    menu_name: "数据质量",
  },
  {
    menu_url: "/dg_dq_check",
    element: <DataCheck></DataCheck>,
    menu_name: "数据核对",
    open_mode: "CURRENT_PAGE",
  },
];

export default routers;
