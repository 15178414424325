import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import { useEffect, useState } from "react";
import { Steps, message } from "antd";
import ApplyTable from "./components/ApplyTable";

import DialogDrawer from "./components/DialogDrawer";
import StatisticalData from "./components/StatisticalData";
import AppDialogDrawer from "../AppManagement/components/DialogDrawer";
import MessageModal from "../../../components/MessageModal";
import projectApi from "../../../apis/project";
import { useNavigate } from "react-router-dom";

const Overview = (props) => {
  const { Step } = Steps;
  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false); // 提交申请
  const [currentNumber, setCurrentNumber] = useState(0); // 控制Steps

  const [addNew, setAddNew] = useState(true); // 新增修改应用
  const [appDialogDrawerVisible, setAppDialogDrawerVisible] = useState(false); // 新增修改应用
  const [tableLoading, setTableLoading] = useState(true);
  const [storePackageList, setStorePackageList] = useState([]); //api服务包列表（已申请）
  const [storeList, setStoreList] = useState({}); //全部服务
  const [params, setParams] = useState({ page: 1, size: 10 });
  const [applyTotal, setApplyTotal] = useState(0); // 应用总数
  const navigate = useNavigate();

  // 获取应用管理列表查询
  const getAppList = () => {
    projectApi.getAppList(params).then((res) => {
      if (res.code === 200) {
        setApplyTotal(res?.data?.total);
      }
    });
  };
  useEffect(() => {
    getAppList();
  }, []);

  // 创建新应用
  const createNew = () => {
    setAppDialogDrawerVisible(true);
    setAddNew(true);
  };
  // 取消服务
  const cancelService = (value) => {
    MessageModal({
      type: "warning",
      description: "确认要取消服务申请吗？",
      title: "取消服务",
      okText: "确定",
      onOk: () => {},
    });
  };

  //  获取api服务包列表（全部）
  const getStorePackageList = () => {
    projectApi.getStorePackageList(params).then((res) => {
      if (res.code === 200) {
        setStoreList(res.data);
      } else {
        setStoreList({});
      }
    });
  };

  // 获取api数据服务列表（已申请过的）
  const getStoreList = () => {
    projectApi
      .getStoreList()
      .then((res) => {
        if (res.code === 200) {
          setStorePackageList(res.data);
        } else {
          setStorePackageList([]);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  // 改变分页数据
  const changePagination = (page, pageSize) => {
    setParams({ page: page, size: pageSize });
  };

  useEffect(() => {
    setTableLoading(true);
    getStoreList();
  }, []);

  useEffect(() => {
    getStorePackageList();
  }, [params]);

  // 申请api服务
  const dialogDrawerSuccess = (value) => {
    projectApi.addStoreServerList(value).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        getStoreList();
        setDialogDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 创建app应用
  const appDialogDrawerSuccess = (value) => {
    projectApi.addApp(value).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setAppDialogDrawerVisible(false);
        getAppList();
      } else {
        message.error(res.message);
      }
    });
  };

  return (
    <div className="Overview">
      {/* 操作指引 */}
      <div className="MySteps">
        <HeaderTitle title="操作指引"></HeaderTitle>
        <div className="stepsValue">
          <Steps
            labelPlacement="vertical"
            current={currentNumber}
            onChange={(e) => setCurrentNumber(e)}
          >
            <Step
              title="申请服务"
              description="去申请"
              subTitle="申请API服务包资源"
              onClick={() => setDialogDrawerVisible(true)}
              icon={
                <div
                  className={currentNumber === 0 ? "stepStatus" : "stepStatu"}
                >
                  1
                </div>
              }
            />
            <Step
              title="创建应用"
              subTitle="创建应用，获取密钥"
              description="去创建"
              onClick={() => createNew()}
              icon={
                <div
                  className={currentNumber === 1 ? "stepStatus" : "stepStatu"}
                >
                  2
                </div>
              }
            />
            <Step
              title="调用服务"
              subTitle="查看请求示例，调用服务"
              description="API文档"
              onClick={() => window.open("/technical_documentation")}
              icon={
                <div
                  className={currentNumber === 2 ? "stepStatus" : "stepStatu"}
                >
                  3
                </div>
              }
            />
            <Step
              title="查看用量"
              subTitle="查看报表了解使用情况"
              description="去查看"
              icon={
                <div
                  className={currentNumber === 3 ? "stepStatus" : "stepStatu"}
                >
                  4
                </div>
              }
              onClick={() => navigate("/mgt/statistics")}
            />
          </Steps>
        </div>
      </div>

      {/* 中间 */}
      {/* <StatisticalData applyTotal={applyTotal}></StatisticalData> */}

      {/* 服务列表 */}
      <div className="cardBox">
        <HeaderTitle
          title="服务列表"
          extra={
            <div
              className="applyValue"
              onClick={() => setDialogDrawerVisible(true)}
            >
              服务申请
            </div>
          }
        ></HeaderTitle>
        <ApplyTable
          cancelService={cancelService}
          tableLoading={tableLoading}
          defaultData={storePackageList}
        ></ApplyTable>
      </div>

      <DialogDrawer
        onOk={dialogDrawerSuccess}
        visible={DialogDrawerVisible}
        onCancel={() => {
          setDialogDrawerVisible(false);
        }}
        storeList={storeList}
        storePackageList={storePackageList}
        changePagination={changePagination}
      ></DialogDrawer>

      <AppDialogDrawer
        addNew={addNew}
        onOk={appDialogDrawerSuccess}
        visible={appDialogDrawerVisible}
        onCancel={() => {
          setAppDialogDrawerVisible(false);
        }}
        storePackageList={storePackageList}
      ></AppDialogDrawer>
    </div>
  );
};
export default Overview;
