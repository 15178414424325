import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
const OpenService = (props) => {
  return (
    <div className="OpenService">
      <div className="contentValue">
        <div className="contentItem">
          <HeaderTitle title="前提条件"></HeaderTitle>
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div className="contentItem">
          <HeaderTitle title="操作步骤"></HeaderTitle>
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div className="contentItem">
          <HeaderTitle title="后续步骤"></HeaderTitle>
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default OpenService;
