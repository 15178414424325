import "./index.scss";
import { message, Button } from "antd";
import { useEffect, useState } from "react";
import ApplicationTable from "./components/ApplicationTable";
import DialogDrawer from "./components/DialogDrawer";
import MessageModal from "../../../components/MessageModal";
import DetailDrawer from "./components/DetailDrawer";
import projectApi from "../../../apis/project";

const AppManagement = (props) => {
  const [storePackageList, setStorePackageList] = useState([]); // api数据服务列表（已经申请过的）
  const [params, setParams] = useState({ page: 1, size: 10 });
  const [tableLoading, setTableLoading] = useState(false);
  const [AppList, setAppList] = useState({}); // 应用管理列表

  const [DialogDrawerLoading, setDialogDrawerLoading] = useState(true);
  const [defaultData, setDefaultData] = useState(null); // 修改api包数据
  const [addNew, setAddNew] = useState(true); // 新增修改应用
  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false); // 新增修改应用

  const [DetailDrawerVisible, setDetailVisible] = useState(false); // 应用详情
  const [DetailDrawerLoading, setDetailDrawerLoading] = useState(true);

  // 获取应用管理列表查询
  const getAppList = () => {
    projectApi
      .getAppList(params)
      .then((res) => {
        if (res.code === 200) {
          setAppList(res.data);
        } else {
          setAppList({});
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  // 获取api数据服务列表
  const getStoreList = () => {
    projectApi.getStoreList().then((res) => {
      if (res.code === 200) {
        const uniqueData = res.data.filter(
          (value, index, self) =>
            index === self?.findIndex((t) => t.api_pkg_id === value.api_pkg_id)
        );
        setStorePackageList(uniqueData);
      } else {
        setStorePackageList([]);
      }
    });
  };

  useEffect(() => {
    getStoreList();
  }, []);

  useEffect(() => {
    setTableLoading(true);
    getAppList();
  }, [params]);

  // 改变分页数据
  const changePagination = (page, pageSize) => {
    setParams({ page: page, size: pageSize });
  };

  // 创建新应用
  const createNew = () => {
    setDialogDrawerLoading(false);
    setDialogDrawerVisible(true);
    setAddNew(true);
  };

  // 修改应用
  const modifyApp = (id) => {
    setDialogDrawerLoading(true);
    setDialogDrawerVisible(true);
    setAddNew(false);
    getApp(id, false);
  };

  // 查看详情
  const getAppDetail = (id) => {
    setDetailDrawerLoading(true);
    setDetailVisible(true);
    getApp(id, true);
  };

  // 获取修改时api服务包信息
  const getApp = (id, flag) => {
    projectApi.getApp(id).then((res) => {
      if (res.code === 200) {
        setDefaultData(res.data);
        flag ? setDetailDrawerLoading(false) : setDialogDrawerLoading(false);
      } else {
        setDefaultData({});
        flag ? setDetailDrawerLoading(false) : setDialogDrawerLoading(false);
      }
    });
  };

  // 创建应用
  const dialogDrawerSuccess = (value) => {
    console.log(value);
    if (addNew) {
      addApp(value);
    } else {
      amendApp(value);
    }
  };

  // 新增应用
  const addApp = (data) => {
    projectApi.addApp(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setTableLoading(true);
        getAppList();
        setDialogDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 修改应用
  const amendApp = (data) => {
    projectApi.modifyApp(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setTableLoading(true);
        getAppList();
        setDialogDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 删除应用
  const deletItem = (item) => {
    MessageModal({
      type: "warning",
      description: "确定要删除应用吗？",
      title: "删除应用",
      message: "应用删除后对应的访问授权将失效",
      okText: "删除",
      onOk: () => {
        projectApi.deleteApp(item).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            setTableLoading(true);
            getAppList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  return (
    <div className="AppManagement">
      <div className="contenalue">
        <Button type="primary" onClick={createNew}>
          创建应用
        </Button>
        <ApplicationTable
          modifyApp={modifyApp}
          deletItem={deletItem}
          setDetailVisible={setDetailVisible}
          defaultData={AppList}
          tableLoading={tableLoading}
          changePagination={changePagination}
          getAppDetail={getAppDetail}
        ></ApplicationTable>
      </div>

      <DialogDrawer
        addNew={addNew}
        onOk={dialogDrawerSuccess}
        visible={DialogDrawerVisible}
        onCancel={() => {
          setDialogDrawerVisible(false);
        }}
        storePackageList={storePackageList}
        defaultData={defaultData}
        loading={DialogDrawerLoading}
      ></DialogDrawer>

      <DetailDrawer
        visible={DetailDrawerVisible}
        onCancel={() => {
          setDetailVisible(false);
        }}
        defaultData={defaultData}
        loading={DetailDrawerLoading}
      ></DetailDrawer>
    </div>
  );
};
export default AppManagement;
