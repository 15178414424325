import "./index.scss";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Drawer, Button, Form, Input, Select, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import DropdownSvg from "../../../../../assets/下拉.svg";
import Scrollbars from "react-custom-scrollbars";
import projectApi from "../../../../../apis/project";
import { useSelector } from "react-redux";
import MySpin from "../../../../../components/MySpin";

const { Option } = Select;

const DialogDrawer = (props) => {
  const [form] = Form.useForm();

  const {
    drawerTitle,
    onCancel,
    onOk,
    visible,
    addNew,
    defaultData,
    layerList,
    subId, // 主题域最新的版本
    loading,
    setLoading,
    ManagementList, // 管理责任人列表
  } = props;

  const [subList, setSubList] = useState([]); // 主题域列表

  // 获取主题域已发布标准
  const getPublishedList = (layerCode) => {
    projectApi
      .getSubPublishedList(
        { ver_no: subId, data_layer_id: layerCode },
        { orders: [{ order_name: "updated_time", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          const data = res.data.items.map((item) => {
            return {
              sub_id: item.sub_id,
              sub_name: item.sub_name,
            };
          });
          let newArr = [];
          let arrId = [];
          for (let item of data) {
            if (arrId.indexOf(item["sub_id"]) === -1) {
              arrId.push(item["sub_id"]);
              newArr.push(item);
            }
          }
          setSubList(newArr);
          setLoading(false);
        } else {
          setSubList([]);
          setLoading(false);
        }
      });
  };

  const data = defaultData?.items;

  useEffect(() => {
    if (addNew === false && visible) {
      getPublishedList(defaultData?.data_layer_id);
    }
  }, [visible]);

  const onFinish = (value) => {
    if (addNew) {
      onOk && onOk(value);
    } else {
      onOk && onOk({ ...defaultData, ...value });
    }
  };
  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <div className="AssetManagementDrawer">
      <Drawer
        title={drawerTitle}
        placement="right"
        width="600px"
        className="AssetDialogDrawer"
        onClose={handleCancel}
        visible={visible}
        push={false}
        closable={false}
        destroyOnClose={true}
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              handleCancel();
            }}
          />
        }
        footer={
          <div className="drawerFooterBtnBox">
            <Button
              onClick={() => {
                form.submit();
              }}
              style={{ background: "#016DB4", color: "#fff" }}
            >
              {addNew ? " 添加" : "确认修改"}
            </Button>

            <Button
              className="noBg"
              onClick={() => {
                handleCancel();
              }}
            >
              取消
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="dialogDrawerForm"
          preserve={false}
          onFinish={onFinish}
          style={{ height: "100%" }}
        >
          {loading ? (
            <MySpin></MySpin>
          ) : (
            <>
              <Form.Item
                label="数据分层"
                name="data_layer_id"
                rules={[
                  {
                    required: true,
                    message: "请选择数据分层",
                  },
                ]}
                initialValue={addNew ? null : defaultData?.data_layer_id}
              >
                <Select
                  placeholder="请选择"
                  showSearch
                  allowClear
                  showArrow="true"
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  style={{ width: "368px" }}
                  onChange={(value) => {
                    getPublishedList(value);
                  }}
                  options={layerList?.map((item) => ({
                    label: item.data_layer_name,
                    value: item.data_layer_id,
                  }))}
                ></Select>
              </Form.Item>

              <Form.Item
                label="主题域"
                name="sub_id"
                rules={[
                  {
                    required: true,
                    message: "请选择主题域",
                  },
                ]}
                initialValue={addNew ? null : defaultData?.sub_id}
              >
                <Select
                  placeholder="请选择"
                  showSearch
                  allowClear
                  showArrow="true"
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  style={{ width: "368px" }}
                  options={subList?.map((item) => ({
                    label: item.sub_name,
                    value: item.sub_id,
                  }))}
                ></Select>
              </Form.Item>
              <Form.Item
                label="管理责任人"
                name="da_rp_id"
                rules={[
                  {
                    required: true,
                    message: "请选择管理责任人",
                  },
                ]}
                initialValue={addNew ? null : defaultData?.da_rp_id}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch={true}
                  style={{ width: "368px" }}
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  options={ManagementList?.map((item) => ({
                    label: item.username,
                    value: item.user_id,
                  }))}
                ></Select>
              </Form.Item>
            </>
          )}
        </Form>
      </Drawer>
    </div>
  );
};

export default DialogDrawer;
