// import axios from 'axios'
// import axios from 'axios'
import axios from "axios";
import { getToken } from "../unit/index";
import { message, Spin } from "antd";

import qs from "qs";
import ReactDOM from "react-dom";
import { Component } from "react";
const service = axios.create({
  //默认地址
  baseURL: "/api", // process.env.REACT_APP_TEST_URL,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 * 10,
});
message.config({
  maxCount: 1,
});

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    //只针对get方式进行序列化
    if (config.method === "get") {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      };
    }

    // 需要token的接口默认添加上token
    if (!config.headers.withOutToken) {
      // 不在header中去掉withOutToken会引发CORS错误
      delete config.headers.withOutToken;
      config.headers["authorization"] = `Bearer ${getToken()}`;
      // config.headers['token'] = `Bearer ${getToken()}`;
    } else {
      delete config.headers.withOutToken;
    }

    return config;
  },
  function (error) {
    console.log(error);
    // 对请求错误做些什么
    // 判断当前请求是否设置了不显示Loading

    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    if (!response) {
      return Promise.reject(response);
    }
    return response.data;
  },
  (error) => {
    if (error && error.response) {
      if (error.response.status === 401) {
        message.error("当前用户不能查看或操作！");
        window.location.href = "/";
      } else if (error.response.status === 422) {
        message.info("请求格式错误，请检查！");
      } else if (error.response.status === 500) {
        message.error({
          content: error.response.data.message,
        });
      } else if (error.response.status === 550) {
        message.info({
          content: error.response.data.message,
          // content:
          //   error.response.data.message !== ""
          //     ? error.response.data.message
          //     : `暂不支持，若仍有问题请联系客服！`,
        });
      } else if (
        error.response.status > 500 &&
        error.response.status !== 550 &&
        error.response.status < 600
      ) {
        message.error({
          content: `服务器错误，请稍后重试，若仍有问题请联系客服！`,
        });
      }
    } else if (error && error.request) {
      message.error({
        content: `网络错误，请检查网络连接后重试，若仍有问题请联系客服！`,
      });
    } else {
      message.error({
        content: `发生错误，请联系客服!`,
      });
    }
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

Component.prototype.$axios = service;
export default service;
