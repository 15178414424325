import "./index.scss";
import { ReactSVG } from "react-svg";
import tableIcon from "../../../../../assets/表16.svg";
import { Drawer, Button, Form, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import Scrollbars from "react-custom-scrollbars";
import HeaderTitle from "../../../../../components/HeaderTitle";

import MySpin from "../../../../../components/MySpin";

const { TextArea } = Input;

const DialogDrawer = (props) => {
  const {
    /* 标题 */
    drawerTitle,
    /* 关闭触发的回调 */
    onCancel,
    /* 确定触发的回调 */
    onOk,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    visible,
    /* 初始值 */
    defaultData,
    loading,
    release, // 发布or修改
    submitCheckData, // 提交审核数据
  } = props;

  const [form] = Form.useForm();

  const handleCancel = () => {
    onCancel && onCancel();
  };

  // 提交表单录入
  const onFinish = (value) => {
    if (release) {
      const apiIds = submitCheckData?.map((item) => item.api_id);
      onOk && onOk(value?.rn, apiIds);
    } else {
      onOk && onOk({ ...value, api_id: defaultData?.api_id });
    }
  };

  const titleTypeBgc = (data) => {
    if (data === "ORIGINAL") {
      return "#61D4AD";
    }
    if (data === "NEW") {
      return "#FEC054";
    }
    if (data === "UPDATE") {
      return "#006AB2";
    }
    if (data === "DISCARD") {
      return "#FE545F";
    }
  };

  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      width="600px"
      className="ServiceDevDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              form.submit();
            }}
            style={{ backgroundColor: "#016DB4", color: "#fff" }}
          >
            {okText ? okText : "确认"}
          </Button>

          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            {cancelText ? cancelText : "取消"}
          </Button>
        </div>
      }
    >
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: `100%`,
            width: "100%",
          }}
        >
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            className="dialogDrawerForm"
            preserve={false}
            onFinish={onFinish}
          >
            {release ? (
              <>
                <Form.Item
                  name="rn"
                  rules={[
                    {
                      required: true,
                      message: "请输入版本更新说明",
                    },
                  ]}
                  label="版本更新说明"
                >
                  <TextArea
                    allowClear
                    style={{
                      width: "540px",
                    }}
                    autoSize={{ minRows: 5 }}
                  />
                </Form.Item>
                <div style={{ marginLeft: "10px", marginBottom: "5px" }}>
                  提交发布内容
                </div>
                {submitCheckData?.map((item, index) => (
                  <div className="tableInfo" key={index}>
                    <ReactSVG src={tableIcon} className="tableIcon" />
                    <div className="nameValue">
                      {`${item?.chi_name} / ${item?.eng_name}`}
                    </div>
                    <div
                      className="typeValue"
                      style={{
                        background: titleTypeBgc(item?.chg_type_code),
                      }}
                    >
                      {item?.chg_type_code_value}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <HeaderTitle title="基本信息"></HeaderTitle>
                <Form.Item
                  label="中文名称"
                  name="chi_name"
                  rules={[
                    {
                      required: true,
                      message: "请输入中文名称，不超过100个字符",
                    },
                    {
                      pattern: /^^(.|\n){0,100}$/,
                      message: "中文名称不超过100个字符",
                    },
                  ]}
                  initialValue={release ? null : defaultData?.chi_name}
                >
                  <Input
                    allowClear
                    placeholder="1~100个字符"
                    style={{ width: "260px" }}
                  />
                </Form.Item>
                <Form.Item
                  label="英文名称"
                  name="eng_name"
                  rules={[
                    {
                      required: true,
                      message: "请输入英文名称，不超过100个字符或符号",
                    },
                    {
                      pattern: /^^(.|\n){0,100}$/,
                      message: "英文名称不超过100个字符或符号",
                    },
                  ]}
                  initialValue={release ? null : defaultData?.eng_name}
                >
                  <Input
                    allowClear
                    placeholder="1-100个英文字符或符号"
                    style={{ width: "260px" }}
                  />
                </Form.Item>
                <Form.Item
                  name="api_desc"
                  label="描述"
                  initialValue={release ? null : defaultData?.api_desc}
                >
                  <TextArea
                    allowClear
                    style={{
                      width: "540px",
                    }}
                    autoSize={{ minRows: 5 }}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </Scrollbars>
      )}
    </Drawer>
  );
};

export default DialogDrawer;
