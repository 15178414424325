import "./index.scss";
import { Table, Pagination, Badge, message } from "antd";
import { useRef, useState, useEffect, forwardRef } from "react";
import moment from "moment";
import MyEmpty from "../../../../../components/Empty";
import DialogDrawer from "../../../../../components/StandardDialogDrawer";
import projectApi from "../../../../../apis/project";
import { ReactSVG } from "react-svg";
import LoadingSvg from "../../../../../assets/loading.svg";
import DetailDrawer from "../DetailDrawer ";
import ReleaseDrawer from "../../../../../components/ReleaseDrawer";

const DataStandardCheckTable = (props) => {
  const {
    defaultData,
    tableName,
    changePagination,
    activeKey,
    changeOrders,
    versonStatusCode, //标准审核状态码
    updateDialogDrawer, // 更新标准
    agreeItem, // 同意
    layerList,
    tableLoading,
    recallItem, // 撤回
    rejectItem, // 拒绝
    applyStandards, // 发布标准
  } = props;

  const refTable = useRef(null);
  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [defaultListData, setDdefaultListData] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false);

  const [addChild, setAddChild] = useState(false);
  const [addNew, setAddNew] = useState(false);

  const data = defaultData?.items;

  const [DetailDrawerVisible, setDetailDrawerVisible] = useState(false); // 查看详情
  const [DetailDrawerLoading, setDetailDrawerLoading] = useState(true); // 查看loading
  const [DetailDrawerInfo, setDetailDrawerInfo] = useState({});
  const [DetailTable, setDetailTable] = useState({});
  const [params, setParams] = useState({ page: 1, size: 10 });
  const [wfTypeCode, setWfTypeCode] = useState("");
  const [versonStatusList, setVersonStatusList] = useState({});
  const [nextVerson, setNextVerson] = useState(null); // 发布标准版本号
  const [ReleaseDrawerVisible, setReleaseDrawerVisible] = useState(false);

  // 获取分层标准
  const getLayerUnpublishedList = () => {
    projectApi
      .getLayerUnpublishedList(params, {
        orders: [{ order_name: "updated_time", order_by: "desc" }],
      })
      .then((res) => {
        if (res.code === 200) {
          setDetailTable(res.data);
          setDetailDrawerLoading(false);
        } else {
          setDetailTable([]);
          setDetailDrawerLoading(false);
        }
      });
  };

  // 获取数据元标准
  const getEleUnpublishedList = () => {
    projectApi
      .getEleUnpublishedList(params, {
        orders: [{ order_name: "updated_time", order_by: "desc" }],
      })
      .then((res) => {
        if (res.code === 200) {
          setDetailTable(res.data);
          setDetailDrawerLoading(false);
        } else {
          setDetailTable([]);
          setDetailDrawerLoading(false);
        }
      });
  };
  // 获取词根标准
  const getEtymaUnpublishedList = () => {
    projectApi
      .getEtymaUnpublishedList(params, {
        orders: [{ order_name: "updated_time", order_by: "desc" }],
      })
      .then((res) => {
        if (res.code === 200) {
          setDetailTable(res.data);
          setDetailDrawerLoading(false);
        } else {
          setDetailTable([]);
          setDetailDrawerLoading(false);
        }
      });
  };

  // 获取主题域
  const getSubUnpublishedList = () => {
    projectApi
      .getSubUnpublishedList(params, {
        orders: [{ order_name: "updated_time", order_by: "desc" }],
      })
      .then((res) => {
        if (res.code === 200) {
          setDetailTable(res.data);
          setDetailDrawerLoading(false);
        } else {
          setDetailTable([]);
          setDetailDrawerLoading(false);
        }
      });
  };

  // 改变分页数据
  const changeDetailPagination = (page, pageSize, id) => {
    setParams({ page: page, size: pageSize });
  };

  useEffect(() => {
    if (wfTypeCode === "DATA_LAYER_RLS") {
      getLayerUnpublishedList();
    } else if (wfTypeCode === "DATA_ELE_RLS") {
      getEleUnpublishedList();
    } else if (wfTypeCode === "WORD_ROOT_RLS") {
      getEtymaUnpublishedList();
    } else if (wfTypeCode === "SUB_RLS") {
      getSubUnpublishedList();
    }
  }, [params]);

  // 审核状态颜色切换
  const changeColor = (value) => {
    if (value === "IN_APPROVAL") {
      return "#FEC054";
    } else if (value === "APPROVED") {
      return "#61D4AD";
    } else if (value === "REJECTED") {
      return "#FE545F";
    } else if (value === "CANCELED") {
      return "#D8D8D8";
    }
  };

  useEffect(() => {
    if (tableName === "myapply") {
      setColumns([
        {
          title: "标准类型",
          dataIndex: "wf_type_code_value",
          sorter: true,
        },
        {
          title: "当前版本",
          dataIndex: "cur_ver_no",
          width: "7%",
        },
        {
          title: "申请发布版本",
          dataIndex: "rls_ver_no",
          width: "8%",
        },
        {
          title: "审核状态",
          dataIndex: "appr_stat_code_value",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.appr_stat_code)} />
                {info.appr_stat_code_value}
              </span>
            );
          },
        },
        {
          title: "申请时间",
          dataIndex: "apply_time",
          sorter: true,
          width: "9%",
        },
        {
          title: "版本说明",
          dataIndex: "rn",
        },
        {
          title: "审核人",
          dataIndex: "apprd_by_id_value",
        },
        {
          title: "审核时间",
          dataIndex: "apprd_time",
          sorter: true,
          width: "9%",
        },
        {
          title: "审核说明",
          dataIndex: "apprd_opn",
        },
        {
          title: "操作",
          width: "12%",
          dataIndex: "操作",
          render: (_, record) => {
            return (
              <div className="operation" key={record.key}>
                {record.appr_stat_code === "IN_APPROVAL" ||
                record.appr_stat_code === "REJECTED" ? (
                  <span
                    style={{
                      color: "#006AB2",
                      marginRight: "16px",
                    }}
                    onClick={() => recallItem(record)}
                  >
                    撤回
                  </span>
                ) : null}
                {record.appr_stat_code === "REJECTED" ? (
                  <span
                    style={{
                      color: "#006AB2",
                      marginRight: "16px",
                    }}
                    onClick={() => {
                      setWfTypeCode(record?.wf_type_code);
                      addStandard(record);
                    }}
                  >
                    重新提交
                  </span>
                ) : null}
              </div>
            );
          },
        },
      ]);
    } else if (tableName === "ToCheck") {
      setColumns([
        {
          title: "标准类型",
          dataIndex: "wf_type_code_value",
          sorter: true,
        },
        {
          title: "当前版本",
          dataIndex: "cur_ver_no",
          width: "7%",
        },
        {
          title: "申请发布版本",
          dataIndex: "rls_ver_no",
          width: "10%",
        },
        {
          title: "申请人",
          dataIndex: "apply_by_id_value",
        },
        {
          title: "申请时间",
          dataIndex: "apply_time",
          sorter: true,
        },
        {
          title: "版本说明",
          dataIndex: "rn",
        },
        {
          title: "操作",
          width: "13%",
          dataIndex: "操作",
          render: (_, record) => {
            return (
              <div className="operation" key={record.key}>
                <span
                  style={{
                    color: "#006AB2",
                    marginRight: "16px",
                  }}
                  onClick={() => {
                    setWfTypeCode(record?.wf_type_code);
                    setDetailDrawerLoading(true);
                    setDetailDrawerVisible(true);
                    setDetailDrawerInfo(record);
                    versonStatusStatics(record?.wf_type_code);
                    if (record?.wf_type_code === "DATA_LAYER_RLS") {
                      getLayerUnpublishedList();
                    } else if (record?.wf_type_code === "DATA_ELE_RLS") {
                      getEleUnpublishedList();
                    } else if (record?.wf_type_code === "WORD_ROOT_RLS") {
                      getEtymaUnpublishedList();
                    } else if (record?.wf_type_code === "SUB_RLS") {
                      getSubUnpublishedList();
                    }
                  }}
                >
                  详情
                </span>
                <span
                  style={{
                    color: "#006AB2",
                    marginRight: "16px",
                  }}
                  onClick={() => agreeItem(record)}
                >
                  同意
                </span>
                <span
                  style={{
                    color: "#006AB2",
                  }}
                  onClick={() => rejectItem(record)}
                >
                  拒绝
                </span>
              </div>
            );
          },
        },
      ]);
    } else if (tableName === "check") {
      setColumns([
        {
          title: "标准类型",
          dataIndex: "wf_type_code_value",
          sorter: true,
        },
        {
          title: "当前版本",
          dataIndex: "cur_ver_no",
          width: "7%",
        },
        {
          title: "申请发布版本",
          dataIndex: "rls_ver_no",
          width: "8%",
        },
        {
          title: "审核状态",
          dataIndex: "appr_stat_code_value",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.appr_stat_code)} />
                {info.appr_stat_code_value}
              </span>
            );
          },
        },
        {
          title: "申请人",
          dataIndex: "apply_by_id_value",
        },
        {
          title: "申请时间",
          dataIndex: "apply_time",
          sorter: true,
          width: "9%",
        },
        {
          title: "版本说明",
          dataIndex: "rn",
        },
        {
          title: "审核人",
          dataIndex: "apprd_by_id_value",
        },
        {
          title: "审核时间",
          dataIndex: "apprd_time",
          sorter: true,
          width: "9%",
        },
        {
          title: "审核说明",
          dataIndex: "apprd_opn",
        },
      ]);
    }

    setTotal(defaultData?.total);
    setPageNumber(defaultData?.page);
    setPageSize(defaultData?.size);
  }, [defaultData]);

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize, activeKey);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  // 改变表格排序
  const tableChange = (pagination, filters, sorter, extra) => {
    if (sorter.order !== undefined) {
      changeOrders(sorter);
    }
  };

  // 修改标准
  const dialogDrawerSuccess = (value, title, defaultData) => {
    setDialogDrawerVisible(false);
    console.log(value, title, defaultData);
    if (title === "修改词根") {
      projectApi
        .updateEtymaStandard({
          ...value,
          chg_type_code: defaultData.chg_type_code,
          word_root_id: defaultData.word_root_id,
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            updateDialogDrawer && updateDialogDrawer();
          } else {
            message.error(res.message);
          }
        });
    } else if (title === "修改数据元") {
      projectApi
        .updateEleStandard({
          ...value,
          data_ele_id: defaultData.data_ele_id,
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            updateDialogDrawer && updateDialogDrawer();
          } else {
            message.error(res.message);
          }
        });
    } else if (title === "修改数据分层") {
      projectApi
        .updateLayerStandard({
          ...value,
          data_layer_id: defaultData.data_layer_id,
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            updateDialogDrawer && updateDialogDrawer();
          } else {
            message.error(res.message);
          }
        });
    } else if (title === "修改主题域") {
      projectApi
        .updateSubStandard({
          ...value,
          data_layer_id: defaultData.data_layer_id,
          sub_id: defaultData.sub_id,
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            updateDialogDrawer && updateDialogDrawer();
          } else {
            message.error(res.message);
          }
        });
    }
  };

  // 点击发布标准
  const addStandard = (data) => {
    getNextVersonList(data?.wf_type_code);
    versonStatusStatics(data?.wf_type_code);
  };

  // 发布状态合计
  const versonStatusStatics = (item) => {
    projectApi.versonStatusStatics({ dg_wf_type_code: item }).then((res) => {
      if (res.code === 200) {
        setVersonStatusList(res.data);
      }
    });
  };

  // 获取发布标准版本号
  const getNextVersonList = (item) => {
    projectApi
      .getNextVersonList({ dg_std_type: item?.split("_RLS")[0] })
      .then((res) => {
        if (res.code === 200) {
          setNextVerson(res.data);
          setReleaseDrawerVisible(true);
        } else {
          setNextVerson([]);
        }
      });
  };

  // 发布标准
  const releaseDrawerSuccess = (data) => {
    applyStandards({ wf_type_code: wfTypeCode, ...data });
    setReleaseDrawerVisible(false);
  };

  return (
    <div className="DataStandardCheckTable">
      <div className="tableContent" ref={refTable}>
        <Table
          className="myFormTable"
          pagination={false}
          columns={columns}
          dataSource={data}
          rowKey={(record, id) => id}
          onChange={tableChange}
          scroll={{
            y: "calc(100vh - 350px)",
          }}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
          loading={
            tableLoading
              ? {
                  indicator: (
                    <ReactSVG
                      src={LoadingSvg}
                      className="anticon-spin anticon myLoadingIcon"
                    />
                  ),
                }
              : false
          }
        />
        {total >= 10 ? (
          <div className="paginationBox">
            <Pagination
              className="myPagination"
              showQuickJumper
              total={total}
              current={pageNumber}
              pageSize={pageSize}
              pageSizeOptions={[10, 20, 50, 100]}
              onChange={onChange}
              showTotal={(total) => `共 ${total} 条`}
              showSizeChanger={true}
            />
          </div>
        ) : null}
      </div>

      <DialogDrawer
        title={drawerTitle}
        okText="确定"
        cancelText="取消"
        defaultData={defaultListData}
        addNew={addNew}
        onOk={dialogDrawerSuccess}
        visible={DialogDrawerVisible}
        onCancel={() => {
          setDialogDrawerVisible(false);
        }}
        addChild={addChild}
        layerList={layerList}
      ></DialogDrawer>
      <DetailDrawer
        onCancel={() => {
          setDetailDrawerVisible(false);
        }}
        visible={DetailDrawerVisible}
        loading={DetailDrawerLoading}
        defaultData={DetailDrawerInfo}
        agreeItem={agreeItem}
        rejectItem={rejectItem}
        DetailTable={DetailTable}
        changePagination={changeDetailPagination}
        wfTypeCode={wfTypeCode}
        versonStatusList={versonStatusList}
      ></DetailDrawer>

      <ReleaseDrawer
        title="重新发布标准"
        onOk={releaseDrawerSuccess}
        visible={ReleaseDrawerVisible}
        onCancel={() => {
          setReleaseDrawerVisible(false);
        }}
        versonStatusList={versonStatusList}
        nextVerson={nextVerson}
      ></ReleaseDrawer>
    </div>
  );
};

export default forwardRef(DataStandardCheckTable);
