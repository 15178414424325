import "./index.scss";
import React, { useState } from "react";
import { Modal, Button, ConfigProvider, message } from "antd";
import MyRangePicker from "../../../../../components/MyRangePicker";
import zhCN from "antd/lib/locale/zh_CN";
import wrapper from "./wrapper";

const MessageModal = (props) => {
  const [visible, setVisible] = useState(true);

  const today = new Date();
  const year = today.getFullYear();
  const m = today.getMonth() + 1;
  const month = m?.toString().padStart(2, "0"); // 月份从0开始，需要加1
  const day = today.getDate();
  const paddedDay = day < 10 ? `0${day}` : day.toString();
  const [amountTime, setAmountTime] = useState([
    `${year}-${month}-${paddedDay}`,
    `${year}-${month}-${paddedDay}`,
  ]); // 日期
  const [time, setTime] = useState([]); // 日期
  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 确定触发的回调 */
    onOk,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    /* 不要按钮 */
    noBtn,
    /* 点击蒙层是否允许关闭 */
    maskClosable,
  } = props;

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const handleOk = () => {
    onOk && onOk(amountTime);
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title={title}
        visible={visible}
        onCancel={handleCancel}
        wrapClassName="messageModal"
        centered
        footer={null}
        maskClosable={maskClosable}
      >
        <div className="messageModalContent">
          <div className="founder" style={{ marginLeft: "10px" }}>
            <div className="name">日期 </div>
            <MyRangePicker
              style={{ width: "250px" }}
              onChange={(time, timeString) => {
                setTime(time);
                setAmountTime(timeString);
              }}
              // time={time}
            ></MyRangePicker>
          </div>
          {!noBtn && (
            <div className="drawerFooterBtnBox btnBox">
              <Button
                className="noBg"
                onClick={() => {
                  handleCancel();
                }}
              >
                {cancelText || "取消"}
              </Button>
              <Button
                style={{
                  background: "#0068B2",
                  borderColor: "#0068B2",
                  color: "#fff",
                }}
                onClick={() => {
                  if (amountTime.length === 0) {
                    message.info("请选择运行日期范围");
                  } else {
                    handleOk();
                  }
                }}
              >
                {okText || "确定"}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default wrapper(MessageModal);
