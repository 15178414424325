import "./index.scss";
import { Tabs, message, Button, Select, Input } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import ReleaseIcon from "../../../assets/icon_发布 1.svg";
import AddIcon from "../../../assets/+.svg";
import DropdownSvg from "../../../assets/下拉.svg";
import DataStandardTable from "../../../components/DataStandardTable";
import Scrollbars from "react-custom-scrollbars";
import trashIcon from "../../../assets/trash.svg";
import importIcon from "../../../assets/导入.svg";
import exportIcon from "../../../assets/导出.svg";
import projectApi from "../../../apis/project";
import ReleaseDrawer from "../../../components/ReleaseDrawer";
import DialogDrawer from "../../../components/StandardDialogDrawer";
import { useGetState } from "ahooks";
import MessageModal from "../../../components/MessageModal";
import ImportDrawer from "../../../components/ImportDrawer";
import { saveBlob } from "../../../unit/unit";
import downloadApi from "../../../apis/project/download.js";
const { TabPane } = Tabs;
const { Option } = Select;
const Element = (props) => {
  const [activeKey, setActiveKey, getActiveKey] = useGetState("1");
  const [tableLoading, setTableLoading] = useState(true);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [addNew, setAddNew] = useState(true);
  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false);
  const [unpublishedList, setUnpublishedList] = useState([]);
  const [publishedList, setpublishedList] = useState([]);
  const [params, setParams] = useState({ page: 1, size: 10 });
  const [versonList, setVersonList] = useState([]);
  const [verson, setVerson] = useState(null);
  const [nextVerson, setNextVerson] = useState(null); // 发布标准版本号
  const [orders, setOrders, getOrders] = useGetState([
    { order_name: "updated_time", order_by: "desc" },
  ]); // 排序
  const [keyValue, setKeyValue] = useState(""); // 检索关键字
  const [selectedRowKey, setSelectedRowKey] = useState(false);

  const [ReleaseDrawerVisible, setReleaseDrawerVisible] = useState(false);
  const [standard, setStandard] = useState(false);
  const [versonStatusCode, setVersonStatusCode] = useState("");
  const [versonStatusList, setVersonStatusList] = useState({});
  const [checkList, setCheckList] = useState({});
  const [importDialogVisible, setImportDialogVisible] = useState(false); // 导入数据元
  const [fileList, setFileList] = useState([]); //导入数据元
  const [importFile, setImportFile] = useState(false); // 正在进行导入操作判断
  const tableRef = useRef();

  const tabChange = (key) => {
    setActiveKey(key);
    if (key === "1") {
      setpublishedList([]);
    }
    if (key === "2") {
      getVersonList();
      setOrders([]);
    }
  };

  useEffect(() => {
    if (activeKey === "1") versonStatus();
  }, [getActiveKey()]);

  useEffect(() => {
    if (activeKey === "1" && versonStatusCode !== "") getUnpublishedList();
  }, [versonStatusCode]);

  useEffect(() => {
    if (activeKey === "2" && verson !== null) {
      getPublishedList(verson);
      getCheckDetail(verson);
    }
  }, [verson]);

  // 获取未发布标准
  const getUnpublishedList = () => {
    let ord = {
      orders,
    };
    if (keyValue !== "") {
      ord.keywords = keyValue;
    }
    setTableLoading(true);
    projectApi
      .getEleUnpublishedList(params, ord)
      .then((res) => {
        if (res.code === 200) {
          setUnpublishedList(res.data);
        } else {
          setUnpublishedList([]);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  // 获取已发布标准
  const getPublishedList = (item) => {
    setTableLoading(true);
    projectApi
      .getElePublishedList({ ver_no: item, ...params }, { orders })
      .then((res) => {
        if (res.code === 200) {
          setpublishedList(res.data);
        } else {
          setpublishedList([]);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  // 获取标准版本
  const getVersonList = () => {
    projectApi
      .getVersonList(
        { dg_std_type: "DATA_ELE" },
        { orders: [{ order_name: "std_ver_id", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          if (res.data.total !== 0) {
            setVerson(res.data.items[0].ver_no);
          }
          setVersonList(res.data);
        } else {
          setVersonList([]);
        }
      });
  };

  // 获取发布标准版本号
  const getNextVersonList = () => {
    projectApi.getNextVersonList({ dg_std_type: "DATA_ELE" }).then((res) => {
      if (res.code === 200) {
        setNextVerson(res.data);
        setReleaseDrawerVisible(true);
      } else {
        setNextVerson([]);
      }
    });
  };

  // 改变分页数据
  const changePagination = (page, pageSize, id) => {
    setParams({ page: page, size: pageSize });
  };

  // 排序
  const changeOrders = (data) => {
    const str = "_value";
    setOrders([
      {
        order_name: data.field.includes("_value")
          ? data.field.split(str).join("")
          : data.field,
        order_by: data.order === "descend" ? "desc" : "asc",
      },
    ]);
    setParams({ page: 1, size: 10 });
  };

  useEffect(() => {
    if (activeKey === "1" && versonStatusCode !== "") {
      getUnpublishedList();
    } else if (activeKey === "2" && verson !== null) {
      getPublishedList(verson);
    }
  }, [params, keyValue, getOrders()]);

  // 发布标准
  const releaseDrawerSuccess = (data) => {
    applyStandards({ wf_type_code: "DATA_ELE_RLS", ...data });
  };

  // 发布标准请求
  const applyStandards = (data) => {
    projectApi.applyStandards(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        versonStatus();
        setReleaseDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 点击发布标准
  const addStandard = () => {
    getNextVersonList();
    projectApi
      .versonStatusStatics({ dg_wf_type_code: "DATA_ELE_RLS" })
      .then((res) => {
        if (res.code === 200) {
          setVersonStatusList(res.data);
        }
      });
  };

  // 审核状态查询
  const versonStatus = () => {
    projectApi.versonStatus({ dg_wf_type_code: "DATA_ELE_RLS" }).then((res) => {
      if (res.code === 200) {
        setVersonStatusCode(res.data?.appr_stat_code);
        if (res.data?.appr_stat_code === "IN_APPROVAL") {
          setStandard(true);
        }
      } else {
        message.error(res.message);
      }
    });
  };

  // 版本选择
  const versonChange = (value) => {
    setVerson(value);
    if (value) {
      getPublishedList(value);
      getCheckDetail(value);
    }
  };

  // 新增数据元
  const dialogDrawerSuccess = (value) => {
    projectApi.AddEleStandard([value]).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setParams({ page: 1, size: 10 });
        setDialogDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 新增数据元
  const elementHandle = () => {
    setDialogDrawerVisible(true);
    setDrawerTitle("新增数据元");
    setAddNew(true);
  };

  // 更新数据元
  const updateDialogDrawer = () => {
    getUnpublishedList();
  };

  // 获取审核详情
  const getCheckDetail = (id) => {
    projectApi
      .getCheckDetail({ ver_no: id, wf_type_code: "DATA_ELE_RLS" })
      .then((res) => {
        if (res.code === 200) {
          setCheckList(res.data);
        }
      });
  };

  // 标准启用
  const operationEnable = (item) => {
    if (versonStatusCode !== "IN_APPROVAL") {
      MessageModal({
        type: "start",
        description: "确认要启用数据元吗？",
        title: "启用数据元",
        okText: "启用",
        onOk: () => {
          projectApi
            .operationEnable({
              oper_object_code: "DATA_ELE_RLS",
              id: item.data_ele_id,
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                getUnpublishedList();
              } else {
                message.error(res.message);
              }
            });
        },
      });
    }
  };

  // 废弃标准
  const operationAbandon = (item) => {
    if (versonStatusCode !== "IN_APPROVAL") {
      MessageModal({
        type: "warning",
        description: "确认要废弃数据元吗？",
        title: "废弃数据元",
        okText: "废弃",
        onOk: () => {
          projectApi
            .operationAbandon({
              oper_object_code: "DATA_ELE_RLS",
              id: item.data_ele_id,
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                getUnpublishedList();
              } else {
                message.error(res.message);
              }
            });
        },
      });
    }
  };

  // 批量删除标准
  const deleteEleStandard = () => {
    const slectRowList = tableRef.current.selectedRowList.map((item, index) => {
      return item.data_ele_id;
    });
    if (slectRowList.length === 0) message.info("请选择需要删除的数据元标准");
    if (slectRowList.length !== 0) {
      MessageModal({
        type: "warning",
        description: "确认要批量删除数据元吗？",
        title: "删除词根",
        okText: "删除",
        onOk: () => {
          projectApi
            .deleteEleStandard({ data_ele_ids: slectRowList })
            .then((res) => {
              if (res.code === 200) {
                setParams({ page: 1, size: 10 });
                message.success(res.message);
                setSelectedRowKey(true);
              } else {
                message.error(res.message);
              }
            });
        },
      });
    }
  };

  // 删除标准
  const deleteItem = (item) => {
    if (versonStatusCode !== "IN_APPROVAL") {
      MessageModal({
        type: "warning",
        description: "确定删除数据元吗？",
        title: "删除",
        okText: "删除",
        onOk: () => {
          projectApi
            .deleteEleStandard({ data_ele_ids: [item.data_ele_id] })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                setParams({ page: 1, size: 10 });
              } else {
                message.error(res.message);
              }
            });
        },
      });
    }
  };
  // 导入数据元
  const uploadFileSuccess = (files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file?.originFileObj);
    });
    projectApi.importEleUnpublishedList(formData).then((res) => {
      if (res.code === 200) {
        setFileList([]);
        setImportDialogVisible(false);
        message.success(
          `成功${res.data.success_num}个，失败${res.data.error_num}个，忽略${res.data.skip_num}个`
        );
        setImportFile(false);
        getUnpublishedList();
      } else {
        message.error(res.message);
      }
    });
  };

  // 导出未发布标准
  const exporEleUnpublishedList = (value) => {
    downloadApi.exporEleUnpublishedList(value).then((blob) => {
      const filename = blob?.headers["content-disposition"].split("''")[1];
      const decodedFilename = decodeURIComponent(filename);
      saveBlob(blob.data, {
        fileName: decodedFilename,
        type: "application/vnd.ms-execel",
      });
    });
    // .catch(() => {
    //   message.error("导出失败");
    // });
  };

  // 导出已发布标准
  const exportElePublishedList = (id) => {
    downloadApi
      .exportElePublishedList(id)
      .then((blob) => {
        saveBlob(blob, {
          fileName: `数据标准-数据元标准-已发布标准-导出-${id}`,
          type: "application/vnd.ms-execel",
        });
      })
      .catch(() => {
        message.error("导出失败");
      });
  };

  return (
    <Scrollbars
      autoHide={true}
      autoHideTimeout={5000}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <div className="ElementContent">
        <Tabs className="myTabs" onChange={tabChange} defaultActiveKey="1">
          <TabPane tab="未发布标准" key="1">
            <div className="noReleseInfo">
              {versonStatusCode === "IN_APPROVAL" && standard ? (
                <div className="promptValue">
                  <InfoCircleFilled
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                    }}
                  />
                  <span>标准审核与发布中，暂不能进行修改</span>
                </div>
              ) : null}

              {versonStatusCode === "REJECTED" ? (
                <div className="promptValue">
                  <InfoCircleFilled
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                      color: "#FE545F",
                    }}
                  />
                  <span style={{ color: "#FE545F" }}>
                    标准审核被驳回，请修改后再重新发布
                  </span>
                </div>
              ) : null}

              <div className="info">
                <div className="selectInfo">
                  <Input
                    placeholder="请输入英文全称/缩写/中文"
                    className="Input"
                    allowClear
                    onPressEnter={(e) => setKeyValue(e.target.value)}
                    onChange={(e) => {
                      if (e.target.value === "") setKeyValue("");
                    }}
                  />
                </div>
                <div className="btnBox">
                  <Button
                    type="primary"
                    icon={<ReactSVG src={AddIcon} className="Icon"></ReactSVG>}
                    onClick={() => elementHandle(true)}
                    disabled={standard}
                  >
                    新增
                  </Button>
                  <Button
                    type="primary"
                    icon={
                      <ReactSVG src={importIcon} className="Icon"></ReactSVG>
                    }
                    onClick={() => {
                      if (importFile) {
                        message.info("正在导入中，请勿重复操作!");
                      } else {
                        setImportDialogVisible(true);
                      }
                    }}
                    disabled={standard}
                  >
                    导入
                  </Button>
                  <Button
                    type="primary"
                    icon={
                      <ReactSVG src={trashIcon} className="Icon"></ReactSVG>
                    }
                    onClick={deleteEleStandard}
                    disabled={standard}
                  >
                    批量删除
                  </Button>
                  <Button
                    type="primary"
                    icon={
                      <ReactSVG src={exportIcon} className="Icon"></ReactSVG>
                    }
                    onClick={() => {
                      keyValue !== ""
                        ? exporEleUnpublishedList(keyValue)
                        : exporEleUnpublishedList();
                    }}
                    disabled={standard}
                  >
                    导出
                  </Button>
                  {versonStatusCode !== "REJECTED" ? (
                    <Button
                      type="primary"
                      icon={
                        <ReactSVG src={ReleaseIcon} className="Icon"></ReactSVG>
                      }
                      onClick={addStandard}
                      disabled={standard}
                    >
                      发布标准
                    </Button>
                  ) : null}

                  {versonStatusCode === "REJECTED" ? (
                    <Button
                      type="primary"
                      icon={
                        <ReactSVG src={ReleaseIcon} className="Icon"></ReactSVG>
                      }
                      onClick={addStandard}
                      disabled={standard}
                    >
                      重新发布
                    </Button>
                  ) : null}
                </div>
              </div>
              <div className="infoBox">
                <DataStandardTable
                  ref={tableRef}
                  defaultData={unpublishedList}
                  tableName="EleUnpublished"
                  changePagination={changePagination}
                  activeKey={getActiveKey()}
                  changeOrders={changeOrders}
                  versonStatusCode={versonStatusCode}
                  updateDialogDrawer={updateDialogDrawer}
                  operationEnable={operationEnable}
                  operationAbandon={operationAbandon}
                  deleteItem={deleteItem}
                  selectedRowKey={selectedRowKey}
                  tableLoading={tableLoading}
                  standard={standard}
                ></DataStandardTable>
              </div>
            </div>
          </TabPane>
          <TabPane tab="已发布标准" key="2">
            <div className="ReleseInfo">
              <div className="promptValue">
                <div className="selectInfo">
                  <div className="label">标准版本</div>
                  <div className="value">
                    <Select
                      placeholder="全部"
                      showSearch
                      // allowClear
                      showArrow="true"
                      onChange={versonChange}
                      value={verson}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                    >
                      {versonList?.items?.map((item, index) => {
                        return (
                          <Option value={item.ver_no} key={index}>
                            {item.ver_no}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">发布时间</div>
                  <div className="detailValue">{checkList?.apprd_time}</div>
                  <div className="detailKey">发布人</div>
                  <div className="detailValue">
                    {checkList?.apply_by_id_value}
                  </div>
                  <div className="detailKey">审核人</div>
                  <div className="detailValue">
                    {checkList?.apprd_by_id_value}
                  </div>
                  {/* <Button
                    type="primary"
                    icon={
                      <ReactSVG
                        src={exportIcon}
                        style={{ marginRight: "6px" }}
                      ></ReactSVG>
                    }
                    disabled={standard}
                    style={{ display: "flex" }}
                    onClick={() => exportElePublishedList(verson)}
                  >
                    导出
                  </Button> */}
                </div>
              </div>
              <div className="infoBox">
                <DataStandardTable
                  defaultData={publishedList}
                  tableName="EleUnpublished"
                  changePagination={changePagination}
                  activeKey={getActiveKey()}
                  changeOrders={changeOrders}
                  flag={true}
                  tableLoading={tableLoading}
                ></DataStandardTable>
              </div>
            </div>
          </TabPane>
        </Tabs>
        <ReleaseDrawer
          title="发布标准"
          onOk={releaseDrawerSuccess}
          visible={ReleaseDrawerVisible}
          onCancel={() => {
            setReleaseDrawerVisible(false);
          }}
          versonStatusList={versonStatusList}
          nextVerson={nextVerson}
          keywords={"数据元"}
        ></ReleaseDrawer>

        <DialogDrawer
          title={drawerTitle}
          okText="确定"
          cancelText="取消"
          addNew={addNew}
          onOk={dialogDrawerSuccess}
          visible={DialogDrawerVisible}
          onCancel={() => {
            setDialogDrawerVisible(false);
          }}
        ></DialogDrawer>

        <ImportDrawer
          okText="导入"
          onTitle="导入数据元"
          cancelText="取消"
          visible={importDialogVisible}
          onCancel={() => {
            setImportDialogVisible(false);
            setFileList([]);
          }}
          successFunc={uploadFileSuccess}
          fileList={fileList}
          setFileList={setFileList}
          setImportFile={setImportFile}
        ></ImportDrawer>
      </div>
    </Scrollbars>
  );
};
export default Element;
