import "./index.scss";
import { useState } from "react";
import { ReactSVG } from "react-svg";
import tableIcon from "../../../../../assets/表16.svg";
import { Drawer, Button, Form, Input, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import MySpin from "../../../../../components/MySpin";
import projectApi from "../../../../../apis/project";
const { TextArea } = Input;

const GovReleaseDrawer = (props) => {
  const {
    /* 关闭触发的回调 */
    onCancel,
    /* 确定触发的回调 */
    onOk,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    visible,
    /* 初始值 */
    defaultData,
    // loading,
  } = props;

  const [form] = Form.useForm();

  const [apiList, setApiList] = useState({});

  const handleCancel = () => {
    onCancel && onCancel();
  };

  // 提交表单录入
  const onFinish = (value) => {
    const apiPkIds = defaultData?.map((item) => item.api_pkg_id);
    onOk &&
      onOk({
        api_pkg_ids: apiPkIds,
        remarks: value.remarks,
      });
  };
  const getPkgService = (id) => {
    projectApi.getPkgService(id).then((res) => {
      if (res.code === 200) {
        setApiList(res.data);
      } else {
        setApiList({});
      }
    });
  };

  const titleTypeBgc = (data) => {
    if (data === "ORIGINAL") {
      return "#61D4AD";
    }
    if (data === "NEW") {
      return "#FEC054";
    }
    if (data === "UPDATE") {
      return "#006AB2";
    }
    if (data === "DISCARD") {
      return "#FE545F";
    }
  };

  return (
    <Drawer
      title="提交发布"
      placement="right"
      width="600px"
      className="ReleaseDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              form.submit();
            }}
            style={{ backgroundColor: "#016DB4", color: "#fff" }}
          >
            {okText ? okText : "提交发布"}
          </Button>

          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            {cancelText ? cancelText : "取消"}
          </Button>
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="ReleaseDrawerForm"
          preserve={false}
          onFinish={onFinish}
        >
          <Form.Item
            name="remarks"
            rules={[
              {
                required: true,
                message: "请输入版本更新说明",
              },
            ]}
            label="版本更新说明"
          >
            <TextArea
              allowClear
              style={{
                width: "540px",
              }}
              autoSize={{ minRows: 5 }}
            />
          </Form.Item>
          <div style={{ marginLeft: "10px", marginBottom: "5px" }}>
            提交发布内容
          </div>

          {defaultData?.map((item, index) => (
            <div className="tableInfo" key={index}>
              <ReactSVG src={tableIcon} className="tableIcon" />
              {apiList?.api_list?.length !== 0 ? (
                <Tooltip
                  trigger={"click"}
                  placement="bottomLeft"
                  title={
                    <div
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                      }}
                    >
                      {apiList?.api_list?.map((data, index) => (
                        <div
                          className="headerTip"
                          style={{ marginBottom: "15px" }}
                          key={index}
                        >
                          <div className="headerTipTitle">{`${data?.chi_name} / ${data?.eng_name}`}</div>
                          <div className="headerTipText">
                            接口URL: {data?.api_url}
                          </div>
                          <div className="headerTipText">
                            请求方式: {data?.req_method_code}
                          </div>
                          <div className="headerTipText">
                            content_type: {data?.content_type}
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <div
                    className="nameValue"
                    onClick={() => getPkgService(item?.api_pkg_id)}
                  >
                    {`${item?.chi_name} / ${item?.eng_name}`}
                  </div>
                </Tooltip>
              ) : (
                <div
                  className="nameValue"
                  onClick={() => getPkgService(item?.api_pkg_id)}
                >
                  {`${item?.chi_name} / ${item?.eng_name}`}
                </div>
              )}

              <div
                className="typeValue"
                style={{
                  background: titleTypeBgc(item?.chg_type_code),
                }}
              >
                新增
              </div>
            </div>
          ))}
        </Form>
      </Scrollbars>
    </Drawer>
  );
};

export default GovReleaseDrawer;
