import "./index.scss";
import { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  Input,
  Form,
  Table,
  Tooltip,
  Pagination,
  ConfigProvider,
} from "antd";
import zhCN from "antd/lib/locale/zh_CN";

import LoadingSvg from "../../../../../assets/loading.svg";
import { InfoCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import wrapper from "./wrapper";
import { ReactSVG } from "react-svg";

import MyEmpty from "../../../../../components/Empty";
import projectApi from "../../../../../apis/project";

const AddModal = (props) => {
  const [visible, setVisible] = useState(true);
  const [form] = Form.useForm();
  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 确定触发的回调 */
    onOk,
    /* 类型 warning 和 默认 */
    type,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    /* 不要按钮 */
    noBtn,
    /* 点击蒙层是否允许关闭 */
    maskClosable,
    addNew,
    defaultData,
  } = props;

  // 表格
  const refTable = useRef(null);
  const [selectedRowList, setSelectedRowList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const [storeList, setStoreList] = useState([]); // api数据服务列表;

  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const columns = [
    {
      dataIndex: "chi_name",
      render: (data, info) => {
        return (
          <Tooltip
            overlayClassName="headerTipContent"
            placement="bottomLeft"
            title={
              <div className="headerTip">
                <div className="headerTipTitle">{`${info?.chi_name} / ${info?.eng_name}`}</div>
                <div className="headerTipText">接口URL: {info?.api_url}</div>
                <div className="headerTipText">
                  请求方式: {info?.req_method_code}
                </div>
                <div className="headerTipText">
                  content_type: {info?.content_type}
                </div>
              </div>
            }
          >
            <div className="titleValue">{`${info?.chi_name} / ${info?.eng_name}`}</div>
          </Tooltip>
        );
      },
    },
  ];

  // 获取api数据服务列表
  const getApiPortList = (data) => {
    // 元数据查询
    let params = {
      page: pageNumber,
      size: pageSize,
    };
    if (data !== undefined) {
      params.api_name = data;
    }
    projectApi.getApiPortList(params).then((res) => {
      if (res.code === 200) {
        setTotal(res.data.total);
        setStoreList(res.data.items);
        if (addNew === false) {
          const ids = defaultData?.api_list?.reduce(
            (prev, cur) => [...prev, cur?.api_id],
            []
          );
          setSelectedRowKeys(ids);
        }
        setLoading(false);
      } else {
        setTotal(0);
        setStoreList([]);
        setLoading(false);
      }
    });
  };

  // 分页
  const onChange = (page, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(page);
  };

  useEffect(() => {
    setLoading(true);
    getApiPortList();
  }, [pageSize, pageNumber]);

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const compareArrays = (arr1, arr2) => {
    let difference = [];
    for (let i = 0; i < arr1.length; i++) {
      let found = false;
      for (let j = 0; j < arr2.length; j++) {
        if (arr1[i].api_id === arr2[j].api_id) {
          found = true;
          break;
        }
      }
      if (!found) {
        difference.push(arr1[i]);
      }
    }
    return difference;
  };

  const handleOk = () => {
    if (addNew) {
      onOk && onOk(selectedRowList);
    } else {
      const data = compareArrays(selectedRowList, defaultData?.api_list);
      setSelectedRowList(data);
      onOk && onOk(data);
    }

    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  // 颜色
  const getStyleOptions = () => {
    let color = "";
    let icon = null;
    let cor = "";
    switch (type) {
      case "warning":
        color = "#FE545F";
        icon = <InfoCircleFilled style={{ color: "#FE545F" }} />;
        cor = "#fff";
        break;
      default:
        color = "#0068B2";
        icon = <CheckCircleFilled style={{ color: "#0068B2" }} />;
        cor = "#fff";
        break;
    }
    return {
      color,
      icon,
      btnStyle: {
        background: color,
        borderColor: color,
        color: cor,
      },
    };
  };

  const StyleOptions = getStyleOptions();

  // 添加查询
  const demandFinish = (value) => {
    if (value.api_name !== "" && value.api_name !== undefined) {
      setLoading(true);
      getApiPortList(value.api_name);
    } else if (value.api_name === "") {
      getApiPortList();
    }
  };

  // 表格第一项多选
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowList(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title={title}
        visible={visible}
        onCancel={handleCancel}
        wrapClassName="PackageGovAddModal"
        centered
        footer={null}
        maskClosable={maskClosable}
        width={984}
      >
        <div className="addModalContent">
          <Form
            form={form}
            autoComplete="off"
            className="modalForm"
            preserve={false}
            onFinish={demandFinish}
          >
            <Form.Item label="接口名称" name="api_name">
              <Input
                allowClear
                bordered={false}
                style={{
                  width: "300px",
                  borderBottom: "1px solid #313131",
                }}
              />
            </Form.Item>
          </Form>
          <Button
            style={{
              background: "#0068B2",
              color: "#fff",
              width: "92px",
              marginTop: "-20px",
            }}
            onClick={() => {
              form.submit();
            }}
          >
            查询
          </Button>

          <div
            className="tableContent"
            style={{ width: "100%" }}
            ref={refTable}
          >
            <Table
              className="myFormTable"
              rowSelection={rowSelection}
              columns={columns}
              dataSource={storeList}
              pagination={false}
              rowKey={(record) => record?.api_id}
              scroll={{
                y: "500px",
              }}
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
              loading={
                loading
                  ? {
                      indicator: (
                        <ReactSVG
                          src={LoadingSvg}
                          className="anticon-spin anticon myLoadingIcon"
                        />
                      ),
                    }
                  : false
              }
            />
            {total >= 5 ? (
              <div
                className={total < 10 ? "myPaginationSite" : "paginationBox"}
              >
                <Pagination
                  className="myPagination"
                  showQuickJumper
                  total={total}
                  pageSize={pageSize}
                  current={pageNumber}
                  showSizeChanger={true}
                  onChange={onChange}
                  showTotal={(total) => `共 ${total} 条`}
                />
              </div>
            ) : null}
          </div>

          {!noBtn && (
            <div className="drawerFooterBtnBox btnBox">
              <Button
                className="noBg"
                onClick={() => {
                  handleCancel();
                }}
              >
                {cancelText || "关闭"}
              </Button>
              <Button
                style={StyleOptions.btnStyle}
                onClick={() => {
                  handleOk();
                }}
              >
                {okText || "添加"}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default wrapper(AddModal);
