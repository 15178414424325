import React, { useState, useEffect } from "react";

import MyEmpty from "../../../../../components/Empty";
import ReactEcharts from "echarts-for-react";
import MySpin from "../../../../../components/MySpin";
import "./index.scss";

const BarChart = (props) => {
  const { barChartList, AllApiList, staticLoading } = props;

  const [barInfo, setBarInfo] = useState([]);

  const [xAxis, setXAxis] = useState([]);

  useEffect(() => {
    setBarInfo(Object?.values(barChartList));

    const result = Object?.keys(barChartList).map((key) => {
      const matchedObject = AllApiList.find(
        (obj) => obj.api_pkg_id === Number(key)
      );
      return matchedObject?.chi_name;
    });
    setXAxis(result);
  }, [barChartList]);

  // 配置对象
  const getOption = (sal, sto) => {
    return {
      title: {
        text: "",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },

      legend: {
        data: ["调用成功", "调用失败"],
      },

      xAxis: {
        type: "category",
        data: xAxis,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "调用成功",
          data: barInfo?.map((item) => item.true),
          type: "bar",
          showBackground: false,
          color: "#006AB2",
          stack: "Ad",
        },
        {
          name: "调用失败",
          data: barInfo?.map((item) => item.false),
          type: "bar",
          showBackground: false,
          color: "#A197FB",
          stack: "Ad",
        },
      ],
    };
  };

  return (
    <div className="BarChart">
      {staticLoading ? (
        <MySpin></MySpin>
      ) : Object?.keys(barChartList)?.length === 0 ? (
        <MyEmpty></MyEmpty>
      ) : (
        <ReactEcharts option={getOption()} style={{ height: "100%" }} />
      )}
    </div>
  );
};

export default BarChart;
