import "./index.scss";
import React, { useEffect, useState, useRef } from "react";
import { Button } from "antd";
import { LoadingOutlined, ColumnWidthOutlined } from "@ant-design/icons";

import VisualBox from "../VisualBox";
import MySpin from "../../../../../components/MySpin";

import flowIcon from "../../../../../assets/流向.svg";
import sourceIcon from "../../../../../assets/来源.svg";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { useFullscreen } from "ahooks";
import icon3 from "assets/API.png";
const BloodRelation = (props) => {
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);
  // 数据分层主题域映射
  const layerSubList = useSelector((state) => state.Project.layerSubList);
  // 元数据类型
  const metaDataType = dicCodeInfoList?.DG_METADATA_TYPE;
  // const [visible, setVisible] = useState(true);
  // const [loading, setloading] = useState(true);
  const [flowLoading, setFlowLoading] = useState(false);
  const [sourceLoading, setSourceLoading] = useState(false);

  const {
    setDetailDrawerVisible,
    setRelationFlag,
    /* 血缘关系 */
    RelationList,
    /* 点击数据本身信息 */
    metaDataInfo,
    /* 查看详情 */
    getmetaDataInfo,
    loading,
  } = props;
  const tableContentRef = useRef();
  const tableContent = tableContentRef.current;

  const [maxwidth, setMaxwidth] = useState(null);
  const [maxheight, setMaxheight] = useState(null);
  const ref = useRef(null);
  useEffect(() => {
    if (tableContent) {
      const { width, height } = tableContent.getBoundingClientRect();
      setMaxwidth(width);
      setMaxheight(height);
      // console.log("Width:", width);
      // console.log("Height:", height);
    }
  }, []);
  const [isFullscreen, { enterFullscreen, exitFullscreen, toggleFullscreen }] =
    useFullscreen(ref);
  // useEffect(() => {
  //   if (tableContent) {
  //     const { width, height } = tableContent.getBoundingClientRect();
  //     setMaxwidth(width);
  //     setMaxheight(height);
  //     // console.log("Width:", width);
  //     // console.log("Height:", height);
  //   }
  // }, [isFullscreen]);

  // useEffect(() => {
  //   RelationList?.length !== 0 ? setloading(false) : setloading(true);
  // }, [RelationList]);

  const handleCancel = () => {
    setRelationFlag(false);
    setDetailDrawerVisible(false);
  };

  const childMethodRef = useRef(null);

  const handleOk = () => {
    if (childMethodRef.current) {
      childMethodRef.current.handleSaveClick(); // 调用子组件中定义的方法
    }
  };

  return (
    <div className="BloodRelation" ref={ref}>
      <div className="addModalContent">
        <div
          className="tableContent"
          style={{ width: "100%" }}
          ref={tableContentRef}
        >
          <div className="blood">血缘关系</div>
          {loading ? (
            <MySpin></MySpin>
          ) : (
            <VisualBox
              childMethodRef={childMethodRef}
              defaultData={RelationList}
              metaDataInfo={metaDataInfo}
              metaDataType={metaDataType}
              getmetaDataInfo={getmetaDataInfo}
              layerSubList={layerSubList}
              setFlowLoading={setFlowLoading}
              setSourceLoading={setSourceLoading}
              maxwidth={maxwidth}
              maxheight={maxheight}
            />
          )}
        </div>
        <div className="UpstreamAndDownstream">
          <Button
            style={{
              background: "rgba(204, 204, 204, 0.5)",
              color: "#313131",
              height: "34px",
            }}
            onClick={() => {
              if (childMethodRef.current) {
                childMethodRef.current.sourceData(); // 调用子组件中定义的方法
              }
            }}
            disabled={sourceLoading}
          >
            {sourceLoading ? (
              <div>
                <LoadingOutlined style={{ fontSize: 14, marginRight: "5px" }} />
                <span>获取中</span>
              </div>
            ) : (
              <div>
                <ReactSVG src={sourceIcon} className="Icon"></ReactSVG>
                获取上游
              </div>
            )}
          </Button>

          <Button
            style={{
              background: "rgba(204, 204, 204, 0.5)",
              color: "#313131",
              height: "34px",
            }}
            onClick={() => {
              if (childMethodRef.current) {
                childMethodRef.current.flowData(); // 调用子组件中定义的方法
              }
            }}
            disabled={flowLoading}
          >
            {flowLoading ? (
              <div>
                <LoadingOutlined style={{ fontSize: 14, marginRight: "5px" }} />
                <span>获取中</span>
              </div>
            ) : (
              <div>
                <ReactSVG src={flowIcon} className="Icon"></ReactSVG>
                获取下游
              </div>
            )}
          </Button>
        </div>
        <div className="btnBox">
          <Button
            onClick={() => {
              handleCancel();
            }}
          >
            关闭
          </Button>
          <Button
            style={{ background: "#006AB2", color: "#fff" }}
            onClick={() => {
              handleOk();
            }}
          >
            下载
          </Button>
          {/* <ColumnWidthOutlined onClick={toggleFullscreen} />; */}
        </div>
      </div>
    </div>
  );
};

export default BloodRelation;
