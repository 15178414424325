import "./index.scss";
import { Table, Pagination, Badge, message } from "antd";
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import MyEmpty from "../Empty";
import DialogDrawer from "../StandardDialogDrawer";
import projectApi from "../../apis/project";
import LoadingSvg from "../../assets/loading.svg";
import { ReactSVG } from "react-svg";

const DataStandardTable = (props, ref) => {
  const {
    defaultData,
    tableName,
    changePagination,
    activeKey,
    changeOrders,
    flag,
    versonStatusCode, //标准审核状态码
    updateDialogDrawer, // 更新标准
    selectedRowKey,
    operationEnable, // 启用
    operationAbandon, // 废弃
    deleteItem, // 删除
    layerList,
    tableLoading,
    standard, // 判断是否禁用
  } = props;

  const refTable = useRef(null);

  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [defaultListData, setDdefaultListData] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false);

  const [addChild, setAddChild] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [selectedRowList, setSelectedRowList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [childList, setChidlist] = useState({}); // 子主题域对应表单数据项
  const [childInfo, setChidInfo] = useState({}); // 子主题域默认值
  const [childLoding, setChidLoding] = useState(true); // 子主题域表格加载状态

  const data = defaultData?.items;
  useEffect(() => {
    if (selectedRowKey) setSelectedRowKeys([]);
  }, [selectedRowKey]);

  // 状态颜色切换
  const changeColor = (value) => {
    if (value === "NEW") {
      return "#D8D8D8";
    } else if (value === "DISCARD") {
      return "#FE545F";
    } else if (value === "ORIGINAL") {
      return "#61D4AD";
    } else if (value === "UPDATE") {
      return "#61D4AD";
    }
  };

  // 子主题域配置
  const expandedRowRender = (item) => {
    let columns = [
      {
        title: "主题域",
        dataIndex: "sub_name",
        sorter: true,
        width: "7.9%",
      },
      {
        title: "数据分层",
        dataIndex: "data_layer_name",
        width: "6.9%",
        sorter: true,
      },
      {
        title: "英文缩写",
        dataIndex: "sub_eng_abbr",
        width: "6.9%",
        sorter: true,
      },
      {
        title: "描述",
        dataIndex: "sub_desc",
        width: "15%",
      },
      {
        title: "状态",
        width: "6%",
        dataIndex: "chg_type_code_value",
        sorter: true,
        render: (data, info) => {
          return (
            <span>
              <Badge color={changeColor(info.chg_type_code)} />
              {info.chg_type_code_value}
            </span>
          );
        },
      },
      {
        title: "版本(*表示有更新)",
        dataIndex: "ver_no",
        width: "8.9%",
        render: (data, info) => {
          return (
            <div>
              {info.ver_no !== null ? (
                <div
                  style={{
                    color:
                      info.chg_type_code === "UPDATE" ? "#FE545F" : "#535353",
                  }}
                >
                  {info.chg_type_code === "UPDATE"
                    ? `*${info.ver_no}`
                    : info.ver_no}
                </div>
              ) : null}
            </div>
          );
        },
      },
      {
        title: "创建人",
        dataIndex: "created_by_id_value",
        width: "7.4%",
      },
      {
        title: "创建时间",
        dataIndex: "created_time",
        sorter: true,
        width: "8.9%",
      },
      {
        title: "更新人",
        dataIndex: "updated_by_id_value",
        width: "7.5%",
      },
      {
        title: "更新时间",
        dataIndex: "updated_time",
        width: "9%",
      },
      {
        title: "操作",
        width: "12%",
        dataIndex: "操作",
        render: (_, record) => {
          if (activeKey === "1") {
            return (
              <div className="operation">
                {record.chg_type_code !== "DISCARD" ? (
                  <span
                    className="modify"
                    onClick={() => layerHandle(record, "修改子主题域")}
                    style={{
                      color: standard ? "#B5B5B5" : "#006AB2",
                    }}
                  >
                    修改
                  </span>
                ) : null}

                {record.chg_type_code !== "NEW" &&
                record.chg_type_code !== "DISCARD" &&
                record.ver_no !== null ? (
                  <span
                    style={{
                      color: standard ? "#B5B5B5" : "#FE545F",
                    }}
                    onClick={() => operationAbandon(record)}
                  >
                    废弃
                  </span>
                ) : null}

                {record.chg_type_code !== "ORIGINAL" &&
                record.chg_type_code !== "DISCARD" &&
                record.ver_no === null ? (
                  <span
                    className="delete"
                    onClick={() => deleteItem(record)}
                    style={{
                      color: standard ? "#B5B5B5" : "#006AB2",
                    }}
                  >
                    删除
                  </span>
                ) : null}

                {record.chg_type_code === "DISCARD" ? (
                  <span
                    style={{
                      color: standard ? "#B5B5B5" : "#61D4AD",
                    }}
                    onClick={() => operationEnable(record)}
                  >
                    启用
                  </span>
                ) : null}
              </div>
            );
          }
        },
      },
    ];
    let pubColumns = [
      {
        title: "主题域",
        dataIndex: "sub_name",
        sorter: true,
        width: "9.8%",
      },
      {
        title: "数据分层",
        dataIndex: "data_layer_name",
        width: "9.8%",
        sorter: true,
      },
      {
        title: "英文缩写",
        dataIndex: "sub_eng_abbr",
        width: "7%",
        sorter: true,
      },
      {
        title: "描述",
        dataIndex: "sub_desc",
        width: "22.7%",
      },
      {
        title: "版本(*表示有更新)",
        dataIndex: "ver_no",
        width: "8.8%",
        render: (data, info) => {
          return (
            <div>
              {info.ver_no !== null ? (
                <div
                  style={{
                    color:
                      info.chg_type_code === "UPDATE" ? "#FE545F" : "#535353",
                  }}
                >
                  {info.chg_type_code === "UPDATE"
                    ? `*${info.ver_no}`
                    : info.ver_no}
                </div>
              ) : null}
            </div>
          );
        },
      },
      {
        title: "创建人",
        dataIndex: "created_by_id_value",
        width: "7.8%",
      },
      {
        title: "创建时间",
        dataIndex: "created_time",
        sorter: true,
        width: "11%",
      },
      {
        title: "更新人",
        dataIndex: "updated_by_id_value",
        width: "7.5%",
      },
      {
        title: "更新时间",
        dataIndex: "updated_time",
        width: "11%",
      },
    ];

    return (
      <Table
        columns={activeKey === "1" ? columns : pubColumns}
        dataSource={childList[item.sub_id]}
        bordered={false}
        pagination={false}
        showHeader={false}
        rowKey={(record) => record?.sub_id}
        locale={{
          emptyText: <MyEmpty></MyEmpty>,
        }}
      />
    );
  };

  useEffect(() => {
    if (tableName === "LayerUnpublished") {
      setColumns([
        {
          title: "分层名称",
          dataIndex: "data_layer_name",
          sorter: true,
        },
        {
          title: "英文名称",
          dataIndex: "data_layer_eng_name",
          sorter: true,
        },
        {
          title: "英文缩写",
          dataIndex: "data_layer_eng_abbr",
          sorter: true,
        },
        {
          title: "描述",
          dataIndex: "data_layer_desc",
          width: "20%",
        },
        {
          title: "状态",
          dataIndex: "chg_type_code_value",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.chg_type_code)} />
                {info.chg_type_code_value}
              </span>
            );
          },
        },
        {
          title: activeKey === "1" ? "版本(*表示有更新)" : "版本",
          dataIndex: "ver_no",
          width: "9%",
          render: (data, info) => {
            return (
              <div>
                {info.ver_no !== null ? (
                  <div
                    style={{
                      color:
                        info.chg_type_code === "UPDATE" ? "#FE545F" : "#535353",
                    }}
                  >
                    {info.chg_type_code === "UPDATE"
                      ? `*${info.ver_no}`
                      : info.ver_no}
                  </div>
                ) : null}
              </div>
            );
          },
        },
        {
          title: "创建人",
          dataIndex: "created_by_id_value",
        },
        {
          title: "创建时间",
          dataIndex: "created_time",
          sorter: true,
          width: "9%",
        },
        {
          title: "更新人",
          dataIndex: "updated_by_id_value",
        },
        {
          title: "更新时间",
          dataIndex: "updated_time",
          width: "9%",
          sorter: true,
        },
        {
          title: "操作",
          width: "8%",
          dataIndex: "operation",
          render: (_, record) => {
            if (activeKey !== "2") {
              return (
                <div className="operation" key={record.key}>
                  {record.chg_type_code !== "DISCARD" ? (
                    <span
                      className="modify"
                      onClick={() => layerHandle(record, "修改数据分层")}
                      style={{
                        color: standard ? "#B5B5B5" : "#006AB2",
                      }}
                    >
                      修改
                    </span>
                  ) : null}

                  {record.chg_type_code !== "NEW" &&
                  record.chg_type_code !== "DISCARD" &&
                  record.ver_no !== null ? (
                    <span
                      style={{
                        color: standard ? "#B5B5B5" : "#FE545F",
                      }}
                      onClick={() => operationAbandon(record)}
                    >
                      废弃
                    </span>
                  ) : null}

                  {record.chg_type_code !== "ORIGINAL" &&
                  record.chg_type_code !== "DISCARD" &&
                  record.ver_no === null ? (
                    <span
                      className="delete"
                      onClick={() => deleteItem(record)}
                      style={{
                        color: standard ? "#B5B5B5" : "#006AB2",
                      }}
                    >
                      删除
                    </span>
                  ) : null}

                  {record.chg_type_code === "DISCARD" ? (
                    <span
                      style={{
                        color: standard ? "#B5B5B5" : "#61D4AD",
                      }}
                      onClick={() => operationEnable(record)}
                    >
                      启用
                    </span>
                  ) : null}
                </div>
              );
            }
          },
        },
      ]);
    } else if (tableName === "EtymaUnpublished") {
      setColumns([
        {
          title: "英文名称",
          dataIndex: "word_root_eng",
          sorter: true,
        },
        {
          title: "英文缩写",
          dataIndex: "word_root_eng_abbr",
          sorter: true,
        },
        {
          title: "中文",
          dataIndex: "word_root_chi",
          sorter: true,
        },
        {
          title: "描述",
          dataIndex: "word_root_desc",
        },
        {
          title: "状态",
          width: "6%",
          dataIndex: "chg_type_code_value",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.chg_type_code)} />
                {info.chg_type_code_value}
              </span>
            );
          },
        },
        {
          title: activeKey === "1" ? "版本(*表示有更新)" : "版本",
          dataIndex: "ver_no",
          width: "9%",
          render: (data, info) => {
            return (
              <div>
                {info.ver_no !== null ? (
                  <div
                    style={{
                      color:
                        info.chg_type_code === "UPDATE" ? "#FE545F" : "#535353",
                    }}
                  >
                    {info.chg_type_code === "UPDATE"
                      ? `*${info.ver_no}`
                      : info.ver_no}
                  </div>
                ) : null}
              </div>
            );
          },
        },
        {
          title: "创建人",
          dataIndex: "created_by_id_value",
        },
        {
          title: "创建时间",
          dataIndex: "created_time",
          width: activeKey === "1" ? "9%" : "11%",
          sorter: true,
        },
        {
          title: "更新人",
          dataIndex: "updated_by_id_value",
        },
        {
          title: "更新时间",
          dataIndex: "updated_time",
          width: activeKey === "1" ? "9%" : "11%",
          sorter: true,
        },
        {
          title: "操作",
          width: "8%",
          dataIndex: "操作",
          render: (_, record) => {
            if (activeKey !== "2") {
              return (
                <div className="operation" key={record.key}>
                  {record.chg_type_code !== "DISCARD" ? (
                    <span
                      className="modify"
                      onClick={() => layerHandle(record, "修改词根")}
                      style={{
                        color: standard ? "#B5B5B5" : "#006AB2",
                      }}
                    >
                      修改
                    </span>
                  ) : null}

                  {record.chg_type_code !== "NEW" &&
                  record.chg_type_code !== "DISCARD" &&
                  record.ver_no !== null ? (
                    <span
                      style={{
                        color: standard ? "#B5B5B5" : "#FE545F",
                        marginRight: "16px",
                      }}
                      onClick={() => operationAbandon(record)}
                    >
                      废弃
                    </span>
                  ) : null}

                  {record.chg_type_code !== "ORIGINAL" &&
                  record.chg_type_code !== "DISCARD" &&
                  record.ver_no === null ? (
                    <span
                      className="delete"
                      onClick={() => deleteItem(record)}
                      style={{
                        color: standard ? "#B5B5B5" : "#006AB2",
                      }}
                    >
                      删除
                    </span>
                  ) : null}

                  {record.chg_type_code === "DISCARD" ? (
                    <span
                      style={{
                        color: standard ? "#B5B5B5" : "#61D4AD",
                      }}
                      onClick={() => operationEnable(record)}
                    >
                      启用
                    </span>
                  ) : null}
                </div>
              );
            }
          },
        },
      ]);
    } else if (tableName === "SubUnpublished") {
      setColumns([
        {
          title: "主题域",
          dataIndex: "sub_name",
          sorter: true,
          width: activeKey === "1" ? "8%" : "10%",
        },
        {
          title: "数据分层",
          dataIndex: "data_layer_name",
          width: activeKey === "1" ? "7%" : "10%",
          sorter: true,
        },
        {
          title: "英文缩写",
          dataIndex: "sub_eng_abbr",
          width: activeKey === "1" ? "7%" : "7%",
          sorter: true,
        },
        {
          title: "描述",
          dataIndex: "sub_desc",
          width: activeKey === "1" ? "15%" : "23%",
        },
        {
          title: "状态",
          width: "6%",
          dataIndex: "chg_type_code_value",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.chg_type_code)} />
                {info.chg_type_code_value}
              </span>
            );
          },
        },
        {
          title: activeKey === "1" ? "版本(*表示有更新)" : "版本",
          dataIndex: "ver_no",
          width: "9%",
          render: (data, info) => {
            return (
              <div>
                {info.ver_no !== null ? (
                  <div
                    style={{
                      color:
                        info.chg_type_code === "UPDATE" ? "#FE545F" : "#535353",
                    }}
                  >
                    {info.chg_type_code === "UPDATE"
                      ? `*${info.ver_no}`
                      : info.ver_no}
                  </div>
                ) : null}
              </div>
            );
          },
        },
        {
          title: "创建人",
          dataIndex: "created_by_id_value",
        },
        {
          title: "创建时间",
          dataIndex: "created_time",
          sorter: true,
          width: activeKey === "1" ? "9%" : "11%",
        },
        {
          title: "更新人",
          dataIndex: "updated_by_id_value",
        },
        {
          title: "更新时间",
          dataIndex: "updated_time",
          width: activeKey === "1" ? "9%" : "11%",
          sorter: true,
        },
        {
          title: "操作",
          width: "12%",
          dataIndex: "操作",
          render: (_, record) => {
            if (activeKey !== "2") {
              return (
                <div className="operation">
                  <span
                    className="add"
                    style={{
                      color: standard ? "#B5B5B5" : "#006AB2",
                    }}
                    onClick={() => subjectHandle(record, "新增子主题域")}
                  >
                    新增子主题
                  </span>

                  {record.chg_type_code !== "DISCARD" ? (
                    <span
                      className="modify"
                      onClick={() => layerHandle(record, "修改主题域")}
                      style={{
                        color: standard ? "#B5B5B5" : "#006AB2",
                      }}
                    >
                      修改
                    </span>
                  ) : null}

                  {record.chg_type_code !== "NEW" &&
                  record.chg_type_code !== "DISCARD" &&
                  record.ver_no !== null ? (
                    <span
                      style={{
                        color: standard ? "#B5B5B5" : "#FE545F",
                      }}
                      onClick={() => operationAbandon(record)}
                    >
                      废弃
                    </span>
                  ) : null}

                  {record.chg_type_code !== "ORIGINAL" &&
                  record.chg_type_code !== "DISCARD" &&
                  record.ver_no === null ? (
                    <span
                      className="delete"
                      onClick={() => {
                        getChild(record, true);
                      }}
                      style={{
                        color: standard ? "#B5B5B5" : "#006AB2",
                      }}
                    >
                      删除
                    </span>
                  ) : null}

                  {record.chg_type_code === "DISCARD" ? (
                    <span
                      style={{
                        color: standard ? "#B5B5B5" : "#61D4AD",
                      }}
                      onClick={() => operationEnable(record)}
                    >
                      启用
                    </span>
                  ) : null}
                </div>
              );
            }
          },
        },
      ]);
    } else if (tableName === "EleUnpublished") {
      setColumns([
        {
          title: "中文名",
          dataIndex: "col_cmnt",
          sorter: true,
        },
        {
          title: "字段名",
          dataIndex: "col_name",
          sorter: true,
        },
        {
          title: "字段类型",
          dataIndex: "col_data_type_code_value",
          sorter: true,
        },
        {
          title: "长度",
          dataIndex: "col_length",
          width: "5%",
        },
        {
          title: "小数位",
          dataIndex: "col_scale",
          width: "5%",
        },
        {
          title: "描述",
          dataIndex: "data_ele_desc",
        },
        {
          title: "状态",
          width: "6%",
          dataIndex: "chg_type_code_value",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.chg_type_code)} />
                {info.chg_type_code_value}
              </span>
            );
          },
        },
        {
          title: activeKey === "1" ? "版本(*表示有更新)" : "版本",
          dataIndex: "ver_no",
          width: "9%",
          render: (data, info) => {
            return (
              <div>
                {info.ver_no !== null ? (
                  <div
                    style={{
                      color:
                        info.chg_type_code === "UPDATE" ? "#FE545F" : "#535353",
                    }}
                  >
                    {info.chg_type_code === "UPDATE"
                      ? `*${info.ver_no}`
                      : info.ver_no}
                  </div>
                ) : null}
              </div>
            );
          },
        },
        {
          title: "创建人",
          dataIndex: "created_by_id_value",
        },
        {
          title: "创建时间",
          dataIndex: "created_time",
          sorter: true,
          width: "8%",
        },
        {
          title: "更新人",
          dataIndex: "updated_by_id_value",
        },
        {
          title: "更新时间",
          dataIndex: "updated_time",
          width: "8%",
          sorter: true,
        },
        {
          title: "操作",
          width: "8%",
          dataIndex: "操作",
          render: (_, record) => {
            if (activeKey !== "2") {
              return (
                <div className="operation">
                  {record.chg_type_code !== "DISCARD" ? (
                    <span
                      className="modify"
                      style={{
                        color: standard ? "#B5B5B5" : "#006AB2",
                      }}
                      onClick={() => layerHandle(record, "修改数据元")}
                    >
                      修改
                    </span>
                  ) : null}

                  {record.chg_type_code !== "NEW" &&
                  record.chg_type_code !== "DISCARD" &&
                  record.ver_no !== null ? (
                    <span
                      style={{
                        color: standard ? "#B5B5B5" : "#FE545F",
                      }}
                      onClick={() => operationAbandon(record)}
                    >
                      废弃
                    </span>
                  ) : null}

                  {record.chg_type_code !== "ORIGINAL" &&
                  record.chg_type_code !== "DISCARD" &&
                  record.ver_no === null ? (
                    <span
                      className="delete"
                      style={{
                        color: standard ? "#B5B5B5" : "#006AB2",
                      }}
                      onClick={() => deleteItem(record)}
                    >
                      删除
                    </span>
                  ) : null}

                  {record.chg_type_code === "DISCARD" ? (
                    <span
                      style={{
                        color: standard ? "#B5B5B5" : "#61D4AD",
                      }}
                      onClick={() => operationEnable(record)}
                    >
                      启用
                    </span>
                  ) : null}
                </div>
              );
            }
          },
        },
      ]);
    }

    setTotal(defaultData?.total);
    setPageNumber(defaultData?.page);
    setPageSize(defaultData?.size);
    if (flag) {
      setColumns((item) =>
        item.filter((data) => data.title !== "操作" && data.title !== "状态")
      );
    }
  }, [defaultData]);

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize, activeKey);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  // 获取子主题域
  const getSubStandardList = (expanded, record) => {
    setChidInfo(record);
    if (expanded) {
      setChidLoding(true);
      getChild(record);
    }
    if (expanded === false) {
      setChidlist((item) => {
        let newObj = {};
        for (let key in item) {
          if (key !== record.sub_id) {
            newObj[key] = item[key];
          }
        }
        return newObj;
      });
    }
  };

  // 获取子主题域请求
  const getChild = (record, flag) => {
    if (activeKey === "1") {
      projectApi
        .getSubStandardList(record.sub_id, {
          publish_type: "UN_PUBLISHED",
          data_layer_name: record?.data_layer_name,
          page: 1,
          size: 100,
        })
        .then((res) => {
          if (res.code === 200) {
            setChidlist({ ...childList, [record.sub_id]: res.data.items });
            setChidLoding(false);
            if (flag) {
              deleteItem(
                record,
                res.data.items.map((item) => item.sub_id)
              );
            }
          } else {
            setChidlist({});
            message.error(res.message);
            setChidLoding(false);
          }
        });
    }
    if (activeKey === "2") {
      projectApi
        .getSubStandardList(record.sub_id, {
          publish_type: "PUBLISHED",
          data_layer_name: record?.data_layer_name,
          page: 1,
          size: 100,
        })
        .then((res) => {
          if (res.code === 200) {
            setChidlist({ ...childList, [record.sub_id]: res.data.items });
            setChidLoding(false);
          } else {
            setChidlist([]);
            message.error(res.message);
            setChidLoding(false);
          }
        });
    }
  };

  // 批量删除选项
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowList(selectedRows);
  };
  // 表格第一项多选
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.chg_type_code !== "NEW" && record.ver_no !== null,
      name: record.chg_type_code,
    }),
  };

  // 暴露表格给父组件
  useImperativeHandle(ref, () => ({
    selectedRowList,
    getChild, // 更新获取子主题域
    childInfo, // 更新子主题域需要的数据
  }));

  // 改变表格排序
  const tableChange = (pagination, filters, sorter, extra) => {
    if (sorter.order !== undefined) {
      changeOrders(sorter);
    }
  };

  // 修改未发布分层标准
  const layerHandle = (item, name) => {
    if (versonStatusCode !== "IN_APPROVAL") {
      setDdefaultListData(item);
      setDialogDrawerVisible(true);
      setDrawerTitle(name);
      setAddNew(false);
      setAddChild(false);
      if (name === "修改子主题域") {
        setAddChild(true); // 子主题判断
      }
    }
  };

  // 新增子主题
  const subjectHandle = (item, name) => {
    if (versonStatusCode !== "IN_APPROVAL") {
      setDdefaultListData(item);
      setDrawerTitle(name);
      setAddNew(true);
      setAddChild(true); // 新增子主题判断
      setDialogDrawerVisible(true);
    }
  };

  // 修改标准
  const dialogDrawerSuccess = (value, title, defaultData, add) => {
    setDialogDrawerVisible(false);
    console.log(value, title, add);
    if (title === "修改词根") {
      projectApi
        .updateEtymaStandard({
          ...value,
          chg_type_code: defaultData.chg_type_code,
          word_root_id: defaultData.word_root_id,
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            updateDialogDrawer && updateDialogDrawer();
          } else {
            message.error(res.message);
          }
        });
    } else if (title === "修改数据元") {
      projectApi
        .updateEleStandard({
          ...value,
          data_ele_id: defaultData.data_ele_id,
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            updateDialogDrawer && updateDialogDrawer();
          } else {
            message.error(res.message);
          }
        });
    } else if (title === "修改数据分层") {
      projectApi
        .updateLayerStandard({
          ...value,
          data_layer_id: defaultData.data_layer_id,
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            updateDialogDrawer && updateDialogDrawer();
          } else {
            message.error(res.message);
          }
        });
    } else if (title === "修改主题域") {
      projectApi
        .updateSubStandard({
          ...value,
          sub_id: defaultData.sub_id,
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            updateDialogDrawer && updateDialogDrawer();
          } else {
            message.error(res.message);
          }
        });
    } else if (title === "修改子主题域") {
      projectApi
        .updateSubStandard({
          ...value,
          data_layer_id: defaultData.data_layer_id,
          sub_id: defaultData.sub_id,
          parent_sub_id: defaultData.parent_sub_id,
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getChild(childInfo);
          } else {
            message.error(res.message);
          }
        });
    } else if (add) {
      projectApi
        .AddSubStandard({
          ...value,
          data_layer_id: defaultData.data_layer_id,
          parent_sub_id: defaultData.sub_id,
        })
        .then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            updateDialogDrawer && updateDialogDrawer();
            if (Object.keys(childInfo).length !== 0) getChild(childInfo);
          } else {
            message.error(res.message);
          }
        });
    }
  };

  return (
    <div className="DataStandardTable">
      <div className="tableContent" ref={refTable}>
        {layerList?.length === 0 && tableName === "SubUnpublished" ? (
          <Table
            className="myFormTable"
            pagination={false}
            columns={columns}
            dataSource={[]}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
          />
        ) : (
          <Table
            expandable={
              tableName === "SubUnpublished"
                ? {
                    expandedRowRender,
                    onExpand: getSubStandardList,
                  }
                : null
            }
            rowSelection={
              tableName !== "LayerUnpublished" &&
              tableName !== "SubUnpublished" &&
              activeKey === "1"
                ? rowSelection
                : null
            }
            className="myFormTable"
            loading={
              tableLoading
                ? {
                    indicator: (
                      <div style={{ background: "#fff" }}>
                        <ReactSVG
                          src={LoadingSvg}
                          className="anticon-spin anticon myLoadingIcon"
                        />
                      </div>
                    ),
                  }
                : false
            }
            pagination={false}
            columns={columns}
            dataSource={data}
            // rowKey={(record, id) => id}
            rowKey={(record, id) => {
              if (tableName === "LayerUnpublished") {
                return record?.data_layer_id;
              } else if (tableName === "EtymaUnpublished") {
                return record?.word_root_id;
              } else if (tableName === "SubUnpublished") {
                return record?.sub_id;
              } else if (tableName === "EleUnpublished") {
                return record?.data_ele_id;
              }
            }}
            onChange={tableChange}
            scroll={{
              y: "calc(100vh - 370px)",
            }}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
          />
        )}

        {total >= 10 ? (
          <div className="paginationBox">
            <Pagination
              className="myPagination"
              showQuickJumper
              current={pageNumber}
              total={total}
              pageSize={pageSize}
              pageSizeOptions={[10, 20, 50, 100]}
              onChange={onChange}
              showTotal={(total) => `共 ${total} 条`}
              showSizeChanger={true}
            />
          </div>
        ) : null}
      </div>

      <DialogDrawer
        title={drawerTitle}
        okText="确定"
        cancelText="取消"
        defaultData={defaultListData}
        addNew={addNew}
        onOk={dialogDrawerSuccess}
        visible={DialogDrawerVisible}
        onCancel={() => {
          setDialogDrawerVisible(false);
        }}
        addChild={addChild}
        layerList={layerList}
      ></DialogDrawer>
    </div>
  );
};

export default forwardRef(DataStandardTable);
