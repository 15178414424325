import "./index.scss";
import { useEffect, useState } from "react";
import { Modal, Button, Table, message } from "antd";
import wrapper from "./wrapper";
import MyEmpty from "../../../../../components/Empty";
import projectApi from "../../../../../apis/project";

const VerifyModal = (props) => {
  const [visible, setVisible] = useState(true);

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 确定触发的回调 */
    onOk,
    /* 类型 warning 和 默认 */
    type,
    /* 详细内容 */
    description,
    /* 提示内容 */
    messageInfo,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    /* 不要按钮 */
    noBtn,
    /* 不要图标 */
    noIcon,
    /* 点击蒙层是否允许关闭 */
    maskClosable,
    data, // 模型校验不通过的数据
    columsSource, // 字段设计数据
    FieldType, // 字段类型
  } = props;

  const [falseList, setFalseList] = useState([]); // 不符合模型规范数据
  const [selectedRowList, setSelectedRowList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [rowId, setRowId] = useState(""); // 选中表

  useEffect(() => {
    const filteredArray = columsSource.filter((value) => {
      const matchingField = data.find((item) => item === value.col_name);

      return matchingField;
    });
    setFalseList(filteredArray);
    // 设置默认全部勾选
    setSelectedRowList(filteredArray);
    const allSelectedRowKeys = filteredArray.map((item, index) => index);
    setSelectedRowKeys(allSelectedRowKeys);
  }, [columsSource, data]);

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const handleOk = () => {
    const data = selectedRowList?.reduce(
      (cur, pre) => [
        ...cur,
        {
          col_name: pre?.col_name,
          col_cmnt: pre?.col_desc,
          col_data_type_code: pre?.col_type_code,
          col_length: pre?.col_length,
          col_scale: pre?.col_scale,
          data_ele_desc: null,
        },
      ],
      []
    );
    if (data?.length !== 0) {
      // 添加数据到数据元标准
      projectApi.AddEleStandard(data).then((res) => {
        if (res.code === 200) {
          message.success("成功添加数据元标准");
          onOk && onOk();
          setVisible(false);
          setTimeout(() => {
            closeDialog();
          }, 500);
        } else {
          message.error("添加数据元标准失败");
        }
      });
    } else {
      onOk && onOk();
      setVisible(false);
      setTimeout(() => {
        closeDialog();
      }, 500);
    }
  };

  const getStyleOptions = () => {
    let color = "";
    let icon = null;
    let col = "";
    switch (type) {
      case "warning":
        color = "#FE545F";
        col = "#fff";
        // icon = <InfoCircleFilled style={{ color: "#FE545F" }} />;
        break;
      case "start":
        color = "#61D4AD";
        col = "#fff";
        // icon = <InfoCircleFilled style={{ color: "#61D4AD" }} />;
        break;
      default:
        color = "#0068B2";
        col = "#fff";
        // icon = <CheckCircleFilled style={{ color: "#0068B2" }} />;
        break;
    }
    return {
      color,
      icon,
      btnStyle: {
        background: color,
        borderColor: color,
        color: col,
      },
    };
  };

  const StyleOptions = getStyleOptions();

  const tableColumns = [
    {
      title: "中文名",
      dataIndex: "col_desc",
    },
    {
      title: "字段名",
      dataIndex: "col_name",
      render: (_, info) => info.col_name,
    },
    {
      title: "主键",
      dataIndex: "pk_flag",
      render: (_, info) => (info?.pk_flag ? "是" : "否"),
    },
    {
      title: "不为空",
      dataIndex: "not_null_flag",

      render: (_, info) => (info?.not_null_flag ? "是" : "否"),
    },
    {
      title: "自增",
      dataIndex: "auto_inc_flag",

      render: (_, info) => (info?.auto_inc_flag ? "是" : "否"),
    },
    {
      title: "字段类型",
      dataIndex: "col_type_code",

      render: (_, info) => FieldType?.map((item) => item[info?.col_type_code]),
    },
    {
      title: "长度",
      dataIndex: "col_length",
      render: (_, info) => info.col_length,
    },
    {
      title: "小数位",
      dataIndex: "col_scale",
      render: (_, info) => info.col_scale,
    },
    {
      title: "默认值",
      dataIndex: "def_value",
    },
  ];

  // 表格第一项多选
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowList(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={handleCancel}
      wrapClassName="VerifyModal"
      centered
      footer={null}
      maskClosable={maskClosable}
      width="900px"
    >
      <div className="messageModalContent">
        {!noIcon && <div className="iconBox">{StyleOptions.icon}</div>}

        {description && <div className="descriptionBox">{description}</div>}
        {messageInfo && (
          <div
            className="messageBox"
            style={{ color: title === "保存模型" ? "#FE545F" : null }}
          >
            {messageInfo}
          </div>
        )}
        <div className="tableContent" style={{ width: "100%" }}>
          <Table
            className="myFormTable"
            rowSelection={rowSelection}
            columns={tableColumns}
            rowKey={(record, id) => id}
            dataSource={falseList}
            scroll={{
              y: "50vh",
            }}
            // onRow={(record) => {
            //   return {
            //     onClick: (e) => {
            //       setRowId(record.chi_name);
            //     },
            //   };
            // }}
            pagination={false}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
          />
        </div>

        {!noBtn && (
          <div className="drawerFooterBtnBox btnBox">
            <Button
              className="noBg"
              onClick={() => {
                handleCancel();
              }}
            >
              {cancelText || "取消"}
            </Button>
            <Button
              style={StyleOptions.btnStyle}
              onClick={() => {
                handleOk();
              }}
            >
              {okText || "确定"}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default wrapper(VerifyModal);
