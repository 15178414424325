import "./index.scss";
import { Tabs, message, Select, Input } from "antd";
import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { useGetState } from "ahooks";
import { useSelector } from "react-redux";
import projectApi from "../../../apis/project";
import Scrollbars from "react-custom-scrollbars";
import DropdownSvg from "../../../assets/下拉.svg";
import ServiceOrderTable from "./components/ServiceOrderTable";
import MessageModal from "../../../components/MessageModal";
import { debounce } from "../../../unit/unit";

const { TabPane } = Tabs;
const { Option } = Select;

const DataServiceOrder = (props) => {
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);
  // 审核状态
  const statueTypeValue = dicCodeInfoList?.DG_API_ORD_APPR_STAT
    ? [{ all: "全部" }, ...dicCodeInfoList?.DG_API_ORD_APPR_STAT]
    : [];
  const ApprType = statueTypeValue?.map((item) => Object.keys(item)).flat();

  const checkApprState = () => {
    return ApprType?.filter((item) => item !== "IN_APPROVAL");
  };

  // 订单状态
  const OrderTypeValue = dicCodeInfoList?.DG_API_ORD_APPR_STAT
    ? [{ all: "全部" }, ...dicCodeInfoList?.DG_API_ORD_APPR_STAT]
    : [];
  const OrderType = OrderTypeValue?.map((item) => Object.keys(item)).flat();

  const [orders, setOrders] = useState(["apply_time", "desc"]);
  const [tableLoading, setTableLoading] = useState(false);
  const [activeKey, setActiveKey, getActiveKey] = useGetState("1");

  const [applicant, setApplicant] = useState(""); // 申请人（待审核）
  const [packageName, setPackageName] = useState(""); // 服务包名（待审核）

  const [checkApplicant, setCheckApplicant] = useState(""); // 申请人（已审核）
  const [checkPackageName, setCheckPackageName] = useState(""); // 服务包名（已审核）

  const [orderApplicant, setOrderApplicant] = useState(""); // 申请人（我的订单）
  const [orderPackageName, setOrderPackageName] = useState(""); // 服务包名（我的订单）

  const [checkApprType, setCheckApprType] = useState("all"); // 已审核审核状态

  const [allOrderType, setAllOrderType] = useState("all"); // 全部订单 订单状态

  const [params, setParams] = useState({ page: 1, size: 10 });
  const [orderInfo, setOrderInfo] = useState({}); // 排序对象

  const [toCheckList, setToCheckList] = useState({}); // 待审核
  const [checkList, setCheckList] = useState({}); // 已审核
  const [allOrder, setAllOrder] = useState({}); // 全部订单

  // tab切换
  const tabChange = (key) => {
    setActiveKey(key);
    if (key === "2") {
      setOrders(["apprd_time", "desc"]);
      setParams({ page: 1, size: 10 });
      setOrderInfo({});
    } else if (key === "3") {
      setOrders(["apprd_time", "desc"]);
      setParams({ page: 1, size: 10 });
      setOrderInfo({});
    } else if (key === "1") {
      setOrders(["apply_time", "desc"]);
      setParams({ page: 1, size: 10 });
      setOrderInfo({});
    }
  };

  // 获取服务开发我的申请
  const getOrderList = (info) => {
    let param = {
      order_list: orders.toString(),
      ...params,
    };

    if (info !== undefined) {
      param = {
        ...params,
        ...info,
      };
    }
    if (applicant !== "" && activeKey === "1") {
      param.apply_by_name = applicant;
    }
    if (packageName !== "" && activeKey === "1") {
      param.api_pkg_name = packageName;
    }

    if (checkApplicant !== "" && activeKey === "2") {
      param.apply_by_name = checkApplicant;
    }
    if (checkPackageName !== "" && activeKey === "2") {
      param.api_pkg_name = checkPackageName;
    }

    if (orderApplicant !== "" && activeKey === "3") {
      param.apply_by_name = orderApplicant;
    }
    if (orderPackageName !== "" && activeKey === "3") {
      param.api_pkg_name = orderPackageName;
    }

    if (activeKey === "1") {
      param.appr_stat_code = ["IN_APPROVAL"];
    }

    if (activeKey === "2" && checkApprType === "all") {
      param.appr_stat_code = ["APPROVED", "REJECTED"];
    }
    if (activeKey === "2" && checkApprType !== "all") {
      param.appr_stat_code = [checkApprType];
    }
    if (activeKey === "3" && allOrderType !== "all") {
      param.ord_stat_code = allOrderType;
    }

    projectApi.getOrderList(param).then((res) => {
      if (res.code === 200) {
        if (activeKey === "1") {
          setToCheckList(res.data);
        } else if (activeKey === "2") {
          setCheckList(res.data);
        } else if (activeKey === "3") {
          setAllOrder(res.data);
        }
        setTableLoading(false);
      } else {
        if (activeKey === "1") {
          setToCheckList({});
        } else if (activeKey === "2") {
          setCheckList({});
        } else if (activeKey === "3") {
          setAllOrder({});
        }
        setTableLoading(false);
      }
    });
  };
  useEffect(() => {
    setTableLoading(true);
    getOrderList();
  }, [
    checkApprType,
    allOrderType,
    params,
    activeKey,
    applicant,
    packageName,
    checkApplicant,
    checkPackageName,
    orderApplicant,
    orderPackageName,
  ]);

  // 改变分页数据
  const changePagination = (page, pageSize) => {
    setParams({ page: page, size: pageSize });
  };

  // 排序
  const changeOrders = (data) => {
    setOrderInfo(data);
    setOrders([data.field, data.order === "descend" ? "desc" : "asc"]);
  };

  useEffect(() => {
    if (Object?.values(orderInfo)?.length !== 0) {
      setTableLoading(true);
      getOrderList();
    }
  }, [orders]);

  // 同意审核
  const agreeItem = (id) => {
    MessageModal({
      type: "start",
      description: "确定同意服务申请吗？",
      title: "同意服务申请",
      okText: "同意",
      onOk: () => {
        projectApi
          .auditOrder({
            appr_stat_code: "APPROVED",
            ord_id: id,
          })
          .then((res) => {
            if (res.code === 200) {
              message.success(res.message);
              setTableLoading(true);
              getOrderList();
            } else {
              message.error(res.message);
            }
          });
      },
    });
  };

  // 驳回拒绝审核
  const rejectItem = (id) => {
    MessageModal({
      type: "warning",
      title: "驳回服务申请",
      rejectTitle: "驳回意见",
      onOk: (item) => {
        projectApi
          .auditOrder({
            appr_stat_code: "REJECTED",
            ord_id: id,
            apprd_opn: item?.apprd_opn,
          })
          .then((res) => {
            if (res.code === 200) {
              message.success(res.message);
              setTableLoading(true);
              getOrderList();
            } else {
              message.error(res.message);
            }
          });
      },
    });
  };
  // 强制取消服务
  const recallItem = (item) => {
    MessageModal({
      type: "warning",
      title: "强制取消服务",
      rejectTitle: "取消理由",
      onOk: (item) => {},
    });
  };

  return (
    <Scrollbars
      autoHide={true}
      autoHideTimeout={5000}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <div className="DataServiceOrderContent">
        <Tabs className="myTabs" onChange={tabChange} defaultActiveKey="1">
          <TabPane tab="待审核" key="1">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">申请人</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      onChange={debounce(
                        (e) => setApplicant(e.target.value),
                        1000
                      )}
                    />
                  </div>

                  <div className="label">API服务包</div>
                  <div className="value">
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      onChange={debounce(
                        (e) => setPackageName(e.target.value),
                        1000
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <ServiceOrderTable
                  tableName="ToCheck"
                  activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  agreeItem={agreeItem}
                  rejectItem={rejectItem}
                  changePagination={changePagination}
                  changeOrders={changeOrders}
                  defaultData={toCheckList}
                ></ServiceOrderTable>
              </div>
            </div>
          </TabPane>
          <TabPane tab="已审核" key="2">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">审核状态</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => setCheckApprType(e)}
                      value={checkApprType}
                    >
                      {checkApprState()?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {statueTypeValue?.map((value) => value[item])}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="label">申请人</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      onChange={debounce(
                        (e) => setCheckApplicant(e.target.value),
                        1000
                      )}
                    />
                  </div>

                  <div className="label">API服务包</div>
                  <div className="value">
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      onChange={debounce(
                        (e) => setCheckPackageName(e.target.value),
                        1000
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <ServiceOrderTable
                  tableName="check"
                  activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  changePagination={changePagination}
                  changeOrders={changeOrders}
                  defaultData={checkList}
                ></ServiceOrderTable>
              </div>
            </div>
          </TabPane>
          <TabPane tab="全部订单" key="3">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">订单状态</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onChange={(e) => setAllOrderType(e)}
                      value={allOrderType}
                    >
                      {OrderType?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {OrderTypeValue?.map((value) => value[item])}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="label">申请人</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      onChange={debounce(
                        (e) => setOrderApplicant(e.target.value),
                        1000
                      )}
                    />
                  </div>

                  <div className="label">API服务包</div>
                  <div className="value">
                    <Input
                      allowClear
                      style={{
                        width: "238px",
                        height: "32px",
                        marginTop: "2px",
                      }}
                      placeholder="请输入"
                      onChange={debounce(
                        (e) => setOrderPackageName(e.target.value),
                        1000
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <ServiceOrderTable
                  tableName="allOrder"
                  activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  changePagination={changePagination}
                  changeOrders={changeOrders}
                  defaultData={allOrder}
                  recallItem={recallItem}
                ></ServiceOrderTable>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Scrollbars>
  );
};
export default DataServiceOrder;
