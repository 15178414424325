import "./index.scss";
import { Tabs, message, Button, Select, Radio, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import { useGetState } from "ahooks";

import projectApi from "../../../apis/project";
import Scrollbars from "react-custom-scrollbars";
import DropdownSvg from "../../../assets/下拉.svg";
import ServiceMonitoringTable from "./components/ServiceMonitoringTable";
import MyRangePicker from "../../../components/MyRangePicker";
import HeaderTitle from "../../../components/HeaderTitle";
import BarChart from "./components/BarChart";
import LineChart from "./components/LineChart";
import exportIcon from "../../../assets/导出.svg";
import { debounce } from "../../../unit/unit";
import moment from "moment";
const plainOptions = [
  {
    label: "按小时",
    value: "hour",
  },
  {
    label: "按日",
    value: "day",
  },
];
const { TabPane } = Tabs;
const { Option } = Select;
const today = new Date();
const year = today.getFullYear();
const m = today.getMonth() + 1;
const month = m?.toString().padStart(2, "0"); // 月份从0开始，需要加1
const day = today.getDate();
const paddedDay = day < 10 ? `0${day}` : day.toString();
const ServiceMonitoring = (props) => {
  const [orders, setOrders, getOrders] = useGetState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [activeKey, setActiveKey, getActiveKey] = useGetState("1");

  const [RadioValue, setRadioValue] = useState("hour"); // 折线图小时/日选择
  const [AllApiList, setAllApiList] = useState([]); // api服务包

  const [lineChartList, setLineChartList] = useState({}); // 折线图数据
  const [barChartList, setBarineChartList] = useState({}); // 柱状图数据
  const [userOptions, setUserOptions] = useState([]); // 用户搜索下拉框选项

  const [amountApi, setAmountApi] = useState([]); // 总揽api服务包
  const [amountTime, setAmountTime] = useState([
    `${year}-${month}-${paddedDay}`,
    `${year}-${month}-${paddedDay}`,
  ]); // 总揽时间段

  const [StatApi, setStatApi] = useState([]); // 统计报表api服务包
  const [StatTime, setStatTime] = useState([
    `${year}-${month}-${paddedDay}`,
    `${year}-${month}-${paddedDay}`,
  ]); // 统计报表时间段
  const [StatName, setStatName] = useState(null); // 统计报表用户账号
  const [StatRadioValue, setStatRadioValue] = useState("hour"); // 统计报表小时/日选择

  const [errorApi, setErrorApi] = useState([]); // 错误日志api服务包
  const [errorTime, setErrorTime] = useState([
    `${year}-${month}-${paddedDay}`,
    `${year}-${month}-${paddedDay}`,
  ]); // 错误日志时间段
  const [errorName, setErrorName] = useState(null); // 错误日志用户账号

  const [params, setParams] = useState({ page: 1, size: 10 });
  const [StatisticsList, setStatisticsList] = useState({}); // 统计报表
  const [errorList, setErrorList] = useState({}); // 错误日志
  const [staticLoading, setStaticLoading] = useState(true);
  const onStatRadioChange = ({ target: { value } }) => {
    setStatRadioValue(value);
    setOrders([]);
  };

  // tab切换
  const tabChange = (key) => {
    setActiveKey(key);
    if (key === "2") {
      setOrders([]);
      setParams({ page: 1, size: 10 });
      setUserOptions([]);
    } else if (key === "3") {
      setOrders([]);
      setUserOptions([]);
      setParams({ page: 1, size: 10 });
    } else if (key === "1") {
      setOrders([]);
    }
  };

  // 获取全部api服务包
  const getAllApiList = async () => {
    const { data } = await projectApi.getAllApiList();
    setAllApiList(data);
  };

  useEffect(() => {
    getAllApiList();
  }, []);

  // 获取用户下拉框选项
  const searchUser = (data) => {
    projectApi.searchUser(data).then((res) => {
      if (res.code === 200) {
        setUserOptions(res.data);
      } else {
        setUserOptions([]);
      }
    });
  };

  // 获取调用统计
  const apiStatisticsList = (name) => {
    projectApi.apiStatisticsList(name).then((res) => {
      if (res.code === 200) {
        setLineChartList(res.data.statistics);
        setBarineChartList(res.data.api_pkg_statistics);
        setStaticLoading(false);
      } else {
        setLineChartList({});
        setBarineChartList({});
        setStaticLoading(false);
      }
    });
  };

  // 时间转换
  const setDate = (data) => {
    const inputDate = new Date(data);
    inputDate.setDate(inputDate?.getDate() + 1);
    const outputDate = inputDate?.toISOString().split("T")[0];
    return outputDate;
  };

  useEffect(() => {
    setStaticLoading(true);
    let data = {};
    if (amountApi?.length !== 0) {
      data.api_pkg_id = amountApi;
    }
    if (amountTime?.length !== 0) {
      data.start_time = `${amountTime[0]} 00:00:00`;
      data.end_time = `${setDate(amountTime[1])} 00:00:00`;
    }
    if (RadioValue !== "") {
      data.statistics_type = RadioValue;
    }
    apiStatisticsList(data);
  }, [RadioValue]);

  // 获取调用统计
  const getApiStatisticsList = () => {
    setStaticLoading(true);
    let data = {};
    if (amountApi?.length !== 0) {
      data.api_pkg_id = amountApi;
    }
    if (amountTime?.length !== 0) {
      data.start_time = `${amountTime[0]} 00:00:00`;
      data.end_time = `${setDate(amountTime[1])} 00:00:00`;
    }
    if (RadioValue !== "") {
      data.statistics_type = RadioValue;
    }
    apiStatisticsList(data);
  };

  // 获取统计报表
  const statementMonitorList = (name) => {
    projectApi.statementMonitorList(name).then((res) => {
      if (res.code === 200) {
        setStatisticsList(res.data);
        setTableLoading(false);
      } else {
        setStatisticsList({});
        setTableLoading(false);
      }
    });
  };

  // 改变分页数据
  const changePagination = (page, pageSize) => {
    setParams({ page: page, size: pageSize });
  };

  // 排序
  const changeOrders = (data) => {
    setOrders([data.field, data.order === "descend" ? "desc" : "asc"]);
    setParams({ page: 1, size: 10 });
  };

  useEffect(() => {
    if (activeKey === "2") {
      setTableLoading(true);
      let data = { ...params };
      if (orders?.length !== 0) {
        data.order_list = orders.toString();
      }
      if (StatApi?.length !== 0) {
        data.api_pkg_id = StatApi;
      }
      if (StatTime?.length !== 0) {
        data.start_time = `${StatTime[0]} 00:00:00`;
        data.end_time = `${setDate(StatTime[1])} 00:00:00`;
      }
      if (StatRadioValue !== "") {
        data.statistics_type = StatRadioValue;
      }
      if (StatName !== null) {
        data.call_by_id = StatName;
      }
      statementMonitorList(data);
    }
  }, [params, StatRadioValue, orders]);

  const getStatementMonitorList = () => {
    if (activeKey === "2") {
      setTableLoading(true);
      let data = { ...params };
      if (orders?.length !== 0) {
        data.order_list = orders.toString();
      }
      if (StatApi?.length !== 0) {
        data.api_pkg_id = StatApi;
      }
      if (StatTime?.length !== 0) {
        data.start_time = `${StatTime[0]} 00:00:00`;
        data.end_time = `${setDate(StatTime[1])} 00:00:00`;
      }
      if (StatRadioValue !== "") {
        data.statistics_type = StatRadioValue;
      }
      if (StatName !== null) {
        data.call_by_id = StatName;
      }

      statementMonitorList(data);
    }
  };

  // 获取错误日志列表
  const errorMonitorList = (name) => {
    projectApi.errorMonitorList(name).then((res) => {
      if (res.code === 200) {
        setErrorList(res.data);
        setTableLoading(false);
      } else {
        setErrorList({});
        setTableLoading(false);
      }
    });
  };

  useEffect(() => {
    if (errorTime?.length !== 0 && activeKey === "3") {
      setTableLoading(true);
      let data = {
        start_time: `${errorTime[0]} 00:00:00`,
        end_time: `${setDate(errorTime[1])} 00:00:00`,
        ...params,
      };

      if (orders?.length !== 0) {
        data.order_list = orders.toString();
      }
      if (errorApi?.length !== 0) {
        data.api_pkg_id = errorApi;
      }

      if (errorName !== null) {
        data.call_by_id = errorName;
      }

      errorMonitorList(data);
    }
  }, [params, orders]);

  const getErrorMonitorList = () => {
    if (errorTime?.length !== 0 && activeKey === "3") {
      setTableLoading(true);
      let data = {
        start_time: `${errorTime[0]} 00:00:00`,
        end_time: `${setDate(errorTime[1])} 00:00:00`,
        ...params,
      };

      if (orders?.length !== 0) {
        data.order_list = orders.toString();
      }
      if (errorApi?.length !== 0) {
        data.api_pkg_id = errorApi;
      }

      if (errorName !== null) {
        data.call_by_id = errorName;
      }

      errorMonitorList(data);
    }
  };

  return (
    <Scrollbars
      autoHide={true}
      autoHideTimeout={5000}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <div className="ServiceMonitoringContent">
        <Tabs className="myTabs" onChange={tabChange} defaultActiveKey="1">
          <TabPane tab="总揽" key="1">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">API服务包</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      mode="multiple"
                      maxTagCount="responsive"
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "300px" }}
                      value={amountApi}
                      onChange={(e) => setAmountApi(e)}
                    >
                      {AllApiList?.map((item) => {
                        return (
                          <Option value={item.api_pkg_id} key={item.api_pkg_id}>
                            {item.chi_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="label">时间段</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <MyRangePicker
                      style={{ width: "250px" }}
                      onChange={(time, timeString) => {
                        setAmountTime(timeString);
                      }}
                    ></MyRangePicker>
                  </div>

                  <Button type="primary" onClick={getApiStatisticsList}>
                    查询
                  </Button>
                </div>
              </div>
              <div className="callInfo">
                <HeaderTitle
                  title="调用统计"
                  extra={
                    <Button
                      type="primary"
                      icon={
                        <ReactSVG src={exportIcon} className="Icon"></ReactSVG>
                      }
                    >
                      导出
                    </Button>
                  }
                ></HeaderTitle>
                <LineChart
                  lineChartList={lineChartList}
                  setRadioValue={setRadioValue}
                  RadioValue={RadioValue}
                  AllApiList={AllApiList}
                  staticLoading={staticLoading}
                ></LineChart>
              </div>

              <div className="apiServiceInfo">
                <HeaderTitle
                  title="API服务包调用统计"
                  extra={
                    <Button
                      type="primary"
                      icon={
                        <ReactSVG src={exportIcon} className="Icon"></ReactSVG>
                      }
                    >
                      导出
                    </Button>
                  }
                ></HeaderTitle>
                <BarChart
                  barChartList={barChartList}
                  AllApiList={AllApiList}
                  staticLoading={staticLoading}
                ></BarChart>
              </div>
            </div>
          </TabPane>
          <TabPane tab="统计报表" key="2">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">API服务包</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      mode="multiple"
                      maxTagCount="responsive"
                      placeholder="请选择"
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "300px" }}
                      value={StatApi}
                      onChange={(e) => setStatApi(e)}
                    >
                      {AllApiList?.map((item) => {
                        return (
                          <Option value={item.api_pkg_id} key={item.api_pkg_id}>
                            {item.chi_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="label">时间段</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <MyRangePicker
                      style={{ width: "250px" }}
                      onChange={(time, timeString) => {
                        setStatTime(timeString);
                      }}
                    ></MyRangePicker>
                  </div>

                  <div className="label">用户账号/名称</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      allowClear
                      showSearch
                      suffixIcon={null}
                      onSearch={debounce((e) => {
                        if (e !== "") {
                          searchUser(e);
                        } else {
                          setUserOptions([]);
                        }
                      }, 500)}
                      style={{ width: "238px" }}
                      placeholder="请输入查询用户"
                      filterOption={false}
                      value={StatName}
                      onChange={(e) => setStatName(e)}
                      onClear={(e) => setUserOptions([])}
                    >
                      {userOptions?.map((data) => (
                        <Option key={data.user_id} value={data.user_id}>
                          {data.user_name}
                        </Option>
                      ))}
                    </Select>
                  </div>

                  <Button type="primary" onClick={getStatementMonitorList}>
                    查询
                  </Button>
                </div>
              </div>

              <div className="radioInfo">
                <Radio.Group
                  options={plainOptions}
                  onChange={onStatRadioChange}
                  value={StatRadioValue}
                />
                <Button
                  type="primary"
                  icon={<ReactSVG src={exportIcon} className="Icon"></ReactSVG>}
                >
                  导出
                </Button>
              </div>
              <div className="infoBox">
                <ServiceMonitoringTable
                  tableName="StaStatement"
                  activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  defaultData={StatisticsList}
                  changePagination={changePagination}
                  changeOrders={changeOrders}
                  AllApiList={AllApiList}
                  StatRadioValue={StatRadioValue}
                ></ServiceMonitoringTable>
              </div>
            </div>
          </TabPane>
          <TabPane tab="错误日志" key="3">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">API服务包</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      mode="multiple"
                      maxTagCount="responsive"
                      placeholder="请选择"
                      allowClear
                      showSearch={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "300px" }}
                      value={errorApi}
                      onChange={(e) => setErrorApi(e)}
                    >
                      {AllApiList?.map((item) => {
                        return (
                          <Option value={item.api_pkg_id} key={item.api_pkg_id}>
                            {item.chi_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="label">时间段</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <MyRangePicker
                      style={{ width: "250px" }}
                      onChange={(time, timeString) => {
                        setErrorTime(timeString);
                      }}
                    ></MyRangePicker>
                  </div>

                  <div className="label">用户账号/名称</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      showSearch
                      allowClear
                      suffixIcon={null}
                      onSearch={debounce((e) => {
                        if (e !== "") {
                          searchUser(e);
                        } else {
                          setUserOptions([]);
                        }
                      }, 500)}
                      style={{ width: "238px" }}
                      placeholder="请输入查询用户"
                      filterOption={false}
                      value={errorName}
                      onChange={(e) => setErrorName(e)}
                      onClear={(e) => setUserOptions([])}
                    >
                      {userOptions?.map((data) => (
                        <Option key={data.user_id} value={data.user_id}>
                          {data.user_name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <Button type="primary" onClick={getErrorMonitorList}>
                    查询
                  </Button>
                </div>
              </div>
              <div className="infoBox">
                <ServiceMonitoringTable
                  tableName="ErrorLog"
                  activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  changePagination={changePagination}
                  changeOrders={changeOrders}
                  defaultData={errorList}
                  AllApiList={AllApiList}
                ></ServiceMonitoringTable>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Scrollbars>
  );
};
export default ServiceMonitoring;
