import "./index.scss";
import React, { useEffect, useState } from "react";
import { Table, Tabs } from "antd";
import MyEmpty from "../../../../../components/Empty";

const { TabPane } = Tabs;

const DetailTable = (props, ref) => {
  const { dbTableList } = props;

  const colData = dbTableList?.table_columns;
  // const fksData = dbTableList?.table_fks;
  const indexsData = dbTableList?.table_indexes;
  const partData = dbTableList?.table_partitions;

  const [fksSource, setFksSource] = useState({}); // 子外键
  const [fksData, setFksData] = useState([]); // 父外键

  // 子参照配置
  const expandedRowRender = (data) => {
    const fkscolumn = [
      {
        title: "字段",
        dataIndex: "fk_col_id",
        editable: true,
      },
      {
        title: "连线起点",
        dataIndex: "re_source_code",
        editable: true,
      },
      {
        title: "连线终点",
        dataIndex: "re_target_code",
        editable: true,
      },
      {
        title: "对应参照字段",
        dataIndex: "ref_col_id",
        editable: true,
      },
    ];

    return (
      <Table
        className="myFormTable"
        bordered={false}
        pagination={false}
        dataSource={fksSource[data.fk_table_id]}
        columns={fkscolumn}
        rowKey={(record, id) => record?.ord}
        locale={{
          emptyText: <MyEmpty></MyEmpty>,
        }}
      />
    );
  };

  useEffect(() => {
    const data = dbTableList?.table_fks?.reduce(
      (cur, pre) => [...cur, { ord: pre.ord, fk_table_id: pre.fk_metadata_id }],
      []
    );
    setFksData(data);

    const kfsItem = dbTableList?.table_fks?.reduce((cur, pre) => {
      const value = cur;
      const preValue = pre.fk_cols?.reduce(
        (cur, pre) => [
          ...cur,
          {
            ord: pre.ord,
            fk_col_id: pre.fk_col_id,
            re_source_code: pre.re_source_code,
            re_target_code: pre.re_target_code,
            ref_col_id: pre.ref_col_id,
          },
        ],
        []
      );
      value[pre.fk_metadata_id] = preValue;
      return value;
    }, {});
    setFksSource(kfsItem);
  }, [dbTableList?.table_fks]);

  const columns = [
    {
      title: "字段名",
      dataIndex: "col_name",
    },
    {
      title: "中文名",
      dataIndex: "col_desc",
    },
    {
      title: "字段类型",
      dataIndex: "col_type_code",
    },
    {
      title: "是否主键",
      dataIndex: "pk_flag",
      render: (data, info) => (info?.pk_flag ? "是" : "否"),
    },
    {
      title: "是否非空",
      dataIndex: "not_null_flag",
      render: (data, info) => (info?.not_null_flag ? "是" : "否"),
    },
    {
      title: "是否自增",
      dataIndex: "auto_inc_flag",
      render: (data, info) => (info?.auto_inc_flag ? "是" : "否"),
    },
    {
      title: "默认值",
      dataIndex: "def_value",
    },
  ];
  const indexColumns = [
    {
      title: "索引名称",
      dataIndex: "index_name",
    },
    {
      title: "字段",
      dataIndex: "cols",
      render: (_, info) => info?.cols?.map((item) => item.col_name)?.join("，"),
    },
    {
      title: "是否唯一索引",
      dataIndex: "uniq_index_flag",
      render: (data, info) => (info?.uniq_index_flag ? "是" : "否"),
    },
  ];
  const foreignKeyColumns = [
    {
      title: "对应参照表",
      dataIndex: "fk_table_id",
    },
  ];

  const partitionColumns = [
    {
      title: "分区名",
      width: "50%",
      dataIndex: "ptn_name",
    },
    {
      title: "分区值",
      width: "50%",
      dataIndex: "ptn_value",
    },
  ];

  //getChildList子表格处理
  const getChildList = async (expanded, record) => {
    setFksSource({
      ...fksSource,
      [record?.fk_table_id]: fksSource[record?.fk_table_id],
    });
  };

  return (
    <div className="DetailTable">
      <Tabs className="myTabs" defaultActiveKey="1">
        <TabPane tab="表结构" key="1">
          <Table
            className="myFormTable"
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={colData}
            bordered={false}
            pagination={false}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
          />
        </TabPane>
        <TabPane tab="索引" key="2">
          <Table
            className="myFormTable"
            columns={indexColumns}
            dataSource={indexsData}
            bordered={false}
            pagination={false}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
          />
        </TabPane>
        <TabPane tab="外键" key="3">
          <Table
            className="myFormTable"
            columns={foreignKeyColumns}
            expandable={{
              expandedRowRender,
              onExpand: getChildList,
            }}
            dataSource={fksData}
            bordered={false}
            pagination={false}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
          />
        </TabPane>
        <TabPane tab="分区" key="4">
          {partData?.length !== 0 ? (
            partData?.map((item) => (
              <>
                <div className="detailsBoxNew">
                  <div className="detailItems" style={{ width: "50%" }}>
                    <div className="Key">分区类型</div>
                    <div className="Value" style={{ width: "200px" }}>
                      {item?.ptn_type_code}
                    </div>
                  </div>
                  <div className="detailItems managementInfo">
                    <div className="Key">分区字段</div>
                    <div className="Value" style={{ width: "200px" }}>
                      {item?.ptn_col_name}
                    </div>
                  </div>
                </div>

                <Table
                  className="myFormTable"
                  columns={partitionColumns}
                  dataSource={item.ptn_cols}
                  bordered={false}
                  pagination={false}
                  locale={{
                    emptyText: <MyEmpty></MyEmpty>,
                  }}
                />
              </>
            ))
          ) : (
            <>
              <div className="detailsBoxNew">
                <div className="detailItems" style={{ width: "50%" }}>
                  <div className="Key">分区类型</div>
                  <div className="Value" style={{ width: "200px" }}>
                    -
                  </div>
                </div>
                <div className="detailItems managementInfo">
                  <div className="Key">分区字段</div>
                  <div className="Value" style={{ width: "200px" }}>
                    -
                  </div>
                </div>
              </div>

              <Table
                className="myFormTable"
                columns={partitionColumns}
                dataSource={partData}
                bordered={false}
                pagination={false}
                locale={{
                  emptyText: <MyEmpty></MyEmpty>,
                }}
              />
            </>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};
export default DetailTable;
