import "./index.scss";
import { useEffect, useState } from "react";

import { Drawer, Button, Form, Input, Tooltip, Checkbox } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import Scrollbars from "react-custom-scrollbars";
import HeaderTitle from "../../../../../components/HeaderTitle";

import MySpin from "../../../../../components/MySpin";

const { TextArea } = Input;

const DialogDrawer = (props) => {
  const {
    /* 关闭触发的回调 */
    onCancel,
    /* 确定触发的回调 */
    onOk,
    visible,
    /* 判断新增还是修改 */
    addNew,
    /* 初始值 */
    defaultData,
    loading,
    /*  api数据服务列表 */
    storePackageList,
  } = props;
  const data = defaultData?.api_pkg_ids?.reduce(
    (prev, cur) => [...prev, cur?.api_pkg_id],
    []
  );

  const [form] = Form.useForm();

  const handleCancel = () => {
    onCancel && onCancel();
  };

  useEffect(() => {
    if (addNew === false) {
      form.setFieldsValue({
        api_pkg_id: data,
      });
    }
  }, [defaultData]);

  // 判断接口是否有新增或者删除
  const compareArrays = (arr1, arr2) => {
    let difference = [];
    for (let i = 0; i < arr1.length; i++) {
      let found = false;
      for (let j = 0; j < arr2.length; j++) {
        if (arr1[i] === arr2[j]) {
          found = true;
          break;
        }
      }
      if (!found) {
        difference.push(arr1[i]);
      }
    }
    return difference;
  };

  // 提交表单录入
  const onFinish = (value) => {
    let param = {
      app_name: value?.app_name,
      app_desc: value?.app_desc,
      app_id: defaultData?.app_id,
    };

    if (addNew) {
      value?.api_pkg_id !== null && value?.api_pkg_id?.length !== 0
        ? onOk && onOk(value)
        : onOk &&
          onOk({
            app_name: value?.app_name,
            app_desc: value?.app_desc,
          });
    } else {
      let del = compareArrays(data, value?.api_pkg_id);
      let add = compareArrays(value?.api_pkg_id, data);

      if (del?.length === 0 && add?.length === 0) {
        onOk && onOk(param);
      } else if (del?.length === 0 && add?.length !== 0) {
        param.add_api_pkg_ids = add;
        onOk && onOk(param);
      } else if (del?.length !== 0 && add?.length === 0) {
        param.del_api_pkg_ids = del;
        onOk && onOk(param);
      } else if (del?.length !== 0 && add?.length !== 0) {
        param.add_api_pkg_ids = add;
        param.del_api_pkg_ids = del;
        onOk && onOk(param);
      }
    }
  };

  return (
    <Drawer
      title={addNew ? "创建应用" : "修改应用"}
      placement="right"
      width="600px"
      className="AppDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              form.submit();
            }}
            style={{ background: "#016DB4", color: "#fff" }}
          >
            {addNew ? "创建" : "保存"}
          </Button>

          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            取消
          </Button>
        </div>
      }
    >
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: `100%`,
            width: "100%",
          }}
        >
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            className="dialogDrawerForm"
            preserve={false}
            onFinish={onFinish}
          >
            <HeaderTitle title="基本信息"></HeaderTitle>
            <Form.Item
              name="app_name"
              label="应用名称"
              initialValue={addNew ? null : defaultData?.app_name}
              rules={[
                {
                  required: true,
                  message: "请输入应用名称",
                },
              ]}
            >
              <Input
                allowClear
                style={{
                  width: "540px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="app_desc"
              label="描述"
              initialValue={addNew ? null : defaultData?.app_desc}
              rules={[
                {
                  required: true,
                  message: "请输入描述",
                },
              ]}
            >
              <TextArea
                allowClear
                style={{
                  width: "540px",
                }}
                autoSize={{ minRows: 5 }}
              />
            </Form.Item>

            <HeaderTitle title="选择服务"></HeaderTitle>
            <Form.Item name="api_pkg_id">
              <Checkbox.Group style={{ width: "100%" }}>
                {storePackageList?.map((data) => (
                  <>
                    <Tooltip
                      overlayClassName="headerTipContent"
                      placement="bottomLeft"
                      title={
                        <div className="headerTip">
                          <div className="headerTipTitle">{`${data?.chi_name} / ${data?.eng_name}`}</div>
                          <div className="headerTipText">
                            服务描述: {data?.api_pkg_desc}
                          </div>
                        </div>
                      }
                    >
                      <Checkbox value={data.api_pkg_id}>
                        {`${data?.chi_name} / ${data?.eng_name}`}
                      </Checkbox>
                    </Tooltip>
                  </>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Scrollbars>
      )}
    </Drawer>
  );
};

export default DialogDrawer;
