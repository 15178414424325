import requestSys from "../request_sys";
import request from "../request";

//获取menu列表
function getMenus() {
  return request({
    url: "/sys_management/menu",
    method: "get",
  });
}

//获取用户信息
function getUserInfo() {
  return requestSys({
    url: "/sys/user/info/",
    method: "get",
  });
}

//用户退出
function logout(redirect) {
  return requestSys({
    url: `/sys/logout/`,
    method: "post",
    data: {
      redirect,
    },
  });
}

export default {
  getMenus,
  getUserInfo,
  logout,
};
