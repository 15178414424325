import {
    createSlice
} from '@reduxjs/toolkit';
const UserInfo = createSlice({
    name: 'user',
    initialState: {
        userInfo: {},
        routers: []
    },
    reducers: {
        setUserRouters: (state, action) => {
            state.routers = action.payload
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
    },
});
export const {
    setUserRouters,
    setUserInfo
} = UserInfo.actions;

export default UserInfo;