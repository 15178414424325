import { combineReducers, configureStore } from "@reduxjs/toolkit";
import User from "./user/user";
import Project from "./project/project";
let all = combineReducers({
  User: User.reducer,
  Project: Project.reducer,
});
export default configureStore({
  reducer: all,
});
