import "./index.scss";
import { Table, Pagination } from "antd";
import { useRef, useState, useEffect, forwardRef } from "react";
import { ReactSVG } from "react-svg";
import MyEmpty from "../../../../../components/Empty";
import LoadingSvg from "../../../../../assets/loading.svg";
import MySpin from "../../../../../components/MySpin";

const ServiceMonitoringTable = (props) => {
  const {
    defaultData,
    tableName,
    changePagination,
    activeKey,
    changeOrders,
    tableLoading,
    AllApiList,
    StatRadioValue,
  } = props;

  const refTable = useRef(null);
  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);

  const data = defaultData?.items;

  useEffect(() => {
    if (tableName === "StaStatement") {
      let columnsToShow = [
        {
          title: "调用日期",
          dataIndex: "call_date",
          sorter: true,
          width: "10%",
        },
        {
          title: "调用时段（小时）",
          dataIndex: "call_hour",
          sorter: true,
          width: "10%",
        },
        {
          title: "用户账号",
          dataIndex: "call_by_id",
          sorter: true,
        },
        {
          title: "用户名称",
          dataIndex: "call_by_id_value",
          sorter: true,
        },
        {
          title: "API服务包",
          dataIndex: "api_pkg_id",
          sorter: true,
          render: (data, info) => {
            return Object.values(AllApiList).find(
              (item) => item?.api_pkg_id === info?.api_pkg_id
            )?.chi_name;
          },
        },
        {
          title: "API服务",
          dataIndex: "api_name",
          sorter: true,
        },
        {
          title: "调用成功次数",
          dataIndex: "succ_count",
        },
        {
          title: "调用失败次数",
          dataIndex: "error_count",
        },
      ];
      if (StatRadioValue === "day") {
        columnsToShow = columnsToShow.filter(
          (column) => column.dataIndex !== "call_hour"
        );
      }

      setColumns(columnsToShow);
    } else if (tableName === "ErrorLog") {
      setColumns([
        {
          title: "用户账号",
          dataIndex: "call_by_id",
          sorter: true,
          width: "10%",
        },
        {
          title: "用户名称",
          dataIndex: "call_by_id_value",
          sorter: true,
          width: "10%",
        },
        {
          title: "API服务包",
          dataIndex: "api_pkg_id",
          sorter: true,
          width: "10%",
          render: (data, info) => {
            return Object.values(AllApiList).find(
              (item) => item?.api_pkg_id === info?.api_pkg_id
            )?.chi_name;
          },
        },
        {
          title: "API服务",
          dataIndex: "api_name",
          sorter: true,
          width: "10%",
        },
        {
          title: "调用日期",
          dataIndex: "call_time",
          sorter: true,
          width: "9%",
        },
        {
          title: "调用参数",
          dataIndex: "call_parm",
        },
        {
          title: "返回结果编码",
          dataIndex: "result_code",
          width: "7%",
        },
        {
          title: "错误日志",
          dataIndex: "fail_log",
          // width: "10%",
        },
      ]);
    }
    setTotal(defaultData?.total);
    setPageSize(defaultData?.size);
    setPageNumber(defaultData?.page);
  }, [defaultData, StatRadioValue]);

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  // 改变表格排序
  const tableChange = (pagination, filters, sorter, extra) => {
    if (sorter.order !== undefined) {
      changeOrders(sorter);
    }
  };

  return (
    <div className="ServiceMonitoringTable">
      {/* {tableLoading ? (
        <MySpin></MySpin>
      ) : (
     
      )} */}
      <div className="tableContent" ref={refTable}>
        <Table
          className="myFormTable"
          pagination={false}
          columns={columns}
          dataSource={data}
          rowKey={(record, id) => id}
          onChange={tableChange}
          scroll={{
            y:
              activeKey === "2" ? "calc(100vh - 380px)" : "calc(100vh - 340px)",
          }}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
          style={{ marginBottom: "8px" }}
          loading={
            tableLoading
              ? {
                  indicator: (
                    <ReactSVG
                      src={LoadingSvg}
                      className="anticon-spin anticon myLoadingIcon"
                    />
                  ),
                }
              : false
          }
        />
        {total >= 10 ? (
          <div className="paginationBox">
            <Pagination
              className="myPagination"
              showQuickJumper
              total={total}
              current={pageNumber}
              pageSize={pageSize}
              pageSizeOptions={[10, 20, 50, 100]}
              onChange={onChange}
              showTotal={(total) => `共 ${total} 条`}
              showSizeChanger={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default forwardRef(ServiceMonitoringTable);
