import "./index.scss";
import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Table, ConfigProvider } from "antd";
import Draggable from "react-draggable";
import zhCN from "antd/lib/locale/zh_CN";
import wrapper from "./wrapper";

const EleStandardDrawer = (props) => {
  const [visible, setVisible] = useState(true);

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 不要按钮 */
    noBtn,
    /* 点击蒙层是否允许关闭 */
    maskClosable,
    /* 数据元标准 */
    elementData,
    onOk,
  } = props;

  // 表格
  const refTable = useRef(null);
  const [selectedRowList, setSelectedRowList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const columns = [
    {
      title: "中文名称",
      dataIndex: "col_cmnt",
    },
    {
      title: "字段名",
      dataIndex: "col_name",
    },
    {
      title: "字段类型",
      dataIndex: "col_data_type_code_value",
    },
    {
      title: "长度",
      dataIndex: "col_length",
    },
    {
      title: "小数位",
      dataIndex: "col_scale",
    },
    {
      title: "描述",
      dataIndex: "data_ele_desc",
    },
  ];

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  const rowSelection =
    title === "新增数据元"
      ? {
          selectedRowKeys,
          onSelect: (record, selected) => {
            if (selected) {
              setSelectedRowKeys((before) => {
                return [...before, record.data_ele_id];
              });
              setSelectedRowList((before) => {
                return [...before, record];
              });
            } else {
              setSelectedRowKeys((before) => {
                return before?.filter((el) => el !== record.data_ele_id);
              });
              setSelectedRowList((before) => {
                return before?.filter(
                  (el) => el.data_ele_id !== record.data_ele_id
                );
              });
            }
          },
          onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
              setSelectedRowKeys((before) => {
                const ids = changeRows?.map((el) => el.data_ele_id);
                return [...before, ...ids];
              });
              setSelectedRowList((before) => {
                return [...before, ...changeRows];
              });
            } else {
              const ids = changeRows?.map((el) => el.data_ele_id);
              setSelectedRowKeys((before) => {
                return before?.filter((el) => ids.indexOf(el) === -1);
              });
              setSelectedRowList((before) => {
                return before?.filter(
                  (el) => ids.indexOf(el.data_ele_id) === -1
                );
              });
            }
          },
        }
      : {
          type: "radio",
          selectedRowKeys,
          onSelect: (record, selected) => {
            if (selected) {
              setSelectedRowKeys([record.data_ele_id]);
              setSelectedRowList([record]);
            } else {
              setSelectedRowKeys([]);
              setSelectedRowList([]);
            }
          },
        };

  const handleOk = () => {
    const data = selectedRowList?.map((item) => {
      return {
        col_desc: item.col_cmnt,
        col_name: item.col_name,
        col_length: item.col_length,
        col_scale: item.col_scale,
        col_type_code: item.col_data_type_code,
        not_null_flag: false,
        pk_flag: false,
        auto_inc_flag: false,
        def_value: "",
        status: true,
      };
    });
    onOk && onOk(data);
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title={title}
        visible={visible}
        onCancel={handleCancel}
        wrapClassName="EleStandardDrawer"
        centered
        footer={null}
        maskClosable={maskClosable}
        width={1200}
        onMouseOver={() => {
          if (disabled) {
            setDisabled(false);
          }
        }}
        onMouseOut={() => {
          setDisabled(true);
        }}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="addModalContent">
          <div
            className="tableContent"
            style={{ width: "100%" }}
            ref={refTable}
          >
            <Table
              className="myFormTable"
              rowSelection={rowSelection}
              dataSource={elementData}
              pagination={false}
              columns={columns}
              rowKey={(record, id) => record?.data_ele_id}
              scroll={{
                y: "500px",
              }}
            />
          </div>

          {!noBtn && (
            <div className="drawerFooterBtnBox btnBox">
              <Button
                className="noBg"
                onClick={() => {
                  handleCancel();
                }}
              >
                关闭
              </Button>
              <Button
                onClick={() => {
                  handleOk();
                }}
                style={{
                  background: "#006AB2",
                  color: "#fff",
                  borderColor: "#006AB2",
                }}
              >
                确定
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default wrapper(EleStandardDrawer);
