import "./index.scss";
import { message, Select, Switch, Button, Cascader } from "antd";
import { useGetState } from "ahooks";
import { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import DropdownSvg from "../../../assets/下拉.svg";
import DataTable from "./components/DataTable";
import Scrollbars from "react-custom-scrollbars";
import projectApi from "../../../apis/project";
import exportIcon from "../../../assets/导出.svg";
import downloadApi from "../../../apis/project/download.js";
import MessageModal from "./components/MessageModal";
const DataCheck = (props) => {
  const [tableLoading, setTableLoading] = useState(true);
  const [taskCheckList, setTaskCheckList] = useState([]);

  const [params, setParams] = useState({ page: 1, size: 10 });

  const [orders, setOrders, getOrders] = useGetState([
    "last_execution_time",
    "desc",
  ]); // 排序
  const [keyValue, setKeyValue] = useState(null); // 用户id
  const [flag, setFlag] = useState(false);
  const tableRef = useRef();
  const [intervalId, setIntervalId] = useState(null);
  const [cascaderoptions, setCascaderoptions] = useState([]);
  const [ManagementList, setManagementList] = useState([]); // 默认管理责任人列表
  const [contrastList, setContrastList] = useState([]); // 处理前比对类型
  const [contrastTypeList, setContrastTypeList] = useState([]); // 处理后比对类型
  const cascaderChange = (value) => {
    setCascaderoptions(value);
  };

  // 查询管理责任人列表
  const getUserList = () => {
    projectApi.getUserList().then((res) => {
      if (res.code === 200) {
        setManagementList(res.data);
      } else {
        setManagementList([]);
      }
    });
  };

  // 数据核对-筛选字典
  const taskCheckOptionsList = () => {
    projectApi.taskCheckOptionsList().then((res) => {
      if (res.code === 200) {
        const data = Object.keys(res.data).map((item) => {
          return {
            label: item,
            value: item,
            children: res.data[item].map((info) => ({
              label: info,
              value: info,
            })),
          };
        });
        setContrastTypeList(data);
        setContrastList(res.data);
      } else {
        setContrastTypeList([]);
        setContrastList([]);
      }
    });
  };
  const stopTimer = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };

  // 函数用于启动定时器
  const startTimer = () => {
    const newIntervalId = setInterval(() => {
      gettaskCheckList();
    }, 60000);
    setIntervalId(newIntervalId);
  };
  useEffect(() => {
    getUserList();
    taskCheckOptionsList();
    return () => stopTimer();
  }, []);

  const gettaskCheck = () => {
    if (tableLoading) {
      return;
    }
    gettaskCheckList();
  };

  // 获取核对任务列表
  const gettaskCheckList = () => {
    setTableLoading(true);
    let result = {};
    if (cascaderoptions.length !== 0) {
      const singleItems = [];
      const multipleItems = [];

      for (const item of cascaderoptions) {
        if (item.length === 1) {
          singleItems.push(item);
        } else {
          multipleItems.push(item);
        }
      }
      const multiple = {};

      multipleItems.forEach((item) => {
        const key = item[0];
        const value = item[1];

        if (multiple[key]) {
          multiple[key].push(value);
        } else {
          multiple[key] = [value];
        }
      });

      const resultObject = {};
      for (const key in multiple) {
        if (multiple.hasOwnProperty(key)) {
          resultObject[key] = multiple[key];
        }
      }
      const single = singleItems.reduce((cur, pre) => {
        return {
          ...cur,
          [pre]: contrastList[pre],
        };
      }, {});
      result = { ...resultObject, ...single };
    }

    let info = {
      user_id: keyValue,
      order_list: orders.toString(),
      ...params,
    };

    projectApi
      .gettaskCheckList(info, result)
      .then((res) => {
        if (res.code === 200) {
          setTaskCheckList(res.data);
        } else {
          setTaskCheckList([]);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  // 改变分页数据
  const changePagination = (page, pageSize) => {
    setParams({ page: page, size: pageSize });
  };

  // 排序
  const changeOrders = (data) => {
    const str = "_value";
    setOrders([
      data.field.includes("_value")
        ? data.field.split(str).join("")
        : data.field,
      data.order === "descend" ? "desc" : "asc",
    ]);
    setParams({ page: 1, size: 10 });
  };

  // 获取核对任务列表
  const taskCheckRun = (id) => {
    MessageModal({
      title: "运行日期范围",
      okText: "运行",
      onOk: (amountTime) => {
        let params = {
          rule_id: id,
          start_time: `${amountTime[0]} 00:00:00`,
          end_time: `${setDate(amountTime[1])} 00:00:00`,
        };
        projectApi.taskCheckRun(params).then((res) => {
          if (res.code === 200) {
            message.success("运行成功");
            gettaskCheckList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };
  const setDate = (data) => {
    const inputDate = new Date(data);
    inputDate.setDate(inputDate?.getDate() + 1);
    const outputDate = inputDate?.toISOString().split("T")[0];
    return outputDate;
  };

  useEffect(() => {
    gettaskCheckList();
  }, [params, getOrders()]);

  // // 导出未发布标准
  // const exportUnpublishedList = () => {
  //   const ids = selectedRowList.map((item) => item.rule_id);

  //   downloadApi.DownloadTaskCheckFile(ids).then((blob) => {
  //     const filename = blob?.headers["content-disposition"]
  //       .split(";")[1]
  //       .trim()
  //       .split("=")[1];
  //     const decodedFilename = decodeURIComponent(filename);
  //     saveBlob(blob.data, {
  //       fileName: decodedFilename,
  //       type: blob.headers["content-type"],
  //     });
  //   });
  // };

  const displayRender = (labels, selectedOptions) => {
    // console.log(cascaderoptions);
    const topLevelLabel = labels[0];
    if (labels.length === 1) {
      return <span>{topLevelLabel}</span>;
    } else {
      const topLevelOption = selectedOptions[0];
      return (
        <span>
          {topLevelLabel}/{labels[1]}
          {topLevelOption.code && ` (${topLevelOption.code})`}
        </span>
      );
    }
  };

  return (
    <Scrollbars
      autoHide={true}
      autoHideTimeout={5000}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <div className="DataCheckContent">
        <div className="myTabs">
          <div className="noReleseInfo">
            <div className="info">
              <div className="selectInfo">
                <div className="founder">
                  <div className="name">创建人</div>

                  <Select
                    placeholder="请选择"
                    className="Input"
                    allowClear
                    showSearch={true}
                    style={{ width: "188px" }}
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    options={ManagementList?.map((item) => ({
                      label: item.username,
                      value: item.user_id,
                    }))}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    onChange={(e) => {
                      if (e !== undefined) {
                        setKeyValue(e);
                      } else {
                        setKeyValue(null);
                      }
                    }}
                    value={keyValue}
                  ></Select>
                </div>
                <div className="founder" style={{ marginLeft: "10px" }}>
                  <div className="name">比对类型 </div>
                  <Cascader
                    placeholder="请选择"
                    style={{ width: "300px" }}
                    options={contrastTypeList}
                    onChange={cascaderChange}
                    multiple
                    maxTagCount="responsive"
                    displayRender={displayRender}
                    value={cascaderoptions}
                  />
                </div>

                <Button
                  type="primary"
                  onClick={gettaskCheck}
                  style={{ marginLeft: "10px" }}
                >
                  查询
                </Button>
              </div>

              <div className="btnBox">
                {/* <Button
                    type="primary"
                    icon={
                      <ReactSVG src={exportIcon} className="Icon"></ReactSVG>
                    }
                    disabled={selectedRowList.length === 0}
                    onClick={() => {
                      if (selectedRowList.length === 0) {
                        return;
                      }

                      exportUnpublishedList();
                    }}
                  >
                    导出
                  </Button> */}
                <Switch
                  defaultChecked={false}
                  checked={flag}
                  style={{ marginRight: "8px", marginLeft: "10px" }}
                  onChange={(e) => {
                    setFlag(e);
                    if (e) {
                      startTimer();
                      gettaskCheckList();
                    } else {
                      stopTimer();
                    }
                  }}
                />

                {flag ? "自动刷新(每分钟)" : null}
              </div>
            </div>
            <div className="infoBox">
              <DataTable
                ref={tableRef}
                defaultData={taskCheckList}
                changePagination={changePagination}
                changeOrders={changeOrders}
                tableLoading={tableLoading}
                gettaskCheckList={gettaskCheckList}
                ManagementList={ManagementList}
                taskCheckRun={taskCheckRun}
                orders={orders}
              ></DataTable>
            </div>
          </div>
        </div>
      </div>
    </Scrollbars>
  );
};
export default DataCheck;
