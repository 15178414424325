import "./index.scss";
import Scrollbars from "react-custom-scrollbars";
import { Input, Button, Pagination, message, Checkbox } from "antd";
import { useEffect, useState } from "react";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import MySpin from "../../../components/MySpin";

import Empty from "../../../components/Empty";
import projectApi from "../../../apis/project";
import PackageGovCard from "./components/PackageGovCard";
import DialogDrawer from "./components/DialogDrawer";
import MessageModal from "../../../components/MessageModal";
import GovReleaseDrawer from "./components/GovReleaseDrawer";
import GovDetailDrawer from "./components/GovDetailDrawer";
const CheckboxGroup = Checkbox.Group;
const orderDataList = [
  { label: "中文名称", key: "chi_name" },
  { label: "英文名称", key: "eng_name" },
  { label: "创建时间", key: "created_time" },
  { label: "更新时间", key: "updated_time" },
];

const PackageGov = (props) => {
  const [serviceName, setServiceName] = useState(""); // api服务名称

  const [upOrderCode, setUpOrderCode] = useState({}); // 上排序选择
  const [downOrderCode, setDownOrderCode] = useState({}); // 下排序选择
  const [orderList, setOrderList] = useState(["updated_time", "desc"]); // 筛选条件

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);

  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false); // api包新增修改判断
  const [defaultData, setDefaultData] = useState(null); // 修改api包数据
  const [addNew, setAddNew] = useState(true); // 新增修改api包判断
  const [DialogDrawerLoading, setDialogDrawerLoading] = useState(true);

  const [ReleaseDrawerVisible, setReleaseDrawerVisible] = useState(false); // 发布审核
  // const [RelDialogDrawerLoading, setRelDialogDrawerLoading] = useState(true);
  const [RelDefaultData, setRelDefaultData] = useState([]); // 审核服务包信息

  const [storePackageList, setStorePackageList] = useState([]); //api服务包列表

  const [verId, setVerId] = useState("");
  const [DetailDrawerVisible, setDetailDrawerVisible] = useState(false); // 查看详情
  const [DetailDrawerLoading, setDetailDrawerLoading] = useState(false); // 查看详情loading
  const [DetailInfo, setDetailInfo] = useState({}); // 详情数据
  const [VersoList, setVersonList] = useState([]); // 详情版本列表

  const [checkAll, setCheckAll] = useState(false); // 控制全选
  const [checkedList, setCheckedList] = useState([]);
  const [plainOptionList, setPlainOptionList] = useState([]); // 全选反选绑定数据
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true); // 控制反选

  // 全选控制小选
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptionList : []);
    setIndeterminate(false);

    setCheckAll(e.target.checked);
  };

  // 小选控制全选
  const checkChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptionList.length);
    setCheckAll(list.length === plainOptionList.length);
  };

  useEffect(() => {
    setLoading(true);
    getDevPackageList();
  }, [pageNumber, pageSize, serviceName, orderList]);

  // 获取api服务包列表
  const getDevPackageList = () => {
    let params = {
      page: pageNumber,
      size: pageSize,
      order_list: orderList.toString(),
    };
    if (serviceName !== "") {
      params.pkg_name = serviceName;
    }
    projectApi.getDevPackageList(params).then((res) => {
      if (res.code === 200) {
        setStorePackageList(res.data.items);
        setPlainOptionList(res.data.items);
        setTotal(res.data.total);
        setLoading(false);
        const allchecked = res.data.items?.some(
          (obj) => obj.rls_stat_code === "TO_BE_RELEASED"
        );
        setChecked(allchecked);
      } else {
        setPlainOptionList([]);
        setStorePackageList([]);
        setTotal(0);
        setLoading(false);
      }
    });
  };

  // 排序 正序
  const sortOrder = (item, id) => {
    setOrderList([item.key, id]);
    setPageNumber(1);
  };

  // 分页
  const pageChange = (page, size) => {
    setPageNumber(page);
    setPageSize(size);
  };

  // 新增api服务包
  const addHandle = () => {
    setAddNew(true);
    setDialogDrawerVisible(true);
    setDialogDrawerLoading(false);
  };

  // 修改api服务包
  const modelHandle = (id) => {
    setDialogDrawerLoading(true);
    setAddNew(false);
    setDialogDrawerVisible(true);
    getPkgService(id);
  };

  // 获取修改时api服务包信息
  const getPkgService = (id) => {
    projectApi.getPkgService(id).then((res) => {
      if (res.code === 200) {
        setDefaultData(res.data);
        setDialogDrawerLoading(false);
      } else {
        setDefaultData({});
        setDialogDrawerLoading(false);
      }
    });
  };

  // 确认录入
  const dialogDrawerSuccess = (value) => {
    if (addNew) {
      addPkgService(value);
    } else {
      modifyPkgService(value);
    }
  };

  // 新增api服务包
  const addPkgService = (data) => {
    projectApi.addPkgService(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setLoading(true);
        getDevPackageList();
        setDialogDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 修改api服务包
  const modifyPkgService = (data) => {
    projectApi.modifyPkgService(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setLoading(true);
        getDevPackageList();
        setDialogDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 删除api服务包
  const DeleteItem = (id) => {
    MessageModal({
      type: "warning",
      description: "确定要删除API服务包吗？",
      title: "删除API服务包",
      okText: "删除",
      onOk: () => {
        projectApi.deletePkgService(id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            setLoading(true);
            getDevPackageList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 确认提交审核
  const subReview = (data) => {
    setReleaseDrawerVisible(true);
    setRelDefaultData(data);
  };

  // 发布审核
  const releaseDrawerSuccess = (data) => {
    reviewApiPkg(data);
  };

  // 提交审核
  const reviewApiPkg = (data) => {
    projectApi.reviewApiPkg(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setLoading(true);
        getDevPackageList();
        setReleaseDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 获取版本
  const getPkgVerNoList = (id) => {
    projectApi.getPkgVerNoList(id).then((res) => {
      if (res.code === 200) {
        res?.data?.length === 0
          ? setVersonList([{ api_pkg_ver_id: 0, ver_no: "当前" }])
          : setVersonList(res.data);
      }
    });
  };

  // 获取详情的信息
  const getDetailInfo = (id, DataInfo, flag) => {
    if (flag) {
      getPkgVerNoList(DataInfo.api_pkg_id);
      DataInfo?.last_ver_no === null
        ? setVerId("当前")
        : setVerId(DataInfo?.last_ver_no);
    }
    setDetailDrawerLoading(true);
    setDetailDrawerVisible(true);
    let params = {
      api_pkg_id: DataInfo.api_pkg_id,
    };
    if (id !== null) {
      params.ver_no = id;
    }

    projectApi.getPkgDetail(params).then((res) => {
      if (res.code === 200) {
        setDetailInfo({ ...DataInfo, ...res.data });
        setDetailDrawerLoading(false);
      } else {
        setDetailInfo({});
        setDetailDrawerLoading(false);
      }
    });
  };

  return (
    <div className="PackageGovContent">
      {/* 检索区域 */}
      <div className="topBox">
        <div className="searchItem" style={{ marginTop: "10px" }}>
          <div className="label">API服务名称 :</div>
          <div className="optionsBox">
            <Input
              bordered={false}
              allowClear
              style={{
                borderBottom: "1px solid #999999",
                width: "280px",
              }}
              onChange={(e) => {
                if (e.target.value === "") setServiceName("");
              }}
              onBlur={(e) => {
                setServiceName(e.target.value);
              }}
              onPressEnter={(e) => {
                setServiceName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="buttonBox">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => addHandle()}
          >
            新增包
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: "15px" }}
            onClick={() => {
              if (checkedList.length !== 0) {
                subReview(checkedList);
              } else if (checkedList.length === 0) {
                message.info("请选择需要审核的API包");
              }
            }}
          >
            批量审核
          </Button>
        </div>
      </div>

      {/* 排序 */}
      <div className="filterItem">
        <div className="optionBoxs">
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            disabled={checked}
          >
            全选
          </Checkbox>
          {orderDataList.map((item) => (
            <div key={item.key} className="optionItem">
              {item.label}
              <div className="iconBox">
                <CaretUpOutlined
                  className="upIcon"
                  onClick={() => {
                    sortOrder(item, "asc");
                    setUpOrderCode(item);
                    setDownOrderCode({});
                  }}
                  style={{ color: item === upOrderCode ? "#0068B2" : null }}
                />
                <CaretDownOutlined
                  className="downIcon"
                  onClick={() => {
                    sortOrder(item, "desc");
                    setDownOrderCode(item);
                    setUpOrderCode({});
                  }}
                  style={{ color: item === downOrderCode ? "#0068B2" : null }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 展示 */}
      <div className="cardBox">
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{ height: "100%", width: "100%" }}
        >
          <CheckboxGroup
            onChange={checkChange}
            value={checkedList}
            style={{ width: "100%" }}
          >
            {loading ? (
              <MySpin></MySpin>
            ) : storePackageList?.length > 0 ? (
              storePackageList?.map((item, index) => (
                <PackageGovCard
                  checkedList={checkedList}
                  defaultData={item}
                  key={index}
                  DeleteItem={DeleteItem}
                  modelHandle={modelHandle}
                  subReview={subReview}
                  getDetailInfo={getDetailInfo}
                ></PackageGovCard>
              ))
            ) : (
              <div className="noData">
                <Empty></Empty>
              </div>
            )}
          </CheckboxGroup>
        </Scrollbars>
      </div>
      {loading ? null : total > 10 ? (
        <div className="paginationBox">
          <Pagination
            onChange={pageChange}
            className="myPagination"
            pageSize={pageSize}
            current={pageNumber}
            total={total}
            showTotal={(total) => `共 ${total} 条`}
            showSizeChanger={true}
            showQuickJumper
            pageSizeOptions={[10, 20, 50, 100]}
          />
        </div>
      ) : null}
      <DialogDrawer
        drawerTitle={addNew ? "新增API服务包" : "修改API服务包"}
        okText={addNew ? "创建" : "保存"}
        cancelText="取消"
        onOk={dialogDrawerSuccess}
        visible={DialogDrawerVisible}
        onCancel={() => {
          setDialogDrawerVisible(false);
        }}
        defaultData={defaultData}
        addNew={addNew}
        loading={DialogDrawerLoading}
      ></DialogDrawer>
      <GovReleaseDrawer
        onOk={releaseDrawerSuccess}
        visible={ReleaseDrawerVisible}
        onCancel={() => {
          setReleaseDrawerVisible(false);
        }}
        defaultData={RelDefaultData}
      ></GovReleaseDrawer>

      <GovDetailDrawer
        VersoList={VersoList}
        visible={DetailDrawerVisible}
        defaultData={DetailInfo}
        onCancel={() => {
          setDetailDrawerVisible(false);
        }}
        loading={DetailDrawerLoading}
        getDetailInfo={getDetailInfo}
        verId={verId}
        setVerId={setVerId}
      ></GovDetailDrawer>
    </div>
  );
};
export default PackageGov;
