import React, { useState, useEffect } from "react";
import { Radio } from "antd";
import ReactEcharts from "echarts-for-react";
import MyEmpty from "../../../../../components/Empty";

import MySpin from "../../../../../components/MySpin";
import "./index.scss";
const plainOptions = [
  {
    label: "按小时",
    value: "hour",
  },
  {
    label: "按日",
    value: "day",
  },
];
const LineChart = (props) => {
  const {
    lineChartList,
    setRadioValue,
    RadioValue,
    AllApiList,
    staticLoading,
  } = props;

  const [lineInfo, setLineInfo] = useState([]);
  const [xAxis, setXAxis] = useState([]);
  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);

  useEffect(() => {
    setLineInfo(Object?.values(lineChartList));
    // const result = Object?.keys(lineChartList).map((key) => {
    //   const matchedObject = AllApiList.find(
    //     (obj) => obj.api_pkg_id === Number(key?.split(" ")[1])
    //   );
    //   return `${key?.split(" ")[0]} ${matchedObject?.chi_name}`;
    // });
    setXAxis(Object?.keys(lineChartList));

    let max = Object?.values(lineChartList)
      ?.map((obj) => obj.true)
      ?.reduce((acc, cur) => acc + cur, 0);
    max = max?.toString()?.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    setMax(max);
    let min = Object?.values(lineChartList)
      ?.map((obj) => obj.false)
      ?.reduce((acc, cur) => {
        if (typeof cur === "undefined") {
          return acc;
        } else {
          return acc + cur;
        }
      }, 0);
    min = min?.toString()?.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    setMin(min);
  }, [lineChartList]);

  // 小时/日切换
  const onChange = ({ target: { value } }) => {
    setRadioValue(value);
  };

  // 配置对象
  const getOption = (sal, sto) => {
    return {
      title: {
        text: "",
      },
      tooltip: {},
      legend: {
        data: ["调用成功", "调用失败"],
      },
      xAxis: {
        data: xAxis,
      },
      yAxis: {},
      dataZoom: [
        {
          type: "slider", // 滑动条型
          start: 0, // 起始位置为0%
          end: 100, // 结束位置为100%
          height: 15,
        },
        {
          type: "inside", // 内置型，可以通过鼠标滚轮缩放
          start: 0,
          end: 100,
          height: 15,
        },
      ],
      series: [
        {
          name: "调用成功",
          type: "line",
          data: lineInfo?.map((item) => item.true),
          color: "#006AB2",
        },
        {
          name: "调用失败",
          type: "line",
          data: lineInfo?.map((item) => item.false),
          color: "#A197FB",
        },
      ],
    };
  };

  return (
    <div className="LineChart">
      {staticLoading ? (
        <MySpin></MySpin>
      ) : Object?.keys(lineChartList)?.length === 0 ? (
        <MyEmpty></MyEmpty>
      ) : (
        <>
          <div className="radioInfo">
            <div>
              调用成功 <span style={{ color: "#006AB2" }}> {max} </span>
              调用失败
              <span style={{ color: "#FE545F" }}> {min} </span>
            </div>
            <Radio.Group
              options={plainOptions}
              onChange={onChange}
              value={RadioValue}
            />
          </div>
          <ReactEcharts option={getOption()} />
        </>
      )}
    </div>
  );
};

export default LineChart;
