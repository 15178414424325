import "./index.scss";
import { ReactSVG } from "react-svg";

import { Drawer, Button, Select, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import HeaderTitle from "../../../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import DropdownSvg from "../../../../../assets/下拉.svg";
import MySpin from "../../../../../components/MySpin";
import tableIcon from "../../../../../assets/表16.svg";

const { Option } = Select;

const GovDetailDrawer = (props) => {
  const {
    /* 关闭触发的回调 */
    onCancel,
    visible,
    /* 初始值 */
    defaultData,
    /* 版本列表 */
    VersoList,
    loading,
    getDetailInfo, // 切换版本获取详情信息
    agreeFlag, //判断详情是从页面还是审核页面打开
    agreeItem,
    rejectItem,
    verId,
    setVerId,
  } = props;

  const titleTypeBgc = (data) => {
    if (data === "ORIGINAL") {
      return "#61D4AD";
    }
    if (data === "NEW") {
      return "#FEC054";
    }
    if (data === "UPDATE") {
      return "#006AB2";
    }
    if (data === "DISCARD") {
      return "#FE545F";
    }
  };

  // 流程状态颜色切换
  const changeColor = (value) => {
    if (value === "TO_BE_RELEASED") {
      return "#6CAEFF";
    } else if (value === "IN_APPROVAL") {
      return "#FEC054";
    } else if (value === "RELEASED") {
      return "#61D4AD";
    } else if (value === "DISCARDED") {
      return "#D8D8D8";
    }
  };

  // 流程状态名字
  const changeName = (value) => {
    if (value === "TO_BE_RELEASED") {
      return "待发布";
    } else if (value === "IN_APPROVAL") {
      return "审核中";
    } else if (value === "RELEASED") {
      return "已发布";
    } else if (value === "DISCARDED") {
      return "已废弃";
    }
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <Drawer
      title="查看服务详情"
      placement="right"
      width="600px"
      className="GovDetailDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          {agreeFlag ? (
            <>
              <Button
                className="noBg"
                onClick={() => {
                  agreeItem(defaultData, "pkg");
                }}
                style={{
                  background: "#016DB4",
                  color: "#fff",
                  borderColor: "#016DB4",
                }}
              >
                同意
              </Button>
              <Button
                className="noBg"
                onClick={() => {
                  rejectItem(defaultData, "pkg");
                }}
                style={{
                  background: "#FE545F",
                  color: "#fff",
                  borderColor: "#FE545F",
                }}
              >
                驳回
              </Button>
            </>
          ) : (
            <Button
              className="noBg"
              onClick={() => {
                handleCancel();
              }}
            >
              关闭
            </Button>
          )}
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <div className="detailsContent">
          {loading ? (
            <MySpin></MySpin>
          ) : (
            <>
              <HeaderTitle
                title="基本信息"
                extra={
                  <>
                    <div className="states">
                      <div
                        className="metaState"
                        style={{
                          background: changeColor(defaultData?.rls_stat_code),
                        }}
                      >
                        {changeName(defaultData?.rls_stat_code)}
                      </div>
                    </div>
                    <span className="titleVenson">版本</span>
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      labelInValue={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      value={verId}
                      style={{ width: "128px" }}
                      onChange={(e) => {
                        setVerId(e.label);
                        if (e?.label !== "当前") {
                          getDetailInfo(e?.label, defaultData, false);
                        } else {
                          getDetailInfo(null, defaultData, false);
                        }
                      }}
                    >
                      {VersoList?.map((item) => {
                        return (
                          <Option value={item.ver_no} key={item.api_pkg_ver_id}>
                            {item.ver_no}
                          </Option>
                        );
                      })}
                    </Select>
                  </>
                }
              ></HeaderTitle>
              <div className="detailsBox">
                <div className="detailItem">
                  <div className="detailKey">中文名称</div>
                  <div className="detailValue">{defaultData?.chi_name}</div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">英文名称 </div>
                  <div className="detailValue">{defaultData?.eng_name}</div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">描述 </div>
                  <div className="detailValue">{defaultData?.api_pkg_desc}</div>
                </div>
              </div>
              <HeaderTitle title="API服务列表"></HeaderTitle>
              <div className="apisBox">
                {defaultData?.api_list?.map((item, index) => (
                  <div className="tableInfo" key={index}>
                    <ReactSVG src={tableIcon} className="tableIcon" />
                    <Tooltip
                      overlayClassName="headerTipContent"
                      placement="bottomLeft"
                      title={
                        <div className="headerTip">
                          <div className="headerTipTitle">{`${item?.chi_name} / ${item?.eng_name}`}</div>
                          <div className="headerTipText">
                            接口URL: {item?.api_url}
                          </div>
                          <div className="headerTipText">
                            请求方式: {item?.req_method_code}
                          </div>
                          <div className="headerTipText">
                            content_type: {item?.content_type}
                          </div>
                        </div>
                      }
                    >
                      <div className="nameValue">
                        {`${item?.chi_name} / ${item?.eng_name}`}
                      </div>
                    </Tooltip>

                    <div
                      className="typeValue"
                      style={{
                        background: titleTypeBgc(item?.chg_type_code),
                      }}
                    >
                      新增
                    </div>
                  </div>
                ))}
              </div>

              <HeaderTitle title="管理信息"></HeaderTitle>
              <div className="detailsBox">
                <div className="detailItem"></div>
                <div className="detailItem managementInfo">
                  <div className="detailKey">创建人</div>
                  <div className="detailValue">
                    {defaultData?.created_by_id_value}
                  </div>
                </div>
                <div className="detailItem managementInfo">
                  <div className="detailKey">创建时间</div>
                  <div className="detailValue">{defaultData?.created_time}</div>
                </div>
                <div className="detailItem managementInfo">
                  <div className="detailKey">更新人</div>
                  <div className="detailValue">
                    {defaultData?.updated_by_id_value}
                  </div>
                </div>
                <div className="detailItem managementInfo">
                  <div className="detailKey">更新时间</div>
                  <div className="detailValue">{defaultData?.updated_time}</div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">
                    {defaultData?.last_ver_no === null ? null : "版本更新说明"}
                  </div>
                  <div className="detailValue">{defaultData?.ver_upd_desc}</div>
                </div>
              </div>
            </>
          )}
        </div>
      </Scrollbars>
    </Drawer>
  );
};

export default GovDetailDrawer;
