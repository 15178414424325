import request from "../request";
// arraybuffer
/* ============================= 系统管理 ============================= */
// 获取用户数据字典
function getDicCodeInfo() {
  return request({
    url: `/sys_management/dics`,
    method: "get",
  });
}

// 获取主题域数据分层映射表
function getLayerSubList() {
  return request({
    url: `/data_definition/metadata/layer_sub_mapping`,
    method: "get",
  });
}

// 管理责任人
function getResponsible(data) {
  return request({
    url: "/sys_management/responsible",
    method: "get",
    params: data,
  });
}

/* ============================= 数据标准(通用) ============================= */

// 获取版本列表
function getVersonList(view, data) {
  return request({
    url: `/data_standard/verson/list`,
    method: "post",
    params: view,
    data,
  });
}

// 获取发布标准版本号
function getNextVersonList(data) {
  return request({
    url: `/data_standard/verson/next_verson`,
    method: "post",
    params: data,
  });
}

// 发布标准
function applyStandards(data) {
  return request({
    url: "/data_standard/apply",
    method: "post",
    data,
  });
}

// 审核状态查询
function versonStatus(data) {
  return request({
    url: `/data_standard/verson/status`,
    method: "post",
    params: data,
  });
}

// 审核状态统计
function versonStatusStatics(data) {
  return request({
    url: `/data_standard/verson/status/statics`,
    method: "post",
    params: data,
  });
}

// 审核详情
function getCheckDetail(data) {
  return request({
    url: `/data_standard/check/detail`,
    method: "post",
    params: data,
  });
}

// 标准启用
function operationEnable(data) {
  return request({
    url: `/data_standard/standard/operation/enable`,
    method: "post",
    data,
  });
}

// 标准废弃
function operationAbandon(data) {
  return request({
    url: `/data_standard/standard/operation/abandon`,
    method: "post",
    data,
  });
}

/* ============================= 数据分层标准 ============================= */

// 获取数据分层标准中未发布标准
function getLayerUnpublishedList(view, data) {
  return request({
    url: `/data_standard/layer/uppublished/list`,
    method: "post",
    params: view,
    data,
    // needLoading:true
  });
}
// 获取数据分层标准中已发布标准
function getLayerPublishedList(view, data) {
  return request({
    url: "/data_standard/layer/published/list",
    method: "post",
    params: view,
    data,
    // needLoading:true
  });
}
// 添加分层标准
function AddLayerStandard(data) {
  return request({
    url: "/data_standard/layer/add",
    method: "post",
    data,
  });
}

// 更新分层标准
function updateLayerStandard(data) {
  return request({
    url: "/data_standard/layer/update",
    method: "post",
    data,
  });
}

// 删除分层标准
function deleteLayerStandard(data) {
  return request({
    url: "/data_standard/layer/delete",
    method: "post",
    data,
  });
}

/* ============================= 词根标准 ============================= */

// 获取词根标准中未发布标准
function getEtymaUnpublishedList(view, data) {
  return request({
    url: "/data_standard/etyma/unpublished/list",
    method: "post",
    params: view,
    data,
  });
}
// 获取词根标准中已发布标准
function getEtymaPublishedList(view, data) {
  return request({
    url: "/data_standard/etyma/published/list",
    method: "post",
    params: view,
    data,
  });
}

// 添加词根标准
function AddEtymaStandard(data) {
  return request({
    url: "/data_standard/etyma/add",
    method: "post",
    data,
  });
}

// 更新词根标准
function updateEtymaStandard(data) {
  return request({
    url: "/data_standard/etyma/update",
    method: "post",
    data,
  });
}

// 删除词根标准
function deleteEtymaStandard(data) {
  return request({
    url: "/data_standard/etyma/delete",
    method: "post",
    data,
  });
}

// 导入词根标准
function importUnpublishedList(data) {
  return request({
    url: `/data_standard/import_data`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}

// 词根模版下载
function DownloadFile() {
  return request({
    url: `/data_standard/down_template`,
    method: "post",
    responseType: "blob",
  });
}

/* ============================= 主题域标准 ============================= */

// 获取主题域标准中未发布标准
function getSubUnpublishedList(view, data) {
  return request({
    url: "/data_standard/sub/uppublished/list",
    method: "post",
    params: view,
    data,
  });
}
// 获取主题域标准中已发布标准
function getSubPublishedList(view, data) {
  return request({
    url: "/data_standard/sub/published/list",
    method: "post",
    params: view,
    data,
  });
}

// 添加主题域标准
function AddSubStandard(data) {
  return request({
    url: "/data_standard/sub/add",
    method: "post",
    data,
  });
}

// 更新主题域标准
function updateSubStandard(data) {
  return request({
    url: "/data_standard/sub/update",
    method: "post",
    data,
  });
}

// 删除主题域标准
function deleteSubStandard(data) {
  return request({
    url: "/data_standard/sub/delete",
    method: "post",
    data,
  });
}

// 根据父主题域id查子主题域
function getSubStandardList(id, data) {
  return request({
    url: `/data_standard/sub/list/${id}`,
    method: "get",
    params: data,
  });
}

// 导入主题域标准
function importSubUnpublishedList(data) {
  return request({
    url: `/data_standard/subject/import_data`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}

// 主题域模版下载
function DownloadSubFile() {
  return request({
    url: `/data_standard/subject/down_template`,
    method: "post",
    responseType: "blob",
  });
}

/* ============================= 数据元标准 ============================= */
// 获取数据元标准中未发布标准
function getEleUnpublishedList(view, data) {
  return request({
    url: "/data_standard/data_ele/unpublished/list",
    method: "post",
    params: view,
    data,
  });
}

// 获取数据元标准中已发布标准
function getElePublishedList(view, data) {
  return request({
    url: "/data_standard/data_ele/published/list",
    method: "post",
    params: view,
    data,
  });
}

// 添加数据元标准
function AddEleStandard(data) {
  return request({
    url: "/data_standard/data_ele/add",
    method: "post",
    data,
  });
}

// 更新数据元标准
function updateEleStandard(data) {
  return request({
    url: "/data_standard/data_ele/update",
    method: "post",
    data,
  });
}

// 删除数据元标准
function deleteEleStandard(data) {
  return request({
    url: "/data_standard/data_ele/delete",
    method: "post",
    data,
  });
}

// 导入数据元标准
function importEleUnpublishedList(data) {
  return request({
    url: `/data_standard/data_ele/import_data`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}

// 数据元模版下载
function DownloadEleFile() {
  return request({
    url: `/data_standard/data_ele/down_template`,
    method: "post",
    responseType: "blob",
  });
}

// 数据元标准校验
function verifyEleStandard(data) {
  return request({
    url: "/data_standard/data_ele/verify",
    method: "post",
    data,
  });
}

// 被模型使用详情
function getEleUsed(id) {
  return request({
    url: `/data_standard/data_ele/used_detail`,
    method: "post",
    params: {
      data_ele_id: id,
    },
  });
}

/* ============================= 审核标准 ============================= */

// 我的申请
function getMyapplyList(view, data) {
  return request({
    url: "/data_standard/check/myapply/list",
    method: "post",
    params: view,
    data,
  });
}

// 待审核
function getToCheckList(view, data) {
  return request({
    url: "/data_standard/check/tocheck/list",
    method: "post",
    params: view,
    data,
  });
}

// 已审核
function getCheckedList(view, data) {
  return request({
    url: "/data_standard/check/checked/list",
    method: "post",
    params: view,
    data,
  });
}

// 审核标准-同意
function checkStandards(id) {
  return request({
    url: `/data_standard/check/${id}`,
    method: "post",
  });
}

// 审核标准-拒绝
function rejectStandards(id, value) {
  return request({
    url: `/data_standard/reject_standard/${id}`,
    method: "post",
    params: {
      apprd_opn: value,
    },
  });
}

// 审核标准-撤回
function withdrawStandards(id) {
  return request({
    url: `/data_standard/withdraw_standard/${id}`,
    method: "post",
  });
}

/* ============================= 数据定义 ============================= */

/* ============================= 元数据管理 ============================= */

// 元数据查询
function getMetadataList(data) {
  return request({
    url: "/data_definition/metadata/list",
    method: "get",
    params: data,
  });
}

// 元数据详情版本查询
function getMetadataVerson(data) {
  return request({
    url: "/data_definition/metadata/ver_list",
    method: "get",
    params: data,
  });
}

// 标签查询
function getMetadataTag(data) {
  return request({
    url: "/data_definition/metadata/tags",
    method: "get",
    params: data,
  });
}

// 添加标签
function addMetadataTag(data) {
  return request({
    url: "/data_definition/metadata/tag/add",
    method: "post",
    data,
  });
}

// 标签列表模糊查询
function getMetadataTagList(data) {
  return request({
    url: "/data_definition/metadata/tags/search",
    method: "get",
    params: data,
  });
}

// 血缘关系查询
function getBloodrRelation(data) {
  return request({
    url: "/data_definition/metadata/blood_relation",
    method: "get",
    params: data,
  });
}

// 数据库连接测试
function dbTestconnection(view, data) {
  return request({
    url: "/data_definition/metadata/db/test_connection",
    method: "post",
    params: view,
    data,
  });
}

// api查询
function getMetadataApi(data) {
  return request({
    url: "/data_definition/metadata/api/query",
    method: "get",
    params: data,
  });
}

// api添加
function addMetadataApi(data) {
  return request({
    url: "/data_definition/metadata/api/add",
    method: "post",
    data,
  });
}

// api修改
function updateMetadataApi(data) {
  return request({
    url: "/data_definition/metadata/api/update",
    method: "post",
    data,
  });
}

// db查询
function getMetadataDb(data) {
  return request({
    url: "/data_definition/metadata/db/query",
    method: "get",
    params: data,
  });
}

// db添加
function addMetadataDb(data) {
  return request({
    url: "/data_definition/metadata/db/add",
    method: "post",
    data,
  });
}

// db修改
function updateMetadataDb(data) {
  return request({
    url: "/data_definition/metadata/db/update",
    method: "post",
    data,
  });
}

// web查询
function getMetadataWeb(data) {
  return request({
    url: "/data_definition/metadata/web/query",
    method: "get",
    params: data,
  });
}

// web添加
function addMetadataWeb(data) {
  return request({
    url: "/data_definition/metadata/web/add",
    method: "post",
    data,
  });
}

// web修改
function updateMetadataWeb(data) {
  return request({
    url: "/data_definition/metadata/web/update",
    method: "post",
    data,
  });
}

// table查询
function getMetadataTable(data) {
  return request({
    url: "/data_definition/metadata/table/query",
    method: "get",
    params: data,
  });
}

// 根据数据库查询所有的表
function getMetadataDBTableList(data) {
  return request({
    url: "/data_definition/metadata/db/table/list",
    method: "post",
    params: data,
  });
}

// table添加
function addMetadataTable(data) {
  return request({
    url: "/data_definition/metadata/table/add",
    method: "post",
    data,
  });
}

// table修改
function updateMetadataTable(data) {
  return request({
    url: "/data_definition/metadata/table/update",
    method: "post",
    data,
  });
}

// 数据存储列表查询
function getMetadataStorage(db_type_code) {
  return request({
    url: "/data_definition/metadata/data_storage",
    method: "get",
    params: {
      db_type_code,
    },
  });
}

// 从数据库中选择表-表数据详情
function getMetaTableDesc(view) {
  return request({
    url: "/data_definition/metadata/table/desc",
    method: "post",
    params: view,
  });
}

// 表数据详情（修改模型的时候选择）
function getMetaDBTable(metadata_ver_id) {
  return request({
    url: "/data_definition/metadata/table_details/query",
    method: "get",
    params: {
      metadata_ver_id,
    },
  });
}
// 废弃元数据
function abandonMetadata(data) {
  return request({
    url: "/data_definition/metadata/abandon",
    method: "delete",
    data,
  });
}

// 撤销废弃元数据
function cannelAbandonMetadata(data) {
  return request({
    url: "/data_definition/metadata/abandon",
    method: "put",
    data,
  });
}

// 模型设计-ddl模式
function addDesignDdl(data) {
  return request({
    url: "/data_definition/model/design/ddl/add",
    method: "post",
    data,
  });
}

// 导入元数据
function importMetaData(id, data) {
  return request({
    url: `/data_definition/import_data/${id}`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}

// 元数据模版下载
function DownloadMetaDataFile(type) {
  return request({
    url: `/data_definition/down_template/${type}`,
    method: "post",
    responseType: "blob",
  });
}

/* ============================= 模型设计中心 ============================= */

// 模型列表查询
function getModeldataList(data) {
  return request({
    url: "/model_design/model/design/list",
    method: "get",
    params: data,
  });
}

// 模型设计-ddl解析转表格（新增）
function parseDesignDdl(data) {
  return request({
    url: "/model_design/model/design/ddl/parse",
    method: "post",
    params: data,
  });
}

// 模型设计-表格转ddl解析（新增）
function tableTransferDdl(data) {
  return request({
    url: "/model_design/model/design/add/ddl",
    method: "post",
    data,
  });
}

// 模型设计-新增
function addDesign(data) {
  return request({
    url: "/model_design/model/design/add",
    method: "post",
    data,
  });
}

// 模型设计-修改
function modifyDesign(data) {
  return request({
    url: "/model_design/model/design/modify",
    method: "post",
    data,
  });
}

// 模型设计-还原
function restoreDesign(id) {
  return request({
    url: "/model_design/model/design/restore",
    method: "post",
    params: {
      table_id: id,
    },
  });
}

// 模型设计-表格数据转ddl（修改）
function modifyDdl(data) {
  return request({
    url: "/model_design/model/design/modify/ddl",
    method: "post",
    data,
  });
}

// 模型设计-查看详情-查看ddl
function tableDefineDdl(id) {
  return request({
    url: "/model_design/model/design/table_define/ddl",
    method: "get",
    params: {
      metadata_id: id,
    },
  });
}

// 模型设计-copy模型数据
function copyTableDesign(id) {
  return request({
    url: "/model_design/model/design/copy",
    method: "post",
    params: {
      table_id: id,
    },
  });
}

// 模型设计-模型是否存在
function TableDesignExists(name) {
  return request({
    url: "/model_design/model/design/exists",
    method: "post",
    params: {
      table_name: name,
    },
  });
}
// 添加参照关系
function addReferenceDesign(data) {
  return request({
    url: "/model_design/model/design/re/add",
    method: "post",
    data,
  });
}

// 查询添加参照关系
function getReferenceDesignList(id) {
  return request({
    url: "/model_design/model/design/re/list",
    method: "post",
    params: {
      table_id: id,
    },
  });
}

// 获取表列表
function getModelTableList(data) {
  return request({
    url: "/model_design/model/design/table/list",
    method: "get",
    params: data,
  });
}

// 获取表字段
function getModelTableFieldList(id) {
  return request({
    url: "/model_design/model/design/table/field",
    method: "get",
    params: {
      table_id: id,
    },
  });
}

// 获取修改模型数据
function getModelDesc(id) {
  return request({
    url: "/model_design/model/design/query",
    method: "post",
    params: {
      table_id: id,
    },
  });
}

// 模型id转原数据id
function getMetaDataId(data) {
  return request({
    url: "/model_design/model/design/table_id_to_metadata_id",
    method: "get",
    params: data,
  });
}

// 废弃模型
function abandonModelData(data) {
  return request({
    url: "/model_design/model/design/original/discard",
    method: "put",
    data,
  });
}

// 删除模型
function deleteModelData(id) {
  return request({
    url: `/model_design/model/design/in_design/${id}`,
    method: "delete",
  });
}

// 提交审核
function submitModelDesign(data) {
  return request({
    url: `/model_design/model/design/submit`,
    method: "post",
    data,
  });
}

// 获取表后缀
function getTableSuffix() {
  return request({
    url: `/model_design/model/design/table/suffix`,
    method: "get",
  });
}

// 模型部署
function deployModelDesign(data) {
  return request({
    url: `/model_design/model/design/deploy`,
    method: "post",
    data,
  });
}

// 获取schemas信息
function getDbSchemas(id) {
  return request({
    url: `/model_design/model/design/db_schemas`,
    method: "get",
    params: {
      ds_metadata_id: id,
    },
  });
}

// 获取部署ddl信息
function getTableDdl(data) {
  return request({
    url: `/model_design/model/design/table_ddl`,
    method: "get",
    params: data,
  });
}

// 模型发布查询
function getModelReleaseData(data) {
  return request({
    url: `/model_design/model/release/list`,
    method: "get",
    params: data,
  });
}

// 模型发布审核（同意和驳回）
function modelReleaseAudit(data) {
  return request({
    url: `/model_design/model/release/audit`,
    method: "post",
    data,
  });
}

// 模型发布撤回审核
function canceledModel(data) {
  return request({
    url: `/model_design/model/release/canceled`,
    method: "post",
    data,
  });
}

// 模型重新提交审核
function modelResubmit(data) {
  return request({
    url: `/model_design/model/design/resubmit`,
    method: "post",
    data,
  });
}

// 终止模型部署
function undeployModel(data) {
  return request({
    url: `/model_design/model/design/undeploy`,
    method: "post",
    data,
  });
}

// 模型部署详情查询
function deployedModel(id) {
  return request({
    url: `/model_design/model/design/deployd`,
    method: "post",
    params: {
      table_id: id,
    },
  });
}

// 表key设计
function setTableKey(data) {
  return request({
    url: `/model_design/model/design/table_keys/add`,
    method: "post",
    data,
  });
}

// 查询表key设计
function getTableKeyList(id) {
  return request({
    url: `/model_design/model/design/table_keys/get`,
    method: "get",
    params: {
      table_id: id,
    },
  });
}

/* ============================= 资产管理设置 ============================= */

// 用户列表查询（初始管理责任人）
function getUserList() {
  return request({
    url: `/sys_management/asset_management/user`,
    method: "get",
  });
}

// 查询默认管理责任人
function getResponsiblePerson() {
  return request({
    url: `/sys_management/asset_management/default_responsible_person`,
    method: "get",
  });
}

// 更新管理责任人
function setResponsiblePerson(data) {
  return request({
    url: `/sys_management/asset_management/default_responsible_person`,
    method: "post",
    data,
  });
}

// 新增管理责任人
function addResponsiblePerson(code, data) {
  return request({
    url: "/sys_management/asset_management/responsible_person",
    method: "post",
    params: {
      asset_type_code: code,
    },
    data,
  });
}

// 修改管理责任人
function updateResponsiblePerson(data) {
  return request({
    url: "/sys_management/asset_management/responsible_person",
    method: "put",
    data,
  });
}

// 查询管理责任人（表格信息）
function getResponsiblePersonList(data) {
  return request({
    url: `/sys_management/asset_management/responsible_person`,
    method: "get",
    params: data,
  });
}
// 删除管理责任人
function deleteResponsiblePersonList(data) {
  return request({
    url: `/sys_management/asset_management/responsible_person`,
    method: "delete",
    data,
  });
}
/* ============================= 数据服务 ============================= */
/* ============================= 服务开发 ============================= */
// 获取服务开发列表
function getApisList(info, data) {
  return request({
    url: "/data_service/service_dev/apis",
    method: "post",
    params: info,
    data,
  });
}

// 服务编辑
function modifyServiceDev(data) {
  return request({
    url: "/data_service/service_dev/modify",
    method: "post",
    data,
  });
}

// 获取服务版本列表
function getSerVerNoList(id) {
  return request({
    url: "/data_service/service_dev/api_ver/list",
    method: "get",
    params: {
      api_id: id,
    },
  });
}

// 获取服务详情信息
function getSerDetail(data) {
  return request({
    url: "/data_service/service_dev/api_detail",
    method: "get",
    params: data,
  });
}

// 服务提交审核
function reviewPkg(value, data) {
  return request({
    url: "/data_service/service_dev/submit",
    method: "post",
    params: {
      rn: value,
    },
    data,
  });
}

/* ============================= API 包管理 ============================= */
// 获取服务包列表
function getDevPackageList(data) {
  return request({
    url: `/data_service/dev_package_list`,
    method: "get",
    params: data,
  });
}

// 获取api包管理-api服务列表-添加接口查询
function getApiPortList(data) {
  return request({
    url: `/data_service/api_list`,
    method: "get",
    params: data,
  });
}

// 查询api服务包
function getPkgService(id) {
  return request({
    url: "/data_service/pkg",
    method: "get",
    params: {
      api_pkg_id: id,
    },
  });
}

// 新增api服务包
function addPkgService(data) {
  return request({
    url: "/data_service/pkg",
    method: "post",
    data,
  });
}

// 修改api服务包
function modifyPkgService(data) {
  return request({
    url: "/data_service/pkg",
    method: "put",
    data,
  });
}
// 删除api服务包
function deletePkgService(data) {
  return request({
    url: "/data_service/pkg",
    method: "delete",
    data,
  });
}

// 获取包版本列表
function getPkgVerNoList(id) {
  return request({
    url: "/data_service/ver_no_list",
    method: "get",
    params: {
      api_pkg_id: id,
    },
  });
}

// 获取包详情信息
function getPkgDetail(data) {
  return request({
    url: "/data_service/pkg_details",
    method: "get",
    params: data,
  });
}

// api包管理提交审核
function reviewApiPkg(data) {
  return request({
    url: "/data_service/pkg_review",
    method: "post",
    data,
  });
}

/* =============================  服务审核与发布 ============================= */
// api服务包-服务审核包列表
function getPkgAuditList(data) {
  return request({
    url: "/data_service/pkg_audit_list",
    method: "get",
    params: data,
  });
}

// api服务包-审核
function auditPkg(data) {
  return request({
    url: "/data_service/pkg/audit",
    method: "post",
    data,
  });
}

// api服务包-撤回
function canceledPkg(data) {
  return request({
    url: "/data_service/pkg/canceled",
    method: "post",
    data,
  });
}

// 服务开发-服务审核包列表
function getApiAuditList(data) {
  return request({
    url: "/data_service/api_audit_list",
    method: "get",
    params: data,
  });
}

// 服务开发-审核
function auditApi(data) {
  return request({
    url: "/data_service/api/audit",
    method: "post",
    data,
  });
}

// 服务开发-撤回
function canceledApi(data) {
  return request({
    url: "/data_service/api/canceled",
    method: "post",
    data,
  });
}

/* =============================  服务订单管理 ============================= */

// 订单管理列表查询
function getOrderList(data) {
  return request({
    url: "/data_service/order",
    method: "get",
    params: data,
  });
}

// 订单管理-审核
function auditOrder(data) {
  return request({
    url: "/data_service/order/audit",
    method: "post",
    data,
  });
}

/* =============================  服务监控 ============================= */
// 搜索用户
function searchUser(data) {
  return request({
    url: "/data_service/monitor/search_user",
    method: "get",
    params: {
      search: data,
    },
  });
}

// 调用统计
function apiStatisticsList(data) {
  return request({
    url: "/data_service/monitor/api_statistics",
    method: "get",
    params: data,
  });
}

// 错误日志列表
function errorMonitorList(data) {
  return request({
    url: "/data_service/monitor/error_log",
    method: "get",
    params: data,
  });
}

// 统计报表列表
function statementMonitorList(data) {
  return request({
    url: "/data_service/monitor/statement",
    method: "get",
    params: data,
  });
}

/* ============================= API Store ============================= */

/* ============================= 概览部分 ============================= */

// 获取api数据服务列表
function getStoreList(data) {
  return request({
    url: `/data_service/store/list`,
    method: "get",
  });
}

// 获取api服务包列表
function getStorePackageList(data) {
  return request({
    url: `/data_service/store/package_list`,
    method: "get",
    params: data,
  });
}

// api服务包申请
function addStoreServerList(data) {
  return request({
    url: `/data_service/store/server_application`,
    method: "post",
    data,
  });
}

// 获取单个api详情（技术文档）
function getApiDetail(id) {
  return request({
    url: `/data_service/store/api/detail`,
    method: "get",
    params: {
      api_id: id,
    },
  });
}

// 获取所有api列表（技术文档）
function getAllApiList() {
  return request({
    url: `/data_service/store/api/list`,
    method: "get",
  });
}

/* ============================= 应用管理 ============================= */

// 获取应用管理列表查询
function getAppList(data) {
  return request({
    url: `/data_service/store/app_list`,
    method: "get",
    params: data,
  });
}

// 查询应用
function getApp(id) {
  return request({
    url: "/data_service/store/app",
    method: "get",
    params: {
      app_id: id,
    },
  });
}

// 新增应用
function addApp(data) {
  return request({
    url: "/data_service/store/app",
    method: "post",
    data,
  });
}

// 修改应用
function modifyApp(data) {
  return request({
    url: "/data_service/store/app",
    method: "put",
    data,
  });
}

// 删除应用
function deleteApp(data) {
  return request({
    url: "/data_service/store/app",
    method: "delete",
    data,
  });
}

// 查询应用列表
function getAppLists() {
  return request({
    url: "/data_service/monitor/user_app",
    method: "get",
  });
}

// 调用统计
function apiStoreList(data) {
  return request({
    url: "/data_service/store/api_statistics",
    method: "get",
    params: data,
  });
}

// 错误日志列表
function errorStoreList(data) {
  return request({
    url: "/data_service/store/error_log",
    method: "get",
    params: data,
  });
}

/* ============================= 数据质量 ============================= */

// 数据核对-筛选字典
function taskCheckOptionsList() {
  return request({
    url: "/data_quality/task_check/options/list",
    method: "get",
  });
}

// 核对任务列表
function gettaskCheckList(info, data) {
  return request({
    url: "/data_quality/task_check/list",
    method: "post",
    params: info,
    data,
  });
}

// 手动运行
function taskCheckRun(params) {
  return request({
    url: "/data_quality/task_check/run",
    method: "post",
    params: params,
  });
}

// 对比数据上传
function importTaskCheck(data, type) {
  return request({
    url: `/data_quality/task_check/upload_file`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      rule_type: type,
    },
    data,
  });
}

// 核对结果详情
function getDetailTaskCheckList(params) {
  return request({
    url: `/data_quality/task_check/detail/list`,
    method: "post",
    params,
  });
}
// 错误数据下标
function getErrorDetailTaskCheck(params) {
  return request({
    url: `/data_quality/task_check/detail/indexes`,
    method: "post",
    params,
  });
}
// 核对结果确认
function updateDetailTaskCheckList(data) {
  return request({
    url: `/data_quality/task_check/detail/update_status`,
    method: "post",
    data,
  });
}
// 对比类型
function getTaskCheckType(params) {
  return request({
    url: `/data_quality/task_check/detail/types`,
    method: "post",
    params,
  });
}

export default {
  getVersonList,
  getLayerUnpublishedList,
  getLayerPublishedList,
  getEtymaUnpublishedList,
  getEtymaPublishedList,
  getSubUnpublishedList,
  getSubPublishedList,
  applyStandards,
  checkStandards,
  getEleUnpublishedList,
  getElePublishedList,
  AddEtymaStandard,
  updateEtymaStandard,
  deleteEtymaStandard,
  AddSubStandard,
  updateSubStandard,
  deleteSubStandard,
  getSubStandardList,
  AddEleStandard,
  updateEleStandard,
  deleteEleStandard,
  DownloadFile,
  getDicCodeInfo,
  versonStatus,
  versonStatusStatics,
  importUnpublishedList,
  deleteLayerStandard,
  AddLayerStandard,
  updateLayerStandard,
  operationEnable,
  operationAbandon,
  getCheckDetail,
  getMetadataList,
  getToCheckList,
  getMyapplyList,
  getCheckedList,
  getNextVersonList,
  dbTestconnection,
  addMetadataApi,
  updateMetadataApi,
  addMetadataDb,
  updateMetadataDb,
  addMetadataWeb,
  updateMetadataWeb,
  getMetaTableDesc,
  addMetadataTable,
  updateMetadataTable,
  abandonMetadata,
  addMetadataTag,
  addDesignDdl,
  getResponsible,
  getBloodrRelation,
  getMetadataApi,
  getMetadataDb,
  getMetadataWeb,
  getMetadataTable,
  getMetadataVerson,
  getMetadataTag,
  cannelAbandonMetadata,
  getMetadataTagList,
  getLayerSubList,
  getMetadataDBTableList,
  getMetaDBTable,
  getMetadataStorage,
  rejectStandards,
  withdrawStandards,
  parseDesignDdl,
  addDesign,
  addReferenceDesign,
  modifyDesign,
  modifyDdl,
  importEleUnpublishedList,
  DownloadEleFile,
  getModelDesc,
  getModeldataList,
  tableTransferDdl,
  getMetaDataId,
  abandonModelData,
  importSubUnpublishedList,
  DownloadSubFile,
  deleteModelData,
  DownloadMetaDataFile,
  importMetaData,
  submitModelDesign,
  getTableSuffix,
  deployModelDesign,
  getDbSchemas,
  getTableDdl,
  getModelReleaseData,
  modelReleaseAudit,
  modelResubmit,
  undeployModel,
  canceledModel,
  getUserList,
  getResponsiblePerson,
  setResponsiblePerson,
  addResponsiblePerson,
  updateResponsiblePerson,
  getResponsiblePersonList,
  deleteResponsiblePersonList,
  getModelTableList,
  getModelTableFieldList,
  getReferenceDesignList,
  verifyEleStandard,
  getStoreList,
  getStorePackageList,
  addStoreServerList,
  getApiDetail,
  getDevPackageList,
  getApisList,
  modifyServiceDev,
  getApiPortList,
  reviewApiPkg,
  addPkgService,
  reviewPkg,
  getPkgService,
  modifyPkgService,
  getPkgVerNoList,
  getPkgDetail,
  getPkgAuditList,
  getSerVerNoList,
  getSerDetail,
  getApiAuditList,
  deletePkgService,
  auditPkg,
  canceledPkg,
  auditApi,
  canceledApi,
  getAppList,
  deleteApp,
  getApp,
  addApp,
  modifyApp,
  getOrderList,
  auditOrder,
  getAllApiList,
  deployedModel,
  searchUser,
  errorMonitorList,
  statementMonitorList,
  apiStatisticsList,
  getAppLists,
  apiStoreList,
  errorStoreList,
  getEleUsed,
  setTableKey,
  getTableKeyList,
  restoreDesign,
  tableDefineDdl,
  copyTableDesign,
  TableDesignExists,
  gettaskCheckList,
  importTaskCheck,
  taskCheckRun,
  taskCheckOptionsList,
  getDetailTaskCheckList,
  updateDetailTaskCheckList,
  getErrorDetailTaskCheck,
  getTaskCheckType,
};
