import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
const CreateApplication = (props) => {
  const data = {
    id: "root",
    label: "Root",
    children: [
      {
        id: "node1",
        label: "Node 1",
        children: [
          {
            id: "node1-1",
            label: "Node 1-1",
          },
          {
            id: "node1-2",
            label: "Node 1-2",
          },
        ],
      },
      {
        id: "node2",
        label: "Node 2",
      },
    ],
  };

  return (
    <div className="CreateApplication">
      <div className="contentValue">
        <div className="contentItem">
          <HeaderTitle title="前提条件"></HeaderTitle>
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          {/* <VisualBox data={data} /> */}
        </div>
      </div>
    </div>
  );
};
export default CreateApplication;
