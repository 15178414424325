import { Checkbox, Badge, Tooltip, message } from "antd";
import {
  DeleteOutlined,
  FormOutlined,
  RollbackOutlined,
  AppstoreAddOutlined,
  CloseCircleOutlined,
  RedoOutlined,
  SlidersOutlined,
  CopyOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import redIcon from "../../../../../assets/表红.svg";
import greyIcon from "../../../../../assets/表灰.svg";
import yellowIcon from "../../../../../assets/表黄.svg";
import blueIcon from "../../../../../assets/表蓝.svg";
import greenIcon from "../../../../../assets/表green.svg";
import { useSelector } from "react-redux";
import projectApi from "../../../../../apis/project";
import ReferenceDrawer from "../ReferenceDrawer";
import LineKeyDefinitionDrawer from "../LineKeyDefinitionDrawer";

const MetaDesignCard = (props) => {
  const {
    modelInfo, // 默认值
    cancelDelete, // 取消废弃模型
    batchDelete, // 废弃模型
    reductionDelete, // 还原模型
    DeleteItem, // 删除模型
    modelHandle, // 修改模型
    setReleaseDrawerVisible, // 提交发布
    setModelMetaInfo, // 模型id对应元数据id数据
    getmetaDataInfo, // 获取元数据信息
    setSubmitModelDesignData, // 提交审核模型信息
    getModeldataList, // 更新模型列表
    // setCopiedFlag,
    copyTableDesign,
  } = props;

  // const [tableList, setTableList] = useState(false); // 查看表定义数据

  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);

  // 连接起点
  const relationType = dicCodeInfoList?.DG_TABLE_DESG_RELATION?.map((item) =>
    Object.keys(item)
  ).flat();

  // 数据分层主题域映射
  const layerSubList = useSelector((state) => state.Project.layerSubList);

  // 模型id 转 元数据id
  const getMetaDataId = () => {
    projectApi.getMetaDataId({ table_id: modelInfo?.table_id }).then((res) => {
      if (res.code === 200) {
        setModelMetaInfo(res.data);
        getmetaDataInfo(res.data);
      } else {
        setModelMetaInfo({});
        message.info("该模型尚未部署，暂时不能查看详情");
      }
    });
  };

  const titleTypeBgc = (data) => {
    if (data === "ORIGINAL") {
      return "#61D4AD";
    }
    if (data === "NEW") {
      return "#FEC054";
    }
    if (data === "UPDATE") {
      return "#006AB2";
    }
    if (data === "DISCARD") {
      return "#FE545F";
    }
  };

  // img src切换
  const changeImage = (value) => {
    if (value === "IN_DESIGN") {
      return blueIcon;
    } else if (value === "IN_APPROVAL") {
      return yellowIcon;
    } else if (value === "TO_BE_DEPLOYED") {
      return redIcon;
    } else if (value === "RELEASED") {
      return greenIcon;
    } else if (value === "DISCARDED") {
      return greyIcon;
    }
  };

  // 流程状态颜色切换
  const changeColor = (value) => {
    if (value === "IN_DESIGN") {
      return (
        <>
          <Badge color="#6CAEFF" /> 设计中
        </>
      );
    } else if (value === "IN_APPROVAL") {
      return (
        <>
          <Badge color="#FEC054" />
          审核中
        </>
      );
    } else if (value === "TO_BE_DEPLOYED") {
      return (
        <>
          <Badge color="#FE545F" />
          待部署
        </>
      );
    } else if (value === "RELEASED") {
      return (
        <>
          <Badge color="#61D4AD" />
          已发布
        </>
      );
    } else if (value === "DISCARDED") {
      return (
        <>
          <Badge color="#D8D8D8" />
          已废弃
        </>
      );
    }
  };

  return (
    <div className="cardItem">
      <div className="leftItem">
        <Checkbox
          value={modelInfo}
          disabled={
            modelInfo?.desg_stat_code === "TO_BE_DEPLOYED" ||
            modelInfo?.desg_stat_code === "IN_APPROVAL"
          }
        ></Checkbox>
      </div>
      <div className="imgItem">
        <img
          src={changeImage(modelInfo?.desg_stat_code)}
          className="infoImg"
          style={{ width: "56px", height: "56px" }}
        ></img>
      </div>
      <div className="rightItem">
        <div
          className="title"
          onClick={() => {
            getMetaDataId();
          }}
        >
          <span> {`${modelInfo?.table_cmnt} / ${modelInfo?.table_name}`}</span>

          <span
            className="titleType"
            style={{ background: titleTypeBgc(modelInfo?.chg_type_code) }}
          >
            {modelInfo?.chg_type_code_value}
          </span>
        </div>
        <div className="webContent">
          {changeColor(modelInfo?.desg_stat_code)}

          <span className="web">{modelInfo?.table_name}</span>
        </div>
        <div className="detailContent">
          <span className="label">存储类型</span>
          <span className="value" style={{ width: "110px" }}>
            {modelInfo?.db_type_code_value}
          </span>
          {Object.values(layerSubList?.DATA_LAYER_RLS).find(
            (item) => item?.data_layer_id === modelInfo?.data_layer_id
          ) !== undefined ? (
            <>
              <span className="label">数据分层</span>
              <span className="value" style={{ width: "100px" }}>
                {Object.values(modelInfo).length !== 0
                  ? Object.values(layerSubList?.DATA_LAYER_RLS).find(
                      (item) => item?.data_layer_id === modelInfo?.data_layer_id
                    )?.data_layer_name
                  : null}
              </span>

              <span className="label">主题域</span>
              <Tooltip
                title={
                  Object.values(modelInfo).length !== 0
                    ? Object.values(layerSubList?.SUB_RLS).find(
                        (item) => item?.sub_id === modelInfo?.sub_id
                      )?.sub_name
                    : null
                }
                overlayClassName="sharesTip"
              >
                <span className="value" style={{ marginLeft: "-15px" }}>
                  {Object.values(modelInfo).length !== 0
                    ? Object.values(layerSubList?.SUB_RLS).find(
                        (item) => item?.sub_id === modelInfo?.sub_id
                      )?.sub_name
                    : null}
                </span>
              </Tooltip>
            </>
          ) : null}
          <span className="label">当前版本</span>
          <span className="value" style={{ width: "60px" }}>
            {modelInfo?.ver_no}
          </span>
          <span className="label">创建人</span>
          <span
            className="value"
            style={{ width: "85px", marginLeft: "-15px" }}
          >
            {modelInfo?.created_by_id_value}
          </span>
          <span className="label">创建时间</span>
          <span className="value">{modelInfo?.created_time}</span>
          <span className="label">更新时间</span>
          <span className="value">{modelInfo?.updated_time}</span>
        </div>
      </div>
      <div className="iconItem">
        <Tooltip placement="top" title="一键复制" arrowPointAtCenter>
          <CopyOutlined
            onClick={() => {
              copyTableDesign(modelInfo?.table_id);
            }}
          />
        </Tooltip>
        <Tooltip placement="top" title="数据键定义" arrowPointAtCenter>
          <SlidersOutlined
            onClick={() => {
              LineKeyDefinitionDrawer({
                maskClosable: true,
                noBtn: false,
                title: "数据键定义",
                dicCodeInfoList,
                tableId: modelInfo?.table_id,
                getModeldataList,
              });
            }}
          />
        </Tooltip>
        {(modelInfo?.chg_type_code !== "DISCARD" &&
          modelInfo?.desg_stat_code === "IN_DESIGN") ||
        modelInfo?.desg_stat_code === "RELEASED" ||
        modelInfo?.desg_stat_code === "DISCARDED" ? (
          <Tooltip placement="top" title="添加参照" arrowPointAtCenter>
            <AppstoreAddOutlined
              onClick={() => {
                ReferenceDrawer({
                  maskClosable: true,
                  noBtn: false,
                  title: "添加参照",
                  dicCodeInfoList,
                  relationType,
                  tableId: modelInfo?.table_id,
                  getModeldataList,
                });
              }}
            />
          </Tooltip>
        ) : null}

        {(modelInfo?.chg_type_code !== "DISCARD" &&
          modelInfo?.desg_stat_code === "IN_DESIGN") ||
        modelInfo?.desg_stat_code === "RELEASED" ||
        modelInfo?.desg_stat_code === "DISCARDED" ? (
          <Tooltip placement="top" title="修改" arrowPointAtCenter>
            <FormOutlined
              onClick={() => {
                modelHandle(modelInfo?.table_id);
              }}
            />
          </Tooltip>
        ) : null}
        {modelInfo?.desg_stat_code === "RELEASED" ||
        (modelInfo?.desg_stat_code === "IN_DESIGN" &&
          modelInfo?.chg_type_code === "UPDATE") ? (
          <Tooltip placement="top" title="废弃" arrowPointAtCenter>
            <DeleteOutlined
              onClick={() => batchDelete([modelInfo?.table_id])}
            />
          </Tooltip>
        ) : null}
        {modelInfo?.desg_stat_code === "IN_DESIGN" &&
        modelInfo?.chg_type_code === "DISCARD" ? (
          <Tooltip placement="top" title="启用" arrowPointAtCenter>
            <RollbackOutlined
              onClick={() => cancelDelete([modelInfo?.table_id])}
            />
          </Tooltip>
        ) : null}
        {modelInfo?.chg_type_code === "NEW" &&
        modelInfo?.desg_stat_code === "IN_DESIGN" ? (
          <Tooltip placement="top" title="删除" arrowPointAtCenter>
            <CloseCircleOutlined
              onClick={() => DeleteItem(modelInfo?.table_id)}
            />
          </Tooltip>
        ) : null}
        {modelInfo?.chg_type_code === "UPDATE" ? (
          <Tooltip placement="top" title="还原" arrowPointAtCenter>
            <RedoOutlined
              onClick={() => reductionDelete(modelInfo?.table_id)}
            />
          </Tooltip>
        ) : null}

        {modelInfo?.desg_stat_code === "IN_DESIGN" ||
        modelInfo?.desg_stat_code === "DISCARDED" ? (
          <Tooltip placement="top" title="提交审核" arrowPointAtCenter>
            <AuditOutlined
              onClick={() => {
                setReleaseDrawerVisible(true);
                setSubmitModelDesignData([modelInfo]);
              }}
            />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};
export default MetaDesignCard;
