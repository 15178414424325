import "./index.scss";
import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Table, Tabs, Form, Input } from "antd";
import MyEmpty from "../../../../../components/Empty";

const { TabPane } = Tabs;
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          style={{ width: "100px" }}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
          minHeight: 22,
          minWidth: 22,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const DetailTable = (props, ref) => {
  const { dbTableList, flag, setDbTableList } = props;
  const refDetailTable = useRef(null);
  // const [indexsData, setIndexsData] = useState([]);
  // const [activeKey, setActiveKey] = useState("1"); // 模型的表单项
  const [fksSource, setFksSource] = useState({}); // 子外键
  const [fksData, setFksData] = useState([]); // 父外键
  // const tabChange = (key) => {
  //   setActiveKey(key);
  // };

  // const fksData = dbTableList?.table_fks;

  const partData = dbTableList?.table_partitions;
  const indexsData = dbTableList?.table_indexes;
  // useEffect(() => {
  //   let newRepList = [];
  //   dbTableList?.table_indexes?.forEach((v) => {
  //     if (v.cols && v.cols.length > 0) {
  //       newRepList = newRepList.concat(
  //         v.cols.map((item) => {
  //           return {
  //             index_name: v.index_name,
  //             col_name: item.col_name,
  //             uniq_index_flag: v.uniq_index_flag,
  //           };
  //         })
  //       );
  //     }
  //   });
  //   setIndexsData(newRepList);
  // }, [dbTableList?.table_indexes]);

  useEffect(() => {
    const data = dbTableList?.table_fks?.reduce(
      (cur, pre) => [...cur, { ord: pre.ord, fk_table_id: pre.fk_metadata_id }],
      []
    );
    setFksData(data);

    const kfsItem = dbTableList?.table_fks?.reduce((cur, pre) => {
      const value = cur;
      const preValue = pre.fk_cols?.reduce(
        (cur, pre) => [
          ...cur,
          {
            ord: pre.ord,
            fk_col_id: pre.fk_col_id,
            re_source_code: pre.re_source_code,
            re_target_code: pre.re_target_code,
            ref_col_id: pre.ref_col_id,
          },
        ],
        []
      );
      value[pre.fk_metadata_id] = preValue;
      return value;
    }, {});
    setFksSource(kfsItem);
  }, [dbTableList?.table_fks]);

  // 子参照配置
  const expandedRowRender = (data) => {
    const fkscolumn = [
      {
        title: "字段",
        dataIndex: "fk_col_id",
        editable: true,
      },
      {
        title: "连线起点",
        dataIndex: "re_source_code",
        editable: true,
      },
      {
        title: "连线终点",
        dataIndex: "re_target_code",
        editable: true,
      },
      {
        title: "对应参照字段",
        dataIndex: "ref_col_id",
        editable: true,
      },
    ];

    return (
      <Table
        className="myFormTable"
        bordered={false}
        pagination={false}
        dataSource={fksSource[data.fk_table_id]}
        columns={fkscolumn}
        rowKey={(record, id) => record?.ord}
        locale={{
          emptyText: <MyEmpty></MyEmpty>,
        }}
      />
    );
  };

  const columns = [
    {
      title: "字段名",
      dataIndex: "col_name",
    },
    {
      title: "中文名",
      dataIndex: "col_desc",
      editable: flag ? false : true,
      // width: "20%",
    },
    {
      title: "字段类型",
      dataIndex: "col_type_code",
    },
    {
      title: "是否主键",
      dataIndex: "pk_flag",
      width: "8%",
      render: (data, info) => (info?.pk_flag ? "是" : "否"),
    },
    {
      title: "是否非空",
      dataIndex: "not_null_flag",
      width: "8%",
      render: (data, info) => (info?.not_null_flag ? "是" : "否"),
    },
    {
      title: "是否自增",
      dataIndex: "auto_inc_flag",
      width: "8%",
      render: (data, info) => (info?.auto_inc_flag ? "是" : "否"),
    },
    {
      title: "默认值",
      dataIndex: "def_value",
    },
  ];
  const indexColumns = [
    {
      title: "索引名称",
      dataIndex: "index_name",
    },
    {
      title: "字段",
      dataIndex: "cols",
      render: (_, info) => info?.cols?.map((item) => item.col_name)?.join("，"),
    },
    {
      title: "是否唯一索引",
      dataIndex: "uniq_index_flag",
      render: (data, info) => (info?.uniq_index_flag ? "是" : "否"),
    },
  ];
  const foreignKeyColumns = [
    {
      title: "对应参照表",
      dataIndex: "fk_table_id",
    },
  ];
  const partitionColumns = [
    {
      title: "分区名",
      dataIndex: "ptn_name",
    },
    {
      title: "分区值",
      dataIndex: "ptn_value",
    },
  ];

  const [data, setData] = useState([]);
  useEffect(() => {
    if (dbTableList?.table_columns.length !== 0) {
      setData(dbTableList?.table_columns);
    }
  }, [dbTableList]);

  const handleSave = (row) => {
    const newData = [...data];
    const index = newData.findIndex((item) => row.ord === item.ord);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setData(newData);
    setDbTableList({ ...dbTableList, table_columns: newData });
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columnss = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  // 暴露表格给父组件
  useImperativeHandle(ref, () => ({
    columns,
    data,
  }));

  //getChildList子表格处理
  const getChildList = async (expanded, record) => {
    setFksSource({
      ...fksSource,
      [record?.fk_table_id]: fksSource[record?.fk_table_id],
    });
  };

  return (
    <div className="DetailTable" ref={refDetailTable}>
      <Tabs className="myTabs" defaultActiveKey="1">
        <TabPane tab="表结构" key="1">
          <Table
            className="myFormTable"
            scroll={{ x: "max-content" }}
            columns={columnss}
            components={components}
            dataSource={data}
            bordered={false}
            pagination={false}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
          />
        </TabPane>
        <TabPane tab="索引" key="2">
          <Table
            className="myFormTable"
            columns={indexColumns}
            dataSource={indexsData}
            bordered={false}
            pagination={false}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
          />
        </TabPane>
        <TabPane tab="外键" key="3">
          <Table
            className="myFormTable"
            columns={foreignKeyColumns}
            expandable={{
              expandedRowRender,
              onExpand: getChildList,
            }}
            dataSource={fksData}
            bordered={false}
            pagination={false}
            locale={{
              emptyText: <MyEmpty></MyEmpty>,
            }}
          />
        </TabPane>
        <TabPane tab="分区" key="4">
          {partData.length !== 0 ? (
            partData?.map((item) => (
              <>
                <div className="detailsBoxNew">
                  <div className="detailItems" style={{ width: "50%" }}>
                    <div className="Key">分区类型</div>
                    <div className="Value" style={{ width: "200px" }}>
                      {item?.ptn_type_code}
                    </div>
                  </div>
                  <div className="detailItems managementInfo">
                    <div className="Key">分区字段</div>
                    <div className="Value" style={{ width: "200px" }}>
                      {item?.ptn_col_name}
                    </div>
                  </div>
                </div>

                <Table
                  className="myFormTable"
                  columns={partitionColumns}
                  dataSource={item.ptn_list}
                  bordered={false}
                  pagination={false}
                  locale={{
                    emptyText: <MyEmpty></MyEmpty>,
                  }}
                />
              </>
            ))
          ) : (
            <>
              <div className="detailsBoxNew">
                <div className="detailItems" style={{ width: "50%" }}>
                  <div className="Key">分区类型</div>
                  <div className="Value" style={{ width: "200px" }}>
                    -
                  </div>
                </div>
                <div className="detailItems managementInfo">
                  <div className="Key">分区字段</div>
                  <div className="Value" style={{ width: "200px" }}>
                    -
                  </div>
                </div>
              </div>

              <Table
                className="myFormTable"
                columns={partitionColumns}
                dataSource={partData}
                bordered={false}
                pagination={false}
                locale={{
                  emptyText: <MyEmpty></MyEmpty>,
                }}
              />
            </>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};
export default forwardRef(DetailTable);
