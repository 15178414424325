import "./index.scss";
import React, { useEffect } from "react";
import { Drawer, Button, Form, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import tableIcon from "../../assets/表16.svg";
import { ReactSVG } from "react-svg";
import MessageModal from "../MessageModal";
import projectApi from "../../apis/project/index";
const { TextArea } = Input;

const ReleaseDrawer = (props) => {
  const [form] = Form.useForm();

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 确定触发的回调 */
    onOk,
    /* 确认按钮文字 */
    okText,
    /* 取消按钮文字 */
    cancelText,
    /* 初始值 */
    defaultData,
    visible,
    versonStatusList,
    nextVerson, // 发布标准版本号
    submitModelDesignData, // 提交审核模型信息
    keywords, // 判断是否是数据元的发布标准
  } = props;

  // 新增模型数量
  const newData = submitModelDesignData?.filter(
    (item) => item?.chg_type_code === "NEW"
  );

  // 修改模型数量
  const updateData = submitModelDesignData?.filter(
    (item) => item?.chg_type_code === "UPDATE"
  );

  // 废弃模型数量
  const discardData = submitModelDesignData?.filter(
    (item) => item?.chg_type_code === "DISCARD"
  );

  // 原有模型数量
  const originalData = submitModelDesignData?.filter(
    (item) => item?.chg_type_code === "ORIGINAL"
  );

  const titleTypeBgc = (data) => {
    if (data === "NEW") {
      return "#FEC054";
    }
    if (data === "UPDATE") {
      return "#006AB2";
    }
    if (data === "DISCARD") {
      return "#FE545F";
    }
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  useEffect(() => {
    form.resetFields();
  }, [defaultData]);

  const onFinish = (value) => {
    const tableIds = submitModelDesignData?.map((item) => item?.table_id);
    const data = { table_id: tableIds, ...value };
    if (title === "发布标准") {
      onOk && onOk(data);
    }

    const dataMap = {};
    const promise = tableIds.reduce((acc, id) => {
      const promise = projectApi.getTableKeyList(id).then((res) => {
        if (res.code === 200) {
          dataMap[id] = res.data;
        } else {
          throw new Error(`Request for id ${id} failed`);
        }
      });
      acc.push(promise);
      return acc;
    }, []);

    Promise.all(promise)
      .then(() => {
        if (hasEmptyArrays(dataMap)) {
          onOk && onOk(data);
        } else {
          MessageModal({
            message: "确认继续提交评审吗",
            description:
              tableIds.length === 1
                ? "该模型没有数据键定义"
                : "存在没有数据键定义的模型",
            title: "数据键定义校验",
            okText: "确认",
            onOk: () => {
              onOk && onOk(data);
            },
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const hasEmptyArrays = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (Array.isArray(obj[key]) && obj[key].length === 0) {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <Drawer
      title={title}
      placement="right"
      width="600px"
      className="ReleaseDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              form.submit();
            }}
            style={{
              backgroundColor: "#016DB4",
              color: "#fff",
              borderColor: "#016DB4",
            }}
          >
            {title === "模型提交发布" ? "提交评审" : "提交发布"}
          </Button>
          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            取消
          </Button>
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="ReleaseDrawerForm"
          preserve={false}
          onFinish={onFinish}
        >
          {title === "模型提交发布" ? (
            <>
              <Form.Item
                name="remarks"
                label="发布说明"
                style={{
                  width: "100%",
                }}
              >
                <TextArea allowClear autoSize={{ minRows: 5 }} />
              </Form.Item>
              <Form.Item
                style={{
                  width: "100%",
                }}
              >
                <div className="releaseContent">
                  本次提交模型
                  <span style={{ color: "#006AB2" }}>
                    {submitModelDesignData?.length}
                  </span>
                  个 (其中： 原有
                  <span>{originalData?.length}</span>项, 新增
                  <span>{newData?.length}</span>
                  项， 修订
                  <span>{updateData?.length}</span>
                  项， 废弃
                  <span>{discardData?.length}</span>
                  项)
                </div>
              </Form.Item>
              {submitModelDesignData?.map((item) => (
                <div className="tableInfo" key={item?.table_id}>
                  <ReactSVG src={tableIcon} className="tableIcon" />
                  <div className="nameValue">
                    {`${item?.table_cmnt} / ${item?.table_name}`}
                  </div>
                  <div
                    className="typeValue"
                    style={{
                      background: titleTypeBgc(item?.chg_type_code),
                    }}
                  >
                    {item?.chg_type_code_value}
                  </div>
                </div>
              ))}
            </>
          ) : null}
          {title === "发布标准" ? (
            <>
              <Form.Item
                name="rls_ver_no"
                label="版本号"
                style={{
                  width: "100%",
                }}
                rules={[
                  { required: true, message: "请输入版本号，不超过10个字符" },
                  {
                    pattern: /^^(.|\n){0,10}$/,
                    message: "版本号不超过10个字符",
                  },
                  {
                    pattern: /^v\d+\.\d+\.\d+$/,
                    message: "版本号格式应为v数字.数字.数字",
                  },
                ]}
                initialValue={nextVerson}
              >
                <Input allowClear placeholder="格式为vx.x.x" />
              </Form.Item>
              <Form.Item
                name="rn"
                label="版本描述"
                style={{
                  width: "100%",
                }}
              >
                <TextArea allowClear autoSize={{ minRows: 5 }} />
              </Form.Item>
              <Form.Item
                style={{
                  width: "100%",
                }}
              >
                <div className="releaseContent">
                  本次发布标准
                  <span style={{ color: "#006AB2" }}>
                    {~~versonStatusList?.ORIGINAL +
                      ~~versonStatusList?.NEW +
                      ~~versonStatusList?.UPDATE +
                      ~~versonStatusList?.DISCARD}
                  </span>
                  项 (其中：原有
                  <span>
                    {versonStatusList?.ORIGINAL
                      ? versonStatusList?.ORIGINAL
                      : 0}
                  </span>
                  项， 新增
                  <span>
                    {versonStatusList?.NEW ? versonStatusList?.NEW : 0}
                  </span>
                  项， 修改
                  <span>
                    {versonStatusList?.UPDATE ? versonStatusList?.UPDATE : 0}
                  </span>
                  项， 废弃
                  <span>
                    {versonStatusList?.DISCARD ? versonStatusList?.DISCARD : 0}
                  </span>
                  项)
                </div>
                {/* {keywords === "数据元" ? (
                  <div
                    style={{
                      marginLeft: "10px",
                      color: "#fe545f",
                      fontSize: "14px",
                    }}
                  >
                    以下包含引用该数据元的模型
                  </div>
                ) : null} */}
              </Form.Item>
              {/* {keywords === "数据元" ? (
                <div className="elementUi">
                  <div className="tableInfo">
                    <div
                      className="typeValue"
                      style={{
                        background: titleTypeBgc("UPDATE"),
                        marginRight: "8px",
                        width: "40px",
                      }}
                    >
                      修改
                    </div>
                    <div
                      className="nameValue"
                      style={{
                        marginRight: "0px",
                      }}
                    >
                      上游数据创建时间
                    </div>
                  </div>
                  <ul className="eleUl">
                    <li className="eleLi"> 上游数据创建时间</li>
                    <li>上游数据创建时间</li>
                    <li>上游数据创建时间</li>
                  </ul>
                </div>
              ) : null} */}
            </>
          ) : null}
        </Form>
      </Scrollbars>
    </Drawer>
  );
};

export default ReleaseDrawer;
