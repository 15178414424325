import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import projectApi from "../../../apis/project";
import DropdownSvg from "../../../assets/下拉.svg";
import DetailTable from "./components/DetailTable";
import DialogDrawer from "./components/DialogDrawer";
import MySpin from "../../../components/MySpin";
import { Button, Form, Select, message } from "antd";

const AssetManagement = (props) => {
  const [form] = Form.useForm();

  const [drawerTitle, setDrawerTitle] = useState("");
  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [subId, setSubId] = useState(""); // 主题域标准版本
  const [layerList, setLayerList] = useState([]);
  const [ManagementList, setManagementList] = useState([]); // 默认管理责任人列表
  const [loading, setLoading] = useState(true);

  const [responsiblePersonList, setResponsiblePersonList] = useState({}); // 管理责任人（表格信息）
  const [params, setParams] = useState({ page: 1, size: 10 });
  const [tableLoading, setTableLoading] = useState(true);

  // 获取数据分层标准版本
  const getLayerVersonList = () => {
    projectApi
      .getVersonList(
        { dg_std_type: "DATA_LAYER" },
        { orders: [{ order_name: "std_ver_id", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          if (res.data.total !== 0) {
            getLayerPublishedList(res.data.items[0].ver_no);
          }
        }
      });
  };

  // 获取数据分层已发布标准
  const getLayerPublishedList = (id) => {
    projectApi
      .getLayerPublishedList(
        { ver_no: id, page: 1 },
        { orders: [{ order_name: "updated_time", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          const data = res.data.items.map((item) => {
            return {
              data_layer_id: item.data_layer_id,
              data_layer_name: item.data_layer_name,
            };
          });
          let newArr = [];
          let arrId = [];
          for (let item of data) {
            if (arrId.indexOf(item["data_layer_id"]) === -1) {
              arrId.push(item["data_layer_id"]);
              newArr.push(item);
            }
          }
          setLayerList(newArr);
        } else {
          setLayerList([]);
        }
      });
  };

  // 获取主题域标准版本
  const getVersonList = () => {
    projectApi
      .getVersonList(
        { dg_std_type: "SUB" },
        { orders: [{ order_name: "std_ver_id", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          if (res.data.total !== 0) {
            setSubId(res.data.items[0].ver_no);
          }
        }
      });
  };

  useEffect(() => {
    getLayerVersonList(); // 获取数据分层最新标准
    getVersonList(); // 获取主题域最新标准
    getUserList(); // 获取初始管理责任人
    getResponsiblePerson(); // 查询管理责任人
    getResponsiblePersonList(); // 查询管理责任人（表格信息）
  }, []);

  //查询管理责任人列表
  const getUserList = () => {
    projectApi.getUserList().then((res) => {
      if (res.code === 200) {
        setManagementList(res.data);
      } else {
        setManagementList([]);
      }
    });
  };

  // 获取初始管理责任人
  const getResponsiblePerson = () => {
    projectApi
      .getResponsiblePerson()
      .then((res) => {
        if (res.code === 200) {
          form.setFieldsValue({
            DS: res.data?.DS?.def_da_rp_id,
            API: res.data?.API?.def_da_rp_id,
            WEB: res.data?.WEB?.def_da_rp_id,
            TABLE: res.data?.TABLE?.def_da_rp_id,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 更新管理责任人
  const setResponsiblePerson = (key, value) => {
    projectApi
      .setResponsiblePerson({
        asset_type_code: key,
        def_da_rp_id: value,
      })
      .then((res) => {
        if (res.code === 200) {
          message.success(res.message);
        } else {
          message.error(res.message);
        }
      });
  };

  // 保存管理责任人
  const onFinish = (value) => {
    setResponsiblePerson(Object.keys(value)[0], value.DS);
    setResponsiblePerson(Object.keys(value)[1], value.API);
    setResponsiblePerson(Object.keys(value)[2], value.WEB);
    setResponsiblePerson(Object.keys(value)[3], value.TABLE);
  };

  // 改变分页数据
  const changePagination = (page, pageSize) => {
    setParams({ page: page, size: pageSize });
  };
  useEffect(() => {
    setTableLoading(true);
    getResponsiblePersonList();
  }, [params]);

  //  查询管理责任人（表格信息）
  const getResponsiblePersonList = () => {
    projectApi
      .getResponsiblePersonList({
        asset_type_code: "TABLE",
        ...params,
      })
      .then((res) => {
        if (res.code === 200) {
          setResponsiblePersonList(res.data);
        } else {
          setResponsiblePersonList({});
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  //  新增管理责任人
  const addResponsiblePerson = (data) => {
    projectApi.addResponsiblePerson("TABLE", data).then((res) => {
      if (res.code === 200) {
        setTableLoading(true);
        message.success(res.message);
        getResponsiblePersonList();
        setDialogDrawerVisible(false);
      } else {
        message.error(res.message);
      }
    });
  };

  // 添加管理责任人
  const dialogDrawerSuccess = (value) => {
    addResponsiblePerson(value);
  };

  return (
    <div className="AssetManagement">
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <Form
          form={form}
          autoComplete="off"
          className="AssetManagementForm"
          preserve={false}
          onFinish={onFinish}
        >
          <HeaderTitle title="数据存储"></HeaderTitle>
          <Form.Item
            name="DS"
            label="默认管理责任人"
            rules={[
              {
                required: true,
                message: "请选择默认管理责任人",
              },
            ]}
          >
            <Select
              placeholder="请选择"
              allowClear
              showSearch={true}
              style={{ width: "368px" }}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              options={ManagementList?.map((item) => ({
                label: item.username,
                value: item.user_id,
              }))}
            ></Select>
          </Form.Item>

          <HeaderTitle title="API服务包"></HeaderTitle>
          <Form.Item
            name="API"
            label="默认管理责任人"
            rules={[
              {
                required: true,
                message: "请选择默认管理责任人",
              },
            ]}
          >
            <Select
              placeholder="请选择"
              allowClear
              showSearch={true}
              style={{ width: "368px" }}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              options={ManagementList?.map((item) => ({
                label: item.username,
                value: item.user_id,
              }))}
            ></Select>
          </Form.Item>

          <HeaderTitle title="网站"></HeaderTitle>
          <Form.Item
            name="WEB"
            label="默认管理责任人"
            rules={[
              {
                required: true,
                message: "请选择默认管理责任人",
              },
            ]}
          >
            <Select
              placeholder="请选择"
              allowClear
              showSearch={true}
              style={{ width: "368px" }}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              options={ManagementList?.map((item) => ({
                label: item.username,
                value: item.user_id,
              }))}
            ></Select>
          </Form.Item>
          <HeaderTitle title="模型/表"></HeaderTitle>
          <Form.Item
            name="TABLE"
            label="默认管理责任人"
            rules={[
              {
                required: true,
                message: "请选择默认管理责任人",
              },
            ]}
          >
            <Select
              placeholder="请选择"
              allowClear
              showSearch={true}
              style={{ width: "368px" }}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              options={ManagementList?.map((item) => ({
                label: item.username,
                value: item.user_id,
              }))}
            ></Select>
          </Form.Item>

          <Button
            style={{
              background: "#016DB4",
              borderColor: "#016DB4",
              color: "#FFFFFF",
              fontSize: "14px",
            }}
            onClick={() => {
              setDialogDrawerVisible(true);
              setAddNew(true);
              setDrawerTitle("添加管理责任人");
            }}
          >
            添加管理责任人
          </Button>
          {/* 表 */}
          <DetailTable
            setAddNew={setAddNew}
            setDrawerTitle={setDrawerTitle}
            setDialogDrawerVisible={setDialogDrawerVisible}
            responsiblePersonList={responsiblePersonList}
            changePagination={changePagination}
            tableLoading={tableLoading}
            layerList={layerList}
            subId={subId}
            ManagementList={ManagementList}
            getResponsiblePersonList={getResponsiblePersonList}
            setTableLoading={setTableLoading}
          ></DetailTable>

          <div className="drawerFooterBtnBox">
            <Button
              onClick={() => {
                form.submit();
              }}
              style={{
                background: "#016DB4",
                borderColor: "#016DB4",
                color: "#FFFFFF",
                fontSize: "14px",
              }}
            >
              保存设置
            </Button>
          </div>
        </Form>
      )}

      <DialogDrawer
        drawerTitle={drawerTitle}
        onOk={dialogDrawerSuccess}
        visible={DialogDrawerVisible}
        onCancel={() => {
          setDialogDrawerVisible(false);
        }}
        layerList={layerList}
        subId={subId}
        ManagementList={ManagementList}
        addNew={addNew}
      ></DialogDrawer>
    </div>
  );
};
export default AssetManagement;
