import "./index.scss";
import Scrollbars from "react-custom-scrollbars";
import { Input, Button, Pagination, message, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

import {
  CaretUpOutlined,
  CaretDownOutlined,
  DownOutlined,
  PlusOutlined,
  DeleteOutlined,
  FormOutlined,
  DeleteFilled,
  CloseOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import projectApi from "../../../apis/project";
import MessageModal from "../../../components/MessageModal";
import submitIcon from "../../../assets/提交.svg";
import MetaApplyCard from "./components/MetaApplyCard";
import MySpin from "../../../components/MySpin";
import Empty from "../../../components/Empty";
import DialogDrawer from "./components/DialogDrawer";
import { set } from "js-cookie";
const CheckboxGroup = Checkbox.Group;
const orderDataList = [
  { label: "变更类型", key: "chg_type_code" },
  { label: "中文名称", key: "table_cmnt" },
  { label: "英文名称", key: "table_name" },
];

const ModelApply = (props) => {
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const [changeTypeCode, setChangeTypeCode] = useState("all"); // 变更类型选择
  const [modeldataName, setModeldataName] = useState(""); // 模型名称
  const [newModeldataName, setNewModeldataName] = useState(""); // 监听模型名称
  const [modelList, setModelList] = useState([]); // 模型查询结果列表
  const [plainOptionList, setPlainOptionList] = useState([]); // 全选反选绑定数据

  const [checkedList, setCheckedList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const [orderList, setOrderList] = useState(["chg_type_code", "desc"]); // 筛选条件
  const [upOrderCode, setUpOrderCode] = useState({}); // 上排序选择
  const [downOrderCode, setDownOrderCode] = useState({}); // 下排序选择

  const [DialogDrawerLoading, setDialogDrawerLoading] = useState(true);
  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false); // 新建修改模部署
  const [addNew, setAddNew] = useState(true); // 新增修改部署判断
  const [deployModelList, setDeployModelList] = useState([]); // 需要部署模型信息
  const [deployedModelInfo, setDeployedModelInfo] = useState({}); //模型部署详情查询

  // 变更类型
  const changeType = dicCodeInfoList?.DG_CHG_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  // 模型查询
  const getModeldataList = () => {
    let params = {
      page: pageNumber,
      size: pageSize,
      order_list: orderList.toString(),
      desg_stat_code: "TO_BE_DEPLOYED",
      // desg_stat_code: "RELEASED",
    };

    if (changeTypeCode !== "all") {
      params.chg_type_code = changeTypeCode;
    }
    if (newModeldataName !== "") {
      params.model_name = newModeldataName;
    }
    projectApi.getModeldataList(params).then((res) => {
      if (res.code === 200) {
        setModelList(res.data.items);
        setTotal(res.data.total);
        setPlainOptionList(res.data.items);
      } else {
        setPlainOptionList([]);
        setModelList([]);
        setTotal(0);
      }
    });
  };

  // 模型数据查询
  useEffect(() => {
    setLoading(true);
    getModeldataList();
  }, [changeTypeCode, newModeldataName, orderList, pageNumber, pageSize]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    // 清除定时器
    return () => clearInterval(timer);
  }, [modelList]);

  // 分页
  const pageChange = (page, size) => {
    setPageNumber(page);
    setPageSize(size);
  };
  // 排序 正序
  const sortOrder = (item, id) => {
    setOrderList([item.key, id]);
    setPageNumber(1);
  };

  // 全选反选
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptionList : []);
    setIndeterminate(false);
    setChecked(e.target.checked ? true : false);
    setCheckAll(e.target.checked);
  };

  // 反选
  const checkChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptionList.length);
    setCheckAll(list.length === plainOptionList.length);
  };

  // 终止部署
  const ceaseDeployment = (value) => {
    MessageModal({
      type: "warning",
      description: "确认要终止部署吗？",
      message: "模型需要重新提交评审才能再次部署",
      title: "终止部署",
      okText: "终止部署",
      onOk: () => {
        projectApi.undeployModel(value).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            setLoading(true);
            getModeldataList();
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 模型部署详情查询
  const deployedModel = (id) => {
    projectApi.deployedModel(id).then((res) => {
      if (res.code === 200) {
        if (res.data !== null) {
          setDeployedModelInfo(res.data);
        }
      } else {
        setDeployedModelInfo({});
      }
    });
  };

  useEffect(() => {
    if (DialogDrawerVisible === false) setDeployedModelInfo({});
  }, [DialogDrawerVisible]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDialogDrawerLoading(false);
    }, 500);
    // 清除定时器
    return () => clearInterval(timer);
  }, [deployedModelInfo]);

  return (
    <div className="ModelApplyContent">
      {DialogDrawerVisible ? (
        <DialogDrawer
          loading={DialogDrawerLoading}
          DialogDrawerVisible={DialogDrawerVisible}
          setDialogDrawerVisible={setDialogDrawerVisible}
          addNew={addNew}
          deployModelList={deployModelList}
          setDeployModelList={setDeployModelList}
          getModeldataList={getModeldataList}
          deployedModelInfo={deployedModelInfo}
        ></DialogDrawer>
      ) : (
        <>
          {/* 检索区域 */}
          <div className="topBox">
            <div className="searchItem">
              <div className="label">变更类型 :</div>
              <div className="optionsBox">
                <span
                  style={{
                    marginRight: "12px",
                    cursor: "pointer",
                    padding: "0 7px",
                    backgroundColor:
                      "all" === changeTypeCode ? "#006AB2" : null,
                    color: "all" === changeTypeCode ? "#fff" : null,
                  }}
                  onClick={() => setChangeTypeCode("all")}
                >
                  全部
                </span>
                {changeType?.map((item, index) => (
                  <div
                    key={index}
                    className="optionItem"
                    onClick={() => setChangeTypeCode(item)}
                    style={{
                      backgroundColor:
                        item === changeTypeCode ? "#006AB2" : null,
                      color: item === changeTypeCode ? "#fff" : null,
                    }}
                  >
                    {dicCodeInfoList?.DG_CHG_TYPE?.map((value) => value[item])}
                  </div>
                ))}
              </div>
            </div>
            <div className="searchItem">
              <div className="label">模型名称 :</div>
              <div className="optionsBox">
                <Input
                  bordered={false}
                  allowClear
                  style={{
                    borderBottom: "1px solid #999999",
                    width: "280px",
                  }}
                  value={modeldataName}
                  onChange={(e) => {
                    setModeldataName(e.target.value);
                    if (e.target.value === "") setNewModeldataName("");
                  }}
                  onPressEnter={(e) => {
                    setNewModeldataName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div style={{ color: "#006AB2" }} className="filterMore">
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    if (checkedList.length === 0) {
                      message.info("请选择需要部署的模型");
                    } else {
                      setDeployModelList(checkedList);
                      setDialogDrawerVisible(true);
                    }
                  }}
                >
                  批量部署
                </Button>
                <Button
                  type="primary"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    if (checkedList.length === 0) {
                      message.info("请选择需要终止部署的模型");
                    } else {
                      const data = checkedList?.map((item) => item.table_id);
                      ceaseDeployment(data);
                    }
                  }}
                >
                  批量终止部署
                </Button>
              </div>
            </div>
          </div>
          {/* 排序 */}
          <div className="filterItem">
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
              style={{ marginTop: "4px" }}
            >
              全选
            </Checkbox>
            {orderDataList.map((item) => (
              <div key={item.key} className="optionItem">
                {item.label}
                <div className="iconBox">
                  <CaretUpOutlined
                    className="upIcon"
                    onClick={() => {
                      sortOrder(item, "asc");
                      setUpOrderCode(item);
                      setDownOrderCode({});
                    }}
                    style={{ color: item === upOrderCode ? "#0068B2" : null }}
                  />
                  <CaretDownOutlined
                    className="downIcon"
                    onClick={() => {
                      sortOrder(item, "desc");
                      setDownOrderCode(item);
                      setUpOrderCode({});
                    }}
                    style={{ color: item === downOrderCode ? "#0068B2" : null }}
                  />
                </div>
              </div>
            ))}
          </div>
          {/* 展示 */}
          <div className="cardBox">
            <Scrollbars
              autoHide={true}
              autoHideTimeout={5000}
              style={{ height: "100%", width: "100%" }}
            >
              <CheckboxGroup
                onChange={checkChange}
                value={checkedList}
                style={{ width: "100%" }}
              >
                {loading ? (
                  <MySpin></MySpin>
                ) : modelList?.length > 0 ? (
                  modelList?.map((item, index) => (
                    <MetaApplyCard
                      key={index}
                      plainOptionList={plainOptionList}
                      modelInfo={item}
                      setDialogDrawerVisible={setDialogDrawerVisible}
                      setDeployModelList={setDeployModelList}
                      ceaseDeployment={ceaseDeployment}
                      deployedModel={deployedModel}
                    ></MetaApplyCard>
                  ))
                ) : (
                  <div className="noData">
                    <Empty></Empty>
                  </div>
                )}
              </CheckboxGroup>
            </Scrollbars>
          </div>
          {loading ? null : total > 10 ? (
            <div className="paginationBox">
              <Pagination
                onChange={pageChange}
                className="myPagination"
                pageSize={pageSize}
                current={pageNumber}
                total={total}
                showTotal={(total) => `共 ${total} 条`}
                showSizeChanger={true}
                showQuickJumper
                pageSizeOptions={[10, 20, 50, 100]}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
export default ModelApply;
