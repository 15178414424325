import "./index.scss";
import { Table, Badge, message, Pagination } from "antd";
import { useRef, useState, useEffect, forwardRef } from "react";
import MyEmpty from "../../../../../components/Empty";

import LoadingSvg from "../../../../../assets/loading.svg";
import { ReactSVG } from "react-svg";
import ImportDrawer from "../ImportDrawer";
import projectApi from "../../../../../apis/project";
import downloadApi from "../../../../../apis/project/download.js";
import { saveBlob } from "../../../../../unit/unit";
import DetailModal from "../DetailModal";
const DataTable = (props, ref) => {
  const {
    defaultData,
    changePagination,
    changeOrders,
    taskCheckRun, // 运行
    tableLoading,
    gettaskCheckList,
    ManagementList,
  } = props;

  const refTable = useRef(null);

  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [importDialogVisible, setImportDialogVisible] = useState(false); // 导入
  const [fileList, setFileList] = useState([]); //导入列表
  const [importFile, setImportFile] = useState(false); // 正在进行导入操作判断
  const [ruleId, setRuleId] = useState("");
  const [compareTypeList, setCompareTypeList] = useState([]);

  const flag = useRef(true);
  // 状态颜色切换
  const changeColor = (value) => {
    if (value === "success") {
      return "#61D4AD";
    } else if (value === "running") {
      return "rgb(254, 192, 84)";
    } else {
      return "#FE545F";
    }
  };

  const changeTXT = (value) => {
    if (value === "success") {
      return "成功";
    } else if (value === "running") {
      return "运行中";
    } else {
      return "失败";
    }
  };

  useEffect(() => {
    setColumns([
      {
        title: "规则ID",
        dataIndex: "rule_id",
      },
      {
        title: "创建人",
        dataIndex: "created_by_id",
        width: "8%",
        render: (data, info) => {
          return (
            <span>
              {
                ManagementList?.find(
                  (item) => item?.user_id === info.created_by_id
                )?.username
              }
            </span>
          );
        },
      },
      {
        title: "执行内容",
        dataIndex: "execute_content",
      },

      {
        title: "最新运行状态",
        dataIndex: "last_run_status",
        width: "8%",
        render: (data, info) => {
          return (
            <span>
              <Badge color={changeColor(info.last_run_status)} />
              {changeTXT(info.last_run_status)}
            </span>
          );
        },
      },
      {
        title: "最新上传时间",
        dataIndex: "last_update_time",
        width: "11%",
        sorter: true,
      },
      {
        title: "最新运行时间",
        dataIndex: "last_execution_time",
        width: "11%",
        sorter: true,
        defaultSortOrder: "desc",
      },
      {
        title: "对比结果差异",
        dataIndex: "is_diff",
        width: "8%",
        render: (data, info) => {
          return (
            <span>
              <Badge
                color={
                  info.is_diff !== null
                    ? info.is_diff
                      ? "#FE545F"
                      : "#61D4AD"
                    : ""
                }
              />
              {info.is_diff !== null ? (info.is_diff ? "是" : "否") : ""}
            </span>
          );
        },
      },
      {
        title: "操作",
        width: "13%",
        dataIndex: "操作",
        render: (_, record) => {
          return (
            <div className="operation">
              <span
                style={{
                  color:
                    record.last_run_status === "running"
                      ? "#B5B5B5"
                      : "#006AB2",
                  cursor:
                    record.last_run_status !== "running"
                      ? "pointer"
                      : "not-allowed",
                }}
                onClick={() => {
                  if (record.last_run_status !== "running") {
                    taskCheckRun(record.rule_id);
                  }
                }}
              >
                运行
              </span>
              <span
                className="derive"
                style={{
                  color: "#006AB2",
                }}
                onClick={() => {
                  if (importFile) {
                    message.info("正在导入中，请勿重复操作!");
                  } else {
                    const id = record?.rule_id.split("_")[1];
                    setImportDialogVisible(true);
                    setRuleId(id);
                    setFileList([]);
                  }
                }}
              >
                导入
              </span>
              <span
                className="derive"
                style={{
                  color: "#006AB2",
                }}
                onClick={() => {
                  getTaskCheckType(record);
                }}
              >
                查看详情
              </span>
            </div>
          );
        },
      },
    ]);

    setTotal(defaultData?.total);
    setPageNumber(defaultData?.page);
    setPageSize(defaultData?.size);
  }, [defaultData]);

  const getTaskCheckType = async (record) => {
    if (flag.current) {
      flag.current = false;
      let params = {
        rule_id: record.rule_id,
      };

      try {
        const res = await projectApi.getTaskCheckType(params);
        const compareTypeLists = res.code === 200 ? res.data : [];

        DetailModal({
          title: "数据详情",
          DetailDrawer: record,
          ManagementList: ManagementList,
          compareTypeLists: compareTypeLists,
        });

        setCompareTypeList(compareTypeLists);
      } catch (error) {
        console.error("Error fetching task check type:", error);
        DetailModal({
          title: "数据详情",
          DetailDrawer: record,
          ManagementList: ManagementList,
          compareTypeLists: [],
        });
        setCompareTypeList([]);
      }
      setTimeout(() => {
        flag.current = true;
      }, 4000);
    }
  };

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  // 改变表格排序
  const tableChange = (pagination, filters, sorter, extra) => {
    if (sorter.order !== undefined) {
      changeOrders(sorter);
    }
  };

  // 导入数据元
  const uploadFileSuccess = (files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file?.originFileObj);
    });
    projectApi
      .importTaskCheck(formData, ruleId)
      .then((res) => {
        if (res.code === 200) {
          setRuleId("");
          setImportFile(false);
          gettaskCheckList();
        } else {
          message.error(res.message);
        }
      })
      .finally(() => {
        setImportDialogVisible(false);
        setFileList([]);
      });
  };

  const DownloadTaskCheckFile = (id) => {
    downloadApi.DownloadTaskCheckFile([id]).then((blob) => {
      const filename = blob?.headers["content-disposition"]
        .split(";")[1]
        .trim()
        .split("=")[1];
      const decodedFilename = decodeURIComponent(filename);
      saveBlob(blob.data, {
        fileName: decodedFilename,
        type: blob.headers["content-type"],
      });
    });
  };

  return (
    <div className="DataTable">
      <div className="tableContent" ref={refTable}>
        <Table
          className="myFormTable"
          loading={
            tableLoading
              ? {
                  indicator: (
                    <div style={{ background: "#fff" }}>
                      <ReactSVG
                        src={LoadingSvg}
                        className="anticon-spin anticon myLoadingIcon"
                      />
                    </div>
                  ),
                }
              : false
          }
          // rowSelection={rowSelection}
          pagination={false}
          onChange={tableChange}
          columns={columns}
          dataSource={defaultData.items}
          rowKey={(record) => {
            return record?.rule_id;
          }}
          scroll={{
            y: "calc(100vh - 370px)",
          }}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
        />
        {total >= 10 ? (
          <div className="paginationBox">
            <Pagination
              className="myPagination"
              showQuickJumper
              current={pageNumber}
              total={total}
              pageSize={pageSize}
              pageSizeOptions={[10, 20, 50, 100]}
              onChange={onChange}
              showTotal={(total) => `共 ${total} 条`}
              showSizeChanger={true}
            />
          </div>
        ) : null}
      </div>
      <ImportDrawer
        okText="导入"
        onTitle="导入对比数据"
        cancelText="取消"
        visible={importDialogVisible}
        onCancel={() => {
          setImportDialogVisible(false);
          setFileList([]);
          setRuleId("");
        }}
        successFunc={uploadFileSuccess}
        fileList={fileList}
        setFileList={setFileList}
        setImportFile={setImportFile}
      ></ImportDrawer>
    </div>
  );
};

export default forwardRef(DataTable);
