import Cookies from "js-cookie";

const TokenKey = "Cdd-Token";
const RedirectUrlKey = "Cdd-RedirectUrl";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, {
    expires: new Date(new Date().getTime() + 23 * 60 * 60 * 1000),
  });
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function setRedirectUrl(redirectUrl) {
  return Cookies.set(RedirectUrlKey, redirectUrl);
}

export function getRedirectUrl() {
  return Cookies.get(RedirectUrlKey);
}

export function removeRedirectUrl() {
  return Cookies.remove(RedirectUrlKey);
}
