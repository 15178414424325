import { getToken } from "./index.js";
// 防抖函数
export function debounce(func, delay, ...arg) {
  let timer = null;
  return function (...args) {
    // 取消已有的定时器（其实可以理解为，取消了上一次的函数调用）
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, [...args, ...arg]);
    }, delay);
  };
}

//创建唯一id方法
export const guid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

//提取汉字
function GetChinese(strValue) {
  if (strValue !== null && strValue !== "") {
    const reg = /[\u4e00-\u9fa5]/g;
    if (strValue.match(reg)) {
      return strValue.match(reg).join("");
    }
  }
  return "";
}

//加千分位符
function comdify(n) {
  var re = /\d{1,3}(?=(\d{3})+$)/g;
  var n1 = n.replace(/^(\d+)((\.\d+)?)$/, function (s, s1, s2) {
    return s1.replace(re, "$&,") + s2;
  });
  return n1;
}

//
function umberFormats(num) {
  if (num) {
    let nums = Number(num).toFixed(2);
    let newNum = Number(nums).toLocaleString("en-US"); // 数字转成千分位 = 123,456.78 有个弊端最多能保留三位小数
    const numArr = newNum.split("."); // 按小数点吧数字拆分 = [123,456, 78]
    if (!numArr[1]) {
      // 如果数组没有下标1的元素，就加.00，例：123,456 = 123,456.00
      newNum = newNum + ".00";
    } else if (numArr[1].length === 1) {
      // 如果数组下标1的元素只有一位小数，就加个0，例：123,456.7 = 123,456.70
      newNum = newNum + "0";
    } else if (numArr[1].length >= 2) {
      // // 如果数组下标1的元素小数位大于等于2位，就截取前两位，例：123,456.789 = 123,456.78
      newNum = numArr[0] + "." + numArr[1].slice(0, 2);
    }
    return newNum;
  } else {
    return "0.00";
  }
}

function amountFormats(num) {
  if (num) {
    let nums = Number(num) / 10000;
    let newNum = comdify(nums.toString()); // 数字转成千分位 = 123,456.78
    const numArr = newNum.split("."); // 按小数点吧数字拆分 = [123,456, 78]
    if (!numArr[1]) {
      // 如果数组没有下标1的元素
      newNum = newNum + "万";
    } else {
      newNum = Number(num).toLocaleString("en-US");
    }
    return newNum;
  } else {
    return "0";
  }
}
//带单位的金额字符串加千分位符
export const addMillimeter = (str, digit) => {
  let num = parseFloat(str);
  let chinese = GetChinese(str);
  num = umberFormats(num);
  return num + chinese;
};

//带单位的金额字符串加千分位符并转成万.
export const addMillimeterAndInit = (str, digit) => {
  let num = parseFloat(str);
  let chinese = GetChinese(str);
  num = amountFormats(num);
  return num + chinese;
};

//判断2个数组是否相等
export function ArrayIsEqual(arr1, arr2) {
  if (arr1 === arr2) {
    //如果2个数组对应的指针相同，那么肯定相等，同时也对比一下类型
    return true;
  } else {
    if (arr1.length != arr2.length) {
      return false;
    } else {
      //长度相同
      for (let i in arr1) {
        //循环遍历对比每个位置的元素
        if (arr1[i] != arr2[i]) {
          //只要出现一次不相等，那么2个数组就不相等
          return false;
        }
      } //for循环完成，没有出现不相等的情况，那么2个数组相等
      return true;
    }
  }
}

//判断小数位是否超过给定的位数
export function isExceedDigit(num, digit) {
  let flag = false;
  const numArr = num.toString().split("."); // 按小数点吧数字拆分 = [123456, 78]
  if (numArr[1] && numArr[1]?.length > digit) {
    flag = true;
  }
  return flag;
}

//下载流文件
export function saveBlob(data, options) {
  return new Promise((resolve, reject) => {
    try {
      const blob = new Blob([data], { type: options.type });
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, options.fileName);
        resolve();
      } else {
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = options.fileName;

        link.style.display = "none";
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(link.href);
        resolve();
      }
    } catch (error) {
      reject(error);
    }
  });
}

//数组 去重 keys为多个条件
export function uniqueArray(array, keys) {
  array = array.reduce((pre, next) => {
    let has = pre.some((item) => {
      let flag = true;
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (item[key] !== next[key]) {
          flag = false;
        }
      }
      return flag;
    });
    if (!has) {
      pre.push(next);
    }
    return pre;
  }, []);
  return array;
}
//下载文件
export function downloadFile(url, project_id) {
  const token = getToken();
  var hiddenIFrameID = "hiddenDownloader" + guid();
  var iframe = document.createElement("iframe");
  iframe.id = hiddenIFrameID;
  iframe.style.display = "none";
  iframe.src = `${
    process.env.REACT_APP_BASE_URL
  }pm/info/rpt/download_file?path=${url}&token=${token}&key=${guid()}&project_id=${project_id}`;
  document.body.appendChild(iframe);
  setTimeout(() => {
    iframe.remove();
  }, 30 * 1000);
}
