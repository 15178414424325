import "./index.scss";
import { Table, Badge } from "antd";
import { ReactSVG } from "react-svg";
import MyEmpty from "../../../../../components/Empty";
import LoadingSvg from "../../../../../assets/loading.svg";

const ApplyTable = (props) => {
  const { defaultData, cancelService, tableLoading } = props;

  // 审核状态颜色切换
  const changeColor = (value) => {
    if (value === "IN_APPROVAL") {
      return "#FEC054";
    } else if (value === "APPROVED") {
      return "#61D4AD";
    } else if (value === "REJECTED") {
      return "#FE545F";
    }
  };

  const columsSource = [
    {
      title: "服务名称",
      dataIndex: "chi_name",
    },
    {
      title: "英文名称",
      dataIndex: "eng_name",
    },
    {
      title: "服务描述",
      dataIndex: "api_pkg_desc",
    },
    {
      title: "服务开通状态",
      dataIndex: "ord_stat_code",
      render: (data, info) => {
        return (
          <span>
            <Badge color={changeColor(info.ord_stat_code)} />
            {info.ord_stat_code_value}
          </span>
        );
      },
    },
    {
      title: "创建人",
      dataIndex: "apply_by_id_value",
    },
    {
      title: "服务创建时间",
      dataIndex: "apply_time",
    },
    {
      title: "更新人",
      dataIndex: "apprd_by_id_value",
    },
    {
      title: "服务更新时间",
      dataIndex: "apprd_time",
    },
    {
      title: "操作",
      width: "7%",
      dataIndex: "操作",
      render: (_, record) => {
        return (
          <div className="operation" key={record.key}>
            <span
              style={{
                color: "#006AB2",
                marginRight: "16px",
              }}
              onClick={() => cancelService(record)}
            >
              取消服务
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div className="ApplyTable">
      <div className="tableContent">
        <Table
          className="myFormTable"
          pagination={false}
          columns={columsSource}
          dataSource={defaultData}
          rowKey={(record) => record?.ord_id}
          scroll={{
            y: "calc(60vh - 100px)",
          }}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
          style={{ marginBottom: "8px" }}
          loading={
            tableLoading
              ? {
                  indicator: (
                    <ReactSVG
                      src={LoadingSvg}
                      className="anticon-spin anticon myLoadingIcon"
                    />
                  ),
                }
              : false
          }
        />
      </div>
    </div>
  );
};

export default ApplyTable;
