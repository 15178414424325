import "./index.scss";
import { Checkbox, Badge, Tooltip } from "antd";
import {
  DeleteOutlined,
  FormOutlined,
  RollbackOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import redIcon from "../../../../../assets/表红.svg";
import deployIcon from "../../../../../assets/部署蓝.svg";
import { useSelector } from "react-redux";

const MetaApplyCard = (props) => {
  const {
    modelInfo, // 默认值
    setDialogDrawerVisible, // 模型部署
    setDeployModelList, // 需要部署的模型信息
    ceaseDeployment, // 终止部署
    deployedModel, // 模型部署详情查询
  } = props;
  // 数据分层主题域映射
  const layerSubList = useSelector((state) => state.Project.layerSubList);

  return (
    <div className="cardItem">
      <div className="leftItem">
        <Checkbox value={modelInfo}></Checkbox>
      </div>
      <div className="imgItem">
        <img
          src={redIcon}
          className="infoImg"
          style={{ width: "56px", height: "56px" }}
        ></img>
      </div>
      <div className="rightItem">
        <div className="title">
          <span>{`${modelInfo?.table_cmnt} / ${modelInfo?.table_name}`}</span>
        </div>
        <div className="webContent">
          <Badge color="#FE545F" />
          待部署
          <span className="web">{modelInfo?.table_name}</span>
        </div>
        <div className="detailContent">
          {/* <span className="label">元数据类型</span>
          <span className="value" style={{ width: "60px" }}>
            表
          </span> */}
          <span className="label">存储类型</span>
          <span className="value" style={{ width: "110px" }}>
            {modelInfo?.db_type_code_value}
          </span>
          {Object.values(layerSubList?.DATA_LAYER_RLS).find(
            (item) => item?.data_layer_id === modelInfo?.data_layer_id
          ) !== undefined ? (
            <>
              <span className="label">数据分层</span>
              <span className="value" style={{ width: "100px" }}>
                {Object.values(modelInfo).length !== 0
                  ? Object.values(layerSubList?.DATA_LAYER_RLS).find(
                      (item) => item?.data_layer_id === modelInfo?.data_layer_id
                    )?.data_layer_name
                  : null}
              </span>

              <span className="label">主题域</span>
              <Tooltip
                title={
                  Object.values(modelInfo).length !== 0
                    ? Object.values(layerSubList?.SUB_RLS).find(
                        (item) => item?.sub_id === modelInfo?.sub_id
                      )?.sub_name
                    : null
                }
                overlayClassName="sharesTip"
              >
                <span className="value" style={{ marginLeft: "-15px" }}>
                  {Object.values(modelInfo).length !== 0
                    ? Object.values(layerSubList?.SUB_RLS).find(
                        (item) => item?.sub_id === modelInfo?.sub_id
                      )?.sub_name
                    : null}
                </span>
              </Tooltip>
            </>
          ) : null}
          <span className="label">当前版本</span>
          <span className="value" style={{ width: "60px" }}>
            {modelInfo?.ver_no}
          </span>
          <span className="label">创建人</span>
          <span
            className="value"
            style={{ width: "70px", marginLeft: "-15px" }}
          >
            {modelInfo?.created_by_id_value}
          </span>
          <span className="label">创建时间</span>
          <span className="value">{modelInfo?.created_time}</span>
          <span className="label">更新时间</span>
          <span className="value">{modelInfo?.updated_time}</span>
        </div>
      </div>
      <div className="iconItem">
        <Tooltip placement="top" title="部署" arrowPointAtCenter>
          <ReactSVG
            src={deployIcon}
            onClick={() => {
              setDialogDrawerVisible(true);
              setDeployModelList([modelInfo]);
              deployedModel(modelInfo?.table_id);
            }}
          ></ReactSVG>
        </Tooltip>
        <Tooltip placement="top" title="终止部署" arrowPointAtCenter>
          <CloseCircleFilled
            style={{ marginLeft: "8px", marginTop: "1.5px" }}
            onClick={() => ceaseDeployment([modelInfo?.table_id])}
          />
        </Tooltip>
      </div>
    </div>
  );
};
export default MetaApplyCard;
