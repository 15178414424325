import "./index.scss";
import { useEffect, useState } from "react";
import { message, Button, Select, Form, Space, Input } from "antd";
import { ReactSVG } from "react-svg";
import ModelStandardTable from "./components/ModelStandardTable";
import Scrollbars from "react-custom-scrollbars";
import DropdownSvg from "../../../assets/下拉.svg";
import projectApi from "../../../apis/project";
import { useSelector } from "react-redux";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";

const { Option } = Select;
const DesignStandard = (props) => {
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);

  // 后缀类型
  const suffixType = dicCodeInfoList?.DG_SUFFIX_RULE?.map((item) =>
    Object.keys(item)
  ).flat();

  // 前缀缩写规则
  const prefixRuleType = dicCodeInfoList?.DG_PREFIX_RULE?.map((item) =>
    Object.keys(item)
  ).flat();

  // 连接字符
  const connectionType = dicCodeInfoList?.DG_CONNECTOR?.map((item) =>
    Object.keys(item)
  ).flat();

  const [ModelTableFlag, setModelTableFlag] = useState(false);
  const [formFlag, setFormFlag] = useState(true);

  const [form] = Form.useForm();

  const onFinish = (value) => {
    setFormFlag(true);
  };
  return (
    <div className="DesignStandardContent">
      {ModelTableFlag ? (
        <ModelStandardTable
          setModelTableFlag={setModelTableFlag}
        ></ModelStandardTable>
      ) : (
        <>
          <Scrollbars
            autoHide={true}
            autoHideTimeout={5000}
            style={{
              height: `100%`,
              width: "100%",
            }}
          >
            <Form
              form={form}
              autoComplete="off"
              className="dialogDrawerForm"
              preserve={false}
              onFinish={onFinish}
            >
              <Form.Item
                label="连接符"
                name="connector_code"
                rules={[
                  {
                    required: true,
                    message: "请选择连接符",
                  },
                ]}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch={true}
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  style={{ width: "368px" }}
                  disabled={formFlag}
                >
                  {connectionType?.map((item) => {
                    return (
                      <Option value={item} key={item}>
                        {dicCodeInfoList?.DG_CONNECTOR?.map(
                          (value) => value[item]
                        )}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="是否强制匹配词根"
                name="frc_match_root_flag"
                rules={[
                  {
                    required: true,
                    message: "请选择是否强制匹配词根",
                  },
                ]}
                style={{ marginLeft: "20px" }}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch={true}
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  style={{ width: "368px" }}
                  disabled={formFlag}
                >
                  <Option value="true">是</Option>
                  <Option value="false">否</Option>
                </Select>
              </Form.Item>

              <Form.List name="names">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <div className="prefixValue">
                      <div>前缀</div>
                      <PlusCircleFilled
                        className="PlusCircleFilled"
                        style={{ color: formFlag ? "#d9d9d9" : "#006ab2" }}
                        onClick={() => {
                          if (formFlag === false) add();
                        }}
                      />
                    </div>

                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} align="baseline">
                        {`${name + 1}`}
                        <Form.Item label="" name={[name, "first"]}>
                          <Select
                            placeholder="请选择"
                            allowClear
                            showSearch={true}
                            suffixIcon={
                              <ReactSVG
                                src={DropdownSvg}
                                style={{ width: "16px", height: "16px" }}
                              ></ReactSVG>
                            }
                            style={{ width: "370px" }}
                            disabled={formFlag}
                          >
                            {prefixRuleType?.map((item) => {
                              return (
                                <Option value={item} key={item}>
                                  {dicCodeInfoList?.DG_PREFIX_RULE?.map(
                                    (value) => value[item]
                                  )}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleFilled
                            onClick={() => remove(name)}
                            style={{
                              color: "#006AB2",
                              fontSize: "18px",
                            }}
                          />
                        ) : null}
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>

              <Form.List name="name">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <div className="prefixValue">
                      <div>后缀</div>
                      <PlusCircleFilled
                        className="PlusCircleFilled"
                        style={{ color: formFlag ? "#d9d9d9" : "#006ab2" }}
                        onClick={() => {
                          if (formFlag === false) add();
                        }}
                      />
                    </div>

                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} align="baseline">
                        {`${name + 1}`}
                        <Form.Item
                          label=""
                          name={[name, "first"]}
                          style={{
                            width: "100%",
                          }}
                        >
                          <Select
                            placeholder="请选择"
                            allowClear
                            showSearch={true}
                            suffixIcon={
                              <ReactSVG
                                src={DropdownSvg}
                                style={{ width: "16px", height: "16px" }}
                              ></ReactSVG>
                            }
                            style={{ width: "370px" }}
                            disabled={formFlag}
                          >
                            {suffixType?.map((item) => {
                              return (
                                <Option value={item} key={item}>
                                  {dicCodeInfoList?.DG_SUFFIX_RULE?.map(
                                    (value) => value[item]
                                  )}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        {fields.length > 1 ? (
                          <MinusCircleFilled
                            onClick={() => remove(name)}
                            style={{
                              color: "#006AB2",
                              fontSize: "18px",
                            }}
                          />
                        ) : null}
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
            </Form>
          </Scrollbars>
          <div className="FooterBtnBox">
            {formFlag ? (
              <>
                <Button onClick={() => setModelTableFlag(true)}>
                  规范性检查
                </Button>
                <Button onClick={() => setFormFlag(false)}>修改</Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => setFormFlag(true)}
                  style={{
                    color: "#535353",
                    backgroundColor: "#fff",
                    borderColor: "#D9D9D9",
                  }}
                >
                  取消
                </Button>
                <Button
                  onClick={() => {
                    form.submit();
                  }}
                >
                  保存
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default DesignStandard;
