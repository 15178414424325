import "./index.scss";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { Drawer, Button, Select, Tag, Badge, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import HeaderTitle from "../../../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import DropdownSvg from "../../../../../assets/下拉.svg";
import flowIcon from "../../../../../assets/流向.svg";
import sourceIcon from "../../../../../assets/来源.svg";
import RelationshipIcon from "../../../../../assets/隶属关系.svg";
import projectApi from "../../../../../apis/project";
import DetailTable from "../DetailTable";
import { useSelector } from "react-redux";
import MySpin from "../../../../../components/MySpin";
const { Option } = Select;
const { TextArea } = Input;

const DetailDrawer = (props) => {
  const {
    /* 关闭触发的回调 */
    onCancel,
    visible,
    /* 初始值 */
    defaultData,
    /* 版本列表 */
    metadataVersoList,
    /* 血缘关系 */
    relationList,
    loading,
    getmetaDataInfo, // 切换版本获取元数据
    chooseList,
    metaDataCode, // 元数据类型选择
    MetadataStorageList, // 数据存储列表
    modelMetaInfo, // 模型id转元数据id以及元数据版本id
    agreeFlag, //判断详情是从模型页面还是审核页面打开
    agreeItem, // 同意审核
    rejectItem, // 拒绝审核
    wfId, // 模型id
  } = props;

  // console.log(defaultData);

  // 数据存储映射名
  const dbName = MetadataStorageList?.find(
    (item) => item.metadata_id === defaultData?.ds_metadata_id
  );

  const [managementResList, setManagementResList] = useState([]);
  const [stateFlag, setStateFlag] = useState(false); // ddl和表切换展示
  const [ddlValue, setDdlValue] = useState(""); // 查看ddl数据

  // 数据分层主题域映射
  const layerSubList = useSelector((state) => state.Project.layerSubList);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  // 获取管理责任人
  const getResponse = () => {
    projectApi
      .getResponsible({
        metadata_type_code: "TABLE",
        data_layer_id: defaultData?.data_layer_id,
        sub_id: defaultData?.sub_id,
      })
      .then((res) => {
        if (res.code === 200) {
          setManagementResList(res.data);
        }
      });
  };
  useEffect(() => {
    setStateFlag(false);
    if (visible) {
      getResponse();
    }
  }, [defaultData]);

  // 查看DDL信息
  const tableDefineDdl = (id) => {
    projectApi.tableDefineDdl(id).then((res) => {
      if (res.code === 200) {
        setDdlValue(res.data);
      } else {
        setDdlValue("");
      }
    });
  };

  const titleTypeBgc = (data) => {
    if (data === "NEW") {
      return "#FEC054";
    }
    if (data === "UPDATE") {
      return "#006AB2";
    }
    if (data === "DISCARD") {
      return "#FE545F";
    }
  };

  return (
    <Drawer
      title="查看元数据"
      placement="right"
      width="600px"
      className="ModelDesignDetailDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          {agreeFlag ? (
            <>
              <Button
                className="noBg"
                onClick={() => {
                  agreeItem([wfId]);
                }}
                style={{
                  background: "#016DB4",
                  color: "#fff",
                  borderColor: "#016DB4",
                }}
              >
                同意
              </Button>
              <Button
                className="noBg"
                onClick={() => {
                  rejectItem([wfId]);
                }}
                style={{
                  background: "#FE545F",
                  color: "#fff",
                  borderColor: "#FE545F",
                }}
              >
                驳回
              </Button>
            </>
          ) : (
            <Button
              className="noBg"
              onClick={() => {
                handleCancel();
              }}
            >
              关闭
            </Button>
          )}
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <div className="detailsContent">
          {loading ? (
            <MySpin></MySpin>
          ) : (
            <>
              <HeaderTitle
                title="基本信息"
                extra={
                  <>
                    <div className="modelStateInfo">
                      <span>
                        {defaultData?.desg_stat_code === "IN_DESIGN" ? (
                          <>
                            <Badge color="#6CAEFF" />
                            设计中
                          </>
                        ) : null}

                        {defaultData?.desg_stat_code === "IN_APPROVAL" ? (
                          <>
                            <Badge color="#FEC054" />
                            审核中
                          </>
                        ) : null}
                        {defaultData?.desg_stat_code === "TO_BE_DEPLOYED" ? (
                          <>
                            <Badge color="#FE545F" />
                            待部署
                          </>
                        ) : null}
                        {defaultData?.desg_stat_code === "RELEASED" ? (
                          <>
                            <Badge color="#61D4AD" />
                            已发布
                          </>
                        ) : null}
                        {defaultData?.desg_stat_code === "DISCARDED" ? (
                          <>
                            <Badge color="#D8D8D8" />
                            已废弃
                          </>
                        ) : null}
                      </span>
                      <span
                        className="metaState"
                        style={{
                          background: titleTypeBgc(defaultData?.chg_type_code),
                        }}
                      >
                        {defaultData?.chg_type_code_value}
                      </span>
                    </div>
                    <span className="titleVenson">版本</span>
                    <Select
                      placeholder="请选择"
                      showSearch={true}
                      labelInValue={true}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      defaultValue={defaultData?.ver_no}
                      style={{ width: "128px" }}
                      onChange={(e) =>
                        getmetaDataInfo({ metadata_ver_id: e.key })
                      }
                    >
                      {metadataVersoList?.map((item) => {
                        return (
                          <Option
                            value={item.ver_no}
                            key={item.metadata_ver_id}
                          >
                            {item.ver_no}
                          </Option>
                        );
                      })}
                    </Select>
                  </>
                }
              ></HeaderTitle>
              <div className="detailsBox">
                <div className="detailItem">
                  <div className="detailKey">元数据类型</div>
                  <div className="detailValue">表</div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">中文名称</div>
                  <div className="detailValue">{defaultData?.chi_name}</div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">英文名称 </div>
                  <div className="detailValue">{defaultData?.eng_name}</div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">数据分层 </div>
                  <div className="detailValue">
                    {Object.values(defaultData).length !== 0
                      ? Object.values(layerSubList?.DATA_LAYER_RLS).find(
                          (item) =>
                            item?.data_layer_id === defaultData?.data_layer_id
                        ).data_layer_name
                      : null}
                  </div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">主题域 </div>
                  <div className="detailValue">
                    {Object.values(defaultData).length !== 0
                      ? Object.values(layerSubList?.SUB_RLS).find(
                          (item) => item?.sub_id === defaultData?.sub_id
                        ).sub_name
                      : null}
                  </div>
                </div>

                <>
                  <div className="detailItem">
                    <div className="detailKey">存储类型 </div>
                    <div className="detailValue">
                      {defaultData?.db_type_code_value}
                    </div>
                  </div>
                </>

                <div className="detailItem">
                  <div className="detailKey">描述 </div>
                  <div className="detailValue">
                    {defaultData?.metadata_desc}
                  </div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">标签 </div>
                  <div className="detailValue">
                    {chooseList?.map((item, index) => (
                      <Tag key={index}>{item.tag_name}</Tag>
                    ))}
                  </div>
                </div>
              </div>

              <HeaderTitle title="技术信息"></HeaderTitle>

              <div className="detailsBox">
                <div className="detailItem">
                  <div className="detailKey">数据存储</div>
                  <div className="detailValue">
                    {`${dbName?.chi_name} / ${dbName?.eng_name}`}
                  </div>
                </div>

                <div
                  className="detailItem"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="detailKey">数据定义 </div>
                  <div className="detailValue" style={{ width: "80px" }}>
                    {stateFlag ? (
                      <Button
                        className="checkBtn btn"
                        onClick={() => setStateFlag(false)}
                      >
                        查看表定义
                      </Button>
                    ) : (
                      <Button
                        className="checkBtn btn"
                        onClick={() => {
                          setStateFlag(true);
                          tableDefineDdl(defaultData?.metadata_id);
                        }}
                      >
                        查看DDL
                      </Button>
                    )}
                  </div>
                </div>
                {stateFlag === false ? (
                  <div className="detailItem">
                    <DetailTable
                      flag={true}
                      dbTableList={defaultData?.table_info}
                    ></DetailTable>
                  </div>
                ) : (
                  <TextArea
                    // allowClear
                    style={{
                      margin: "10px auto auto 45px",
                      width: "545px",
                      color: "#006AB2",
                    }}
                    autoSize={{ minRows: 15 }}
                    value={ddlValue}
                  />
                )}

                {/* 血缘关系 */}
                {metaDataCode !== "DS" ? (
                  <div className="detailItem">
                    <div className="detailKey">血缘关系</div>
                    <div className="detailValue">
                      {relationList?.map((item, index) => (
                        <div className="relationshipDetail" key={index}>
                          <ReactSVG
                            src={
                              item.cons_type_code === "SOURCE"
                                ? sourceIcon
                                : flowIcon
                            }
                            className="sourceIcon"
                          ></ReactSVG>
                          {`${item.chi_name} / ${item.eng_name}`}
                        </div>
                      ))}
                    </div>
                    {/* <ReactSVG
                      src={RelationshipIcon}
                      style={{ marginRight: "8px" }}
                    ></ReactSVG> */}
                  </div>
                ) : null}
              </div>
              <HeaderTitle title="管理信息"></HeaderTitle>
              <div className="detailsBox">
                <div className="detailItem">
                  <div className="detailKey">管理责任人</div>
                  <div className="detailValue">
                    {managementResList?.da_rp_id_value ||
                      managementResList?.def_da_rp_id_value}
                  </div>
                </div>
                <div className="detailItem managementInfo">
                  <div className="detailKey">创建人</div>
                  <div className="detailValue">
                    {defaultData?.created_by_id_value}
                  </div>
                </div>
                <div className="detailItem managementInfo">
                  <div className="detailKey">创建时间</div>
                  <div className="detailValue">{defaultData?.created_time}</div>
                </div>
                <div className="detailItem managementInfo">
                  <div className="detailKey">更新人</div>
                  <div className="detailValue">
                    {defaultData?.updated_by_id_value}
                  </div>
                </div>
                <div className="detailItem managementInfo">
                  <div className="detailKey">更新时间</div>
                  <div className="detailValue">{defaultData?.updated_time}</div>
                </div>
              </div>
            </>
          )}
        </div>
      </Scrollbars>
    </Drawer>
  );
};

export default DetailDrawer;
