import "./index.scss";
import { Table, Pagination, Badge } from "antd";
import { useRef, useState, useEffect, forwardRef } from "react";
import MyEmpty from "../../../../../components/Empty";
import MySpin from "../../../../../components/MySpin";
import LoadingSvg from "../../../../../assets/loading.svg";
import { ReactSVG } from "react-svg";
const ServiceOrderTable = (props) => {
  const {
    defaultData,
    tableName,
    changePagination,
    changeOrders,
    tableLoading,
    agreeItem, // 同意
    rejectItem, // 驳回
    recallItem, // 强制取消服务
  } = props;

  const refTable = useRef(null);
  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  // const [loading, setLoading] = useState(true);

  const data = defaultData?.items;

  // 审核状态颜色切换
  const changeColor = (value) => {
    if (value === "IN_APPROVAL") {
      return "#FEC054";
    } else if (value === "APPROVED") {
      return "#61D4AD";
    } else if (value === "REJECTED") {
      return "#FE545F";
    }
  };

  useEffect(() => {
    if (tableName === "ToCheck") {
      setColumns([
        {
          title: "申请人账号",
          dataIndex: "apply_by_id",
          sorter: true,
        },
        {
          title: "申请人名称",
          dataIndex: "apply_by_id_value",
          sorter: true,
        },
        {
          title: "API服务包",
          dataIndex: "chi_name",
          sorter: true,
        },
        {
          title: "申请时间",
          dataIndex: "apply_time",
          sorter: true,
          width: "15%",
        },
        {
          title: "申请原因",
          dataIndex: "apply_purp",
          width: "20%",
        },
        {
          title: "操作",
          width: "12%",
          dataIndex: "操作",
          render: (_, record) => {
            return (
              <div className="operation" key={record.key}>
                <span
                  style={{
                    color: "#006AB2",
                    marginRight: "16px",
                  }}
                  onClick={() => agreeItem(record?.ord_id)}
                >
                  同意
                </span>
                <span
                  style={{
                    color: "#006AB2",
                  }}
                  onClick={() => rejectItem(record?.ord_id)}
                >
                  拒绝
                </span>
              </div>
            );
          },
        },
      ]);
    } else if (tableName === "check") {
      setColumns([
        {
          title: "申请人账号",
          dataIndex: "apply_by_id",
          sorter: true,
        },
        {
          title: "申请人名称",
          dataIndex: "apply_by_id_value",
          sorter: true,
        },
        {
          title: "API服务包",
          dataIndex: "chi_name",
          sorter: true,
        },
        {
          title: "申请时间",
          dataIndex: "apply_time",
          sorter: true,
          width: "8%",
        },
        {
          title: "申请原因",
          dataIndex: "apply_purp",
          width: "15%",
        },
        {
          title: "审核状态",
          dataIndex: "appr_stat_code",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.appr_stat_code)} />
                {info.appr_stat_code_value}
              </span>
            );
          },
        },
        {
          title: "审核人",
          dataIndex: "apprd_by_id_value",
        },
        {
          title: "审核时间",
          dataIndex: "apprd_time",
          sorter: true,
          width: "8%",
        },
        {
          title: "审核意见",
          dataIndex: "apprd_opn",
        },
      ]);
    } else if (tableName === "allOrder") {
      setColumns([
        {
          title: "申请人账号",
          dataIndex: "apply_by_id",
          sorter: true,
        },
        {
          title: "申请人名称",
          dataIndex: "apply_by_id_value",
          sorter: true,
        },
        {
          title: "API服务包",
          dataIndex: "chi_name",
          sorter: true,
        },
        {
          title: "订单状态",
          dataIndex: "ord_stat_code",
          sorter: true,
          render: (data, info) => {
            return (
              <span>
                <Badge color={changeColor(info.ord_stat_code)} />
                {info.ord_stat_code_value}
              </span>
            );
          },
        },
        {
          title: "申请时间",
          dataIndex: "apply_time",
          sorter: true,
          width: "8%",
        },
        {
          title: "审核人",
          dataIndex: "apprd_by_id_value",
        },
        {
          title: "开通/审核时间",
          dataIndex: "apprd_time",
          sorter: true,
          width: "8%",
        },
        {
          title: "取消人",
          dataIndex: "canl_by_id_value",
        },
        {
          title: "取消时间",
          dataIndex: "canl_time",
          sorter: true,
          width: "8%",
        },
        // {
        //   title: "操作",
        //   width: "10%",
        //   dataIndex: "操作",
        //   render: (_, record) => {
        //     return (
        //       <div className="operation" key={record.key}>
        //         {record?.ord_stat_code === "EFFECTIVE" ? (
        //           <span
        //             style={{
        //               color: "#006AB2",
        //               marginRight: "16px",
        //             }}
        //             onClick={() => recallItem(record)}
        //           >
        //             强制取消服务
        //           </span>
        //         ) : null}
        //       </div>
        //     );
        //   },
        // },
      ]);
    }

    setTotal(defaultData?.total);
    setPageNumber(defaultData?.page);
    setPageSize(defaultData?.size);
  }, [defaultData]);

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  // 改变表格排序
  const tableChange = (pagination, filters, sorter, extra) => {
    if (sorter.order !== undefined) {
      changeOrders(sorter);
    }
  };

  return (
    <div className="ServiceOrderTable">
      <div className="tableContent" ref={refTable}>
        <Table
          className="myFormTable"
          pagination={false}
          columns={columns}
          dataSource={data}
          rowKey={(record, id) => id}
          onChange={tableChange}
          scroll={{
            y: "670px",
            x: "100%",
          }}
          locale={{
            emptyText: <MyEmpty></MyEmpty>,
          }}
          style={{ marginBottom: "8px" }}
          loading={
            tableLoading
              ? {
                  indicator: (
                    <div style={{ background: "#fff" }}>
                      <ReactSVG
                        src={LoadingSvg}
                        className="anticon-spin anticon myLoadingIcon"
                      />
                    </div>
                  ),
                }
              : false
          }
        />
        {total >= 10 ? (
          <div className="paginationBox">
            <Pagination
              className="myPagination"
              showQuickJumper
              total={total}
              current={pageNumber}
              pageSize={pageSize}
              pageSizeOptions={[10, 20, 50, 100]}
              onChange={onChange}
              showTotal={(total) => `共 ${total} 条`}
              showSizeChanger={true}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default forwardRef(ServiceOrderTable);
