import "./index.scss";
import { useEffect, useState } from "react";
import {
  VerticalAlignBottomOutlined,
  CloudFilled,
  LinkOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { ReactSVG } from "react-svg";
import projectApi from "../../../../../apis/project";

import {
  Drawer,
  Button,
  Form,
  Select,
  Upload,
  message,
  Typography,
} from "antd";
import HeaderTitle from "../../../../../components/HeaderTitle";
import { CloseOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import DropdownSvg from "../../../../../assets/下拉.svg";
import { debounce } from "../../../../../unit/unit";
import { saveBlob } from "../../../../../unit/unit";

import { useSelector } from "react-redux";
const { Dragger } = Upload;
const { Option } = Select;
const { Paragraph } = Typography;

const BatchDrawer = (props) => {
  const [form] = Form.useForm();
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);
  const [tagInfo, setTagInfo] = useState([]);
  const [chooseTag, setChooseTag] = useState(""); // tag搜索内容
  const [total, setTotal] = useState(10);
  const [chooseList, setChooseList] = useState([]);
  const [chooseField, setChooseField] = useState(""); // 选择编辑的字段
  const [metaCode, setMetaCode] = useState(null); // 元数据选择字段

  // 存储类型
  const DBType = dicCodeInfoList?.DG_DB_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  const {
    /* 关闭触发的回调 */
    onCancel,
    /* 确定触发的回调 */
    onOk,
    /* 确认按钮文字 */
    okText,
    /* 标题文字 */
    onTitle,
    /* 取消按钮文字 */
    cancelText,
    /* 初始值 */
    defaultData,
    visible,
    metaDataType,
    layerList,
    /* 选中值 */
    checkedList,
    tagList, // 标签列表
    setImportDialogVisible,
    getMetadataList,
    metaDataCode, // 元数据类型选择
    successFunc, // 导入元数据成功回调
    fileList, // 导入选中文件
    setFileList, // 设置导入选中文件
    setImportFile, // 判断是否在进行导入操作
  } = props;

  const checkMetadataVerds = checkedList?.map((item) => item.metadata_ver_id);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  useEffect(() => {
    form.resetFields();
  }, [defaultData]);

  useEffect(() => {
    if (visible) {
      setTagInfo(tagList);
      setChooseList([]);
      setMetaCode(metaDataCode);
    }
  }, [visible]);

  const onFinish = (value) => {
    if (onTitle === "导入元数据") {
      if (fileList.length === 0) {
        message.info("请至少上传一个文件");
        return;
      }
      successFunc && successFunc(metaCode, fileList);
    }
  };

  const tagSubmit = () => {
    const tagNameList = chooseList
      ?.filter((item) => Object.keys(item).length !== 4)
      ?.reduce((pre, cur) => [...pre, { tag_name: cur.label }], []);
    const tagIdList = chooseList
      ?.filter((item) => Object.keys(item).length === 4)
      ?.reduce((pre, cur) => [...pre, { tag_id: cur.value }], []);
    console.log(tagNameList, tagIdList);
    projectApi
      .addMetadataTag({
        tags: [...tagNameList, ...tagIdList],
        metadata_ver_ids: checkMetadataVerds,
      })
      .then((res) => {
        if (res.code === 200) {
          setImportDialogVisible(false);
          getMetadataList();
          message.success(res.message);
        } else {
          message.error(res.message);
        }
      });
  };

  // 搜索tag
  const handleValue = (e) => {
    if (e !== "") {
      projectApi.getMetadataTagList({ tag_name: e }).then((res) => {
        if (res.code === 200) {
          setTotal(res.data.total);
          setTagInfo(res.data.items);
          if (res.data.total === 0) {
            setChooseTag(e);
          }
        } else {
          setTagInfo([]);
        }
      });
    }
  };

  // 上传处理
  const fileChange = (info) => {
    if (info.file?.size / 1048576 > 10) {
      message.info("请上传10MB以下的文件!");
      return;
    }

    setFileList((prev) => {
      prev.push(info.file);
      return [...prev];
    });
  };

  // 下载模版
  const downloadTemplate = () => {
    projectApi.DownloadMetaDataFile(metaCode).then((blob) => {
      saveBlob(blob, {
        fileName: "数据定义-元数据-模版下载.xlsx",
        type: "application/vnd.ms-execel",
      });
    });
    // .catch(() => {
    //   message.error("下载失败");
    // });
  };

  return (
    <Drawer
      title={onTitle}
      placement="right"
      width="600px"
      className="BatchDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              form.submit();
              if (onTitle === "批量添加标签") {
                tagSubmit();
              }
              if (onTitle === "导入元数据") {
                setImportFile(true);
              }
            }}
            style={{ background: "#016DB4", color: "#fff" }}
          >
            {okText ? okText : "确认"}
          </Button>
          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            {cancelText ? cancelText : "取消"}
          </Button>
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          className="dialogDrawerForm"
          preserve={false}
          onFinish={onFinish}
        >
          {onTitle === "导入元数据" ? (
            <>
              <HeaderTitle title="上传"> </HeaderTitle>
              <div className="downLoadValue" onClick={() => downloadTemplate()}>
                <span style={{ cursor: "pointer" }}>下载导入模版</span>
                <VerticalAlignBottomOutlined
                  style={{ fontSize: "16px", cursor: "pointer" }}
                />
              </div>

              <Form.Item
                label="元数据类型"
                name="元数据类型"
                rules={[
                  {
                    required: true,
                    message: "请选择元数据类型",
                  },
                ]}
                initialValue={metaDataCode}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  showSearch={true}
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  style={{ width: "540px" }}
                  onChange={(e) => setMetaCode(e)}
                >
                  {metaDataType?.map((item) => {
                    return (
                      <Option value={item} key={item}>
                        {dicCodeInfoList?.DG_METADATA_TYPE?.map(
                          (value) => value[item]
                        )}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item>
                <Dragger
                  className="draggerBox"
                  fileList={[]}
                  name="file"
                  multiple={true}
                  action=""
                  onChange={fileChange}
                  // beforeUpload={(data) => {
                  //   return false;
                  // }}
                  accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                >
                  <div className="uploadBox">
                    <CloudFilled />
                    <div className="text">
                      将文件拖拽到此处或<span>点击上传</span>
                    </div>
                    <div className="fileTip">
                      （只能上传xls/xlsx文件，且不超过10MB）
                    </div>
                  </div>
                </Dragger>

                {fileList.map((file, index) => (
                  <div className="fileLineItem" key={Math.random()}>
                    <div className="fileItem">
                      <LinkOutlined />
                      <Paragraph
                        ellipsis={{
                          rows: 1,
                          tooltip: file.name,
                          expandable: false,
                        }}
                        style={{
                          width: `500px`,
                          color: "#313131",
                        }}
                      >
                        {file.name}
                      </Paragraph>
                    </div>
                    <CloseCircleOutlined
                      className="closeIcon"
                      onClick={() => {
                        setFileList((prev) => {
                          prev = prev.filter((_) => {
                            return _.uid !== file.uid;
                          });
                          return [...prev];
                        });
                      }}
                    ></CloseCircleOutlined>
                  </div>
                ))}
              </Form.Item>
            </>
          ) : null}

          {onTitle === "批量编辑元数据" ? (
            <>
              <Form.Item
                label="编辑字段"
                // name="data_layer_id"
                rules={[
                  {
                    required: true,
                    message: "请选择需要编辑的字段",
                  },
                ]}
              >
                <Select
                  placeholder="全部"
                  showSearch
                  allowClear
                  showArrow="true"
                  suffixIcon={
                    <ReactSVG
                      src={DropdownSvg}
                      style={{ width: "16px", height: "16px" }}
                    ></ReactSVG>
                  }
                  style={{ width: "540px" }}
                  options={[
                    {
                      value: "1",
                      label: "数据分层",
                    },
                    {
                      value: "2",
                      label: "主题域",
                    },
                    {
                      value: "3",
                      label: "存储类型",
                    },
                    {
                      value: "4",
                      label: "标签",
                    },
                    {
                      value: "5",
                      label: "管理责任人",
                    },
                  ]}
                  onChange={(e) => setChooseField(e)}
                ></Select>
              </Form.Item>

              {chooseField === "1" ? (
                <Form.Item
                  label="数据分层"
                  name="data_layer_id"
                  rules={[
                    {
                      required: true,
                      message: "请选择数据分层",
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择"
                    showSearch
                    allowClear
                    showArrow="true"
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "540px" }}
                  >
                    {layerList?.map((item) => {
                      return (
                        <Option
                          value={item.data_layer_id}
                          key={item.data_layer_id}
                        >
                          {item.data_layer_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null}
              {chooseField === "2" ? (
                <Form.Item
                  label="主题域"
                  name=""
                  rules={[
                    {
                      required: true,
                      message: "请选择主题域",
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择"
                    showSearch
                    allowClear
                    showArrow="true"
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "540px" }}
                  >
                    {layerList?.map((item) => {
                      return (
                        <Option
                          value={item.data_layer_id}
                          key={item.data_layer_id}
                        >
                          {item.data_layer_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null}
              {chooseField === "3" ? (
                <Form.Item
                  label="存储类型"
                  name=""
                  rules={[
                    {
                      required: true,
                      message: "请选择存储类型",
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择"
                    showSearch
                    allowClear
                    showArrow="true"
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "540px" }}
                  >
                    {DBType?.map((item) => {
                      return (
                        <Option value={item} key={item}>
                          {dicCodeInfoList?.DG_DB_TYPE?.map(
                            (value) => value[item]
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null}
              {chooseField === "4" ? (
                <Form.Item
                  label="标签"
                  name=""
                  rules={[
                    {
                      required: true,
                      message: "请选择标签",
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择"
                    showSearch
                    allowClear
                    showArrow="true"
                    suffixIcon={
                      <ReactSVG
                        src={DropdownSvg}
                        style={{ width: "16px", height: "16px" }}
                      ></ReactSVG>
                    }
                    style={{ width: "540px" }}
                  >
                    {DBType?.map((item) => {
                      return (
                        <Option value={item} key={item}>
                          {dicCodeInfoList?.DG_DB_TYPE?.map(
                            (value) => value[item]
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null}
            </>
          ) : null}

          {onTitle === "批量添加标签" ? (
            <>
              <div style={{ marginBottom: "8px" }}>标签</div>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="搜索或添加选项"
                mode="multiple"
                maxTagCount="responsive"
                showSearch
                dropdownRender={(menu) => (
                  <div className="subjectScopeSearchPoP">
                    {total === 0 ? (
                      <>
                        {/* <PlusOutlined /> */}
                        <div
                          style={{
                            margin: "5px",
                            marginLeft: "12px",
                            cursor: "pointer",
                            color: "#535353",
                          }}
                          onClick={() => {
                            const data = chooseList?.some(
                              (item) => item.label === chooseTag
                            );
                            if (!data) {
                              setChooseList([
                                ...chooseList,
                                {
                                  key: Math.random(1000),
                                  label: chooseTag,
                                  value: Math.random(1000),
                                },
                              ]);
                            }
                          }}
                        >
                          添加「 {chooseTag} 」为新选项
                        </div>
                      </>
                    ) : null}
                    {menu}
                  </div>
                )}
                value={chooseList}
                filterOption={false}
                labelInValue={true}
                defaultActiveFirstOption={false}
                onSearch={debounce(handleValue, 1000)}
                // onInputKeyDown={handleValue}
                allowClear
                showArrow="true"
                onChange={(e) => {
                  setChooseList(e);
                  setTagInfo(tagList);
                }}
                onDropdownVisibleChange={(flag) => {
                  if (flag === false) {
                    setTotal(10);
                    setTagInfo(tagList);
                  }
                }}
                suffixIcon={
                  <ReactSVG
                    src={DropdownSvg}
                    style={{ width: "16px", height: "16px" }}
                  ></ReactSVG>
                }
              >
                {tagInfo && tagInfo.length > 0
                  ? tagInfo.map((item, index) => (
                      <Option value={item.tag_id} key={index}>
                        {item.tag_name}
                      </Option>
                    ))
                  : null}
              </Select>
            </>
          ) : null}
        </Form>
      </Scrollbars>
    </Drawer>
  );
};

export default BatchDrawer;
