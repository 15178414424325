import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getToken } from "../../unit/index";
import nProgress from "nprogress";
import { setRedirectUrl } from "../../unit/index";
import "nprogress/nprogress.css";

const PrivateRoute = (props) => {
  useEffect(() => {
    nProgress.done();
    return () => nProgress.start();
  });
  const { children } = props;
  // 判断是否登录
  if (!getToken()) {
    setRedirectUrl(window.location.href);
  }
  return getToken() ? (
    // 如果是登录用户，则可以访问我们传入的 children 组件
    <>{children}</>
  ) : (
    // 未登录用户重定向到 login 页面
    <Navigate replace={true} to="/login" />
  );
};

export default PrivateRoute;
