import request from "../download_request";
// arraybuffer
/* ============================= 导出接口 ============================= */
// 未发布词根标准导出
function exportUnpublishedList(keywords) {
  return request({
    url: "/data_standard/unpublished/export",
    method: "post",
    responseType: "blob",
    params: {
      keywords,
    },
  });
}
// 已发布词根标准导出
function exportPublishedList(id) {
  return request({
    url: `/data_standard/published/export`,
    method: "post",
    responseType: "blob",
    params: {
      ver_no: id,
    },
  });
}
// 未发布主题域标准导出
function exportSubUnpublishedList(keywords) {
  return request({
    url: "/data_standard/sub/unpublished/export",
    method: "post",
    responseType: "blob",
    params: {
      keywords,
    },
  });
}

// 已发布主题域标准导出
function exportSubPublishedList(id) {
  return request({
    url: `/data_standard/published/export`,
    method: "post",
    responseType: "blob",
    params: {
      ver_no: id,
    },
  });
}

// 对比结果下载（导出）
function DownloadTaskCheckFile(ids) {
  return request({
    url: `/data_quality/task_check/down_file`,
    method: "post",
    dataType: "json",
    responseType: "blob",
    data: ids,
    withCredentials: true,
  });
}
// 未发布数据元标准导出
function exporEleUnpublishedList(keywords) {
  return request({
    url: "/data_standard/data_ele/unpublished/export",
    method: "post",
    responseType: "blob",
    params: {
      keywords,
    },
  });
}

// 已发布数据元标准导出
function exportElePublishedList(id) {
  return request({
    // url: `/data_standard/published/export`,
    method: "post",
    responseType: "blob",
    params: {
      ver_no: id,
    },
  });
}

export default {
  exportUnpublishedList,
  exportPublishedList,
  exportSubUnpublishedList,
  exportSubPublishedList,
  DownloadTaskCheckFile,
  exporEleUnpublishedList,
  exportElePublishedList,
};
