import "./index.scss";
import { Tabs, message, Select } from "antd";
import { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import DropdownSvg from "../../../assets/下拉.svg";
import DataStandardCheckTable from "./components/DataStandardCheckTable";
import { useSelector } from "react-redux";
import projectApi from "../../../apis/project";
import { useGetState } from "ahooks";
import MessageModal from "../../../components/MessageModal";
import Scrollbars from "react-custom-scrollbars";

const { TabPane } = Tabs;
const { Option } = Select;
const Check = (props) => {
  const dicCodeInfoList = useSelector((state) => state.Project.dicCodeInfoList);

  const [orders, setOrders, getOrders] = useGetState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [activeKey, setActiveKey, getActiveKey] = useGetState("1");
  const [standardTypeCode, setStandardTypeCode] = useState("all"); // 我的申请标准类型
  const [tocheckTypeCode, setTockeckTypeCode] = useState("all"); // 待审核标准类型
  const [checkTypeCode, setCkeckTypeCode] = useState("DATA_LAYER"); // 已审核标准类型

  const [apprStateCode, setApprStateCode] = useState("IN_APPROVAL"); // 我的申请审核状态
  const [checkApprStateCode, setCheckApprStateCode] = useState("APPROVED"); // 已审核审核状态

  const [params, setParams] = useState({ page: 1, size: 10 });
  const [toCheckList, setToCheckList] = useState({}); // 待审核
  const [myapplyList, setMyapplyList] = useState({}); // 我的申请
  const [checkList, setCheckList] = useState({}); // 已审核

  // 标准类型
  const standard = dicCodeInfoList?.DG_STD_TYPE
    ? [{ all: "全部" }, ...dicCodeInfoList?.DG_STD_TYPE]
    : [];

  const standardType = standard?.map((item) => Object.keys(item)).flat();

  // 已审核标准类型
  const standardTypes = dicCodeInfoList?.DG_STD_TYPE?.map((item) =>
    Object.keys(item)
  ).flat();

  // 审核状态
  const apprState = dicCodeInfoList?.DG_WF_APPR_STAT?.map((item) =>
    Object.keys(item)
  ).flat();

  // 已审核
  const checkApprState = () => {
    return apprState?.filter(
      (item) =>
        item !== "DEPLOYED" &&
        item !== "TERMINATED" &&
        item !== "IN_APPROVAL" &&
        item !== "CANCELED"
    );
  };

  // 我的申请
  const applyApprState = () => {
    return apprState?.filter(
      (item) => item !== "DEPLOYED" && item !== "TERMINATED"
    );
  };

  // tab切换
  const tabChange = (key) => {
    setActiveKey(key);
    if (key === "2") {
      setOrders([]);
    } else if (key === "3") {
      setOrders([]);
    } else if (key === "1") {
      setOrders([]);
    }
  };

  // 获取我的申请
  const getMyapplyList = (info) => {
    if (standardTypeCode === "all") {
      projectApi
        .getMyapplyList(
          {
            wf_appr_stat: apprStateCode,
            ...params,
          },
          { orders: info }
        )
        .then(async (res) => {
          if (res.code === 200) {
            await setMyapplyList(res.data);
          } else {
            setMyapplyList({});
          }
        })
        .finally(() => {
          setTableLoading(false);
        });
    } else {
      projectApi
        .getMyapplyList(
          {
            dg_std_type: standardTypeCode,
            wf_appr_stat: apprStateCode,
            ...params,
          },
          { orders: info }
        )
        .then(async (res) => {
          if (res.code === 200) {
            await setMyapplyList(res.data);
          } else {
            setMyapplyList({});
          }
        })
        .finally(() => {
          setTableLoading(false);
        });
    }
  };

  // 获取待审核数据
  const getToCheckList = (info) => {
    if (tocheckTypeCode === "all") {
      projectApi
        .getToCheckList(params, { orders: info })
        .then((res) => {
          if (res.code === 200) {
            setToCheckList(res.data);
          } else {
            setToCheckList([]);
          }
        })
        .finally(() => {
          setTableLoading(false);
        });
    } else {
      projectApi
        .getToCheckList(
          { dg_std_type: tocheckTypeCode, ...params },
          { orders: info }
        )
        .then((res) => {
          if (res.code === 200) {
            setToCheckList(res.data);
          } else {
            setToCheckList([]);
          }
        })
        .finally(() => {
          setTableLoading(false);
        });
    }
  };

  // 获取已审核数据
  const getCheckedList = (info) => {
    projectApi
      .getCheckedList(
        {
          dg_std_type: checkTypeCode,
          wf_appr_stat: checkApprStateCode,
          ...params,
        },
        { orders: info }
      )
      .then((res) => {
        if (res.code === 200) {
          setCheckList(res.data);
        } else {
          setCheckList([]);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setTableLoading(false);
    }, 500);
    // 清除定时器
    return () => clearInterval(timer);
  }, [toCheckList, myapplyList, checkList]);

  // 改变分页数据
  const changePagination = (page, pageSize) => {
    setParams({ page: page, size: pageSize });
  };

  useEffect(() => {
    if (activeKey === "1" && orders.length !== 0) {
      setTableLoading(true);
      getMyapplyList([{ order_name: "apply_time", order_by: "desc" }]);
    } else if (activeKey === "2") {
      setTableLoading(true);
      getToCheckList([{ order_name: "apply_time", order_by: "desc" }]);
    } else if (activeKey === "3") {
      setTableLoading(true);
      getCheckedList([{ order_name: "apprd_time", order_by: "desc" }]);
    }
  }, [params]);

  // 排序
  const changeOrders = (data) => {
    const str = "_value";
    setOrders([
      {
        order_name: data.field.includes("_value")
          ? data.field.split(str).join("")
          : data.field,
        order_by: data.order === "descend" ? "desc" : "asc",
      },
    ]);
    setParams({ page: 1, size: 10 });
  };

  useEffect(() => {
    if (activeKey === "1" && orders.length !== 0) {
      setTableLoading(true);
      getMyapplyList(getOrders());
    } else if (activeKey === "2" && orders.length !== 0) {
      setTableLoading(true);
      getToCheckList(getOrders());
    } else if (activeKey === "3" && orders.length !== 0) {
      setTableLoading(true);
      getCheckedList(getOrders());
    }
  }, [orders]);

  useEffect(() => {
    if (activeKey === "1") {
      setTableLoading(true);
      getMyapplyList([{ order_name: "apply_time", order_by: "desc" }]);
    }
  }, [standardTypeCode, apprStateCode]);

  useEffect(() => {
    if (getActiveKey() === "2") {
      setTableLoading(true);
      getToCheckList([{ order_name: "apply_time", order_by: "desc" }]);
    }
  }, [tocheckTypeCode]);

  useEffect(() => {
    if (getActiveKey() === "3") {
      setTableLoading(true);
      getCheckedList([{ order_name: "apprd_time", order_by: "desc" }]);
    }
  }, [checkTypeCode, checkApprStateCode]);

  useEffect(() => {
    if (activeKey === "2") {
      setTableLoading(true);
      getToCheckList([{ order_name: "apply_time", order_by: "desc" }]);
    } else if (activeKey === "3") {
      setTableLoading(true);
      getCheckedList([{ order_name: "apprd_time", order_by: "desc" }]);
    } else if (activeKey === "1") {
      setTableLoading(true);
      getMyapplyList([{ order_name: "apply_time", order_by: "desc" }]);
    }
  }, [activeKey]);

  // 同意审核
  const agreeItem = (item) => {
    MessageModal({
      type: "start",
      description: "确定同意发布吗？",
      title: "同意发布",
      okText: "启用",
      onOk: () => {
        projectApi.checkStandards(item.wf_id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            setParams({ page: 1, size: 10 });
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 撤回审核
  const recallItem = (item) => {
    MessageModal({
      type: "warning",
      description: "确定要撤回发布吗？",
      title: "撤回发布",
      okText: "撤回",
      onOk: () => {
        projectApi.withdrawStandards(item.wf_id).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            getMyapplyList([{ order_name: "apply_time", order_by: "desc" }]);
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 驳回拒绝审核
  const rejectItem = (data) => {
    MessageModal({
      type: "warning",
      title: "驳回发布",
      rejectTitle: "驳回意见",
      onOk: (item) => {
        projectApi.rejectStandards(data.wf_id, item?.apprd_opn).then((res) => {
          if (res.code === 200) {
            message.success(res.message);
            setParams({ page: 1, size: 10 });
          } else {
            message.error(res.message);
          }
        });
      },
    });
  };

  // 发布标准请求
  const applyStandards = (data) => {
    projectApi.applyStandards(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        getMyapplyList([{ order_name: "apply_time", order_by: "desc" }]);
      } else {
        message.error(res.message);
      }
    });
  };

  return (
    <Scrollbars
      autoHide={true}
      autoHideTimeout={5000}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <div className="CheckContent">
        <Tabs className="myTabs" onChange={tabChange} defaultActiveKey="1">
          <TabPane tab="我的申请" key="1">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">标准类型</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="全部"
                      showSearch
                      // allowClear
                      value={standardTypeCode}
                      showArrow="true"
                      onChange={(value) => {
                        setStandardTypeCode(value);
                      }}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                    >
                      {standardType?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {standard?.map((value) => value[item])}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="label">审核状态</div>
                  <div className="value">
                    <Select
                      placeholder="全部"
                      showSearch
                      showArrow="true"
                      value={apprStateCode}
                      onChange={(value) => {
                        setApprStateCode(value);
                      }}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                    >
                      {applyApprState()?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {dicCodeInfoList.DG_WF_APPR_STAT.map(
                              (value) => value[item]
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <DataStandardCheckTable
                  changeOrders={changeOrders}
                  defaultData={myapplyList}
                  tableName="myapply"
                  changePagination={changePagination}
                  activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  recallItem={recallItem}
                  applyStandards={applyStandards}
                ></DataStandardCheckTable>
              </div>
            </div>
          </TabPane>
          <TabPane tab="待审核" key="2">
            <div className="ReleseInfo">
              <div className="promptValue">
                <div className="selectInfo">
                  <div className="label">标准类型</div>
                  <div className="value">
                    <Select
                      placeholder="全部"
                      showSearch
                      // allowClear
                      showArrow="true"
                      onChange={(value) => {
                        setTockeckTypeCode(value);
                      }}
                      value={tocheckTypeCode}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                    >
                      {standardType?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {standard?.map((value) => value[item])}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <DataStandardCheckTable
                  changeOrders={changeOrders}
                  defaultData={toCheckList}
                  tableName="ToCheck"
                  changePagination={changePagination}
                  activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                  agreeItem={agreeItem}
                  rejectItem={rejectItem}
                ></DataStandardCheckTable>
              </div>
            </div>
          </TabPane>
          <TabPane tab="已审核" key="3">
            <div className="noReleseInfo">
              <div className="info">
                <div className="selectInfo">
                  <div className="label">标准类型</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="全部"
                      showSearch
                      // allowClear
                      value={checkTypeCode}
                      showArrow="true"
                      onChange={(value) => {
                        setCkeckTypeCode(value);
                      }}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                    >
                      {standardTypes?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {dicCodeInfoList.DG_STD_TYPE.map(
                              (value) => value[item]
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="label">审核状态</div>
                  <div className="value">
                    <Select
                      placeholder="全部"
                      showSearch
                      // allowClear
                      showArrow="true"
                      value={checkApprStateCode}
                      onChange={(value) => {
                        setCheckApprStateCode(value);
                      }}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                    >
                      {checkApprState()?.map((item) => {
                        return (
                          <Option value={item} key={item}>
                            {dicCodeInfoList.DG_WF_APPR_STAT.map(
                              (value) => value[item]
                            )}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="infoBox">
                <DataStandardCheckTable
                  changeOrders={changeOrders}
                  defaultData={checkList}
                  tableName="check"
                  changePagination={changePagination}
                  activeKey={getActiveKey()}
                  tableLoading={tableLoading}
                ></DataStandardCheckTable>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Scrollbars>
  );
};
export default Check;
