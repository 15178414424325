import "./index.scss";
import HeaderTitle from "../../../components/HeaderTitle";
import { Button, Select, Form } from "antd";
import { ReactSVG } from "react-svg";
import { useEffect, useState } from "react";
import MyRangePicker from "../../../components/MyRangePicker";
import DropdownSvg from "../../../assets/下拉.svg";
import LineChart from "./components/LineChart";
import exportIcon from "../../../assets/导出.svg";
import DetailTable from "./components/DetailTable";
import projectApi from "../../../apis/project";
import moment from "moment";
import { tuple } from "antd/lib/_util/type";
const { Option } = Select;
const today = new Date();
const year = today.getFullYear();
const m = today.getMonth() + 1;
const month = m?.toString().padStart(2, "0"); // 月份从0开始，需要加1
const day = today.getDate();
const paddedDay = day < 10 ? `0${day}` : day.toString();

const MgtStatistics = (props) => {
  const [AllApiList, setAllApiList] = useState([]); // API服务包
  const [AllAppList, setAllAppList] = useState({}); // 全部应用
  const [AllAppKeyList, setAllAppKeyList] = useState([]); // 全部应用key值
  const [RadioValue, setRadioValue] = useState("hour"); // 折线图小时/日选择

  const [lineChartList, setLineChartList] = useState({}); // 折线图数据

  const [amountApi, setAmountApi] = useState([]); // api服务包
  const [amountTime, setAmountTime] = useState([
    `${year}-${month}-${paddedDay}`,
    `${year}-${month}-${paddedDay}`,
  ]); // 时间段
  const [amountApp, setAmountApp] = useState([]); // 应用

  const [errorList, setErrorList] = useState({}); // 错误日志
  const [params, setParams] = useState({ page: 1, size: 10 });
  const [orders, setOrders] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [staticLoading, setStaticLoading] = useState(true);
  // 获取全部api服务包
  const getAllApiList = async () => {
    const { data } = await projectApi.getAllApiList();
    setAllApiList(data);
  };
  // 获取全部应用
  const getAppLists = async () => {
    const { data } = await projectApi.getAppLists();
    setAllAppKeyList(Object.keys(data));
    setAllAppList(data);
  };

  useEffect(() => {
    getAllApiList();
    getAppLists();
  }, []);

  // 获取调用统计
  const apiStatisticsList = (name) => {
    projectApi.apiStatisticsList(name).then((res) => {
      if (res.code === 200) {
        setLineChartList(res.data.statistics);
        setStaticLoading(false);
      } else {
        setLineChartList({});
        setStaticLoading(false);
      }
    });
  };
  // 时间转换
  const setDate = (data) => {
    const inputDate = new Date(data);
    inputDate.setDate(inputDate?.getDate() + 1);
    const outputDate = inputDate?.toISOString().split("T")[0];
    return outputDate;
  };

  useEffect(() => {
    if (amountTime?.length !== 0) {
      setStaticLoading(true);
      let data = {
        start_time: `${amountTime[0]} 00:00:00`,
        end_time: `${setDate(amountTime[1])} 00:00:00`,
      };
      if (amountApi?.length !== 0 || amountApp?.length !== 0) {
        const datas = [].concat(
          ...amountApp?.map((item) => AllAppList[item]?.api_pkg_id)
        );
        const set = new Set([...amountApi, ...datas]);
        const newArr = Array.from(set);

        data.api_pkg_id = newArr;
      }

      if (RadioValue !== "") {
        data.statistics_type = RadioValue;
      }
      apiStatisticsList(data);
    }
  }, [RadioValue]);

  const getApiStatisticsList = () => {
    if (amountTime?.length !== 0) {
      setStaticLoading(true);
      let data = {
        start_time: `${amountTime[0]} 00:00:00`,
        end_time: `${setDate(amountTime[1])} 00:00:00`,
      };
      if (amountApi?.length !== 0 || amountApp?.length !== 0) {
        const datas = [].concat(
          ...amountApp?.map((item) => AllAppList[item]?.api_pkg_id)
        );
        const set = new Set([...amountApi, ...datas]);
        const newArr = Array.from(set);

        data.api_pkg_id = newArr;
      }

      if (RadioValue !== "") {
        data.statistics_type = RadioValue;
      }

      apiStatisticsList(data);
      setTableLoading(true);
      let errordata = {
        start_time: `${amountTime[0]} 00:00:00`,
        end_time: `${setDate(amountTime[1])} 00:00:00`,
        ...params,
      };

      if (orders?.length !== 0) {
        errordata.order_list = orders.toString();
      }
      if (amountApi?.length !== 0 || amountApp?.length !== 0) {
        const datas = [].concat(
          ...amountApp?.map((item) => AllAppList[item]?.api_pkg_id)
        );
        const set = new Set([...amountApi, ...datas]);
        const newArr = Array.from(set);

        errordata.api_pkg_id = newArr;
      }
      if (amountApp?.length !== 0) {
        errordata.call_by_id = amountApp;
      }
      errorStoreList(errordata);
    }
  };

  // 改变分页数据
  const changePagination = (page, pageSize) => {
    setParams({ page: page, size: pageSize });
  };

  // 排序
  const changeOrders = (data) => {
    setOrders([data.field, data.order === "descend" ? "desc" : "asc"]);
  };

  // 获取错误日志列表
  const errorStoreList = (name) => {
    projectApi
      .errorStoreList(name)
      .then((res) => {
        if (res.code === 200) {
          setErrorList(res.data);
        } else {
          setErrorList({});
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  useEffect(() => {
    if (amountTime?.length !== 0) {
      setTableLoading(true);
      let data = {
        start_time: `${amountTime[0]} 00:00:00`,
        end_time: `${setDate(amountTime[1])} 00:00:00`,
        ...params,
      };

      if (orders?.length !== 0) {
        data.order_list = orders.toString();
      }
      if (amountApi?.length !== 0 || amountApp?.length !== 0) {
        const datas = [].concat(
          ...amountApp?.map((item) => AllAppList[item]?.api_pkg_id)
        );
        const set = new Set([...amountApi, ...datas]);
        const newArr = Array.from(set);

        data.api_pkg_id = newArr;
      }
      if (amountApp?.length !== 0) {
        data.call_by_id = amountApp;
      }
      errorStoreList(data);
    }
  }, [params, orders]);

  return (
    <div className="MgtStatistics">
      <div className="topValue">
        <div className="selectInfo">
          <div className="label">应用</div>
          <div className="value" style={{ marginRight: "24px" }}>
            <Select
              mode="multiple"
              maxTagCount="responsive"
              placeholder="请选择"
              showSearch={true}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              style={{ width: "300px" }}
              onChange={(e) => setAmountApp(e)}
            >
              {AllAppKeyList?.map((item) => {
                return (
                  <Option value={item} key={item}>
                    {AllAppList[item]?.app_name}
                  </Option>
                );
              })}
            </Select>
          </div>

          <div className="label">API服务包</div>
          <div className="value" style={{ marginRight: "24px" }}>
            <Select
              mode="multiple"
              maxTagCount="responsive"
              placeholder="请选择"
              showSearch={true}
              suffixIcon={
                <ReactSVG
                  src={DropdownSvg}
                  style={{ width: "16px", height: "16px" }}
                ></ReactSVG>
              }
              style={{ width: "300px" }}
              value={amountApi}
              onChange={(e) => setAmountApi(e)}
            >
              {AllApiList?.map((item) => {
                return (
                  <Option value={item.api_pkg_id} key={item.api_pkg_id}>
                    {item.chi_name}
                  </Option>
                );
              })}
            </Select>
          </div>

          <div className="label">时间段</div>
          <div className="value" style={{ marginRight: "24px" }}>
            <MyRangePicker
              style={{ width: "250px" }}
              onChange={(time, timeString) => {
                setAmountTime(timeString);
              }}
            ></MyRangePicker>
          </div>
          <Button type="primary" onClick={getApiStatisticsList}>
            查询
          </Button>
        </div>
      </div>
      <div className="HeaderTitle">
        <HeaderTitle
          title="服务列表"
          extra={
            <Button
              type="primary"
              icon={<ReactSVG src={exportIcon} className="Icon"></ReactSVG>}
            >
              导出
            </Button>
          }
        ></HeaderTitle>
      </div>
      <div className="guidanceValue">
        <LineChart
          lineChartList={lineChartList}
          setRadioValue={setRadioValue}
          RadioValue={RadioValue}
          AllApiList={AllApiList}
          staticLoading={staticLoading}
        ></LineChart>
      </div>
      <div className="HeaderTitle">
        <HeaderTitle
          title="调用失败详情"
          extra={
            <Button
              type="primary"
              icon={<ReactSVG src={exportIcon} className="Icon"></ReactSVG>}
            >
              导出
            </Button>
          }
        ></HeaderTitle>
      </div>
      <div className="tableValue">
        <DetailTable
          tableLoading={tableLoading}
          changePagination={changePagination}
          changeOrders={changeOrders}
          defaultData={errorList}
          AllApiList={AllApiList}
        ></DetailTable>
      </div>
    </div>
  );
};
export default MgtStatistics;
