import "./index.scss";
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Select,
  Modal,
  Table,
  Tooltip,
  ConfigProvider,
  message,
  Checkbox,
} from "antd";

import LoadingSvg from "../../../../../assets/loading.svg";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import DropdownSvg from "../../../../../assets/下拉.svg";
import zhCN from "antd/lib/locale/zh_CN";
import wrapper from "./wrapper";
import { ReactSVG } from "react-svg";
import MyEmpty from "../../../../../components/Empty";
import projectApi from "../../../../../apis/project";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const LineKeyDefinitionDrawer = (props) => {
  const [visible, setVisible] = useState(true);

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    /* 销毁组件回调(高阶组件自定义) */
    closeDialog,
    /* 不要按钮 */
    noBtn,
    /* 点击蒙层是否允许关闭 */
    maskClosable,
    /* 表对应的id */
    tableId,
    // // 是否选择了数据行键定义
    // handleStateChange,
    // flag,
    /* 更新模型信息 */
    getModeldataList,
  } = props;

  // 表格
  const refTable = useRef(null);
  const [tableKeyList, setTableKeyList] = useState([]);
  const [colNameList, setColNameList] = useState([]); // 字段
  const [dataSource, setDataSource] = useState([
    {
      key: "1",
      dataKey: [],
    },
  ]);
  const [checkAllIndeterminate, setCheckAllIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const [tableLoading, setTableLoading] = useState(true);

  const [mdDataSource, setMdDataSource] = useState([
    {
      key: "1",
      dataKey: [],
    },
  ]);
  const [mdCheckAllIndeterminate, setMdCheckAllIndeterminate] = useState(false);
  const [mdCheckAll, setMdCheckAll] = useState(false);
  // 获取字段
  const getModelTableFieldList = (id) => {
    projectApi.getModelDesc(id).then((res) => {
      if (res.code === 200) {
        const list = res?.data?.columns.reduce(
          (pre, info) => [...pre, { name: info?.col_name, id: info?.col_id }],
          []
        );
        setColNameList(list);
        getTableKeyList(tableId, list);
      } else {
        setColNameList([]);
      }
    });
  };

  useEffect(() => {
    getModelTableFieldList(tableId);
    // getTableKeyList(tableId);
  }, [tableId]);

  useEffect(() => {
    setTableLoading(true);
  }, []);

  // 查询表key定义
  const getTableKeyList = (id, list) => {
    projectApi
      .getTableKeyList(id)
      .then((res) => {
        if (res.code === 200) {
          setTableKeyList(res?.data);

          const rowKeyList = res.data
            .filter((item) => item.key_type === 1)
            ?.map((item) => item.col_id);
          if (rowKeyList.length === list.length) {
            setCheckAll(true);
            setCheckAllIndeterminate(false);
          } else {
            setCheckAllIndeterminate(true);
            setCheckAll(false);
          }
          if (rowKeyList.length === 0) {
            setCheckAllIndeterminate(false);
          }
          setDataSource([
            {
              key: "1",
              dataKey: rowKeyList,
            },
          ]);

          const mdKeyList = res.data
            .filter((item) => item.key_type === 2)
            ?.map((item) => item.col_id);
          if (mdKeyList.length === list.length) {
            setMdCheckAll(true);
            setMdCheckAllIndeterminate(false);
          } else {
            setMdCheckAllIndeterminate(true);
            setMdCheckAll(false);
          }
          if (mdKeyList.length === 0) {
            setMdCheckAllIndeterminate(false);
          }

          setMdDataSource([
            {
              key: "1",
              dataKey: mdKeyList,
            },
          ]);
        } else {
          setTableKeyList([]);
        }
      })
      .finally((res) => setTableLoading(false));
  };

  // 数据行键定义
  const handleOk = () => {
    let rowKeyList = dataSource[0].dataKey?.reduce(
      (cur, pre, index) => [
        ...cur,
        {
          col_id: pre,
          ord: index + 1,
        },
      ],
      []
    );
    let mdKeyList = mdDataSource[0].dataKey?.reduce(
      (cur, pre, index) => [
        ...cur,
        {
          col_id: pre,
          ord: index + 1,
        },
      ],
      []
    );

    const data = {
      table_id: tableId,
    };
    if (tableKeyList.length === 0) {
      data.row_key_cols = rowKeyList;
      data.md5_key_cols = mdKeyList;
    } else {
      const map = new Map();
      tableKeyList.forEach((item) => {
        const key = `${item.col_id}_${item.key_type}`;
        if (!map.has(key)) {
          map.set(key, item.key_id);
        }
      });

      rowKeyList.forEach((item) => {
        const key = `${item.col_id}_1`;
        if (map.has(key)) {
          item.key_id = map.get(key);
        }
      });

      mdKeyList.forEach((item) => {
        const key = `${item.col_id}_2`;
        if (map.has(key)) {
          item.key_id = map.get(key);
        }
      });

      data.row_key_cols = rowKeyList;
      data.md5_key_cols = mdKeyList;
    }

    projectApi.setTableKey(data).then((res) => {
      if (res.code === 200) {
        // handleStateChange(tableId);
        message.success(res.message);
        getModeldataList();
        setVisible(false);
        setTimeout(() => {
          closeDialog();
        }, 500);
      } else {
        message.error(res.message);
      }
    });
  };

  const columns = [
    {
      title: "row_key 定义",
      dataIndex: "dataKey",
      key: "dataKey",
      render: (dataKey, record) => (
        <Select
          mode="multiple"
          placeholder="请选择row_key定义"
          allowClear
          filterOption={false}
          suffixIcon={
            <ReactSVG
              src={DropdownSvg}
              style={{ width: "16px", height: "16px" }}
            ></ReactSVG>
          }
          style={{ width: "100%" }}
          value={dataKey}
          onChange={(value) => handleSelectChange(value, record.key)}
          dropdownRender={(menu) => (
            <div className="subjectScopeSearchPoP">
              <Checkbox
                style={{ margin: "5px", marginLeft: "12px" }}
                indeterminate={checkAllIndeterminate}
                checked={checkAll}
                onChange={(e) => {
                  const newData = [...dataSource];
                  const target = newData.find(
                    (item) => item.key === record.key
                  );
                  if (e.target.checked) {
                    const data = colNameList.map((item) => item.id);
                    target.dataKey = data;
                  } else {
                    target.dataKey = [];
                  }
                  setDataSource(newData);
                  setCheckAll(e.target.checked);
                  setCheckAllIndeterminate(false);
                  e.stopPropagation();
                }}
              >
                全选
              </Checkbox>
              {menu}
            </div>
          )}
        >
          {colNameList?.map((item) => {
            return (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      ),
    },
  ];
  const handleSelectChange = (value, key) => {
    if (value.length === colNameList.length) {
      setCheckAll(true);
      setCheckAllIndeterminate(false);
    } else {
      setCheckAllIndeterminate(true);
      setCheckAll(false);
    }
    if (value.length === 0) {
      setCheckAllIndeterminate(false);
    }
    const newData = [...dataSource];
    const target = newData.find((item) => item.key === key);
    if (target) {
      target.dataKey = value;
      setDataSource(newData);
    }
  };

  const mdColumns = [
    {
      title: "md5_key 定义",
      dataIndex: "dataKey",
      key: "dataKey",
      render: (dataKey, record) => (
        <Select
          mode="multiple"
          placeholder="请选择md5_key定义"
          allowClear
          filterOption={false}
          suffixIcon={
            <ReactSVG
              src={DropdownSvg}
              style={{ width: "16px", height: "16px" }}
            ></ReactSVG>
          }
          style={{ width: "100%" }}
          value={dataKey}
          onChange={(value) => mdhandleSelectChange(value, record.key)}
          dropdownRender={(menu) => (
            <div className="subjectScopeSearchPoP">
              <Checkbox
                style={{ margin: "5px", marginLeft: "12px" }}
                indeterminate={mdCheckAllIndeterminate}
                checked={mdCheckAll}
                onChange={(e) => {
                  const newData = [...mdDataSource];
                  const target = newData.find(
                    (item) => item.key === record.key
                  );
                  if (e.target.checked) {
                    const data = colNameList.map((item) => item.id);
                    target.dataKey = data;
                  } else {
                    target.dataKey = [];
                  }
                  setMdDataSource(newData);
                  setMdCheckAll(e.target.checked);
                  setMdCheckAllIndeterminate(false);
                  e.stopPropagation();
                }}
              >
                全选
              </Checkbox>
              {menu}
            </div>
          )}
        >
          {colNameList?.map((item) => {
            return (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      ),
    },
  ];
  const mdhandleSelectChange = (value, key) => {
    if (value.length === colNameList.length) {
      setMdCheckAll(true);
      setMdCheckAllIndeterminate(false);
    } else {
      setMdCheckAllIndeterminate(true);
      setMdCheckAll(false);
    }
    if (value.length === 0) {
      setMdCheckAllIndeterminate(false);
    }
    const newData = [...mdDataSource];
    const target = newData.find((item) => item.key === key);
    if (target) {
      target.dataKey = value;
      setMdDataSource(newData);
    }
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
    setTimeout(() => {
      closeDialog();
    }, 500);
  };

  return (
    <ConfigProvider locale={zhCN}>
      <Modal
        title={title}
        visible={visible}
        onCancel={handleCancel}
        wrapClassName="LineKeyDefinitionDrawer"
        centered
        footer={null}
        maskClosable={maskClosable}
        width={884}
      >
        <div className="addModalContent">
          <div
            className="tableContent"
            style={{ width: "100%" }}
            ref={refTable}
          >
            <Table
              className="myFormTable"
              // rowKey={(record, index) => record.key}
              rowKey="id"
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              loading={
                tableLoading
                  ? {
                      indicator: (
                        <ReactSVG
                          src={LoadingSvg}
                          className="anticon-spin anticon myLoadingIcon"
                        />
                      ),
                    }
                  : false
              }
            />

            <Table
              style={{ marginTop: "40px" }}
              className="myFormTable"
              // rowKey={(record, index) => record.key}
              rowKey="md"
              dataSource={mdDataSource}
              columns={mdColumns}
              pagination={false}
            />
          </div>

          {!noBtn && (
            <div className="drawerFooterBtnBox btnBox">
              <Button
                className="noBg"
                onClick={() => {
                  handleCancel();
                }}
              >
                关闭
              </Button>
              <Button
                onClick={() => {
                  handleOk();
                }}
                style={{
                  background: "#006AB2",
                  color: "#fff",
                  borderColor: "#006AB2",
                }}
                disabled={
                  dataSource[0].dataKey.length === 0 &&
                  mdDataSource[0].dataKey.length === 0
                }
              >
                确定
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default wrapper(LineKeyDefinitionDrawer);
