import "./index.scss";
import { Drawer, Button, Table } from "antd";
import { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import HeaderTitle from "../../../../../components/HeaderTitle";
import Scrollbars from "react-custom-scrollbars";
import MyEmpty from "../../../../../components/Empty";

import MySpin from "../../../../../components/MySpin";

const DetailDrawer = (props) => {
  const {
    /* 关闭触发的回调 */
    onCancel,
    visible,
    /* 初始值 */
    defaultData,
    loading,
  } = props;

  const [apiIsHidden, setApiIsHidden] = useState(false);
  const [secretIsHidden, setSecretIsHidden] = useState(false);

  const handleCancel = () => {
    onCancel && onCancel();
  };
  const Columns = [
    {
      title: "服务名称",
      dataIndex: "chi_name",
    },
    {
      title: "调用量限制",
      dataIndex: "",
    },
    {
      title: "并发限制",
      dataIndex: "",
    },
  ];

  return (
    <Drawer
      title="详情"
      placement="right"
      width="600px"
      className="ApplicationDetailDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            className="noBg"
            onClick={() => {
              handleCancel();
            }}
          >
            关闭
          </Button>
        </div>
      }
    >
      {loading ? (
        <MySpin></MySpin>
      ) : (
        <Scrollbars
          autoHide={true}
          autoHideTimeout={5000}
          style={{
            height: `100%`,
            width: "100%",
          }}
        >
          <div className="detailsContent">
            <>
              <HeaderTitle title="基本信息"></HeaderTitle>
              <div className="detailsBox">
                <div className="detailItem">
                  <div className="detailKey">应用名称</div>
                  <div className="detailValue">{defaultData?.app_name}</div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">AppID</div>
                  <div className="detailValue">{defaultData?.app_id}</div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">API Key </div>
                  <div className="detailValue IsHidden">
                    <div>
                      {apiIsHidden ? defaultData?.api_key : "**************"}
                    </div>
                    <div
                      className="show"
                      onClick={() => setApiIsHidden(!apiIsHidden)}
                    >
                      {apiIsHidden ? "隐藏" : "显示"}
                    </div>
                  </div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">Secret Key </div>
                  <div className="detailValue IsHidden">
                    <div>
                      {secretIsHidden
                        ? defaultData?.secret_key
                        : "**************"}
                    </div>
                    <div
                      className="show"
                      onClick={() => setSecretIsHidden(!secretIsHidden)}
                    >
                      {secretIsHidden ? "隐藏" : "显示"}
                    </div>
                  </div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">创建时间 </div>
                  <div className="detailValue">{defaultData?.created_time}</div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">更新时间 </div>
                  <div className="detailValue">{defaultData?.updated_time}</div>
                </div>

                <div className="detailItem">
                  <div className="detailKey">描述 </div>
                  <div className="detailValue">{defaultData?.app_desc}</div>
                </div>
              </div>

              <HeaderTitle title="服务列表"></HeaderTitle>
              <div className="detailTable">
                <Table
                  className="myFormTable"
                  columns={Columns}
                  dataSource={defaultData?.api_pkg_ids}
                  bordered
                  pagination={false}
                  locale={{
                    emptyText: <MyEmpty></MyEmpty>,
                  }}
                  rowKey={(record) => Math.random()}
                />
              </div>
            </>
          </div>
        </Scrollbars>
      )}
    </Drawer>
  );
};

export default DetailDrawer;
