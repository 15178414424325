import "./index.scss";
import { Button, Drawer, Tabs, Input, Badge } from "antd";
import { CloseOutlined, CaretDownOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { useState } from "react";

const { TabPane } = Tabs;

const BtnBox = (props) => {
  return (
    <div className="drawerFooterBtnBox">
      <Button
        onClick={() => {
          props.onClose();
        }}
      >
        关闭
      </Button>
    </div>
  );
};

const data1 = [
  {
    id: 1,
    status: "待核查",
    type: "司法案件",
    personLiable: "张三",
    date: "2022-02-02",
    content: "(2020) 沪73知民初309号 超科林微电子设备有限公司!!!!!!!!!",
  },
  {
    id: 2,
    status: "需关注",
    type: "注册资本变更",
    personLiable: "张三",
    date: "2022-02-02",
    content: "(2020)",
  },
];

const data2 = [
  {
    id: 1,
    status: "有风险",
    type: "注册资本变更",
    personLiable: "张三",
    date: "2022-02-02",
    content: "(2020) 沪73知民初309号 超科林微电子设备有限公司!!!!!!!!!",
  },
  {
    id: 2,
    status: "需关注",
    type: "注册资本变更",
    personLiable: "张三",
    date: "2022-02-02",
    content: "(2020) 沪73知民初309号 超科林微电子设备有限公司!!!!!!!!!",
  },
];

const NoticeDrawer = (props) => {
  const [data, setData] = useState(data1);
  const [choiceKey, setChoiceKey] = useState();

  const [color, setColor] = useState("red");

  const tabs = ["未读", "全部"];

  const tabChange = (key) => {
    setChoiceKey(key);
    if (key == "未读") {
      setData(data1);
      setColor("red");
    } else {
      setData(data2);
      setColor("#0068B2");
    }
  };

  return (
    <Drawer
      className="noticeDrawer"
      width="600px"
      closable={false}
      title="通知提醒"
      placement="right"
      push={false}
      onClose={props.onClose}
      visible={props.visible}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={<BtnBox onClose={props.onClose}></BtnBox>}
    >
      <Tabs
        className="myTabs"
        onChange={tabChange}
        tabBarExtraContent={
          choiceKey == "未读" ? (
            <div className="typeSreach">全部标记为已读</div>
          ) : (
            <div className="typeSreach">清除所有已读消息</div>
          )
        }
      >
        {tabs.map((key) => (
          <TabPane tab={key} key={key}>
            <Scrollbars
              autoHide={true}
              autoHideTimeout={5000}
              style={{ height: "100%", width: "100%" }}
            >
              <div className="cardBox">
                {data.map((item, index) => (
                  <div className="cardItem" key={item.id}>
                    <Badge color={color} />
                    <div>
                      <div className="top">
                        <span>{item.content}</span>
                        <span className="active">
                          {item.personLiable + item.date + item.type}
                        </span>
                      </div>
                      <div className="bottom">
                        <div className="valueItem">
                          <div className="label">时间</div>
                          <div className="value">{item.date}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Scrollbars>
          </TabPane>
        ))}
      </Tabs>
    </Drawer>
  );
};

export default NoticeDrawer;
