import request from "axios";
import { useNavigate } from "react-router-dom";
import {
  getToken,
  setToken,
  getRedirectUrl,
  removeRedirectUrl,
} from "../../unit/index";

const Login = (props) => {
  const Navigate = useNavigate();
  let url = document.location.href;
  let ticket = "";
  if (/ticket=(ST-[^#/?]+)/.test(url)) {
    ticket = /ticket=(ST-[^#/?]+)/.exec(url)[1];
  }

  const previousUser = localStorage.getItem("previousUser"); // 获取上次登录的用户信息

  request
    .get(`${process.env["REACT_APP_BASE_URL_SYS"]}api/sys/login/`, {
      params: {
        ticket,
        redirect: url,
      },
    })
    .then((res) => {
      if (res.data.data.status === "redirect") {
        window.location.href = res.data.data.login_url;
      } else if (res.data.data.status === "login") {
        const token = res.data.data.token;
        setToken(token);

        const currentUser = getToken(); // 获取当前登录的用户信息

        if (previousUser && previousUser !== currentUser) {
          removeRedirectUrl(); // 清空上次登录用户的记录
        }

        localStorage.setItem("previousUser", currentUser); // 存储当前登录的用户信息

        if (getRedirectUrl()) {
          window.location.href = getRedirectUrl();
        } else {
          Navigate("/");
        }
      }
    });

  return <div></div>;
};

export default Login;
