import { useState, useEffect } from "react";
import { Layout, Select, Popconfirm, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./index.scss";
import logo from "../../../../assets/logo-g.png";
import logos from "../../../../assets/logo.png";
import { debounce } from "../../../../unit/unit";

import NoticeDrawer from "../../../../components/NoticeDrawer";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getToken, removeToken, setRedirectUrl } from "../../../../unit/index";
import UserApi from "../../../../apis/user/index";

const { Header } = Layout;

export function MyHeader(props) {
  const token = getToken();
  const location = useLocation();
  const { menuList, apiName, setApiName } = props;

  const [visible, setVisible] = useState(false);
  const [userOptions, setUserOptions] = useState([]); // 搜索下拉框选项
  const Navigate = useNavigate();
  const { Option } = Select;

  const queryParams = new URLSearchParams(location.search);
  const showName = queryParams.get("show_name");

  // 获取用户下拉框选项
  const searchUser = (keyword) => {
    const isAlphaNumeric = /^[a-z0-9]+$/.test(keyword); // 检查是否只包含小写字母或数字
    const info = menuList.filter((item) => {
      if (isAlphaNumeric && item.api_id) {
        return (
          item.api_id.includes(keyword) || item.menu_name.includes(keyword)
        );
      } else {
        return (
          item.menu_name.includes(keyword) || item.api_id.includes(keyword)
        );
      }
    });

    setUserOptions(info);
  };

  useEffect(() => {
    setUserOptions(menuList);
  }, [menuList]);

  useEffect(() => {
    if (!apiName) {
      setUserOptions(menuList);
    }
  }, [apiName]);

  return (
    <div className="headerContainer">
      <Header>
        <div className="headerLeft">
          <div className="logo">
            {<img src={logos} className="logoImg" alt="" />}
          </div>
          <div className="left-title">技术文档</div>
          <div
            className="left-value
          "
          >
            <Select
              allowClear
              showSearch
              suffixIcon={<SearchOutlined style={{ fontSize: "14px" }} />}
              onSearch={debounce((e) => {
                if (e !== "") {
                  searchUser(e);
                } else {
                  setUserOptions(menuList);
                }
              }, 500)}
              style={{ width: "248px" }}
              placeholder="请输入查询接口名称/接口ID"
              filterOption={false}
              value={apiName}
              onChange={(e) => setApiName(e)}
              onClear={(e) => setUserOptions(menuList)}
            >
              {userOptions?.map((data) => (
                <Option key={data.api_id} value={data.menu_name}>
                  {data.menu_name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <>
          <div className="ant-header-right">
            <img src={logo} alt="" />

            {!token ? (
              <a onClick={() => Navigate("/login")}>登录</a>
            ) : (
              <span className="sys-pepo">{showName}</span>
            )}
          </div>
        </>
      </Header>

      <NoticeDrawer
        visible={visible}
        onClose={() => setVisible(false)}
      ></NoticeDrawer>
    </div>
  );
}

export default MyHeader;
