import "./index.scss";
import { Tabs, message, Button, Select, Input } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import Scrollbars from "react-custom-scrollbars";
import ReleaseIcon from "../../../assets/icon_发布 1.svg";
import AddIcon from "../../../assets/+.svg";
import DropdownSvg from "../../../assets/下拉.svg";
import DataStandardTable from "../../../components/DataStandardTable";
import projectApi from "../../../apis/project";
import DialogDrawer from "../../../components/StandardDialogDrawer";
import ReleaseDrawer from "../../../components/ReleaseDrawer";
import { useGetState } from "ahooks";
import MessageModal from "../../../components/MessageModal";
import importIcon from "../../../assets/导入.svg";
import exportIcon from "../../../assets/导出.svg";
import ImportDrawer from "../../../components/ImportDrawer";
import { saveBlob } from "../../../unit/unit";
import downloadApi from "../../../apis/project/download.js";
const { TabPane } = Tabs;
const { Option } = Select;
const Subject = (props) => {
  const [orders, setOrders, getOrders] = useGetState([
    { order_name: "updated_time", order_by: "desc" },
  ]);
  const [activeKey, setActiveKey, getActiveKey] = useGetState("1");
  const [tableLoading, setTableLoading] = useState(true);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [addNew, setAddNew] = useState(true);
  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false);
  const [unpublishedList, setUnpublishedList] = useState([]);
  const [publishedList, setpublishedList] = useState([]);
  const [params, setParams, getParams] = useGetState({ page: 1, size: 10 });
  const [verson, setVerson] = useState(null);
  const [versonList, setVersonList] = useState([]);
  const [layerList, setLayerList] = useState([]);
  const [layer, setLayer] = useState(null);
  const [unlayer, setUnlayer] = useState(null);
  const [nextVerson, setNextVerson] = useState(null); // 发布标准版本号
  const [keyValue, setKeyValue] = useState(""); // 检索关键字

  const [ReleaseDrawerVisible, setReleaseDrawerVisible] = useState(false);
  const [addChild, setAddChild] = useState(false);

  const [standard, setStandard] = useState(false);
  const [versonStatusCode, setVersonStatusCode] = useState("");
  const [versonStatusList, setVersonStatusList] = useState({});

  const [importDialogVisible, setImportDialogVisible] = useState(false); // 导入主题域
  const [fileList, setFileList] = useState([]); //导入主题域
  const [importFile, setImportFile] = useState(false); // 正在进行导入操作判断

  const [checkList, setCheckList] = useState({});
  const ref = useRef({ verson, layer });
  const tableRef = useRef();

  const tabChange = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (activeKey === "1") {
      versonStatus();
      getLayerVersonList();
    } else if (activeKey === "2") {
      getLayerVersonList();
      getVersonList();
    }
  }, [getActiveKey()]);

  useEffect(() => {
    if (activeKey === "2" && verson !== null) {
      getCheckDetail(verson);
    }
  }, [verson]);

  // 获取标准版本
  const getVersonList = () => {
    projectApi
      .getVersonList(
        { dg_std_type: "SUB" },
        { orders: [{ order_name: "std_ver_id", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          setVersonList(res.data);
          if (res.data.total !== 0) {
            setVerson(res.data.items[0].ver_no);
          }
        } else {
          setVersonList([]);
        }
      });
  };
  // 获取数据分层标准版本
  const getLayerVersonList = () => {
    projectApi
      .getVersonList(
        { dg_std_type: "DATA_LAYER" },
        { orders: [{ order_name: "std_ver_id", order_by: "desc" }] }
      )
      .then((res) => {
        if (res.code === 200) {
          if (res.data.total !== 0) {
            getLayerPublishedList(res.data.items[0].ver_no);
          }
        }
      });
  };

  // 获取数据分层已发布标准
  const getLayerPublishedList = (id) => {
    projectApi
      .getLayerPublishedList({ ver_no: id, page: 1 }, { orders })
      .then((res) => {
        if (res.code === 200) {
          const data = res.data.items.map((item) => {
            return {
              data_layer_id: item.data_layer_id,
              data_layer_name: item.data_layer_name,
            };
          });
          let newArr = [];
          let arrId = [];
          for (let item of data) {
            if (arrId.indexOf(item["data_layer_id"]) === -1) {
              arrId.push(item["data_layer_id"]);
              newArr.push(item);
            }
          }
          setLayerList(newArr);
          if (newArr.length !== 0) {
            setUnlayer(newArr[0].data_layer_id);
            setLayer(newArr[0].data_layer_id);
          }
        } else {
          setLayerList([]);
        }
      });
  };

  // 版本选择
  const versonChange = (value) => {
    setVerson(value);
  };

  // 数据分层选择
  const layerChange = (value) => {
    setUnlayer(value);
  };

  // 获取未发布标准
  const getUnpublishedList = (id) => {
    let data = {
      data_layer_id: id,
      ...getParams(),
    };
    if (keyValue !== "") {
      data.sub_name = keyValue;
    }

    setTableLoading(true);
    projectApi
      .getSubUnpublishedList(data, { orders })
      .then((res) => {
        if (res.code === 200) {
          setUnpublishedList(res.data);
        } else {
          setUnpublishedList([]);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  // 获取已发布标准
  const getPublishedList = () => {
    setTableLoading(true);
    projectApi
      .getSubPublishedList(
        { ver_no: verson, data_layer_id: layer, ...getParams() },
        { orders }
      )
      .then((res) => {
        if (res.code === 200) {
          setpublishedList(res.data);
        } else {
          setpublishedList([]);
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  // 获取发布标准版本号
  const getNextVersonList = () => {
    projectApi.getNextVersonList({ dg_std_type: "SUB" }).then((res) => {
      if (res.code === 200) {
        setNextVerson(res.data);
        setReleaseDrawerVisible(true);
      } else {
        setNextVerson([]);
      }
    });
  };

  useEffect(() => {
    let { verson: prevA, layer: prevB } = ref.current;
    if (verson === null && layer === null) {
      setpublishedList([]);
      setTableLoading(false);
    }
    if (verson !== null && layer !== null) {
      ref.current = { verson, layer };
      getPublishedList();
    }
  }, [verson, layer]);

  useEffect(() => {
    if (unlayer !== null) getUnpublishedList(unlayer);
    if (unlayer === null) {
      setUnpublishedList([]);
      setTableLoading(false);
    }
  }, [unlayer]);

  // 排序
  const changeOrders = (data) => {
    const str = "_value";
    setOrders([
      {
        order_name: data.field.includes("_value")
          ? data.field.split(str).join("")
          : data.field,
        order_by: data.order === "descend" ? "desc" : "asc",
      },
    ]);
    setParams({ page: 1, size: 10 });
  };

  // 发布标准
  const releaseDrawerSuccess = (data) => {
    applyStandards({ wf_type_code: "SUB_RLS", ...data });
  };

  // 发布标准请求
  const applyStandards = (data) => {
    projectApi.applyStandards(data).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        setReleaseDrawerVisible(false);
        versonStatus();
      } else {
        message.error(res.message);
      }
    });
  };

  // 审核状态查询
  const versonStatus = () => {
    projectApi.versonStatus({ dg_wf_type_code: "SUB_RLS" }).then((res) => {
      if (res.code === 200) {
        setVersonStatusCode(res.data?.appr_stat_code);
        if (res.data?.appr_stat_code === "IN_APPROVAL") {
          setStandard(true);
        }
      } else if (
        res.data?.appr_stat_code === "IN_APPROVAL" &&
        unlayer !== null
      ) {
        getUnpublishedList(unlayer);
      } else {
        message.error(res.message);
      }
    });
  };

  // 新增主题域
  const subjectHandle = (item, addNew) => {
    setDialogDrawerVisible(true);
    setDrawerTitle("新增主题域");
    setAddNew(true);
    setAddChild(false);
  };

  // 新增主题域请求
  const dialogDrawerSuccess = (value) => {
    setDialogDrawerVisible(false);
    projectApi.AddSubStandard(value).then((res) => {
      if (res.code === 200) {
        message.success(res.message);
        getUnpublishedList(unlayer);
      } else {
        message.error(res.message);
      }
    });
  };

  // 更新主题域
  const updateDialogDrawer = () => {
    getUnpublishedList(unlayer);
  };

  // 改变分页数据
  const changePagination = (page, pageSize, id) => {
    setParams({ page: page, size: pageSize });
  };

  useEffect(() => {
    if (activeKey === "1" && unlayer !== null) {
      getUnpublishedList(unlayer);
    } else if (activeKey === "2" && verson !== null && layer !== null) {
      getPublishedList();
    }
  }, [params, keyValue, getOrders()]);

  // 点击发布标准
  const addStandard = () => {
    getNextVersonList();
    projectApi
      .versonStatusStatics({ dg_wf_type_code: "SUB_RLS" })
      .then((res) => {
        if (res.code === 200) {
          setVersonStatusList(res.data);
        } else {
        }
      });
  };

  // 获取审核详情
  const getCheckDetail = (id) => {
    projectApi
      .getCheckDetail({ ver_no: id, wf_type_code: "SUB_RLS" })
      .then((res) => {
        if (res.code === 200) {
          setCheckList(res.data);
        } else {
        }
      });
  };

  // 标准启用
  const operationEnable = (item) => {
    if (versonStatusCode !== "IN_APPROVAL") {
      MessageModal({
        type: "start",
        description: "确认要启用主题域吗？",
        title: "启用主题域",
        okText: "启用",
        onOk: () => {
          projectApi
            .operationEnable({
              oper_object_code: "SUB_RLS",
              id: item.sub_id,
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                getUnpublishedList(unlayer);
                if (Object.keys(tableRef.current.childInfo)?.length !== 0) {
                  tableRef.current.getChild(tableRef.current.childInfo);
                }
              } else {
                message.error(res.message);
              }
            });
        },
      });
    }
  };

  // 废弃标准
  const operationAbandon = (item) => {
    if (versonStatusCode !== "IN_APPROVAL") {
      MessageModal({
        type: "warning",
        description: "确认要废弃主题域吗？",
        title: "废弃主题域",
        okText: "废弃",
        onOk: () => {
          projectApi
            .operationAbandon({
              oper_object_code: "SUB_RLS",
              id: item.sub_id,
            })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                getUnpublishedList(unlayer);
                if (Object.keys(tableRef.current.childInfo)?.length !== 0) {
                  tableRef.current.getChild(tableRef.current.childInfo);
                }
              } else {
                message.error(res.message);
              }
            });
        },
      });
    }
  };

  // 删除标准
  const deleteItem = (item, childId) => {
    if (childId === undefined && versonStatusCode !== "IN_APPROVAL") {
      console.log(1);
      MessageModal({
        type: "warning",
        description: "确定要删除主题域吗？",
        title: "删除",
        okText: "删除",
        onOk: () => {
          projectApi
            .deleteSubStandard({ sub_ids: [item.sub_id] })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                setParams({ page: 1, size: 10 });
                tableRef.current.getChild(tableRef.current.childInfo);
              } else {
                message.error(res.message);
              }
            });
        },
      });
    } else if (versonStatusCode !== "IN_APPROVAL") {
      MessageModal({
        type: "warning",
        description: "确定要删除主题域吗？",
        title: "删除",
        okText: "删除",
        onOk: () => {
          projectApi
            .deleteSubStandard({ sub_ids: [item.sub_id, ...childId] })
            .then((res) => {
              if (res.code === 200) {
                message.success(res.message);
                setParams({ page: 1, size: 10 });
                tableRef.current.getChild(tableRef.current.childInfo);
              } else {
                message.error(res.message);
              }
            });
        },
      });
    }
  };

  // 导入主题域
  const uploadFileSuccess = (files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file?.originFileObj);
    });
    projectApi.importSubUnpublishedList(formData).then((res) => {
      if (res.code === 200) {
        setFileList([]);
        setImportDialogVisible(false);
        setImportFile(false);
        message.success(
          `成功${res.data.success_num}个，失败${res.data.error_num}个，忽略${res.data.skip_num}个`
        );
        getUnpublishedList();
      } else {
        message.error(res.message);
      }
    });
  };
  // 导出未发布标准
  const exportUnpublishedList = (value) => {
    downloadApi.exportSubUnpublishedList(value).then((blob) => {
      const filename = blob?.headers["content-disposition"].split("''")[1];
      const decodedFilename = decodeURIComponent(filename);
      saveBlob(blob.data, {
        fileName: decodedFilename,
        type: "application/vnd.ms-execel",
      });
    });
    // .catch(() => {
    //   message.error("导出失败");
    // });
  };

  // 导出已发布标准
  const exportPublishedList = (id) => {
    // projectApi
    //   .exportSubPublishedList(id)
    //   .then((blob) => {
    //     saveBlob(blob, {
    //       fileName: `数据标准-主题域标准-已发布标准-导出-${id}`,
    //       type: "application/vnd.ms-execel",
    //     });
    //   })
    //   .catch(() => {
    //     message.error("导出失败");
    //   });
  };

  return (
    <Scrollbars
      autoHide={true}
      autoHideTimeout={5000}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <div className="SubjectContent">
        <Tabs className="myTabs" onChange={tabChange} defaultActiveKey="1">
          <TabPane tab="未发布标准" key="1">
            <div className="noReleseInfo">
              {versonStatusCode === "IN_APPROVAL" ? (
                <div className="promptValue">
                  <InfoCircleFilled
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                    }}
                  />
                  <span>标准审核与发布中，暂不能进行修改</span>
                </div>
              ) : null}

              {versonStatusCode === "REJECTED" ? (
                <div className="promptValue">
                  <InfoCircleFilled
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                      color: "#FE545F",
                    }}
                  />
                  <span style={{ color: "#FE545F" }}>
                    标准审核被驳回，请修改后再重新发布
                  </span>
                </div>
              ) : null}

              <div className="info">
                <div className="selectInfo">
                  <div className="label">数据分层</div>
                  <div>
                    <Select
                      placeholder="全部"
                      showSearch
                      // allowClear
                      showArrow="true"
                      value={unlayer}
                      onChange={layerChange}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "238px", height: "28px" }}
                      onPopupScroll={(e) => {
                        console.log(e);
                      }}
                    >
                      {layerList?.map((item) => {
                        return (
                          <Option
                            value={item.data_layer_id}
                            key={item.data_layer_id}
                          >
                            {item.data_layer_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="inputInfo">
                    <Input
                      allowClear
                      placeholder="请输入查询主题域"
                      className="Input"
                      onPressEnter={(e) => setKeyValue(e.target.value)}
                      onChange={(e) => {
                        if (e.target.value === "") setKeyValue("");
                      }}
                    />
                  </div>
                </div>

                <div className="btnBox">
                  <Button
                    type="primary"
                    icon={<ReactSVG src={AddIcon} className="Icon"></ReactSVG>}
                    onClick={() => subjectHandle(true)}
                    disabled={standard}
                  >
                    新增
                  </Button>

                  <Button
                    type="primary"
                    icon={
                      <ReactSVG src={importIcon} className="Icon"></ReactSVG>
                    }
                    onClick={() => {
                      if (importFile) {
                        message.info("正在导入中，请勿重复操作!");
                      } else {
                        setImportDialogVisible(true);
                      }
                    }}
                    disabled={standard}
                  >
                    导入
                  </Button>
                  <Button
                    type="primary"
                    icon={
                      <ReactSVG src={exportIcon} className="Icon"></ReactSVG>
                    }
                    disabled={standard}
                    onClick={() => {
                      keyValue !== ""
                        ? exportUnpublishedList(keyValue)
                        : exportUnpublishedList();
                    }}
                  >
                    导出
                  </Button>

                  {versonStatusCode !== "REJECTED" ? (
                    <Button
                      type="primary"
                      icon={
                        <ReactSVG src={ReleaseIcon} className="Icon"></ReactSVG>
                      }
                      onClick={addStandard}
                      disabled={standard}
                    >
                      发布标准
                    </Button>
                  ) : null}

                  {versonStatusCode === "REJECTED" ? (
                    <Button
                      type="primary"
                      icon={
                        <ReactSVG src={ReleaseIcon} className="Icon"></ReactSVG>
                      }
                      onClick={addStandard}
                      disabled={standard}
                    >
                      重新发布
                    </Button>
                  ) : null}
                </div>
              </div>
              <div className="infoBox">
                <DataStandardTable
                  ref={tableRef}
                  defaultData={unpublishedList}
                  tableName="SubUnpublished"
                  changePagination={changePagination}
                  activeKey={getActiveKey()}
                  changeOrders={changeOrders}
                  versonStatusCode={versonStatusCode}
                  updateDialogDrawer={updateDialogDrawer}
                  operationEnable={operationEnable}
                  operationAbandon={operationAbandon}
                  deleteItem={deleteItem}
                  layerList={layerList}
                  tableLoading={tableLoading}
                  standard={standard}
                ></DataStandardTable>
              </div>
            </div>
          </TabPane>
          <TabPane tab="已发布标准" key="2">
            <div className="ReleseInfo">
              <div className="promptValue">
                <div className="selectInfo">
                  <div className="label">标准版本</div>
                  <div className="value" style={{ marginRight: "24px" }}>
                    <Select
                      placeholder="全部"
                      showSearch
                      // allowClear
                      showArrow="true"
                      onChange={versonChange}
                      value={verson}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "230px", height: "28px" }}
                    >
                      {versonList?.items?.map((item, index) => {
                        return (
                          <Option value={item.ver_no} key={index}>
                            {item.ver_no}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="label">数据分层</div>
                  <div>
                    <Select
                      placeholder="全部"
                      showSearch
                      // allowClear
                      value={layer}
                      showArrow="true"
                      onChange={(value) => setLayer(value)}
                      suffixIcon={
                        <ReactSVG
                          src={DropdownSvg}
                          style={{ width: "16px", height: "16px" }}
                        ></ReactSVG>
                      }
                      style={{ width: "230px", height: "28px" }}
                    >
                      {layerList?.map((item) => {
                        return (
                          <Option
                            value={item.data_layer_id}
                            key={item.data_layer_id}
                          >
                            {item.data_layer_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div className="detailItem">
                  <div className="detailKey">发布时间</div>
                  <div className="detailValue">{checkList?.apprd_time}</div>
                  <div className="detailKey">发布人</div>
                  <div className="detailValue">
                    {checkList?.apply_by_id_value}
                  </div>
                  <div className="detailKey">审核人</div>
                  <div className="detailValue">
                    {checkList?.apprd_by_id_value}
                  </div>
                  {/* <Button
                    type="primary"
                    icon={
                      <ReactSVG
                        src={exportIcon}
                        style={{ marginRight: "6px" }}
                      ></ReactSVG>
                    }
                    disabled={standard}
                    style={{ display: "flex" }}
                    onClick={() => exportPublishedList(verson)}
                  >
                    导出
                  </Button> */}
                </div>
              </div>
              <div className="infoBox">
                <DataStandardTable
                  activeKey={getActiveKey()}
                  changeOrders={changeOrders}
                  defaultData={publishedList}
                  tableName="SubUnpublished"
                  changePagination={changePagination}
                  flag={true}
                  tableLoading={tableLoading}
                ></DataStandardTable>
              </div>
            </div>
          </TabPane>
        </Tabs>

        <DialogDrawer
          title={drawerTitle}
          okText="确定"
          cancelText="取消"
          addNew={addNew}
          onOk={dialogDrawerSuccess}
          visible={DialogDrawerVisible}
          onCancel={() => {
            setDialogDrawerVisible(false);
          }}
          layerList={layerList}
          addChild={addChild}
        ></DialogDrawer>

        <ReleaseDrawer
          title="发布标准"
          onOk={releaseDrawerSuccess}
          visible={ReleaseDrawerVisible}
          onCancel={() => {
            setReleaseDrawerVisible(false);
          }}
          versonStatusList={versonStatusList}
          nextVerson={nextVerson}
        ></ReleaseDrawer>
        {/* <StandardDetailsDrawer
        title="发布详情"
        visible={setStandardDrawerVisible}
        onCancel={() => {
          setStandardDrawerVisible(false);
        }}
      ></StandardDetailsDrawer> */}

        <ImportDrawer
          okText="导入"
          onTitle="导入主题域"
          cancelText="取消"
          visible={importDialogVisible}
          onCancel={() => {
            setImportDialogVisible(false);
            setFileList([]);
          }}
          successFunc={uploadFileSuccess}
          fileList={fileList}
          setFileList={setFileList}
          setImportFile={setImportFile}
        ></ImportDrawer>
      </div>
    </Scrollbars>
  );
};
export default Subject;
