import "./index.scss";
import {
  Table,
  Pagination,
  Badge,
  message,
  Tabs,
  Button,
  Select,
  Input,
} from "antd";
import { useRef, useState, useEffect, forwardRef } from "react";
import { useSize } from "ahooks";
import moment from "moment";
import MyEmpty from "../../../../../components/Empty";
import projectApi from "../../../../../apis/project";
import MySpin from "../../../../../components/MySpin";
import LoadingSvg from "../../../../../assets/loading.svg";
import exportIcon from "../../../../../assets/导出.svg";
import { ReactSVG } from "react-svg";

const ModelStandardTable = (props) => {
  const {
    defaultData,
    changePagination,
    activeKey,
    changeOrders,
    tableLoading,
    setModelTableFlag,
  } = props;

  const refTable = useRef(null);
  const [columns, setColumns] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [defaultListData, setDdefaultListData] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [DialogDrawerVisible, setDialogDrawerVisible] = useState(false);

  const [addChild, setAddChild] = useState(false);
  const [addNew, setAddNew] = useState(false);

  const data = defaultData?.items;
  const size = useSize(refTable);

  useEffect(() => {
    setColumns([
      {
        title: "表中文名",
        dataIndex: "",
      },
      {
        title: "表名",
        dataIndex: "",
      },
      {
        title: "建议的命名",
        dataIndex: "",
      },
      {
        title: "当前版本",
        dataIndex: "",
      },
      {
        title: "数据分层",
        dataIndex: "",
      },
      {
        title: "主题域",
        dataIndex: "",
      },
      {
        title: "前后缀规范性检查",
        dataIndex: "",
      },
      {
        title: "词根规范性检查",
        dataIndex: "",
      },
    ]);

    setTotal(defaultData?.total);
  }, [defaultData]);

  // 分页
  const onChange = (page, pageSize) => {
    changePagination(page, pageSize, activeKey);
    setPageSize(pageSize);
    setPageNumber(page);
  };

  // 改变表格排序
  const tableChange = (pagination, filters, sorter, extra) => {
    if (sorter.order !== undefined) {
      changeOrders(sorter);
    }
  };

  return (
    <>
      <div className="ModelStandardTable">
        <div className="topValue">
          <div className="releaseContent">
            本次检查模型
            <span style={{ color: "#006AB2" }}>120</span>个 (其中： 符合规范
            <span style={{ color: "#61D4AD" }}>105</span>
            个， 不符合规范
            <span style={{ color: "#FE545F" }}>15</span>个）
          </div>
          <Button
            type="primary"
            icon={<ReactSVG src={exportIcon} className="Icon"></ReactSVG>}
          >
            导出
          </Button>
        </div>

        {tableLoading ? (
          <MySpin></MySpin>
        ) : (
          <div className="tableContent">
            <Table
              className="myFormTable"
              pagination={false}
              columns={columns}
              dataSource={data}
              // rowKey={(record, id) => id}
              rowKey={() => Math.random()}
              onChange={tableChange}
              scroll={{
                y: "670px",
                x: "100%",
              }}
              locale={{
                emptyText: <MyEmpty></MyEmpty>,
              }}
              style={{ marginBottom: "8px" }}
              loading={tableLoading}
            />
            {total >= 10 ? (
              <div className="paginationBox">
                <Pagination
                  className={
                    defaultData?.items?.length >= 10
                      ? "myPagination"
                      : "myPaginationSite"
                  }
                  showQuickJumper
                  total={total}
                  current={pageNumber}
                  pageSize={pageSize}
                  pageSizeOptions={[10, 20, 50, 100]}
                  onChange={onChange}
                  showTotal={(total) => `共 ${total} 条`}
                  showSizeChanger={true}
                />
              </div>
            ) : null}
          </div>
        )}

        <div className="drawerFooterBtnBox">
          <Button
            style={{
              background: "#fff",
            }}
            onClick={() => setModelTableFlag(false)}
          >
            关闭
          </Button>
        </div>
      </div>
    </>
  );
};

export default ModelStandardTable;
