import "./index.scss";
import { useEffect } from "react";
import {
  VerticalAlignBottomOutlined,
  CloudFilled,
  LinkOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import projectApi from "../../apis/project";
import { Drawer, Button, Form, Upload, message, Typography } from "antd";
import HeaderTitle from "../HeaderTitle";
import { CloseOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { saveBlob } from "../../unit/unit";

const { Dragger } = Upload;

const { Paragraph } = Typography;

const BtnBox = (props) => {
  return (
    <div className="drawerFooterBtnBox">
      <Button
        onClick={() => {
          props.onFinish();
          props.setImportFile(true);
        }}
        type="primary"
      >
        确定
      </Button>
      <Button
        className="noBg"
        onClick={() => {
          props.onClose();
        }}
      >
        关闭
      </Button>
    </div>
  );
};

const ImportDrawer = (props) => {
  const [form] = Form.useForm();
  const {
    /* 关闭触发的回调 */
    onCancel,
    /* 标题文字 */
    onTitle,
    /* 初始值 */
    defaultData,
    visible,
    setImportFile, // 正在进行导入操作判断
    fileList,
    setFileList,
  } = props;

  const handleCancel = () => {
    onCancel && onCancel();
  };

  useEffect(() => {
    form.resetFields();
  }, [defaultData]);

  const onFinish = () => {
    if (fileList.length === 0) {
      message.info("请至少上传一个文件");
      return;
    }
    props.successFunc && props.successFunc(fileList);
  };

  // 上传处理
  const fileChange = (info) => {
    if (info.file?.size / 1048576 > 10) {
      message.info("请上传10MB以下的文件!");
      return;
    }

    setFileList((prev) => {
      prev.push(info.file);
      return [...prev];
    });
  };

  // 下载模版
  const downloadTemplate = () => {
    if (onTitle === "导入词根") {
      projectApi
        .DownloadFile()
        .then((blob) => {
          saveBlob(blob, {
            fileName: "数据标准-词根标准-模版下载.xlsx",
            type: "application/vnd.ms-execel",
          });
        })
        .catch(() => {
          message.error("下载失败");
        });
    }
    if (onTitle === "导入数据元") {
      projectApi
        .DownloadEleFile()
        .then((blob) => {
          saveBlob(blob, {
            fileName: "数据标准-数据元标准-模版下载.xlsx",
            type: "application/vnd.ms-execel",
          });
        })
        .catch(() => {
          message.error("下载失败");
        });
    }
    if (onTitle === "导入主题域") {
      projectApi
        .DownloadSubFile()
        .then((blob) => {
          saveBlob(blob, {
            fileName: "数据标准-主题域标准-模版下载.xlsx",
            type: "application/vnd.ms-execel",
          });
        })
        .catch(() => {
          message.error("下载失败");
        });
    }
  };

  return (
    <Drawer
      title={onTitle}
      placement="right"
      width="600px"
      className="uploadFileDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <BtnBox
          onClose={props.onCancel}
          onFinish={onFinish}
          setImportFile={setImportFile}
        ></BtnBox>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <div className="dialogDrawerForm">
          <HeaderTitle title="上传"> </HeaderTitle>
          <div className="downLoadValue" onClick={() => downloadTemplate()}>
            <span style={{ cursor: "pointer" }}>下载导入模版</span>
            <VerticalAlignBottomOutlined
              style={{ fontSize: "16px", cursor: "pointer" }}
            />
          </div>

          <Dragger
            className="draggerBox"
            fileList={[]}
            name="file"
            multiple={true}
            action=""
            onChange={fileChange}
            // beforeUpload={(data) => {
            //   return false;
            // }}
            accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            <div className="uploadBox">
              <CloudFilled />
              <div className="text">
                将文件拖拽到此处或<span>点击上传</span>
              </div>
              <div className="fileTip">
                （只能上传xls/xlsx文件，且不超过10MB）
              </div>
            </div>
          </Dragger>

          {fileList.map((file, index) => (
            <div className="fileLineItem" key={Math.random()}>
              <div className="fileItem">
                <LinkOutlined />
                <Paragraph
                  ellipsis={{
                    rows: 1,
                    tooltip: file.name,
                    expandable: false,
                  }}
                  style={{
                    width: `500px`,
                    color: "#313131",
                  }}
                >
                  {file.name}
                </Paragraph>
              </div>
              <CloseCircleOutlined
                className="closeIcon"
                onClick={() => {
                  setFileList((prev) => {
                    prev = prev.filter((_) => {
                      return _.uid !== file.uid;
                    });
                    return [...prev];
                  });
                }}
              ></CloseCircleOutlined>
            </div>
          ))}
        </div>
      </Scrollbars>
    </Drawer>
  );
};

export default ImportDrawer;
